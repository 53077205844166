export const buildBrandFilter = brand => {
  return brand
    ? [{ field: 'Vendor', operator: 'eq', valuestring: brand }]
        .filter(x => x.valuestring)
        .reduce((a, c, i) => {
          let k = Object.keys(c)
          for (let z = 0; z < k.length; z++) a[`filters[${i}].${k[z]}`] = c[k[z]]

          return a
        }, {})
    : []
}
