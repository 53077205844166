import { useContext } from 'react'
import { ProductPickerContext, ProductPickerProvider } from './context'
import { productPickerPages } from './utils'
import { ProductList, SubCategories, Categories } from './components'
import { ProductView } from 'components/ProductView/ProductView'
import { pages } from 'pages'

const { CATEGORIES, SUBCATEGORIES, PRODUCT_LIST, PRODUCT_DETAILS } = productPickerPages

//  const { onAdd, practiceId, products, onFinish } = props
export const ProductPicker = props => {
  return (
    <ProductPickerProvider {...props}>
      <div className="select-product">
        <ProductPickerInner />
      </div>
    </ProductPickerProvider>
  )
}

export const ProductPickerInner = () => {
  const { actions, selectProduct } = useContext(ProductPickerContext)

  const render = () => {
    switch (selectProduct.subPage) {
      case CATEGORIES:
        return <Categories />
      case SUBCATEGORIES:
        return <SubCategories />
      case PRODUCT_LIST:
        return <ProductList />
      case PRODUCT_DETAILS:
        return (
          <ProductView
            isInOrder={false} // search through products here
            product={selectProduct.product}
            onAdd={actions.onAdd}
            // onUpdate={actions.onUpdate}
            // onDelete={actions.onDelete}
            onBack={() =>
              selectProduct?.subCategory
                ? actions.setSelectProduct({ ...selectProduct, subPage: productPickerPages.PRODUCT_LIST })
                : actions.setPage(pages.REVIEW)
            }
            onAddAnother={() =>
              actions.setSelectProduct({
                subPage: productPickerPages.CATEGORIES,
                product: null,
                subCategory: null,
                category: null,
              })
            }
          />
        )
      default:
        return null
    }
  }

  return render()
}
