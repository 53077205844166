import apiv2 from 'apiv2'

/**
 *
 * @param {Object} params
 * @param {string|number} params.medbotId
 * @param {Object} params.product
 * @param {number} params.product.quantity
 * @param {string|number} params.product.variantId
 * @param {string|null} params.product.doctorsMessage
 * @param {string} params.product.refillFrequency
 */
export const ADD_PRODUCT_TO_MEDBOT = async ({ product, medbotId }) => {
  const url = '/medbot/lineitems/add/:medbotId'.replace(':medbotId', medbotId)

  return await apiv2.post(url, {
    ...product,
    orderId: medbotId, ///yes this is correct
    isRecommendedAutoship: true,
    taxable: true,
  })
}
