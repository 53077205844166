import { createContext } from 'react'
import { useImmerReducer } from 'use-immer'
import { init, useProductPickerActions, ProductPickerReducer } from './ProductPickerReducer'

export const ProductPickerContext = createContext(init)

export const ProductPickerProvider = ({ children, onFinish, products, onAdd, practiceId }) => {
  const [state, dispatch] = useImmerReducer(ProductPickerReducer, init)
  const actions = useProductPickerActions(dispatch)

  return (
    <ProductPickerContext.Provider
      value={{
        ...state,
        products,
        practiceId,
        actions: { ...actions, onFinish, onAdd },
      }}
    >
      {children}
    </ProductPickerContext.Provider>
  )
}
