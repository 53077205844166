import { useNavigate } from 'react-router-dom'
import { Form } from 'react-final-form'

import { PracticeModal } from 'components'
import { Button, Loader } from 'components/Global'

import './AddPractice.scss'

import { useAddPractice } from './useAddPractice'

const AddPractice = () => {
  const nav = useNavigate()
  const { createPractice, loading } = useAddPractice()

  if (loading)
    return (
      <div className="practice-details--loading">
        <Loader />
      </div>
    )

  return (
    <div className="practices new">
      <Form
        onSubmit={vals => {
          createPractice(vals)
        }}
      >
        {({ handleSubmit }) => {
          return (
            <form className="new-practice-form" onSubmit={handleSubmit}>
              <PracticeModal.General add />
              {/* <PracticeModal.Fees add />
              <PracticeModal.Billing add cardError={cardError} />
              <div className="payments-emr-wrapper">
                <PracticeModal.Payments add />
              </div> */}
              <div className="action-buttons">
                <Button
                  onClick={e => {
                    e.preventDefault()
                    nav(-1)
                  }}
                >
                  Cancel
                </Button>
                <Button>Create Practice</Button>
              </div>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

export default AddPractice
