import { useFetch } from 'utilities'

export const useReportsV2 = (token, unauthorized) => ({
  /**
   * @example
   * reportsV2.salesSummary({
   *    // Query String Params:
   *    params: {
   *        practiceId: 0, //0 for admins
   *        page: 1,
   *        pagesize: 6,
   *        fromdate: YYYY-MM-DD,
   *        toDate: YYYY-MM-DD
   *    }
   * })
   * //returns
   * {
   *   salesList: [
   *        {
   *            practiceName: "Gulfcoast Eye Care",
   *            providerName: "First Last",
   *            salesCount: 1,
   *            totalNetSales: 0.000,
   *            medbotsSent: 18
   *            reportDate: "mm/dd/yy"
   *        },
   *        ...
   *   ],
   *    totalRows: 400,
   *    page: 1,
   *    pageSize: 6
   * }
   */
  salesSummary: useFetch({ method: 'GET', endpoint: '/ReportsV2/salessummary', unauthorized, token }),
  /**
   * @example
   * reportsV2.medbotsSentByUser({
   *    // Query String Params:
   *    params: {
   *        practiceId: 0, //0 for admins
   *        page: 1,
   *        pagesize: 6,
   *        fromdate: YYYY-MM-DD,
   *        toDate: YYYY-MM-DD
   *    }
   * })
   * // returns
   * {
   *   staffList: [
   *        {
   *            practiceName: "Alexander Orthopaedic Associates",
   *            providerFirstName: "Vladimir",
   *            providerLastName: "Alexander",
   *            medBotsSent: 1,
   *            sentDate: "2023-11-28T00:00:00",
   *            totalRows: 81
   *        },
   *        ...
   *   ],
   *    totalRows: 400,
   *    page: 1,
   *    pageSize: 6
   * }
   */
  medBotsSentByUser: useFetch({
    method: 'GET',
    endpoint: '/ReportsV2/medbotssentbystaff',
    unauthorized,
    token,
  }),
  /**
   * @example
   * reportsV2.productSummary({
   *    // Query String Params:
   *    params: {
   *        practiceId: 0, //0 for admins
   *        page: 1,
   *        pagesize: 6,
   *        fromdate: YYYY-MM-DD,
   *        toDate: YYYY-MM-DD
   *    }
   * })
   * // returns
   * {
   *   summaryList: [
   *        {
   *            practiceName: "Alexander Orthopaedic Associates",
   *            productName: "Bone Health",
   *            medbotsSent: 0,
   *            salesCount: 0,
   *            totalNetSales: 0,
   *            reportDate: "06/15/2023 00:00:00"
   *        },
   *        ...
   *   ],
   *    totalRows: 400,
   *    page: 1,
   *    pageSize: 6
   * }
   */
  productSummary: useFetch({
    method: 'GET',
    endpoint: '/ReportsV2/productsummary',
    unauthorized,
    token,
  }),
  /**
   * @example
   * reportsV2.wellnessStoreSummary({
   *    // Query String Params:
   *    params: {
   *        practiceId: 0, //0 for admins
   *        page: 1,
   *        pagesize: 6,
   *        fromdate: YYYY-MM-DD,
   *        toDate: YYYY-MM-DD
   *    }
   * })
   * // returns
   * {
   *    summaryList: [
   *        {
   *            practiceName: "Alexander Orthopaedic Associates",
   *            optInsCollected: 11,
   *            salesCount: 4,
   *            totalNetSales: 0.000,
   *            reportDate: "2023-11-28T00:00:00",
   *        },
   *        ...
   *   ],
   *    totalRows: 400,
   *    page: 1,
   *    pageSize: 6
   * }
   */
  wellnessStoreSummary: useFetch({
    method: 'GET',
    endpoint: '/ReportsV2/wellnessstoresummary',
    unauthorized,
    token,
  }),
})
