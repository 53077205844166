import { useMemo } from 'react'

const keys = {
  ADD_PROUCT: 'ADD_PRODUCT',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  VIEW_MEDBOT: 'VIEW_MEDBOT',
  NAME_BOT: 'NAME_BOT',
  VIEW_PRODUCT: 'VIEW_PRODUCT',
  RESET: 'RESET',
  SET_PRACTICE_ID: 'SET_PRACTICE_ID',
  /*
  ADD_DOCTOR: 'ADD_DOCTOR',
  DELETE_DOCTOR: 'DELETE_DOCTOR',
  */
}

export const init = {
  hasEdits: false,
  lineItems: [], //products in medbot
  medBotName: null,
  medbotId: null,
  practiceId: null, //important for creating medBots
  // doctors: [],
}

export const MedBotsReducer = (draft, { type, payload }) => {
  switch (type) {
    case keys.ADD_PROUCT:
      //payload = product
      draft.hasEdits = true
      return void (draft.lineItems = [...draft.lineItems, payload])
    case keys.DELETE_PRODUCT:
      //payload = productId
      draft.hasEdits = true
      return void (draft.lineItems = draft.lineItems.filter(prod => prod.productId !== payload))
    case keys.UPDATE_PRODUCT:
      //payload = newProduct
      draft.hasEdits = true
      return void (draft.lineItems = draft.lineItems.map(prod =>
        prod.productId === payload.productId ? payload : prod
      ))
    case keys.VIEW_MEDBOT:
      //payload = medbot
      return { ...payload, hasEdits: false }
    case keys.NAME_BOT:
      // payload = name
      draft.hasEdits = true
      return void (draft.medBotName = payload)
    case keys.SET_PRACTICE_ID:
      return void (draft.practiceId = payload)
    /*
    case keys.ADD_DOCTOR:
      //payload = {DoctorId: 224, Name: 'Albert Snape'}
      draft.hasEdits = true
      return void (draft.doctors = [...draft.doctors, payload])
    case keys.DELETE_DOCTOR:
      ///payload = doctorId
      draft.hasEdits = true
      return void (draft.doctors = [...draft.doctors.filter(doc => doc.doctorId !== payload)])
      */
    case keys.RESET:
      return init
    default:
      return draft
  }
}

export const useMedBotActions = dispatch =>
  useMemo(
    () => ({
      /* 
      addDoctor: doctorObj => dispatch({ type: keys.ADD_DOCTOR, payload: doctorObj }),
      deleteDoctor: doctorId => dispatch({ type: keys.DELETE_DOCTOR, payload: doctorId }),
      */
      addProduct: product => dispatch({ type: keys.ADD_PROUCT, payload: product }),
      deleteProduct: productId => dispatch({ type: keys.DELETE_PRODUCT, payload: productId }),
      updateProduct: newProduct => dispatch({ type: keys.UPDATE_PRODUCT, payload: newProduct }),
      viewMedBot: medbot => dispatch({ type: keys.VIEW_MEDBOT, payload: medbot }),
      nameBot: name => dispatch({ type: keys.NAME_BOT, payload: name }),
      reset: () => dispatch({ type: keys.RESET }),
      setPracticeId: practiceId => dispatch({ type: keys.SET_PRACTICE_ID, payload: practiceId }),
    }),
    [dispatch]
  )
