import dayjs from 'dayjs'
import { useMemo } from 'react'

const keys = {
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_PRACTICE_ID: 'SET_PRACTICE_ID',
  RESET: 'RESET',
  GENERATE: 'GENERATE',
  SET_PAGE: 'SET_PAEG',
  SET_TOTAL_ROWS: 'SET_TOTAL_ROWS',
}

export const init = {
  fromdate: null,
  toDate: null,
  practiceId: 0,
  page: 1,
  pageSize: 20,
  totalRows: 0,
}

export const ReportsReducer = (draft, { type, payload }) => {
  switch (type) {
    case keys.SET_START_DATE:
      return void (draft.fromdate = payload)
    case keys.SET_END_DATE:
      return void (draft.toDate = payload)
    case keys.SET_PRACTICE_ID:
      return void (draft.practiceId = payload)
    case keys.GENERATE:
      draft.fromdate = payload.fromdate
      draft.toDate = payload.toDate
      draft.practiceId = payload.practiceId
      return draft
    case keys.SET_PAGE:
      return void (draft.page = payload)
    case keys.SET_TOTAL_ROWS:
      return void (draft.totalRows = payload)
    case keys.RESET:
      return init
    default:
      return draft
  }
}

const formatDate = date => dayjs(date).format('YYYY-MM-DD')

export const useReportsActions = dispatch =>
  useMemo(
    () => ({
      setStartDate: date => dispatch({ type: keys.SET_START_DATE, payload: formatDate(date) }),
      setEndDate: date => dispatch({ payload: formatDate(date), type: keys.SET_END_DATE }),
      setPracticeId: id => dispatch({ payload: id, type: keys.SET_PRACTICE_ID }),
      setPage: page => dispatch({ payload: page, type: keys.SET_PAGE }),
      setTotalRows: totalRows => dispatch({ payload: totalRows, type: keys.SET_TOTAL_ROWS }),
      generate: ({ startDate, endDate, practiceId }) =>
        dispatch({
          payload: {
            fromdate: formatDate(startDate),
            toDate: formatDate(endDate),
            practiceId: practiceId ?? 0,
          },
          type: keys.GENERATE,
        }),
    }),
    [dispatch]
  )
