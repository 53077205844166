export const api = {
  API_URL: process.env.REACT_APP_API_URL,
  AUTH_URL: process.env.REACT_APP_AUTHENTICATION_API_URL,
}

export const stripe = {
  KEY: process.env.REACT_APP_STRIPE_PUB_KEY,
}

export const FLYER_URL = process.env.REACT_APP_FLYER_URL

export const orderStatuses = {
  AUTHORIZED: 'Authorized',
  READY: 'Ready To Ship',
  PURCHASED: 'Packaging Order',
  SHIPPING: 'Order Shipped',
  DELIVERED: 'Delivered',
}

export const sortByKeys = {
  first: 'FirstName',
  last: 'LastName',
  email: 'Email',
}

export const States = [
  { name: 'ALABAMA', abbr: 'AL' },
  { name: 'ALASKA', abbr: 'AK' },
  { name: 'AMERICAN SAMOA', abbr: 'AS' },
  { name: 'ARIZONA', abbr: 'AZ' },
  { name: 'ARKANSAS', abbr: 'AR' },
  { name: 'CALIFORNIA', abbr: 'CA' },
  { name: 'COLORADO', abbr: 'CO' },
  { name: 'CONNECTICUT', abbr: 'CT' },
  { name: 'DELAWARE', abbr: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', abbr: 'DC' },
  { name: 'FEDERATED STATES OF MICRONESIA', abbr: 'FM' },
  { name: 'FLORIDA', abbr: 'FL' },
  { name: 'GEORGIA', abbr: 'GA' },
  { name: 'GUAM', abbr: 'GU' },
  { name: 'HAWAII', abbr: 'HI' },
  { name: 'IDAHO', abbr: 'ID' },
  { name: 'ILLINOIS', abbr: 'IL' },
  { name: 'INDIANA', abbr: 'IN' },
  { name: 'IOWA', abbr: 'IA' },
  { name: 'KANSAS', abbr: 'KS' },
  { name: 'KENTUCKY', abbr: 'KY' },
  { name: 'LOUISIANA', abbr: 'LA' },
  { name: 'MAINE', abbr: 'ME' },
  { name: 'MARSHALL ISLANDS', abbr: 'MH' },
  { name: 'MARYLAND', abbr: 'MD' },
  { name: 'MASSACHUSETTS', abbr: 'MA' },
  { name: 'MICHIGAN', abbr: 'MI' },
  { name: 'MINNESOTA', abbr: 'MN' },
  { name: 'MISSISSIPPI', abbr: 'MS' },
  { name: 'MISSOURI', abbr: 'MO' },
  { name: 'MONTANA', abbr: 'MT' },
  { name: 'NEBRASKA', abbr: 'NE' },
  { name: 'NEVADA', abbr: 'NV' },
  { name: 'NEW HAMPSHIRE', abbr: 'NH' },
  { name: 'NEW JERSEY', abbr: 'NJ' },
  { name: 'NEW MEXICO', abbr: 'NM' },
  { name: 'NEW YORK', abbr: 'NY' },
  { name: 'NORTH CAROLINA', abbr: 'NC' },
  { name: 'NORTH DAKOTA', abbr: 'ND' },
  { name: 'NORTHERN MARIANA ISLANDS', abbr: 'MP' },
  { name: 'OHIO', abbr: 'OH' },
  { name: 'OKLAHOMA', abbr: 'OK' },
  { name: 'OREGON', abbr: 'OR' },
  { name: 'PALAU', abbr: 'PW' },
  { name: 'PENNSYLVANIA', abbr: 'PA' },
  { name: 'PUERTO RICO', abbr: 'PR' },
  { name: 'RHODE ISLAND', abbr: 'RI' },
  { name: 'SOUTH CAROLINA', abbr: 'SC' },
  { name: 'SOUTH DAKOTA', abbr: 'SD' },
  { name: 'TENNESSEE', abbr: 'TN' },
  { name: 'TEXAS', abbr: 'TX' },
  { name: 'UTAH', abbr: 'UT' },
  { name: 'VERMONT', abbr: 'VT' },
  { name: 'VIRGIN ISLANDS', abbr: 'VI' },
  { name: 'VIRGINIA', abbr: 'VA' },
  { name: 'WASHINGTON', abbr: 'WA' },
  { name: 'WEST VIRGINIA', abbr: 'WV' },
  { name: 'WISCONSIN', abbr: 'WI' },
  { name: 'WYOMING', abbr: 'WY' },
]

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const emptyCart = {
  LineItems: [],
  subtotal: 0,
  doctorUserId: null,
  doctor: { t3ID: null },
  practiceId: null,
  id: 0,
  medbotId: null,
  isLessExpensiveList: false,
  // isChanged: false, // need this to be true to  verify
}

export const customQueryKeys = {
  DASHBOARD: 'DASHBOARD',
  ANALYTICS: 'ANALYTICS',
  ANALYTICS_MEDBOTS: 'ANALYTICS_MEDBOTS',
  ANALYTICS_REV: 'ANALYTICS_REV',
  PRODUCTS_TABLE: ' PRODUCTS_TABLE',
  PRODUCTS: 'PRODUCTS',
  PRACTICE_DETAILS: 'PRACTICE_DETAILS',
  ORDER_DETAILS: 'ORDER_DETAILS',
  ORDERS: 'ORDERS',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
}

export const defaultStaleTime = 120000

export const userRoles = {
  DOCTOR: 'doctor',
  STAFF: 'staff',
  STAFFTIER2: 'stafftier2',
}

export const providerTypes = {
  ARNP: 'ARNP',
  PA: 'PA',
  DOCTOR: 'doctor',
}
