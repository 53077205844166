import { useContext, useState } from 'react'
import './Preferences.scss'
import { MainContext } from 'context'

const useTogglePreferences = () => {
  const {
    patient: { user, actions, api },
  } = useContext(MainContext)
  const [variables] = useState({ patientId: user?.patientId })
  const [state, setState] = useState({
    email: user?.emailNotifications,
    sms: user?.smsNotifications,
  })

  const [msg, setMsg] = useState(null)

  const success = data => () => {
    setMsg('Notification preferences updated!')
    actions?.setUser({ ...user, ...data })
  }

  const onError = () => {
    setMsg('Could not update preferences')
    setState({
      email: user?.emailNotifications,
      sms: user?.smsNotificaitons,
    })
  }

  const submit = () => {
    state.sms !== user?.smsNotificaitons && toggleSMS({ booleanBody: !state.sms })
    state.email !== user?.emailNotifications && toggleEmail({ booleanBody: !state.email })
  }

  const { mutate: toggleSMS, isLoading } = api.toggleSMSPreferences({
    variables,
    queryOptions: {
      onSuccess: success({ smsNotificaitons: state.sms }),
      onError,
    },
  })

  const { mutate: toggleEmail, isLoading: emailLoading } = api.toggleEmailPreferences({
    variables,
    queryOptions: {
      onSuccess: success({ emailNotifications: state.email }),
      onError,
    },
  })

  return {
    isLoading: isLoading || emailLoading,
    state,
    setState,
    submit,
    msg,
  }
}

export default useTogglePreferences
