import { AUTHORIZE_CONFIG } from './config'
import axios from 'axios'

/**
 * @param {Object} params
 * @param {String} params.customerProfileId
 * @param {String} params.customerPaymentProfileId
 *
 * {@link https://developer.authorize.net/api/reference/index.html#customer-profiles-delete-customer-payment-profile}
 */
export const DELETE_AUTHORIZE_CUSTOMER_PAYMENT_PROFILE = async params => {
  return await axios
    .post(AUTHORIZE_CONFIG.url, {
      deleteCustomerPaymentProfileRequest: {
        merchantAuthentication: {
          name: AUTHORIZE_CONFIG.name,
          transactionKey: AUTHORIZE_CONFIG.transactionKey,
        },
        customerProfileId: params.customerProfileId,
        customerPaymentProfileId: params.customerPaymentProfileId,
      },
    })
    .then(res => {
      if (res.data.messages.resultCode === 'Error') {
        throw new Error(res.data.messages?.message?.[0]?.text || 'unknown error occured')
      }
      return res
    })
}
