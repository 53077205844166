import NumberFormat from 'react-number-format'

export const Date = ({ onChange, ...rest }) => (
  <NumberFormat
    {...rest}
    format="####-##-##"
    allowNegative={false}
    mask={['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D']}
    onValueChange={({ formattedValue }) => onChange(formattedValue)}
  />
)

export const BirthDate = ({ onChange, ...rest }) => (
  <NumberFormat
    {...rest}
    format="##/##/####"
    allowNegative={false}
    placeholder={'MM/DD/YYYY'}
    mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
    onValueChange={({ formattedValue }) => onChange(formattedValue)}
  />
)

export const Zip = ({ onChange, ...rest }) => (
  <NumberFormat {...rest} format={'#####'} allowNegative={false} onValueChange={({ value }) => onChange(value)} />
)

export const CardNumber = ({ onChange, ...rest }) => (
  <NumberFormat
    {...rest}
    onValueChange={({ value }) => onChange(`${value}`)}
    format={'#### #### #### ####'}
    allowNegative={false}
  />
)

export const ExpireDate = ({ onChange, ...rest }) => (
  <NumberFormat
    {...rest}
    format={'##/##'}
    placeholder="MM/YY"
    allowNegative={false}
    onValueChange={({ formattedValue }) => onChange(formattedValue)}
  />
)

export const Cvc = ({ onChange, ...rest }) => (
  <NumberFormat {...rest} format={'####'} placeholder="123" onValueChange={({ value }) => onChange(value)} />
)
export const Money = ({ onChange, ...rest }) => (
  <NumberFormat
    {...rest}
    prefix="$"
    placeholder="$"
    decimalScale={2}
    fixedDecimalScale
    onValueChange={({ value }) => onChange(value)}
  />
)

export const Routing = ({ onChange, ...rest }) => (
  <NumberFormat {...rest} allowLeadingZeros={true} type="password" onValueChange={({ value }) => onChange(value)} />
)

export const Phone = ({ onChange, ...rest }) => (
  <NumberFormat {...rest} format={'(###)###-####'} onValueChange={({ value }) => onChange(value)} />
)

export const PhoneFormatTwo = ({ onChange, ...rest }) => (
  <NumberFormat {...rest} format={'###-###-####'} onValueChange={({ value }) => onChange(value)} />
)

export const Percent = ({ onChange, ...rest }) => (
  <NumberFormat {...rest} suffix={'%'} decimalScale={2} onValueChange={({ value }) => onChange(value)} />
)

export const PhoneNumberWithUSAContryCode = ({ onChange, ...rest }) => (
  <NumberFormat {...rest} format={'+1 (###) ### ####'} onValueChange={({ value }) => onChange(value)} />
)
