import { createContext, useContext, useMemo, useState } from 'react'

const PatientMenuContext = createContext('open') //open, opening, closed, closing

export const PatientMenuProvider = ({ children }) => {
  const [state, setState] = useState('open')

  const toggleMenu = () => setState(state === 'open' || state === 'opening' ? 'closing' : 'opening')

  const isOpen = useMemo(() => state === 'open' || state === 'opening', [state])

  return (
    <PatientMenuContext.Provider value={{ state, setState, toggleMenu, isOpen }}>
      {children}
    </PatientMenuContext.Provider>
  )
}

export const usePatientMenu = () => useContext(PatientMenuContext)
