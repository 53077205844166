import { MainContext } from 'context'
import _ from 'lodash'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useReportsState } from '../../context'

export const useReport = ({ admin, reportType, ...rest }) =>
  admin.api.reportsV2[_.camelCase(reportType.replaceAll('_', ''))]({ ...rest })

export const useReportData = () => {
  const { admin } = useContext(MainContext)
  const { reportType } = useParams()
  const { actions, ...state } = useReportsState()

  return useReport({
    admin,
    reportType,
    params: {
      practiceId: admin.user.practiceId || state.practiceId || 0,
      page: state.page,
      pagesize: state.pageSize,
      fromdate: state.fromdate,
      toDate: state.toDate,
    },
    queryOptions: {
      enabled: !!state?.fromdate && !!state?.toDate,
      cacheTime: 0,
      onSuccess: data => {
        actions.setTotalRows(data?.totalRows)
      },
    },
  })
}
