import { PracticeModal } from 'components'
import { EditButton } from 'components/Global'
import { MainContext } from 'context'
import { useContext, useMemo } from 'react'
import { customQueryKeys } from 'utilities/constants'
import { useModals, usePracticeAvatar } from 'utilities/hooks'
import { AccountStatus } from './AccountStatus'

export const GeneralInfo = ({ id, data }) => {
  const { admin } = useContext(MainContext)
  const { success, openErrorModal } = useModals()

  const isAdmin = useMemo(() => admin?.user?.role?.includes('admin'), [admin?.user])

  const { postAvatar, avatar } = usePracticeAvatar({
    onSuccess: success('Practice updated', ['avatars', 'practice', 'practiceId', id]),
    onError: err => {
      let avatarError = err?.toString()?.includes('The Avatar Width or Height has exceeded the maximum 300 x 300')
      openErrorModal(
        avatarError
          ? 'The avatar width or height has exceeded the maximum 300 x 300.'
          : 'There was an error uploading the logo.'
      )
    },
    practiceId: id,
    keys: [customQueryKeys.PRACTICE_DETAILS],
  })

  return (
    <div className="detail-tile detail-tile--general">
      <div className="detail-tile__header">
        <h4 className="detail-tile__title detail-tile__title--main">Practice Info</h4>
        <EditButton modal={<PracticeModal.General postAvatar={postAvatar} avatar={avatar} id={id} data={data} />} />
      </div>
      <div className="detail-tile__data">
        <div className="detail-tile__practice-logo">
          {avatar ? <img src={avatar} alt="practice-logo" /> : <span className="no-img">No Image Available</span>}
        </div>

        <div className="detail-tile__data--general--without-payments-billing">
          <div className="practice-title--with-activation-button">
            <h5 className="detail-tile__data-title">{data?.name}</h5>
          </div>
          {isAdmin ? (
            <AccountStatus inGeneralSection />
          ) : (
            <div className="detail-tile__data-item detail-tile__data-item--fill" />
          )}
          <div className="detail-tile__data-item detail-tile__data-item--address">
            <h6 className="detail-tile__data-sub-title">Address</h6>
            <p>{data?.address1 || 'None'}</p>
            <p>
              {data?.city}, {data?.state} {data?.zip}
            </p>
          </div>
          <div className="detail-tile__data-item detail-tile__data-item--phone">
            <h6 className="detail-tile__data-sub-title">Phone Number</h6>
            <p>{data?.phoneNumber || 'None'}</p>
          </div>
          {/* <div className="detail-tile__data-item detail-tile__data-item--fax">
          <h6 className="detail-tile__data-sub-title">Fax Number</h6>
          <p>{data?.fax || 'None'}</p>
        </div> */}
          <div className="detail-tile__data-item detail-tile__data-item--wbsite">
            <h6 className="detail-tile__data-sub-title">Website</h6>
            <p>{data?.website || 'None'}</p>
          </div>
          {/* <div className="detail-tile__data-item detail-tile__data-item--email">
          <h6 className="detail-tile__data-sub-title">Email</h6>
          <p>{data?.email || 'None'}</p>
        </div> */}
          <div className="detail-tile__header">
            <h4 className="detail-tile__title">Admin Contact Info</h4>
          </div>
          <div className="detail-tile__data-item detail-tile__data-item--admin-email">
            <h6 className="detail-tile__data-sub-title">Email</h6>
            <p>{data?.physicianAdminContactEmail || 'None'}</p>
          </div>
          <div className="detail-tile__data-item detail-tile__data-item--admin-phone">
            <h6 className="detail-tile__data-sub-title">Phone Number</h6>
            <p>{data?.physicianAdminContactPhone || 'None'}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
