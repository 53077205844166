import { Table } from 'components'
import dayjs from 'dayjs'
import { useMemo } from 'react'

const columns = [
  { Header: 'PRACTICE', accessor: 'practiceName', unsortable: true },
  { Header: 'PRODUCT', accessor: 'productName', unsortable: true },
  { Header: 'MEDBOTS SENT', accessor: 'medbotsSent', unsortable: true },
  { Header: 'PURCHASED', accessor: 'salesCount', unsortable: true },
  { Header: 'TOTAL SALES', accessor: 'totalNetSales', unsortable: true },
  { Header: 'SALES DATE', accessor: 'reportDate', unsortable: true },
]

const ProductSummary = ({ data, isLoading, error }) => {
  const productData = useMemo(
    () =>
      data?.summaryList?.map(item => ({
        ...item,
        totalNetSales: `$${item.totalNetSales?.toFixed(2)}`,
        reportDate: dayjs(item.reportDate).format('MM/DD/YYYY'),
      })),
    [data]
  )

  return <Table loading={isLoading} error={error} data={{ columns, data: productData || [] }} />
}

export default ProductSummary
