import { Button, DatePicker } from 'components'
import { Form } from 'react-final-form'
import dayjs from 'dayjs'
import { useContext, useMemo } from 'react'
import SearchableDropdown from './SearchableDropdown'
import { useReportsState } from '../../context'
import { MainContext } from 'context'

export const Filters = () => {
  const { actions, ...state } = useReportsState()
  const { admin } = useContext(MainContext)

  const initialValues = useMemo(
    () => ({
      startDate: dayjs().subtract(1, 'month').format('MM-01-YYYY'),
      endDate: dayjs().format('MM-01-YYYY'),
      practiceId: state?.practiceId,
    }),
    [state]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={v => {
        const end = dayjs(v.endDate)
        const start = dayjs(v.startDate)
        if (end.isBefore(start)) {
          return ['Start date should come before end date.']
        }
      }}
      onSubmit={v => actions.generate(v)}
      render={({ handleSubmit, form }) => {
        const { errors } = form.getState()

        return (
          <>
            <form onSubmit={handleSubmit}>
              <div className="date-filters">
                <DatePicker
                  format={v => dayjs(v).isValid() && dayjs(v).format('MM/DD/YYYY')}
                  name="startDate"
                  label="Start Date"
                  autoComplete={false}
                />
                <span>to</span>
                <DatePicker
                  format={v => dayjs(v).isValid() && dayjs(v).format('MM/DD/YYYY')}
                  name="endDate"
                  label="End Date"
                  autoComplete={false}
                />
              </div>

              {admin?.user?.role?.includes('admin') && (
                <SearchableDropdown className="practice-searchable-dropdown" name="practiceId" label="Practices" />
              )}
            </form>
            {!!Object.keys(errors).length && (
              <div className="reports-view__sub-header--error-note">
                <span role="note" className="error">
                  Start date should come before end date.
                </span>
              </div>
            )}

            <Button type="submit" className={'reports-view__sub-header--generate fade-in'} onClick={handleSubmit}>
              Generate Report
            </Button>
          </>
        )
      }}
    />
  )
}
