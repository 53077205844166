import { useState } from 'react'
import { EyeHideIcon, EyeShowIcon } from 'assets/vector'
import './Password.scss'
import { useSvgButton } from 'utilities/hooks'

const ToggleEye = ({ onClick, isHidden }) => {
  const hide = useSvgButton(
      <EyeHideIcon type="button" onClick={onClick} className="showhide-eye" role="img" aria-label="hide content eye" />
    ),
    show = useSvgButton(
      <EyeShowIcon type="button" onClick={onClick} className="showhide-eye" role="img" aria-label="show content eye" />
    )
  return isHidden ? hide : show
}

const Password = ({ input, rest }) => {
  const [hide, setHide] = useState(true)
  const hidable = rest?.hidable ?? true

  return (
    <div className={`password-wrapper ${hidable ? '' : 'password-wrapper--no-hide'}`}>
      <input
        {...input}
        // {...rest}
        type={hide ? 'password' : 'text'}
        className={(rest?.className || '') + 'password-field'}
      />
      {hidable ? <ToggleEye onClick={() => setHide(hide => !hide)} isHidden={hide} /> : null}
    </div>
  )
}

export default Password
