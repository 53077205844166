import Lottie from 'react-lottie-player'
import { alertLottieData } from 'assets/lottie'
import { Button } from 'components/Global'

import './AlertModal.scss'

export const AlertModal = ({
  title,
  message,
  support,
  buttonText,
  cancelOrder,
  onClick,
  confirmText,
  onConfirm,
  onDeny,
  denyText,
  children,
  refundProduct,
  closeModal,
  displayActionsGrid,
  admin,
  addThanks,
  isAdmin,
  redConfirm = false,
  ...rest
}) => {
  return (
    <div
      {...rest}
      aria-describedby="alert-modal-message"
      className={`modal-content alert-modal alert-modal__wrapper ${
        isAdmin || admin ? 'alert-modal--admin' : 'alert-modal--patient'
      } ${refundProduct ? 'alert-modal__refund' : ''}`}
    >
      <Lottie
        role="img"
        aria-label="Alert Animation"
        play
        loop={false}
        animationData={alertLottieData}
        className="alert-modal__lottie"
      />
      {title && <h2 className="alert-modal__title">{title}</h2>}
      <p id="alert-modal-message" className="alert-modal__message">
        {message}
      </p>
      {addThanks && <p style={{ fontWeight: '600' }}>Thank you!</p>}
      {/* {support ? (
        <div className="alert-modal alert-modal__contact">
          <p className="alert-modal alert-modal__contact-message">contact us at</p>
          <p className="alert-modal alert-modal__contact-number">(555)555-5555</p>
        </div>
      ) : null} */}
      {children}
      <div className={`alert-modal alert-modal__actions ${displayActionsGrid ? 'rows' : 'columns'}`}>
        {denyText || onDeny ? (
          <Button autoFocus className="deny" onClick={onDeny || (() => closeModal())}>
            {denyText}
          </Button>
        ) : null}
        <Button
          autoFocus={!denyText && !onDeny}
          tabIndex={0}
          className={`confirm ${redConfirm ? 'red' : ''}`}
          onClick={onClick || onConfirm || (() => closeModal())}
        >
          {buttonText || confirmText || 'Okay'}
        </Button>
      </div>
    </div>
  )
}
