import { useMemo } from 'react'
import { headerSorts, pages, types } from '../constants'
import { backPage } from './utils'

const keys = {
  SET_TYPE: 'SET_TYPE',
  SET_PROVIDER: 'SET_PROVIDER',
  SET_PATIENT: 'SET_PATIENT',
  SET_PAGE: 'SET_PAGE',
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_MEDBOT: 'SET_MEDBOT',
  RESET: 'RESET',
  SET_SORT: 'SET_SORT',
  BACK: 'BACK',
  SET_SELECT_PRODUCT: 'SET_SELECT_PRODUCT',
  REVIEW_PRODUCT: 'REVIEW_PRODUCT',
}

export const createInit = {
  type: types.MEDBOT,
  provider: null,
  products: [],
  medbot: null,
  patient: null,
  page: pages.SELECT_MEDBOT,
  sortListBy: { letter: null, keyword: '', asc: false, header: null },
}

export const CreateOrderReducer = (draft, { type, payload }) => {
  switch (type) {
    case keys.SET_TYPE:
      const page = payload === types.MEDBOT ? pages.SELECT_MEDBOT : pages.SELECT_PROVIDER
      return {
        ...createInit,
        type: payload,
        page,
        sortListBy: { ...createInit.sortListBy, header: headerSorts[page] },
      }
    case keys.SET_PROVIDER:
      return void (draft.provider = payload)
    case keys.SET_PATIENT:
      // draft.page = pages.REVIEW
      return void (draft.patient = payload)
    case keys.SET_PAGE:
      const keyword = payload === pages.SELECT_PATIENT ? draft.sortListBy.keyword : ''
      draft.sortListBy = { asc: false, keyword, letter: null, header: headerSorts[payload] }
      return void (draft.page = payload)
    case keys.SET_MEDBOT:
      return void (draft.medbot = payload)
    case keys.SET_PRODUCTS:
      return void (draft.products = payload)
    case keys.RESET:
      return { ...createInit, type: draft.type }
    case keys.BACK:
      return void (draft.page = backPage(draft))
    case keys.SET_SORT:
      if (payload?.letter === draft.sortListBy.letter) {
        return void (draft.sortListBy = { ...draft.sortListBy, ...payload, letter: null })
      }
      return void (draft.sortListBy = { ...draft.sortListBy, ...payload })
    case keys.SET_SELECT_PRODUCT:
      return void (draft.selectProduct = { ...draft.selectProduct, ...payload })
    case keys.REVIEW_PRODUCT:
      // draft.page = pages.PRODUCT_VIEW
      return void (draft.selectProduct = {
        ...draft.selectProduct,
        product: payload,
      })
    default:
      return draft
  }
}

export const useCreateOrderActions = dispatch =>
  useMemo(
    () => ({
      reviewProduct: product => dispatch({ type: keys.REVIEW_PRODUCT, payload: product }),
      back: () => dispatch({ type: keys.BACK }),
      reset: () => dispatch({ type: keys.RESET }),
      setType: type => dispatch({ type: keys.SET_TYPE, payload: type }),
      setProvider: provider => dispatch({ type: keys.SET_PROVIDER, payload: provider }),
      setPatient: patient => dispatch({ type: keys.SET_PATIENT, payload: patient }),
      setPage: page => dispatch({ type: keys.SET_PAGE, payload: page }),
      setMedbot: medbot => dispatch({ type: keys.SET_MEDBOT, payload: medbot }),
      setProducts: products => dispatch({ type: keys.SET_PRODUCTS, payload: products }),
      setSortListBy: sortOptions => dispatch({ type: keys.SET_SORT, payload: sortOptions }),
    }),
    [dispatch]
  )
