import api from 'apiv2'
import { useEffect } from 'react'

export const requestIntercept = token => async config => {
  try {
    // if (!config?.headers) throw new Error('No headers on the request')
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
  } catch (e) {
    return config
  }
}

export const responseSuccess = async res => {
  return res
}

export const responseError = logout => async error => {
  if (error?.response?.status === 401) {
    logout?.()
  }
  throw error
}

const AxiosInterceptors = ({ children, token, logout }) => {
  useEffect(() => {
    const reqInterceptor = api.interceptors.request.use(requestIntercept(token))

    const resInterceptor = api.interceptors.response.use(responseSuccess, responseError(logout))
    return () => {
      api.interceptors.request.eject(reqInterceptor)
      api.interceptors.response.eject(resInterceptor)
    }
  }, [logout, token])

  return children
}

export default AxiosInterceptors
