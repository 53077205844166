import { setMarker } from 'pages/Admin/CreateOrder/utils'
import useGetPatients from './useGetPatients'
import { Button, Loader, Search } from 'components'
import { AlphaListHeader } from 'components/AlphabeticList'
import { PatientItem } from './PatientItem'
import './PatientList.scss'
import useSortLisBy from '../useSortListBy'
import { useEffect, useMemo } from 'react'
import { ChevronRightIcon } from 'assets/vector'

export const BlockedPatientList = ({
  practiceId = null,
  onSelectPatient,
  onBlockAPatient,
  blockedPatients = false,
}) => {
  const [sortListBy, setSortListBy, reset] = useSortLisBy()
  const { paginate, data, isLoading, error } = useGetPatients(sortListBy, blockedPatients, practiceId)

  useEffect(() => {
    reset()
  }, [reset])

  const search = keyword => {
    paginate.setPage(1)
    setSortListBy({ asc: false, keyword: keyword?.keyword?.replace(/\\/g, '') || '' })
  }

  const mapPatients = () => {
    let prevPatient = null
    return isLoading ? (
      <Loader />
    ) : (
      data?.map((patient, i) => {
        let addTab = setMarker(prevPatient, patient.lastName)
        prevPatient = patient.lastName
        return <PatientItem key={i} addTab={addTab} onSelectPatient={onSelectPatient} patient={patient} />
      })
    )
  }

  const columns = useMemo(
    () =>
      [
        {
          header: 'Patient Name',
          sortable: true,
          onClick: () => setSortListBy({ asc: !sortListBy.asc, header: 'Patient Name' }),
        },
        { header: 'DOB', sortable: false },
        { header: 'Blocked Because', sortable: false },
      ].filter(item => (blockedPatients ? true : item.header !== 'Blocked Because')),
    [setSortListBy, sortListBy, blockedPatients]
  )

  return (
    <>
      <div className="blocked-patients-wrapper">
        <div className="blocked-patients-search">
          <Search
            onSubmit={search}
            placeholder={`Seach ${blockedPatients ? 'Blocked' : ''} Patients`}
            name={'keyword'}
          />
          {blockedPatients && (
            <Button
              disabled={!practiceId}
              onClick={onBlockAPatient}
              className={'blocked-patients-search__buttons--block-a-patient-button'}
            >
              Block A Patient
            </Button>
          )}
        </div>
        <div style={{ height: '10px' }} />
        {/* <AlphaSearch setSortListBy={setSortListBy} sortListBy={sortListBy} /> */}
        <AlphaListHeader
          sortListBy={sortListBy}
          className={`blocked-patients-${blockedPatients ? 'blocked' : 'unblocked'}`}
          columns={columns}
        />
        <ol className="create-order-page__alphabetic-list blocked-patients-list">
          {!practiceId ? (
            <li className="blocked-patients-error__no-practice-id">Please choose a practice to view its patients.</li>
          ) : error ? (
            <li className="blocked-patients-error">There was an error</li>
          ) : (
            mapPatients()
          )}
        </ol>
      </div>
      <Paginate data={data} {...paginate} />
    </>
  )
}

const Paginate = ({ data, pagesize, setPage, page }) => {
  const prevShow = useMemo(() => page > 1 && !!data?.length, [page, data])
  const nextShow = useMemo(() => data?.length === pagesize, [pagesize, data])

  return (
    <div className="blocked-patients-pagination">
      <button
        disabled={!prevShow}
        className={`blocked-patients-pagination__prev-btn ${prevShow ? 'show' : 'hide'}`}
        onClick={() => setPage(page > 1 ? page - 1 : 1)}
      >
        <ChevronRightIcon className="flip" />
        Previous
      </button>

      <div className={`blocked-patients-pagination__page-actual ${data?.length || page > 1 ? 'show' : 'hide'}`}>
        <span>{page}</span>
      </div>

      <button
        disabled={!nextShow}
        className={`blocked-patients-pagination__next-btn ${nextShow ? 'show' : 'hide'}`}
        onClick={() => setPage(page + 1)}
      >
        Next
        <ChevronRightIcon />
      </button>
    </div>
  )
}
