import './SelectReportType.scss'
import { useNavigate } from 'react-router-dom'

const types = [
  'Sales Summary',
  'Product Summary',
  'MedBots Sent By User',
  // 'MedBots Sent By Practice and Provider',
  // 'MedBot Sales By Practice and Provider',
  'Wellness Store Summary',
]

export const SelectReportType = () => {
  const mapTypes = () => types.map((type, i) => <ReportTypeCard type={type} key={i} index={i + 1} />)

  return (
    <>
      <h4>Choose A Report</h4>
      <div className="reports-select-type">{mapTypes()}</div>
    </>
  )
}

const ReportTypeCard = ({ type, index }) => {
  const nav = useNavigate()

  return (
    <button onClick={() => nav(type.replaceAll(' ', '_'))} className={`reports-select-type__card pos__${index}`}>
      <h5>
        <span>{type}</span>
      </h5>
    </button>
  )
}
