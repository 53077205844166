import { useState, useEffect } from 'react'
import { CheckmarkOpenIcon, CheckmarkCheckedIcon } from 'assets/vector/index'
import './Reasons.scss'

export const Reasons = ({ selected, reasons, passReasonsUp, setOther = null }) => {
  const [text, setText] = useState('')
  const showTextArea = selected?.includes('Other')

  const renderReasons = reasons => {
    return reasons?.map((reason, i) => {
      return <Reason selected={selected} setSelectedReasons={passReasonsUp} text={reason} key={i} />
    })
  }

  useEffect(() => {
    /* clear other reason if they unselect it */
    showTextArea && setOther ? setOther?.(text) : setOther?.('')
  }, [showTextArea, text, setOther])

  return (
    <div className={`reasons-wrap`}>
      {renderReasons(reasons)}
      {setOther && (
        <textarea
          maxLength={150}
          className={`${showTextArea ? 'visible' : 'invisible'}`}
          onChange={e => setText(e.target.value)}
        ></textarea>
      )}
    </div>
  )
}

////
const Reason = ({ text, selected, setSelectedReasons }) => {
  const addReason = () => setSelectedReasons(text)
  const removeReason = () => setSelectedReasons('')
  const chosen = selected === text

  return (
    <div className="reason">
      {!chosen ? (
        <button id="unbtn">
          <CheckmarkOpenIcon onClick={addReason} className="checkmark" />
        </button>
      ) : (
        <button id="unbtn">
          <CheckmarkCheckedIcon onClick={removeReason} className="checkmark" />
        </button>
      )}
      <span>{text}</span>
    </div>
  )
}
