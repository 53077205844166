import { MedBotsProvider } from './context'
import { Outlet } from 'react-router-dom'
import { AllMedBots, View, ProductDetails, ProductSelection } from './components'
import './MedBots.scss'
import { Namer } from './components/Namer'
import { useContext } from 'react'
import { MainContext } from 'context'
import { AddDoctors } from './components/AddDoctors'

const MedBots = () => {
  const { admin } = useContext(MainContext)

  return (
    <MedBotsProvider practiceId={admin.user?.practiceId}>
      <div className="medbots-wrapper">
        <Outlet />
      </div>
    </MedBotsProvider>
  )
}

export const MedBotsPages = {
  Base: MedBots,
  AllMedBots,
  View,
  ProductDetails,
  CreateName: Namer,
  SelectProducts: ProductSelection,
  AddDoctors,
}
