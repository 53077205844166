import { useCallback, useContext } from 'react'
import { CreateOrderContext, useCreateOrderNav } from '../../context'
import './SelectPatient.scss'
import { PaginatedPatientList } from 'components/AlphabeticList'

export const SelectPatient = () => {
  const nav = useCreateOrderNav()
  const { actions, provider, sortListBy } = useContext(CreateOrderContext)

  const onSelectPatient = useCallback(
    patient => {
      actions.setPatient(patient)
      nav(`review`)
    },
    [actions, nav]
  )

  return (
    <>
      {/* <UpgradeEmr show={cannotConnect && !isLoading && !error} /> */}
      <PaginatedPatientList
        initSearchKeyword={sortListBy.keyword}
        onSelectPatient={onSelectPatient}
        practiceId={provider?.practiceId}
      />
    </>
  )
}
