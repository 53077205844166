export const types = { MEDBOT: 'MEDBOT', SINGLE: 'SINGLE' }

export const pages = {
  SELECT_MEDBOT: 'SELECT_MEDBOT',
  SELECT_PROVIDER: 'SELECT_PROVIDER',
  SELECT_PRODUCT: 'SELECT_PRODUCT',
  SELECT_PATIENT: 'SELECT_PATIENT',
  PRODUCT_VIEW: 'PRODUCT_VIEW',
  REVIEW: 'REVIEW',
}

//titles = {SELECT_MEDBOT: select medbot, ....}
// export const titles = Object.values(pages).reduce(
//   (acc, page) => ({ ...acc, [page]: page?.split('_')?.join(' ').toLowerCase() }),
//   {}
// )

export const titles = {
  select: 'Select Medbot',
  provider: 'Select Provider',
  product: 'Select Product',
  patient: 'Select Patient',

  review: 'Review',
}

export const alphabet = Array.from(Array(26))
  .map((e, i) => i + 65)
  .map(x => String.fromCharCode(x))

export const headerSorts = {
  SELECT_MEDBOT: 'Medbot Name',
  SELECT_PROVIDER: 'Provider Name',
  SELECT_PRODUCT: null,
  SELECT_PATIENT: 'Patient Last Name',
  REVIEW: null,
}
