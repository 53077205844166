import { AlertModal, Button, SuccessModal } from 'components'
import { useCallback, useContext } from 'react'
import { MedBotsContext } from '../../context'
import { MainContext } from 'context'
import { useNavigate } from 'react-router-dom'
import { Loading } from './View'

export const RedButton = ({ edit }) => {
  const { modal, admin } = useContext(MainContext)
  const { actions, medbotId } = useContext(MedBotsContext)
  const nav = useNavigate()

  const { mutate, isLoading } = admin.api.createEditMedbots.deleteMedbot({
    variables: { medbotId },
    queryOptions: {
      onSuccess: () =>
        modal.actions.open(
          <SuccessModal
            title="MedBot deleted!"
            onClick={() => {
              nav('/admin/medbots')
              modal.actions.close()
            }}
          />
        ),
      onError: () => modal.actions.open(<AlertModal message={'There was an error deleting the MedBot.'} />),
    },
  })

  const onDelete = useCallback(
    () =>
      modal.actions.open(
        <AlertModal
          message="Are you sure you want to delete this MedBot?"
          denyText="No"
          onDeny={modal.actions.close}
          onConfirm={() => {
            modal.actions.close()
            mutate()
          }}
        />
      ),
    [modal?.actions, mutate]
  )

  const onCancel = useCallback(
    () =>
      modal.actions.open(
        <AlertModal
          message="Are you sure you want to cancel?"
          onDeny={modal.actions.close}
          denyText={'No'}
          onConfirm={() => {
            actions.reset()
            modal.actions.close()
            nav('/admin/medbots')
          }}
        />
      ),
    [actions, modal, nav]
  )

  return (
    <>
      <Loading isLoading={isLoading} />
      <Button onClick={edit ? onDelete : onCancel} className={'medbots-view__action-buttons--cancel'}>
        {edit ? 'Delete MedBot' : 'Cancel'}
      </Button>
    </>
  )
}
