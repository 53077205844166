import { LeftArrowInBlueCircleIcon } from 'assets/vector'
import './StoreBackButton.scss'
import { useCallback } from 'react'
import { useNavWithState } from 'utilities/Patient'

export const StoreBackButton = ({ backTo = null, text = 'BACK' }) => {
  const { navWithState } = useNavWithState()

  const handleClick = useCallback(() => navWithState(backTo ? backTo : -1), [navWithState, backTo])

  return (
    <button onClick={handleClick} className="store-back-button">
      <LeftArrowInBlueCircleIcon role="button" />
      <p>{text}</p>
    </button>
  )
}
