import { useContext, useMemo } from 'react'
import { ProductTableContext } from '../context/ProductsTableContext'

export const Filter = ({ img, isLoading, name }) => {
  const { collection, actions } = useContext(ProductTableContext)

  const shouldDim = useMemo(() => name !== collection && collection !== null, [collection, name])

  return (
    <button onClick={() => !isLoading && actions.setCollection(name)} className={`category ${shouldDim ? 'dim' : ''}`}>
      <img src={img} alt="product" />
    </button>
  )
}

export const SubFilter = ({ name, isLoading }) => {
  const { sub, actions } = useContext(ProductTableContext)

  const shouldDim = useMemo(() => name !== sub && sub !== null, [name, sub])

  return (
    <button onClick={() => !isLoading && actions.setSub(name)} className={`sub-category ${shouldDim ? 'dim' : ''}`}>
      <span>{name}</span>
      <div className="img"></div>
    </button>
  )
}

export * from './hooks'
export * from './FilterByBrand'
