import { Field } from 'react-final-form'
import { MultiField } from 'components/Global'

/*
from the server we get specialties = string[]
we need to format for react final form {specialtyName: [specialtyName] }
*/
export const formatSpecialties = specialties =>
  specialties?.reduce?.((a, c) => ({ ...a, [c.specialty]: [c.specialty] }), {})

export const onSubmit = ({ values, getState, isAdmin, buildBody, edit, user }) => {
  const { avatar, ...vals } = values
  const {
    modified: { avatar: avatarMod, ...others },
  } = getState()

  let mods = Object?.values?.(others)?.includes?.(true)
  let body = buildBody(isAdmin, vals)
  let isValid = true

  if (edit) {
    if (user?.role === 'doctor') {
      isValid = validateProvider({ body, user, avatar })
    }
  }

  return { body, isValid, isModified: mods, avatarMod, avatar }
}

function validateProvider({ body, user, avatar }) {
  if ((body?.specialties?.length && avatar) || (body?.specialties?.length && user?.avatar)) {
    return true
  }
  return false
}

export const MapMultiField = ({ required, name, className, label, fields, type, parse, format }) => {
  if (!fields.length) return null

  const mapFields = fields =>
    fields.map((field, i) => {
      return (
        <Field
          key={i}
          type={type}
          className={`multi-field__item ${className || ''} ${field}`}
          component="input"
          label={field}
          value={field}
          parse={parse && parse}
          format={format && format}
        />
      )
    })

  return (
    <MultiField required={required} name={name} className={className} label={label}>
      {mapFields(fields)}
    </MultiField>
  )
}
