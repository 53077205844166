import { createContext, useContext, useMemo } from 'react'
import { useImmerReducer } from 'use-immer'
import paymentReducer, { initPaymentState, usePaymentReducer } from './PaymentReducer'

export const PaymentContext = createContext(initPaymentState)

export const PaymentProvider = ({ children, data, isLoading }) => {
  const [state, dispatch] = useImmerReducer(paymentReducer, initPaymentState)
  const actions = usePaymentReducer(dispatch)

  const value = useMemo(
    () => ({ ...state, data, isLoading: isLoading || state.isLoading, actions }),
    [state, data, isLoading, actions]
  )

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
}

export const usePaymentState = () => useContext(PaymentContext)
