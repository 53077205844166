import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useContext, useMemo } from 'react'
import { MainContext } from 'context'
import { useOrder } from 'pages/RecommendationPortal/CurrentOrder/useOrder'
import './PaymentForm.scss'
import { Spinner } from 'pages/RecommendationPortal/Spinner'
import { PaymentProvider } from './context'

export const PaymentFormV2 = () => {
  const { patient } = useContext(MainContext)
  const { isLoading, orderId } = useOrder()
  const { pathname } = useLocation()

  const isValidPathname = useMemo(() => pathname.includes('shipping') || pathname.includes('checkout'), [pathname])
  const blockScreenForLoading = useMemo(() => !!patient?.order && isLoading, [isLoading, patient?.order])

  if (!isValidPathname) return <Navigate replace to={`/order/${patient.order.id}/payment/shipping`} />
  if (!isLoading && patient.order.status !== 'draft order') return <Navigate replace to={`/order/${orderId}`} />

  return (
    <PaymentProvider isLoading={isLoading} data={patient.order}>
      {blockScreenForLoading ? (
        <Spinner />
      ) : (
        <div className="payment-form-wrapper">
          <Outlet />
        </div>
      )}
    </PaymentProvider>
  )
}
