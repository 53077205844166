import { useSvgButton } from 'utilities/hooks'
import { BackButtonIcon } from 'assets/vector'

export const PageHeader = ({ title, onBack = null }) => {
  return (
    <div className="medbots-header">
      <h4 className="medbots-header__title">{title}</h4>
      {useSvgButton(
        <BackButtonIcon
          style={{ display: !onBack ? 'none' : 'block' }}
          className={`medbots-header__back-button`}
          onClick={onBack}
        />
      )}
    </div>
  )
}
