import { Reasons } from 'pages'
import { AlertModal } from '../Alert'
import { useState } from 'react'

const reasons = ['Patient chooses no contact', 'Unhappy patient', 'Patient has left practice']

export const BlockPatientReason = ({ message, onConfirm, closeModal }) => {
  const [reason, setReason] = useState()

  return (
    <AlertModal
      message={message}
      onConfirm={() => onConfirm(reason)}
      onDeny={closeModal}
      isAdmin
      confirmText={'Block'}
      denyText={'Back'}
      style={{ width: 'max-content' }}
    >
      <Reasons selected={reason} reasons={reasons} passReasonsUp={reason => setReason(reason)} />
    </AlertModal>
  )
}
