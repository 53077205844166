import { createContext } from 'react'
import { useImmerReducer } from 'use-immer'
import { createInit, useCreateOrderActions, CreateOrderReducer } from './CreateOrderReducer'

export const CreateOrderContext = createContext(createInit)

export const CreateOrderProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(CreateOrderReducer, createInit)
  const actions = useCreateOrderActions(dispatch)

  return <CreateOrderContext.Provider value={{ ...state, actions }}>{children}</CreateOrderContext.Provider>
}
