import { useContext, useEffect } from 'react'
import { MainContext } from 'context'
import { useParamsToObject } from 'utilities/hooks'
import { useLocation } from 'react-router-dom'
import { LOCATION_STATE_MODES } from 'utilities/Patient/constants'

export const useDocAvatar = () => {
  const { patient } = useContext(MainContext)
  const { doctorId } = useParamsToObject()

  const { data } = patient?.api?.getDocAvatar({
    variables: { doctorId: patient?.order?.doctorId || doctorId },
    queryOptions: {
      enabled: !!patient?.order?.doctorId || !!doctorId,
      onSuccess: res => {
        patient.actions.setBubbleOpen(true)
        patient.actions.setBubbleProps({ image: res })
      },
    },
  })

  return { data }
}

export const useBubbleTitle = () => {
  const {
    patient: { actions, order },
  } = useContext(MainContext)
  const { state } = useLocation()

  useEffect(() => {
    if (!order?.doctorLastName) return
    actions.setBubbleOpen(true)

    if (order?.doctorFirstName === 'Convenience Store' || state?.mode === LOCATION_STATE_MODES.CONVENIENCE_STORE) {
      actions.setBubbleProps({ title: `Hi, it's ${order?.practiceName}` })
      return
    }

    const type = order?.providerType
    const docFull = `${order?.doctorFirstName} ${order?.doctorLastName}`
    const docLast = order?.doctorLastName

    switch (type?.toLowerCase()) {
      case 'doctor':
        return actions.setBubbleProps({ title: `Hi, it's Dr. ${docLast}!` })
      default:
        return actions.setBubbleProps({ title: `Hi, it's ${docFull}, ${type}!` })
    }
  }, [order, state, actions])
}
