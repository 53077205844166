import { confettiLottieData, medtrixCheckFlipLottieData } from 'assets/lottie'
import { Button } from 'components/Global'
import { useState } from 'react'
import Lottie from 'react-lottie-player'
import './SuccessModal.scss'

const SuccessModal = ({ title, message, buttonText, onClick, closeModal, ...rest }) => {
  const [lottie, setLottie] = useState(true)
  const { admin } = rest

  return (
    <div {...rest} className={`modal-content modal-content--success ${!admin ? 'patient' : ''}`}>
      {admin && (
        <Lottie play loop={false} animationData={medtrixCheckFlipLottieData} style={{ width: 300, height: 300 }} />
      )}
      {title ? <h3 className="modal-content__title modal-content__title--success">{title}</h3> : null}
      {message ? <p className="modal-content__message modal-content__message--success">{message}</p> : null}
      <Button className={`continue-button ${admin ? 'admin' : 'patient'}`} onClick={onClick || (() => closeModal())}>
        {buttonText || 'Continue'}
      </Button>
      {lottie ? (
        <div className="lottie__wrapper lottie__wrapper--success">
          <Lottie
            animationData={confettiLottieData}
            play
            loop={false}
            onComplete={() => {
              setLottie(false)
            }}
            className={'lottie lottie--success'}
            style={{ width: '100%' }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default SuccessModal
