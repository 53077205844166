import './CustomLoader.scss'
import Lottie from 'react-lottie-player'
import { threeDotsLoadingData } from 'assets/lottie'

export const CustomLoader = () => (
  <div className="current-order-loading">
    <span>We are gathering the</span>
    <span>medical products</span>
    <span>recommended for you.</span>
    <Lottie className="current-order-loading__lottie" play loop animationData={threeDotsLoadingData} />
  </div>
)
