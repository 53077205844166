import { Loader, Search } from 'components'
import { useCallback } from 'react'
import { setMarker } from 'pages/Admin/CreateOrder/utils'
import { AlphaListHeader, AlphaSearch } from 'components/AlphabeticList'
import { useGetProviders } from './useGetProviders'
import { ProviderItem } from './ProviderItem'
import './ProviderList.scss'
import useSortLisBy from '../useSortListBy'

export const ProviderList = ({ onSelectProvider }) => {
  const [sortListBy, setSortListBy] = useSortLisBy()
  const { data, isLoading, error } = useGetProviders(sortListBy)

  const search = keyword => setSortListBy({ keyword: keyword?.keyword?.replace(/\\/g, '') || '' })

  const mapItems = useCallback(() => {
    let prevProvider = null
    return isLoading ? (
      <Loader />
    ) : error ? (
      <p className="create-order-page__alphabetic-list--error-msg">There was an error</p>
    ) : (
      data?.map((provider, i) => {
        const nameSplit = provider.name.split(' ')
        const lastName = nameSplit[nameSplit.length - 1]
        let addTab = setMarker(prevProvider, lastName)
        prevProvider = lastName
        return <ProviderItem onSelectProvider={onSelectProvider} key={i} addTab={addTab} provider={provider} />
      })
    )
  }, [data, isLoading, error, onSelectProvider])

  return (
    <div className="alphabetic-list-wrapper select-provider-wrapper">
      <Search onSubmit={search} placeholder={'Search Provider'} name={'keyword'} />
      <AlphaSearch setSortListBy={setSortListBy} sortListBy={sortListBy} />
      <AlphaListHeader
        sortListBy={sortListBy}
        className={'select-provider'}
        columns={[
          {
            header: 'Provider Name',
            sortable: true,
            onClick: () => setSortListBy({ asc: !sortListBy.asc, header: 'Provider Name' }),
          },
          {
            header: 'Provider Type',
            sortable: false,
          },
        ]}
      />
      <ol className="create-order-page__alphabetic-list select-provider-list">{mapItems()}</ol>
    </div>
  )
}
