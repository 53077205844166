import { useMemo } from 'react'

import { usePaymentState } from '../context'

export const CostSummary = () => {
  const { totals, data } = usePaymentState()

  return totals ? <Totals totals={totals} data={data} /> : <Subtotal data={data} />
}

export const Totals = () => {
  const { totals, data } = usePaymentState()

  const shipping = totals.shipping || 0
  const subtotal = totals.subtotalPrice || data.totalPrice || 0
  const tax = totals.tax || 0
  const totalPrice = totals.totalPrice
  const totalBeforeTax = totalPrice - tax

  const itemsInCart = useMemo(() => data?.LineItems?.reduce((acc, cur) => (acc += cur?.quantity), 0), [data?.LineItems])

  return (
    <div className={`cost-breakdown cost-breakdown__complete`}>
      <div className={`cost-breakdown-collapsable`}>
        <CostItem name={`Items (${itemsInCart})`} price={subtotal?.toFixed(2)} />
        <CostItem name={'Shipping and handling'} price={shipping?.toFixed(2)} />
        <CostItem name={'Total before tax'} price={totalBeforeTax?.toFixed(2)} />
        <CostItem name={'Estimated tax to be collected'} price={tax?.toFixed(2)} />
        <CostItem className={'cost-item--total'} name={'Order total'} price={totalPrice.toFixed(2)} />
      </div>
    </div>
  )
}

const CostItem = ({ name, price, className }) => {
  return (
    <div className={`cost-item ${className || ''}`}>
      <span>{name}:</span>
      <span>${price}</span>
    </div>
  )
}

export const Subtotal = () => {
  const { data } = usePaymentState()

  const subtotal = (data?.LineItems || []).reduce((acc, cur) => {
    if (cur?.subscribed && cur?.subscribedPrice) {
      //use subscribed discount price
      return acc + cur?.subscribedPrice * cur?.quantity
    }

    if (cur?.useOverride && cur?.overridePrice) {
      //use override price if it exists
      return acc + cur?.overridePrice * cur?.quantity
    }

    //use base retail for default
    return acc + cur?.retailPrice * cur?.quantity
  }, 0)

  return (
    <div className={`cost-breakdown cost-breakdown__total`}>
      <div className="cost-breakdown__total--label">
        <span>{'Subtotal'}</span>
      </div>
      <div className="cost-breakdown__total--price">
        <span>${formatNum(subtotal)}</span>
      </div>
    </div>
  )
}

function formatNum(num) {
  return Number(num).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
