import apiv2 from 'apiv2'

/**
 * @typedef FeatureSettings
 * @property {number} practiceId
 * @property {boolean} textEnabled
 * @property {boolean} textEnabled
 * @property {boolean} threeDayReminderEnabled
 * @property {boolean} fourteenDayReminderEnabled
 * @property {boolean} storeEnabled
 * @property {boolean} subscribeAndSaveEnabled
 */

/**
 * fetch feature settings for a practice
 * used to show UI according to their active settings
 *
 * @param {String} practiceId
 * @returns {Promise<FeatureSettings>}
 */
export const GET_FEATURE_SETTINGS = async practiceId => {
  const url = '/patientportal/practice/featuresettings/:practiceId'.replace(':practiceId', practiceId)
  return await apiv2.get(url)
}
