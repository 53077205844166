import { createContext, useContext, useMemo } from 'react'
import { ReportsReducer, init, useReportsActions } from './ReportsReducer'
import { useImmerReducer } from 'use-immer'
import { MainContext } from 'context'

export const ReportsContext = createContext(init)

export const ReportsProvider = ({ children }) => {
  const { admin } = useContext(MainContext)
  const [{ practiceId: stateId, ...state }, dispatch] = useImmerReducer(ReportsReducer, init)
  const actions = useReportsActions(dispatch)

  const practiceId = useMemo(
    () => (admin?.user?.practiceId ? admin.user.practiceId : stateId),
    [stateId, admin?.user?.practiceId]
  )

  return <ReportsContext.Provider value={{ ...state, practiceId, actions }}>{children}</ReportsContext.Provider>
}

export const useReportsState = () => useContext(ReportsContext)
