import { AlertModal } from 'components'
import { MainContext } from 'context'
import { useCallback, useContext } from 'react'
import { useStore } from '../context'
import { useNavWithState } from 'utilities/Patient'

export const useStoreCheckout = () => {
  const { patient, modal } = useContext(MainContext)
  const { navWithState } = useNavWithState()
  const {
    patient: { email, id },
    practice,
    doctorId,
  } = useStore()

  const { mutate, ...rest } = patient.api.store.createOrder({
    queryOptions: {
      onSuccess: data => {
        const id = data?.localId || data?.id || data?.orderId
        patient.actions.setOrder({ ...data, id })
        navWithState(`/order/${id}/payment/shipping`, { state: { from: 'cart' } })
      },
      onError: () => modal.actions.open(<AlertModal message={'There was an error creating your order.'} />),
    },
  })

  const updateLineItemsAndCheckout = useCallback(
    lineItems => {
      patient.actions.setOrder({ ...patient.order, LineItems: lineItems })
      navWithState(`/order/${patient.order.id}/payment/shipping`, { state: { from: 'cart' } })
    },
    [patient, navWithState]
  )

  /**
   * @param {Object} order - details for the order
   * @param {number} order.practiceId - practice Id for order
   * @param {string} order.patientEmail - patient email
   * @param {Object[]} order.lineItems - array of products on order
   */
  const createOrder = useCallback(
    ({ practiceId = null, lineItems, patientEmail = null }) => {
      if (patient?.order?.id && patient?.order?.status === 'draft order') {
        updateLineItemsAndCheckout(lineItems)
        return
      }

      const pId = practiceId ?? practice.id

      mutate({
        variables: { practiceId: pId },
        body: {
          isFromStore: true,
          practiceId: pId,
          lineItems: lineItems.map(product => ({ ...product, refillFrequency: product?.refillFrequency || 'Never' })),
          patient: {
            id: id || null,
            email: patientEmail ?? email,
          },
          doctor: {
            t3ID: doctorId,
          },
          isAutoShip: false,
          medbotId: null,
        },
      })
    },
    [mutate, practice, email, patient, updateLineItemsAndCheckout, doctorId, id]
  )

  return { createOrder, ...rest }
}
