import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useNavWithState = () => {
  const { state, ...location } = useLocation()
  const nav = useNavigate()

  const navWithState = useCallback(
    (path, options = {}) => {
      const addedState = options?.state ? options.state : {}
      const newOptions = { ...options, state: { ...state, ...addedState } }

      nav(path, newOptions)
    },
    [state, nav]
  )

  return { navWithState, location: { state, ...location } }
}
