import { useMemo } from 'react'
import { SubAndSaveOption } from './SubAndSaveOption'
import { RefillFrequencyPicker } from '../RefillFrequencyPicker/RefillFreqencyPicker'

const formatNum = num =>
  Number(num).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export function SubAndSavePicker({ product, ...props }) {
  const displayPrice = useMemo(() => {
    if (product?.useOverride) {
      return formatNum(product?.overridePrice)
    }

    if (!product?.retailPrice) return 'Invalid Price'

    return formatNum(product.retailPrice)
  }, [product])

  const subAndSaveEnabled = product?.sandsAvailable

  return (
    <div className="sub-and-save">
      {subAndSaveEnabled && (
        <SubAndSaveOption
          type="subAndSave"
          title="Subscribe & Save:"
          price={product?.subscribedPrice || formatNum(displayPrice * 0.9)}
          isSelected={product?.subscribed}
          onSelect={() => {
            props.toggleSubAndSave(true, product)
          }}
        >
          <RefillFrequencyPicker excludeOptions={['Never']} product={product} {...props} />
        </SubAndSaveOption>
      )}
      <SubAndSaveOption
        title="Purchase Now:"
        price={displayPrice}
        isSelected={!product?.subscribed}
        onSelect={() => props.toggleSubAndSave(false, product)}
      >
        {subAndSaveEnabled && (
          <RefillFrequencyPicker labelOverride="Send Me a Reminder to Reorder:" product={product} {...props} />
        )}
      </SubAndSaveOption>
    </div>
  )
}
