import { PaginatedPatientList } from 'components'
import { useSendWellness } from './context'
import { useSvgButton } from 'utilities/hooks'
import { useNavigate } from 'react-router-dom'
import { BackButtonIcon } from 'assets/vector'

export const PatientList = () => {
  const nav = useNavigate()
  const { actions, practiceId, keyword } = useSendWellness()

  return (
    <div className="create-order-wrapper">
      <div className="create-order-header">
        <h4 className="create-order-header__title">Select Patient</h4>
        {useSvgButton(<BackButtonIcon onClick={() => nav(-1)} className="create-order-header__back-button" />)}
      </div>
      <PaginatedPatientList
        initSearchKeyword={keyword}
        practiceId={practiceId}
        onSelectPatient={patient => {
          actions.selectPatient(patient)
          nav(-1)
        }}
      />
    </div>
  )
}
