import { createContext, useContext } from 'react'
import { useImmerReducer } from 'use-immer'
import { SendWellnessReducer, initialState, useSendWellnessActions } from './SendWellnessReducer'

export const SendWellnessContext = createContext({})

export const SendWellnessProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(SendWellnessReducer, initialState)
  const actions = useSendWellnessActions(dispatch)

  return <SendWellnessContext.Provider value={{ actions, ...state }}>{children}</SendWellnessContext.Provider>
}

export const useSendWellness = () => useContext(SendWellnessContext)
