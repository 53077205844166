import { Button, CloseButton } from 'components/Global'
import './DeactivateModal.scss'

export const DeactivateModal = ({ closeModal, name, denyText, onDeny, onConfirm, onClick, reactivate }) => {
  return (
    <div className="modal-content deactivate-modal deactivate-modal-wrapper">
      <CloseButton className="close-btn" onClick={() => closeModal()} />
      <div className="deactivate-modal_header">
        <span className="deactivate-modal_header--text">
          Are you sure you want to {reactivate ? 'reactivate' : 'deactivate'}
        </span>
        <span className="deactivate-modal_header--name">{name}</span>
      </div>
      <div className="actions">
        {denyText || onDeny ? (
          <Button autoFocus className="deny" onClick={onDeny || (() => closeModal())}>
            {denyText}
          </Button>
        ) : null}
        <Button autoFocus={!denyText} tabIndex={0} className="confirm" onClick={onClick || onConfirm}>
          {reactivate ? 'reactivate' : 'Deactivate'}
        </Button>
      </div>
    </div>
  )
}

export const ActivateModal = props => <DeactivateModal reactivate {...props} />
