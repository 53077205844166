import './CostBreakdown.scss'
import { useMemo } from 'react'
/*
basePrice + markupPrice = subtotal
(subtotal + shipping) * taxPercentage = tax
subtotal + tax + shipping = totalPrice
*/

export const CostBreakdown = ({ data, isPaymentForm, isDropdown }) => {
  return isPaymentForm ? <Breakdown data={data} isDropdown={isDropdown} /> : <Subtotal data={data} />
}

const Subtotal = ({ data }) => {
  return (
    <div className={`cost-breakdown cost-breakdown__total`}>
      <div className="cost-breakdown__total--label">
        <span>{'Subtotal'}</span>
      </div>
      <div className="cost-breakdown__total--price">
        <span>${data?.subTotal?.toFixed(2) || data?.subtotalPrice?.toFixed(2) || data?.subtotal?.toFixed(2)}</span>
      </div>
    </div>
  )
}

const Breakdown = ({ data, isDropdown }) => {
  // const shipping = data?.shipping || data?.totalShipping || 0
  // const subtotal = data?.subTotal || data?.subtotal || data?.subtotalPrice || 0
  // const tax = data?.tax || data?.totalTax || 0
  const totalPrice = data?.totalPrice || data?.total || 0
  // const totalBeforeTax = totalPrice - tax

  const itemsInCart = useMemo(() => data?.LineItems?.reduce((acc, cur) => (acc += cur?.quantity), 0), [data?.LineItems])

  return (
    <div className={`cost-breakdown cost-breakdown__complete ${isDropdown ? 'dropdown' : ''}`}>
      <div className={`cost-breakdown-collapsable`}>
        {/* <CostItem name={`Items (${itemsInCart})`} price={subtotal?.toFixed(2)} /> */}
        {/* <CostItem name={'Shipping and handling'} price={shipping?.toFixed(2)} />
        <CostItem name={'Total before tax'} price={totalBeforeTax?.toFixed(2)} /> */}
        {/* <CostItem name={'Estimated tax to be collected'} price={tax?.toFixed(2)} /> */}
        <CostItem className={'cost-item--total'} name={`Items (${itemsInCart})`} price={totalPrice?.toFixed(2)} />
      </div>
    </div>
  )
}

const CostItem = ({ name, price, className }) => {
  return (
    <div className={`cost-item ${className || ''}`}>
      <span>{name}:</span>
      <span>${price}</span>
    </div>
  )
}

/*
V3
<div className="cost-breakdown__total">
        <div className="cost-breakdown__total--label">
          <span>{data?.totalPrice ? 'Total Cost' : 'Subtotal'}</span>
         {!isNaN(data?.totalShipping) && <span>(Shipping included)</span>} 
        </div>
        <div className="cost-breakdown__total--price">
          <span>${data?.totalPrice?.toFixed?.(2) || data?.subtotal?.toFixed(2)}</span>
          <SmBlueArrowDown className={`up-down-svg ${isOpen && 'open'}`} />
        </div>
      </div>
      <div className={`cost-breakdown-collapsable ${isOpen && 'open'}`}>
        <div className="cost-breakdown-collapsable__sales-tax">
          <span>SALES TAX</span>
          <span>${data?.totalTax?.toFixed(2) || data?.tax?.toFixed?.(2) || '--'}</span>
        </div>
        <div className="cost-breakdown-collapsable__shipping">
          <span>SHIPPING</span>
          <span>${data?.totalShipping?.toFixed(2) || data?.shipping?.toFixed?.(2) || '--'}</span>
        </div>
      </div>

*/
