import { GET_PATIENT_INFO } from 'apiv2'
import { useAuth } from 'context/PatientPortal'
import { useEffect } from 'react'
import { useQuery } from 'react-query'

export function usePatientData() {
  const { patientId } = useAuth()

  useEffect(() => {
    if (!patientId) {
      console.warn('No Patient Id found')
    }
  }, [patientId])

  const keys = ['getPatient', patientId]

  const query = useQuery(keys, () => GET_PATIENT_INFO(patientId))

  return {
    keys,
    patient: {
      //customer profile defaulting for now
      customerProfileId: 'no_customer_profile_id',
      ...query?.data?.data,
    },
    ...query,
  }
}
