import { Button, Input, CloseButton, Loader } from 'components/Global'
import { Form } from 'react-final-form'
import './ForgotPassword.scss'
import { useParamsToObject } from 'utilities/hooks'
import { useRequestPasswordReset } from './useRequestPasswordReset'

export const ForgotPassword = ({ closeModal, resendInvite = null, resendLoading, onSubmitOverride = null }) => {
  const { email } = useParamsToObject()
  const { sendEmail, isLoading } = useRequestPasswordReset()

  if (isLoading || resendLoading) return <Loader />

  return (
    <div className="modal-content forgot-password-modal-wrapper">
      {!resendInvite && <CloseButton className="close-btn" onClick={closeModal} />}
      <span className="forgot-password-modal_header">{resendInvite ? 'Resend Invite' : 'Forgot Password'}</span>
      <Form
        onSubmit={({ email }) =>
          onSubmitOverride
            ? onSubmitOverride()
            : resendInvite
            ? resendInvite({ variables: { email } })
            : sendEmail(email)
        }
        initialValues={{ email }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="forgot-password-modal_form">
            <Input className="forgot-password-modal_input-email" label="Email" name="email" required={true} />
            <div className="actions">
              {!resendInvite && (
                <Button onClick={closeModal} className="button-cancel">
                  Cancel
                </Button>
              )}
              <Button type="submit" className="button-send">
                {resendInvite ? 'Resend Invite' : 'Send Recovery Email'}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  )
}
