import { AUTHORIZE_CONFIG } from './config'
import axios from 'axios'

/**
 *
 * @param {String} profileId
 *
 * {@link https://developer.authorize.net/api/reference/index.html#customer-profiles-get-customer-profile}
 */
export const GET_AUTHORIZE_CUSTOMER_PROFILE = async profileId => {
  return await axios
    .post(AUTHORIZE_CONFIG.url, {
      getCustomerProfileRequest: {
        merchantAuthentication: {
          name: AUTHORIZE_CONFIG.name,
          transactionKey: AUTHORIZE_CONFIG.transactionKey,
        },
        customerProfileId: profileId,
        includeIssuerInfo: 'false',
      },
    })
    .then(res => {
      if (res.data.messages.resultCode === 'Error') {
        throw new Error(res.data.messages?.message?.[0]?.text || 'unknown error occured')
      }
      return res
    })
}
