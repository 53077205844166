import Lottie from 'react-lottie-player'
import { handWaveLottieData } from 'assets/lottie'
import './Directions.scss'
import { useCallback, useContext, useRef, useState } from 'react'
import { ProductViewContext } from '../context'

export const Directions = ({ doctorsMessage }) => {
  const { actions } = useContext(ProductViewContext)
  const [edit, setEdit] = useState(false)
  const ref = useRef()

  const updateDirections = useCallback(
    e => {
      e.preventDefault()
      if (edit) actions.updateProduct({ doctorsMessage: ref.current.value })
    },
    [edit, ref, actions]
  )

  const handleClick = useCallback(
    e => {
      updateDirections(e)
      setEdit(!edit)
    },
    [updateDirections, edit]
  )

  return (
    <div className="select-product-detail__directions">
      <h6>
        Message from your Doctor <Lottie className="waving-hand" animationData={handWaveLottieData} play loop={true} />
      </h6>
      <div className={`select-product-detail__directions-text ${edit ? 'edit' : ''}`}>
        <form
          className={`select-product-detail__directions-form ${edit ? '' : 'hide'}`}
          onKeyPress={e => (window.event.keyCode === 13 ? handleClick(e) : null)}
          onSubmit={handleClick}
        >
          <textarea
            ref={ref}
            onChange={updateDirections}
            defaultValue={doctorsMessage}
            className="select-product-detail__directions-text area"
          ></textarea>
          <button onClick={handleClick} className="select-product-detail__directions-edit-btn">
            Save
          </button>
        </form>
        <div className={`select-product-detail__directions-text-preview ${edit ? 'hide' : ''}`}>
          <span>
            {doctorsMessage || 'Leave a personal message for the patient on why you recommended this product.'}
          </span>
          <button onClick={handleClick} className="select-product-detail__directions-edit-btn">
            Click To Edit
          </button>
        </div>
      </div>
    </div>
  )
}
