import { BlueRightArrow } from 'assets/vector'
import './ListItem.scss'
import productFallbackImg from 'assets/raster/productFallbackImg.png'

export const ListItem = ({ name, image, pos, onClick }) => {
  return (
    <button
      onClick={e => {
        onClick()
      }}
      type="button"
      className={`store-list-item slide-in-pos__${pos}`}
    >
      <div className="store-list-item__img">
        <img alt="" src={image} onError={({ currentTarget }) => (currentTarget.src = productFallbackImg)} />
      </div>
      <div className="store-list-item__info">
        <h4 className="store-list-item__info-name">{name}</h4>
      </div>
      <BlueRightArrow className="store-list-item__arrow" />
    </button>
  )
}
