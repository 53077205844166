import { BlueRightArrow } from 'assets/vector'
import { Loader } from 'components'
import { MainContext } from 'context'
import { useCallback, useContext } from 'react'
import { BackButtonInPage } from 'components/AlphabeticList'
import { ProductPickerContext } from '../context'
import { productPickerPages } from '../utils'
import _ from 'lodash'

export const ProductList = () => {
  const { admin } = useContext(MainContext)
  const { selectProduct, practiceId, products, actions } = useContext(ProductPickerContext)

  const { data, isLoading, error, isFetching } = admin.api.products.categories.productsBySubCategory({
    key: ['products', selectProduct.subCategory, selectProduct.category, practiceId],
    variables: {
      subcategory: selectProduct.subCategory,
      collection: _.startCase(selectProduct.category),
      practiceId: `/${practiceId}`,
    },
    params: { pageSize: 1000 },
  })

  const alreadyInOrder = useCallback(productId => products.some(exist => exist?.productId === productId), [products])

  const mapProducts = useCallback(
    () =>
      isLoading || isFetching ? (
        <Loader />
      ) : error ? (
        <p className="select-product-products__list--error">There was an error</p>
      ) : (
        data?.results.map((p, i) => !alreadyInOrder(p.productId) && <ProductInList key={i} product={p} />)
      ),
    [data, isLoading, error, alreadyInOrder, isFetching]
  )

  return (
    <div className="select-product-products">
      <div className="select-product-products-head">
        <BackButtonInPage
          className={'select-product-products'}
          onClick={() => actions.setSelectProduct({ subPage: productPickerPages.SUBCATEGORIES })}
        />
      </div>
      <h5>
        {_.startCase(selectProduct.category)}/{selectProduct.subCategory}
      </h5>
      <div className="select-product-products__list">{mapProducts()}</div>
    </div>
  )
}

const ProductInList = ({ product }) => {
  const { actions } = useContext(ProductPickerContext)

  const handleClick = useCallback(() => {
    actions.setSelectProduct({ product, subPage: productPickerPages.PRODUCT_DETAILS })
  }, [actions, product])

  return (
    <button className="select-product-products__list--item" onClick={handleClick}>
      <div className="select-product-products__list--item-img">
        <img alt="product" src={product.image[0].src} />
      </div>
      <div className="select-product-products__list--item-title">
        <span className="vendor">{product.vendor || '--'}</span>
        <span className="name">{product.productName}</span>
      </div>
      <BlueRightArrow className="select-product-products__list--item-arrow" />
    </button>
  )
}
