import { BlueCirclePlusSign } from 'assets/vector'

export const ProviderReview = ({
  provider,
  onRemove = null,
  onReplace = null,
  label = 'Order From Provider',
  noProviderMessage = 'There is no provider for your order.',
}) => {
  const doctorFormat = `Dr. ${provider?.name}`
  const otherFormat = `${provider?.name} ${provider?.providerType}`
  const displayName = provider?.providerType?.toLowerCase() === 'doctor' ? doctorFormat : otherFormat

  return (
    <div className="review-order-provider">
      <span className="review-order-provider__label">{label}</span>
      {provider ? (
        <div className="review-order-provider__provider-detail">
          <div className="review-order-provider__provider-detail--avatar">
            <img
              alt="provider"
              src={provider?.avatarUrl || ''}
              onError={({ currentTarget }) => (currentTarget.style.display = 'none')}
            />
            <span className="inital">{provider.name.split(' ')[1][0].toUpperCase()}</span>
          </div>
          <span>{displayName}</span>
          {onRemove ? (
            <button className="review-order-provider__provider-detail--remove-blue-button" onClick={onRemove}>
              <BlueCirclePlusSign />)
            </button>
          ) : null}
        </div>
      ) : (
        <span className="review-order-provider__no-provider-notice">{noProviderMessage}</span>
      )}
      {onReplace && (
        <button className="review-order-provider__provider-detail--replace-button" onClick={onReplace}>
          {provider ? 'Change Provider' : 'Choose Provider'}
        </button>
      )}
    </div>
  )
}
