import { CREATE_TRANSACTION_REQUEST } from 'apiv2'
import { useMutation, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePaymentState } from '../context'

import dayjs from 'dayjs'
import customParse from 'dayjs/plugin/customParseFormat'
import { useModal } from 'context/Modal/ModalContext'
import { AlertModal } from 'components'

dayjs.extend(customParse)

function useProcessPayment() {
  const queryClient = useQueryClient()
  const { pathname } = useLocation()
  const nav = useNavigate()
  const { actions } = useModal()

  const paymentState = usePaymentState()

  const { mutate, ...mutation } = useMutation(
    async creditCard => {
      try {
        const transactionRes = await CREATE_TRANSACTION_REQUEST({
          orderId: paymentState.data.id,
          shippingAddress: paymentState.shippingAddress,
          creditCard,
          amount: paymentState.totals.totalPrice,
        })

        return Promise.resolve(transactionRes)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['patient_order', `${paymentState.data.id}`])
        nav(pathname?.replace('payment/checkout', 'approved'), { replace: true })
      },
      onError() {
        actions.open(<AlertModal message={'There was an error processing your payment'} />)
      },
    }
  )

  const processPayment = values => {
    mutate({
      cardNumber: values.cardNumber,
      cardCode: values.cvc,
      expirationDate: dayjs(values.expiration, 'MM/YY').format('YYYY-MM'),
    })
  }

  return { processPayment, ...mutation }
}

export default useProcessPayment
