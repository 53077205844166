import { useMemo } from 'react'

const keys = {
  SET_STORE_ID: 'SET_STORE_ID',
  SET_CONDITION_ID: 'SET_CONDITION_ID',
  SET_PATIENT_INFO: 'SET_PATIENT_INFO',
  SET_PRACTICE_INFO: 'SET_PRACTICE_INFO',
  SET_ALL: 'SET_ALL',
  SET_DOC_ID: 'SET_DOC_ID',
  SET_INVITE_ID: 'SET_INVITE_ID',
}

export const init = {
  patient: {
    id: null,
    email: null,
    cellNumber: null,
    hasAgreedToTerms: true,
  },
  practice: {
    id: null,
    name: null,
  },
  storeId: null,
  conditionId: null,
  doctorId: null,
  inviteId: null,
}

export const StoreReducer = (draft, { payload, type }) => {
  switch (type) {
    case keys.SET_STORE_ID:
      return void (draft.storeId = payload)
    case keys.SET_CONDITION_ID:
      return void (draft.conditionId = payload)
    case keys.SET_PATIENT_INFO:
      return void (draft.patient = payload)
    case keys.SET_ALL:
      return payload
    case keys.SET_DOC_ID:
      return void (draft.doctorId = payload)
    case keys.SET_PRACTICE_INFO:
      return void (draft.practice = payload)
    case keys.SET_INVITE_ID:
      return void (draft.inviteId = payload)
    default:
      return draft
  }
}

export const useStoreActions = dispatch =>
  useMemo(
    () => ({
      setInviteId: id => dispatch({ type: keys.SET_INVITE_ID, payload: id }),
      setDocId: id => dispatch({ type: keys.SET_DOC_ID, payload: id }),
      setStoreId: id => dispatch({ type: keys.SET_STORE_ID, payload: id }),
      setConditionId: id => dispatch({ type: keys.SET_CONDITION_ID, payload: id }),
      setPatient: patient => dispatch({ type: keys.SET_PATIENT_INFO, payload: patient }),
      setAll: store => dispatch({ type: keys.SET_ALL, payload: store }),
      setPractice: ({ id, name }) => dispatch({ type: keys.SET_PRACTICE_INFO, payload: { id, name } }),
    }),
    [dispatch]
  )
