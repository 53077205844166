import { handWaveLottieData } from 'assets/lottie'
import { CaratIcon } from 'assets/vector'
import Lottie from 'react-lottie-player'
import './PatientMenuHeader.scss'
import { usePatientMenu } from '../context/PatientMenuContext'
import { useMemo } from 'react'
import { usePatientData } from 'utilities/Patient'

export function PatientMenuHeader() {
  const { state, toggleMenu } = usePatientMenu()

  const { data, isLoading } = usePatientData()

  const isOpen = useMemo(() => state === 'open' || state === 'opening', [state])

  return (
    <div className={`patient-portal-menu__header ${isOpen ? 'open' : 'closed'}`}>
      <div className="patient-portal-menu__header--greeting fade-away-if-closed">
        <span>Good Day </span>
        <Lottie play loop={true} animationData={handWaveLottieData} className="wave-lottie" />
      </div>
      <div className="patient-portal-menu__header--name fade-away-if-closed">
        <h3 id={isLoading ? 'loading' : ''}>
          {data?.data?.firstName || ''} {data?.data?.lastName || ''}
        </h3>
      </div>
      <button onClick={toggleMenu} className="patient-portal-menu__header--toggle">
        <CaratIcon
          style={{ transform: `rotate(${!isOpen ? '180' : '0'}deg)` }}
          className={`carat-icon`}
          alt="carat arrow"
          role="img"
        />
      </button>
    </div>
  )
}
