import Lottie from 'react-lottie-player'
import { handWaveLottieData } from 'assets/lottie'
import { CaratIcon } from 'assets/vector'
import './UserProfile.scss'
import { MainContext } from 'context'
import { useContext, useState } from 'react'
import { AvatarWithFallback } from 'components/Global'

const UserProfile = ({ sidebarState, toggleSidebar, admin, practice }) => {
  const {
    admin: { user, api, actions },
  } = useContext(MainContext)
  const [tried, setTried] = useState(false)

  const { data, isLoading /* error */ } = api.user.getUser({
    variables: { userId: user?.userId },
    queryOptions: { enabled: !user?.firstName, onSuccess: res => actions.setUser({ ...user, ...res }) },
    keys: ['get_user_sidebar', 'user_sidebar'],
  })

  const { data: avatarData /* isLoading: avatarLoading */ } = api.user.getAvatar({
    variables: { userId: user?.userId },
    queryOptions: {
      enabled: !user?.avatarUrl && !tried,
      onSuccess: res => {
        try {
          const avatarUrl = URL?.createObjectURL?.(res)
          actions.setUser({ ...user, avatarUrl })
        } catch {
          actions.setUser(user)
        }
      },
      onError: () => setTried(true),
    },
    keys: ['get_user_avatar_sidebar', 'user_sidebar'],
  })

  let firstName = user?.firstName || data?.firstName
  let lastName = user?.lastName || data?.lastName
  let avatar = avatarData || user?.avatarUrl

  return (
    <div className={`user-profile ${admin ? 'admin' : practice ? 'practice' : ''} ${sidebarState}`}>
      <AvatarWithFallback imgSrc={avatar} avatar={avatar} firstName={firstName} lastName={lastName} showName={false} />
      <div className={`name-greeting-wrapper ${sidebarState}`}>
        <div className="greeting-wrapper">
          <p className="greeting">Good Day</p>
          <Lottie play loop={true} animationData={handWaveLottieData} className="wave-lottie" />
        </div>
        <h3 className={`name ${isLoading ? 'name--loading' : ''}`}>
          {firstName} {lastName?.[0]?.toUpperCase?.()}.
        </h3>
      </div>
      <button onClick={toggleSidebar} className="toggle-closed">
        <CaratIcon className={`carat-icon ${sidebarState}`} alt="carat arrow" role="img" />
      </button>
    </div>
  )
}

export default UserProfile
