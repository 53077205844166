import { CheckmarkCheckedIcon } from 'assets/vector'
import { MainContext } from 'context'
import dayjs from 'dayjs'
import { forwardRef, useContext } from 'react'
import { Verbage } from '../Verbage'
import './ForDownload.scss'

export const ForDownload = forwardRef(({ first, last, signedOnDate }, ref) => {
  const { patient } = useContext(MainContext)

  return (
    <>
      {/* <div className="HIDE-TERMS"> */}
      <div ref={ref} className="terms-for-download">
        <Verbage />
        <div className="footer">
          <div className="first-last">
            <p>
              {first || patient?.user?.firstName} {last || patient?.user?.lastName}
            </p>
          </div>
          <div className="checkbox-wrap">
            <p className="checkbox-label ">
              Patient has read, consent and agree to Medtrix User Agreement and Privacy Policy.
            </p>
            <div className="checkbox-check">
              <CheckmarkCheckedIcon />
              <p>Yes</p>
            </div>
            <div className="signed-on">
              <p className="signed-on">Signed on</p>
              <p className="date">{dayjs(signedOnDate || patient?.user?.termsDate).format('MM/DD/YYYY')}</p>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* <div className="hide-terms" /> */}
    </>
  )
})
