import { MainContext } from 'context'
import { useCallback, useContext } from 'react'
import { MedBotsContext } from '../../context'
import { AlertModal, SuccessModal } from 'components'
import { useMutation, useQueryClient } from 'react-query'
import { UPDATE_MEDBOT } from 'apiv2/calls/admin'

export const useMedbotUpdate = () => {
  const queryClient = useQueryClient()
  const { modal } = useContext(MainContext)
  const { actions, medbotId, hasEdits, ...medbot } = useContext(MedBotsContext)

  const { mutate, ...rest } = useMutation({
    mutationFn: UPDATE_MEDBOT,
    onSuccess: () => {
      modal.actions.open(<SuccessModal title="MedBot updated!" />)
      queryClient.invalidateQueries(['get_medbot_details'])
    },
    onError: () => modal.actions.open(<AlertModal message={'Could not update MedBot'} />),
  })

  const updateMedBot = useCallback(
    medBotName =>
      mutate({
        medbotId,
        body: {
          medbotDraftOrder: { medbotId, ...medbot, medBotName, localLineItems: medbot.lineItems },
        },
      }),
    [medbot, medbotId, mutate]
  )

  return { updateMedBot, ...rest }
}
