import { useCallback, useContext } from 'react'
import { MainContext } from 'context'
import { AlertModal } from '../../Alert'
import { SuccessModal } from '../../Success'
import { useQueryClient } from 'react-query'

const fetchProps = {
  headers: {
    Accept: 'application/json',
  },
}
const skipKey = ['specialties', 'avatar', 'filename']
const providerTypeFormat = {
  Doctor: 'Doctor',
  'Advanced Registered Nurse Practitioner': 'ARNP',
  'Physicians Assistant': 'PA',
}

const useProviderActions = () => {
  const qc = useQueryClient()
  const { admin, modal } = useContext(MainContext)

  const success = msg => () => {
    qc.invalidateQueries('user')
    modal.actions.open(<SuccessModal title={msg} />)
  }

  const { mutate, isLoading, error } = admin.api.user.createProvider({
    fetchProps,
    queryOptions: {
      onSuccess: success('Provider created!'),
      onError: () => {
        modal?.actions?.open(<AlertModal message={'There was an error creating the provider.'} />)
      },
    },
  })

  const {
    mutate: editP,
    error: editErr,
    isLoading: editLoading,
  } = admin.api.user.editProvider({
    fetchProps,
    queryOptions: {
      onSuccess: success('Provider updated!'),
      onError: () =>
        modal?.actions?.open(<AlertModal message={'There was an error updating the provider information.'} />),
    },
  })

  const buildForm = useCallback(
    values => {
      //this was causing bugs later down the road
      values.firstName = values.firstName.trim()
      values.lastName = values.lastName.trim()

      const fd = new FormData()

      for (const key in values) {
        if (key === 'providerType') fd.append('providerType', providerTypeFormat[values[key]])
        else if (!skipKey.includes(key)) fd.append(key, values[key])
      }
      if (values?.avatar) {
        fd.append('providerAvatar', values.avatar)
      }
      if (values?.specialties)
        Object.values(values.specialties)
          .flat()
          .forEach(spec => fd.append('specialties', spec))
      fd.append('practiceId', admin?.practices?.one?.id || null)

      return fd
    },
    [admin.practices.one.id]
  )

  const editProvider = useCallback(values => editP({ body: buildForm(values) }), [editP, buildForm])

  const create = useCallback(values => mutate({ body: buildForm(values) }), [mutate, buildForm])

  return { create, editProvider, isLoading: isLoading || editLoading, error: error || editErr }
}

export default useProviderActions
