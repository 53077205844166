import 'react-image-crop/dist/ReactCrop.css'
import './AvatarCrop.scss'
import { useCallback, useEffect, useState } from 'react'
import { canvasPreview } from './canvasPreview'

const useCanvasPreview = (crop, imgRef, previewCanvasRef, isPracticeLogo = false) => {
  const [isAllowed, setIsAllowed] = useState(true)
  const [dimensions, setDimensions] = useState()

  const restraint = isPracticeLogo ? 600 : 300

  const evaluateDimensions = useCallback(
    (width, height) => (width > restraint || height > restraint ? setIsAllowed(false) : setIsAllowed(true)),
    [restraint]
  )

  const generateCanvasPreview = useCallback(async () => {
    if (crop.height === 50 && crop.width === 50 && crop.x === 25) return
    if (!crop?.width || !crop?.height || !imgRef.current || !previewCanvasRef.current) return

    const { width, height } = await canvasPreview(imgRef.current, previewCanvasRef.current, crop)
    setDimensions({ width, height })
    evaluateDimensions(width, height)
  }, [crop, imgRef, previewCanvasRef, evaluateDimensions])

  useEffect(() => void generateCanvasPreview(), [generateCanvasPreview])

  return { isAllowed, height: dimensions?.height, width: dimensions?.width }
}

export default useCanvasPreview
