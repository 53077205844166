import { MainContext } from 'context'
import { useCallback, useContext, useState } from 'react'
import { CreateOrderContext } from '../../context'
import { AlertModal } from 'components'
import { useParams } from 'react-router-dom'

/*
1. Create Draft Order
2. Use Local Id Returned to send order
*/
export const useSendOrder = () => {
  const { admin, modal } = useContext(MainContext)
  const { provider, products, patient, medbot } = useContext(CreateOrderContext)
  const [success, setSuccess] = useState(false)
  const { type } = useParams()

  const onError = useCallback(
    () => modal.actions.open(<AlertModal message="There was an error trying to send the order." />),
    [modal]
  )

  const { mutate, isLoading } = admin.api.createOrder.sendOrder({
    queryOptions: {
      onError,
      onSuccess: () => setSuccess(true),
    },
  })

  const { mutate: createDraft, isLoading: draftLoading } = admin.api.createOrder.createDraftOrder({
    variables: { practiceId: provider?.practiceId },
    queryOptions: {
      onError,
      onSuccess: res => (res?.localId ? mutate({ variables: { orderId: res.localId } }) : onError()),
    },
  })

  const handleSendOrder = useCallback(
    values => {
      const docNameArr = provider.name.split(' ')

      createDraft({
        body: {
          medbotId: medbot?.medbotId || null,
          practiceId: provider?.practiceId,
          doctor: { t3ID: provider.doctorId, firstName: docNameArr[0], lastName: docNameArr[1] },
          // we need to set refiill frequency to never if it is not set
          lineItems: products.map(product => ({ ...product, refillFrequency: product?.refillFrequency || 'Never' })),
          patient: {
            ...patient,
            firstName: values?.patientFirstName || null,
            lastName: values?.patientLastName || null,
            email: values?.patientEmail,
            cellNumber: values?.patientPhone,
          },
          isAutoShip: false,
        },
      })
    },
    [createDraft, provider, patient, products, medbot]
  )

  // Requirements for a valid order
  const hasProvider = provider?.practiceId
  const hasProducts = products?.length > 0
  const hasMedbot = type === 'medbot' ? medbot?.medbotId : true

  return {
    canSend: hasProvider && hasProducts && !!hasMedbot,
    success,
    sendOrder: handleSendOrder,
    isLoading: isLoading || draftLoading,
  }
}
