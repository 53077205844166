import { useParamsToObject } from 'utilities/hooks'
import { MedBotDarkBlue } from 'assets/vector'
import { Form } from 'react-final-form'
import { Input, Button } from 'components/Global'
import { AlertIcon } from 'assets/vector'
import './SetPassword.scss'
import { ModalContainer } from 'components'
import { passwordValidation } from 'utilities/utils'
import { useResetPassword } from './useResetPassword'

export const SetPassword = ({ create }) => {
  //query params
  const { email } = useParamsToObject()

  const { onResetPassword, isLoading, isError } = useResetPassword(create)

  const onSubmit = ({ password, email }) => onResetPassword({ newPassword: password, email })

  return (
    <main className="login admin">
      <ModalContainer isAdmin />
      <Form
        onSubmit={onSubmit}
        initialValues={{ email }}
        validate={values => {
          const errors = {}
          if (values.password !== values.password2) {
            errors.password2 = 'Passwords do not match'
          }
          return errors
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="login-form set-password admin">
            <MedBotDarkBlue className="medtrix-logo-horizontal-admin" />
            <h2>{create ? 'Create A Password' : 'Reset Password'}</h2>
            {create && <Input className="email" label="Email" type="text" name="email" />}
            <p>
              Passwords should contain a minimum of 8 characters, using a mix of uppercase and lowercase letters,
              numbers, and special characters.
            </p>
            <Input validate={passwordValidation} label="New Password" type="password" name="password" required={true} />
            <Input
              validate={passwordValidation}
              label="Retype New Password"
              type="password"
              name="password2"
              required={true}
            />
            <Button type="submit">{isLoading ? 'Loading...' : 'Save Password'}</Button>
            <div className="errors">
              {isError && (
                <div className="set-password-error">
                  <AlertIcon />
                  <span>Could not reset your password.</span>
                </div>
              )}
            </div>
          </form>
        )}
      />
    </main>
  )
}

/*
  render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="login-form reset-password admin">
            <MedtrixHorizontal className="medtrix-logo-horizontal-admin" />
            <h2></h2>
            <p>
              Passwords should contain a minimum of 8 characters, using a mix of uppercase and lowercase letters,
              numbers, and special characters.
            </p>
            <Input label="New Password" type="password" />
            <Input label="Retype New Password" type="password" />
            {err && (
              <p className="password__warning">
                <AlertIcon className="field-error__alert" />
                {err}
              </p>
            )}
            <Button type="submit">Save Password</Button>
          </form>

*/
