import { useContext } from 'react'
import { Form } from 'react-final-form'
import { Button, CloseButton, Input, Loader } from 'components/Global'
import { CardNumber, Cvc, ExpireDate } from 'components/Global/Input/MaskedInputs'
import './PaymentInfoModal.scss'
import { MainContext } from 'context'
import { useParams } from 'react-router-dom'
import { useModals } from 'utilities/hooks'
import { AlertModal } from '../Alert'

const PaymentInfoModal = ({ closeModal, orderInfo, ...rest }) => {
  const { admin, modal } = useContext(MainContext)
  const { id: orderId } = useParams()
  const { success, error } = useModals()

  const { mutate: payOrder, isLoading } = admin.api.orders.payOrder({
    variables: { orderId },
    queryOptions: {
      onSuccess: success('Payment Approved!', ['orders', 'orderId', 'detail', orderId]),
      onError: error('There was an error processing the payment.'),
    },
  })

  if (isLoading) return <Loader />

  return (
    <div className="modal-content payment-info-modal payment-info-modal-wrapper" {...rest}>
      <CloseButton onClick={() => closeModal()} />
      <h2>Payment</h2>
      <Form
        onSubmit={values => {
          const { paymentInfo } = orderInfo

          if (!paymentInfo?.city || !paymentInfo?.address1 || !paymentInfo?.state || !paymentInfo?.zip) {
            modal.actions.open(
              <AlertModal message="Cannot pay for the order. Please make sure the patient information is complete." />
            )
            return
          }

          payOrder({
            body: {
              expirationMo: +values?.exp?.slice?.(0, 2),
              expirationYr: +values?.exp?.substring?.(2),
              creditCardNumber: values?.cardNumber,
              nameOnCard: values?.name,
              cvc: values?.cvc,
              ...orderInfo,
            },
          })
        }}
        initialValues={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="login-form patient">
            <div className="input-row name">
              <Input label="Card Number" name="cardNumber" maskedInput={CardNumber} type="text" />
            </div>
            <div className="input-row card">
              <Input label="Expiration Date" name="exp" maskedInput={ExpireDate} type="text" />
              <Input label="CVC" name="cvc" maskedInput={Cvc} type="text" />
            </div>
            <div className="input-row billing-1">
              <Input label="Name on card" name="name" component="input" type="text" />
            </div>

            <div className="input-row actions">
              <Button
                onClick={e => {
                  e.preventDefault()
                  closeModal()
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Process Payment</Button>
            </div>
          </form>
        )}
      />
    </div>
  )
}

export default PaymentInfoModal

/* <div className="input-row billing-2">
                <Input label="City" name="city" component="input" type="text" />
                <Dropdown placeholder={''} label="State" options={States.map(state => ({ name: state.abbr }))} />
                <Input label="Zipcode" name="zip" maskedInput={Zip} type="text" />
              </div> */

/* <Input label="Billing Address" name="street" component="input" type="text" /> */
