import './PatientPortalMenu.scss'
import { Hamburger, PatientMenuHeader, PatientNavItems, NavFooter } from './components'
import { PatientMenuProvider, usePatientMenu } from './components/context/PatientMenuContext'

export function PatientPortalMenu() {
  return (
    <PatientMenuProvider>
      <Header />
      <Aside />
    </PatientMenuProvider>
  )
}

function Aside() {
  const { isOpen } = usePatientMenu()

  return (
    <aside className={`patient-portal-aside ${isOpen ? 'open' : 'closed'}`}>
      <nav>
        <PatientMenuHeader />
        <PatientNavItems />
        <NavFooter />
      </nav>
    </aside>
  )
}

function Header() {
  return (
    <header>
      <Hamburger />
    </header>
  )
}
