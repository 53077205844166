import { NavLink, useLocation } from 'react-router-dom'
import './PatientNavItems.scss'
import { usePatientMenu } from '../context/PatientMenuContext'
import { CreateOrderIcon, PracticeIcon, DashboardIcon, OrdersIcon } from 'assets/vector'
import { useWindowDimensions } from 'utilities/hooks'
import { useMemo } from 'react'

const navItems = [
  { name: 'Home', icon: <DashboardIcon />, link: '/patient', exactMatch: true },
  { name: 'Subscriptions', icon: <CreateOrderIcon />, link: 'subscriptions' },
  { name: 'Update Payment Method', icon: <OrdersIcon />, link: 'payment-method' },
  { name: 'Patient Information', icon: <PracticeIcon />, link: 'info' },
  // { name: 'Contact Us', icon: <BasketIcon />, link: 'contact-us' },
]

export function PatientNavItems() {
  const { isOpen } = usePatientMenu()
  return (
    <ul className={`patient-portal-menu__nav ${isOpen ? 'menu-open' : 'menu-closed'}`}>
      {navItems.map(item => (
        <Item key={item.name} {...item} />
      ))}
    </ul>
  )
}

function Item({ name, link, icon, exactMatch = false }) {
  const { isOpen, toggleMenu } = usePatientMenu()
  const { pathname } = useLocation()
  const { width } = useWindowDimensions()

  const isSelected = useMemo(() => {
    if (exactMatch) {
      return pathname === link
    }

    return pathname.includes(link)
  }, [exactMatch, pathname, link])

  return (
    <li key={name} className={`patient-portal-menu__nav-item menu-${isOpen ? 'open' : 'closed'}`}>
      <NavLink
        onClick={() => {
          //close the menu if mobile mode
          if (width <= 600) {
            toggleMenu()
          }
        }}
        className={`${isSelected ? 'selected' : 'unselected'}`}
        to={link}
      >
        <div className="patient-portal-menu__nav-item--icon-wrap">{icon}</div>
        <span>{name}</span>
      </NavLink>
    </li>
  )
}
