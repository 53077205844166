import { useParams } from 'react-router-dom'
import { useReportData } from '../useReportData'
import { useMemo } from 'react'
import { Pagination } from 'components'
import { useReportsState } from 'pages/Admin/Reports/context'

import SalesSummary from './SalesSummary'
import ProductSummary from './ProductSummary'
import MedBotsSentByUser from './MedBotsSentByUser'
import WellnessStoreSummary from './WellnessStoreSummary'

const ReportsTable = () => {
  const { reportType } = useParams()
  const query = useReportData()
  const { actions, ...state } = useReportsState()

  const tableMap = {
    Sales_Summary: () => <SalesSummary {...query} />,
    Product_Summary: () => <ProductSummary {...query} />,
    MedBots_Sent_By_User: () => <MedBotsSentByUser {...query} />,
    Wellness_Store_Summary: () => <WellnessStoreSummary {...query} />,
  }

  const totalPages = useMemo(() => {
    if (query?.data?.totalRows) {
      return Math.ceil(query?.data?.totalRows / state.pageSize)
    }
    return 1
  }, [state, query])

  return (
    <>
      {tableMap[reportType]()}
      <Pagination page={state.page} totalPages={totalPages} selectPage={actions.setPage} />
    </>
  )
}

export default ReportsTable
