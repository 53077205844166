import { Button, CloseButton } from 'components/Global'
import './AlternateModal.scss'

export const AlternateModal = ({
  closeModal,
  removeAlt,
  addAlt,
  productImg,
  onConfirm,
  openProductList,
  productName,
  ...props
}) => {
  return (
    <div className="alternate-modal-wrap">
      <CloseButton onClick={closeModal} />
      <img
        className="alternate-modal__product-img"
        src={productImg || ''}
        alt="product"
        onError={({ currentTarget }) => (currentTarget.style.display = 'none')}
      />
      <span>Are you sure you want to {removeAlt ? 'remove' : 'add'}</span>
      <span className="alternate-modal__product-name">{productName}</span>
      <span>as an alternate product?</span>
      <div className="alternate-modal__actions">
        <Button onClick={onConfirm}>Yes</Button>
        <Button onClick={closeModal}>No</Button>
      </div>
      {addAlt && (
        <Button
          className="alternate-modal__add-choose-again-btn"
          onClick={() => {
            onConfirm()
            openProductList()
          }}
        >
          Add and Choose another product
        </Button>
      )}
    </div>
  )
}
