import { SpeechBubbleTail } from 'assets/vector'
import Lottie from 'react-lottie-player'
import { handWaveLottieData } from 'assets/lottie'
import './DoctorMessage.scss'
import { useContext } from 'react'
import { MainContext } from 'context'

export const DoctorMessage = ({
  msg,
  avatar = false,
  lastInitial = 'D',
  title,
  doctorId = null,
  increasedImgSizes = false, //preserving styles of images not in patietn portal
}) => {
  let { data, isLoading } = useDocAvatar(doctorId)
  if (!msg) return null

  return (
    <div className="doctor-message-wrap">
      <div className="doctor-message-header">
        <span>{title}</span>
        <Lottie className="hand-wave-lottie" play loop animationData={handWaveLottieData} />
      </div>

      <div className="doctor-message-body">
        <div className={`avatar-wrap ${increasedImgSizes ? 'increasedImgSize' : ''}`}>
          {(!isLoading && avatar) || (!isLoading && data) ? (
            <img
              alt="doc_avatar"
              src={avatar || data}
              onError={({ currentTarget }) => {
                currentTarget.style.display = 'none'
              }}
            />
          ) : null}
          {lastInitial}
        </div>
        <SpeechBubbleTail className={`doctor-message-body__tail ${increasedImgSizes ? 'increasedImgSize' : ''}`} />
        <p className="doctor-message-body__message">{msg || ''}</p>
      </div>
    </div>
  )
}

const useDocAvatar = (doctorId = null) => {
  const { patient } = useContext(MainContext)
  return patient?.api.getDocAvatar({
    variables: { doctorId },
    queryOptions: {
      enabled: !!doctorId,
    },
  })
}
