import { QuantityControls } from './QuantityControls'
import { AutoshipControls } from './AutoshipControls'
import './Controls.scss'

//required props
//product, handleDelete, handleAdd, handleMinus, onTurnOff, onTurnOn, patient: boolean, showAutoShipControl
export const ProductControls = ({ showAutoShipControls = true, showQtyControls = true, ...props }) => (
  <div className="product-controls">
    {showAutoShipControls && <AutoshipControls {...props} />}
    {showQtyControls && <QuantityControls {...props} />}
  </div>
)
