import { Loader } from 'components'
import { MainContext } from 'context'
import { productPickerPages } from '../utils'
import { useCallback, useContext, useEffect } from 'react'
import { BackButtonInPage } from 'components/AlphabeticList'
import { ProductPickerContext } from '../context'
import _ from 'lodash'

export const SubCategories = () => {
  const { admin } = useContext(MainContext)
  const { selectProduct, practiceId, actions } = useContext(ProductPickerContext)

  useEffect(() => {
    if (!selectProduct.category) actions.setSelectProduct({ subPage: productPickerPages.CATEGORIES })
  }, [selectProduct, actions])

  const { data, isLoading, error } = admin.api.products.categories.getSubsByTitle({
    variables: { title: _.startCase(selectProduct.category), practiceId: `/${practiceId}` },
  })

  const mapItems = useCallback(
    () =>
      isLoading ? (
        <Loader />
      ) : error ? (
        <p className="select-product-subcategories__list--error">There was an error</p>
      ) : (
        data?.map((item, i) => <SubCategory key={i} item={item} />)
      ),
    [data, isLoading, error]
  )

  return (
    <div className="select-product-subcategories">
      <div className="select-product-subcategories-head">
        <BackButtonInPage
          onClick={() => actions.setSelectProduct({ subPage: productPickerPages.CATEGORIES })}
          className="select-product-subcategories"
        />
      </div>
      <h5 className="select-product-subcategories__category">{_.startCase(selectProduct.category)}</h5>
      <div className="select-product-subcategories__list">{mapItems()}</div>
    </div>
  )
}

const SubCategory = ({ item }) => {
  const { actions } = useContext(ProductPickerContext)

  return (
    <button
      onClick={() => actions.setSelectProduct({ subCategory: item.category, subPage: productPickerPages.PRODUCT_LIST })}
      className="select-product-subcategories__list--item"
    >
      <span>{item.category}</span>
    </button>
  )
}
