import { useMemo } from 'react'

const keys = {
  LOGIN_PATIENT: 'LOGIN_PATIENT',
  LOGOUT_USER: 'LOGOUT_USER',
}

const session = JSON.parse(sessionStorage?.getItem?.('_MEDBOT_PATIENT_SESSION'))

export const userInit = {
  token: session?.token || null,
  patientId: session?.patientId || null,
  isLoggedIn: !!session?.token && session?.patientId,
}

/*
{
      isLoggedIn: false, // we set this ourselves
      //below comes from API
      token: null,
      userId: null,
      patientId: null,
      firstName: null || 'Guest',
      lastName: null || 'User',
    }
  */

export const authReducer = (draft, { payload, type }) => {
  switch (type) {
    case keys.LOGIN_PATIENT:
      sessionStorage.setItem(
        '_MEDBOT_PATIENT_SESSION',
        JSON.stringify({ patientId: payload.patientId, token: payload.token })
      )
      return { ...payload, isLoggedIn: true }
    case keys.LOGOUT_USER:
      sessionStorage.removeItem('_MEDBOT_PATIENT_SESSION')
      return userInit
    default:
      return draft
  }
}

export const useAuthActions = dispatch =>
  useMemo(
    () => ({
      login: user => dispatch({ type: keys.LOGIN_PATIENT, payload: user }),
      logoutUser: () => dispatch({ type: keys.LOGOUT_USER }),
    }),
    [dispatch]
  )
