import { useContext } from 'react'
import { MedBotsContext } from '../../context'
import './View.scss'
import { useParams } from 'react-router-dom'
import { GET_MEDBOT_DETAILS } from 'apiv2/calls/admin'
import { useQuery } from 'react-query'

export const MEDBOT_DETAIL_KEYS = ['medbot_details']

export const useMedbotDetails = () => {
  const { medbotId } = useParams()
  const { actions, hasEdits } = useContext(MedBotsContext)

  return useQuery(['get_medbot_details', medbotId], () => GET_MEDBOT_DETAILS(medbotId), {
    enabled: !!medbotId || !hasEdits,
    // staleTime: 0,
    onSuccess({ data }) {
      actions.viewMedBot({
        lineItems: data?.localLineItems,
        medbotId: data?.localId,
        ...data,
      })
    },
  })
}
