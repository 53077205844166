import { ArrowDownIcon, BackButtonIcon, UpAndDownArrowsIcon } from 'assets/vector'
import { alphabet } from 'pages'
import './AlphaList.scss'

export const AlphaSearch = ({ setSortListBy, sortListBy }) => {
  return (
    <div className="alphabet-search">
      {alphabet.map((letter, i) => (
        <button
          key={i}
          onClick={() => setSortListBy({ ...sortListBy, letter: letter === sortListBy?.letter ? null : letter })}
          className={`alphabet-search__letter ${letter === sortListBy?.letter ? 'active' : ''}`}
        >
          {letter}
        </button>
      ))}
    </div>
  )
}

export const Tab = ({ letter }) => (
  <div className="create-order-page__alphabetic-list--tab" id={letter}>
    <span>{letter}</span>
  </div>
)

export const AlphaListHeader = ({ className, columns, sortListBy }) => {
  return (
    <div className={`create-order-page__list-header ${className || ''}-header`}>
      {columns.map(({ header, onClick, sortable }, i) => {
        return sortable ? (
          <div key={i} className={`create-order-page__item ${className || 'no-class'}-header__${header}`}>
            <button onClick={onClick}>
              {header}
              <UpAndDownArrowsIcon
                className={`sort-arrows ${sortListBy.header === header ? 'on' : 'off'} ${
                  sortListBy.asc ? 'asc' : 'desc'
                }`}
              />
            </button>
          </div>
        ) : (
          <div key={i} className={`create-order-page__item ${className || 'no-class'}-header__${header}`}>
            <span>{header}</span>
          </div>
        )
      })}
    </div>
  )
}

export const SelectButtonInList = ({ text }) => {
  return (
    <div className="--select-button">
      <ArrowDownIcon />
      <span className="--select-button__text">{text}</span>
    </div>
  )
}

export const BackButtonInPage = ({ onClick, className }) => (
  <button onClick={onClick} className={`select-product__back-button ${className}__back-button `}>
    <BackButtonIcon /> Back
  </button>
)
