import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ChevronRightIcon } from 'assets/vector/icons'
import { StripeDropdown } from './Components'
import { MessageBubble } from 'components'
import './SubHeader.scss'
import { StoreBackButton } from 'pages/RecommendationPortal/Store/components'
import { useStoreNavigation } from './Components/hooks'

const SubHeader = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleDropdown = () => setIsOpen(!isOpen)
  const { pathname } = useLocation()
  const { backTo, showStoreBackButton } = useStoreNavigation()

  const showButton = useMemo(() => pathname?.includes('payment'), [pathname])

  return (
    <div className="subheader">
      <div className="subheader-top">
        {showStoreBackButton && <StoreBackButton backTo={backTo} />}
        {showButton && <OrderDetailsButton open={toggleDropdown} />}
      </div>
      <StripeDropdown isOpen={isOpen} close={toggleDropdown} />
      <MessageBubble />
    </div>
  )
}

const OrderDetailsButton = ({ open }) => (
  <button onClick={open} className="detail-open-btn">
    <span>Order Details</span>
    <ChevronRightIcon id="down-arrow" />
  </button>
)

export default SubHeader
