import { useCallback, useContext, useEffect, useState } from 'react'
import { MainContext } from 'context'
import { formatUpdateBody } from '../utils'
import { useUpdateOrder } from './hooks'

/*
We need to let the backend know about any changes to the order that
may have occured. 

This will sync the order with the backend on inital render of the payment form

Solves bug where users navigate to payment form with browswer forward button
*/
export const useSyncOrderWithBackend = () => {
  const { patient } = useContext(MainContext)
  const [isSynced, setSynced] = useState(false)
  const { updateOrder, isLoading } = useUpdateOrder({ orderId: patient.order.id })

  const syncWithBackend = useCallback(() => {
    if (!patient?.order || patient?.order?.status !== 'draft order') return
    updateOrder({ body: formatUpdateBody({ data: patient.order }) })
    setSynced(true)
  }, [updateOrder, patient?.order])

  useEffect(() => {
    if (!isSynced) syncWithBackend()
  }, [syncWithBackend, isSynced])

  return { synced: isSynced, isSyncing: isLoading }
}
