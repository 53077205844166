import { AlertModal } from 'components'
import { MainContext } from 'context'
import { useContext } from 'react'

const useAgree = (patientId = null, onSuccess = null) => {
  const { patient, modal } = useContext(MainContext)

  const {
    mutate: agree,
    isLoading,
    error,
  } = patient.api.agreeToTerms({
    variables: { patientId: patient?.order?.patientId || patientId },
    queryOptions: {
      onSuccess: () => {
        onSuccess?.()
        patient.actions.toggleHasAgreed(true)
      },
      onError: () => modal.actions.open(<AlertModal message={'There was an error.'} />),
    },
  })

  return { agree, isLoading, error }
}

export default useAgree
