import lastYearSvg from './lastYearPointStyle.svg'
import thisYearSvg from './thisYearPointStyle.svg'

///
const pointHW = 17
const thisYearPointStyle = new Image()
thisYearPointStyle.src = thisYearSvg
thisYearPointStyle.height = pointHW
thisYearPointStyle.width = pointHW
///
const lastYearPointStyle = new Image()
lastYearPointStyle.src = lastYearSvg
lastYearPointStyle.height = pointHW
lastYearPointStyle.width = pointHW
///

export { lastYearPointStyle, thisYearPointStyle }
