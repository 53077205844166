import { useContext, useEffect, useState, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { MainContext } from 'context'
import { removeNullValues } from 'utilities/utils'
import { SuccessModal, AlertModal, DeactivateModal, ActivateModal } from 'components/Modals'

export const useUserActions = user => {
  const { admin, modal } = useContext(MainContext)
  const queryClient = useQueryClient()
  const [avatar, setAvatar] = useState(null)
  const [body, setBody] = useState(null)
  const [type, setType] = useState(user ? 'edit' : 'create')

  const isDoc = user?.role === 'doctor'
  const isStaff = user?.role === 'staff' || user?.role === 'stafftier2'
  const isAdmin = user?.role === 'admin'

  const success = title => () => {
    modal?.actions?.open(<SuccessModal title={title} />)
    queryClient.invalidateQueries('user')
  }

  const error = msg => {
    queryClient.invalidateQueries('user')
    queryClient.invalidateQueries('avatar')
    modal?.actions?.open(<AlertModal message={msg} />)
  }

  const { mutate: createUser, isLoading: createLoading } = admin.api.user.createUser({
    queryOptions: {
      onSuccess: avatar ? data => postAvatar(data) : success('User created!'),
      onError: e =>
        error(
          e.message.includes('already taken')
            ? 'This email address has already been taken.'
            : 'There was an error creating the user.'
        ),
    },
  })

  const { mutate: editUser, isLoading: editLoading } = admin.api.user.editUser({
    queryOptions: {
      onSuccess: avatar ? () => postAvatar(user) : success('User updated!'),
      onError: () => error('Something went wrong'),
    },
  })

  const { mutate: deactivateUser, isLaoding: deactivateLoading } = admin.api.user.deactivateUser({
    queryOptions: {
      onSuccess: success('User deactivated!'),
      onError: () => error('Something went wrong.'),
    },
  })

  const { mutate: activateUser, isLaoding: activateLoading } = admin.api.user.activateUser({
    queryOptions: {
      onSuccess: success('User Activated!'),
      onError: () => error('Something went wrong.'),
    },
  })

  const editMsg = isDoc && !user?.isActive ? 'Provider Invite Sent' : 'User updated!'
  const createMsg = 'User Created'
  const { mutate: postStaffAvatar, isLoading: staffAvatarLoading } = admin.api.user.addStaffAvatar({
    fetchProps: {
      headers: {
        Accept: 'application/json',
      },
    },
    queryOptions: {
      onSuccess: success(type === 'edit' ? editMsg : createMsg),
      onError: data => error('Could not add the user avatar ' + data?.message),
    },
  })

  const { mutate: postDocAvatar, isLoading: docAvatarLoading } = admin.api.user.addDocAvatar({
    fetchProps: {
      headers: {
        Accept: 'application/json',
      },
    },
    queryOptions: {
      onSuccess: success(type === 'edit' ? 'User updated!' : 'User created'),
      onError: data => error('Could not add the user avatar ' + data?.message),
    },
  })

  const { mutate: postAdminAvatar, isLoading: adminAvatarLoading } = admin.api.user.addAdminAvatar({
    fetchProps: {
      headers: {
        Accept: 'application/json',
      },
    },
    queryOptions: {
      onSuccess: success(type === 'edit' ? 'User updated!' : 'User created'),
      onError: data => error('Could not add the user avatar ' + data?.message),
    },
  })

  const buildBody = useCallback(
    (isAdmin, { delegatedAccess, ...values }) => {
      const specialties = isDoc && values?.specialties ? Object?.values?.(values?.specialties)?.flat() || [] : null

      const getRole = () => {
        if (isAdmin) return 'admin'
        //if they select delegated access on user modal
        else if (delegatedAccess) return 'stafftier2'
        // if there is already a role on the user
        else if (user?.role) return user?.role
        // if the user is a physician
        else if (values?.isPhysician === 'Y') return 'doctor'
        else return 'staff'
      }

      return removeNullValues({
        npi: isAdmin ? null : user?.npi || null,
        ...user,
        ...values,
        isActive: isDoc ? true : user ? user.isActive : true,
        practiceId: isAdmin ? null : admin.practices.one.id,
        role: getRole(),
        isPhysician: isAdmin ? null : values?.isPhysician === 'Y' ? true : false,
        relatedDoctors: null,
        sendInvite: !user?.isActive ? true : false,
        specialties,
      })
    },
    [admin?.practices?.one?.id, isDoc, user]
  )

  const postAvatar = useCallback(
    data => {
      const fd = new FormData()
      fd.append('avatar', avatar)
      if (isStaff) {
        postStaffAvatar({ variables: { userId: data?.userID }, body: fd })
      } else if (isAdmin) {
        postAdminAvatar({ variables: { userId: data?.userID }, body: fd })
      } else {
        postDocAvatar({ variables: { userId: data?.userID }, body: fd })
      }
    },
    [avatar, postDocAvatar, postStaffAvatar, isAdmin, isStaff, postAdminAvatar]
  )

  /// update body first before trying avatar
  useEffect(() => {
    if (body) {
      user?.userID ? editUser({ body }) : createUser({ body })
    }
    return () => setBody(null)
  }, [body, createUser, setBody, editUser, user?.userID])

  //for only avatar updates
  useEffect(() => {
    if (avatar && !body) {
      postAvatar(user)
    }
    return () => setAvatar(null)
  }, [postAvatar, avatar, body, user])

  return {
    buildBody,
    editUser: (body, avatar) => {
      setType('edit')
      avatar && setAvatar(avatar)
      setBody(body)
    },
    createUser: (body, avatar) => {
      setType('create')
      avatar && setAvatar(avatar)
      setBody(body)
    },
    isLoading:
      editLoading ||
      adminAvatarLoading ||
      createLoading ||
      deactivateLoading ||
      activateLoading ||
      docAvatarLoading ||
      staffAvatarLoading,
    deactivate: user => {
      modal.actions.open(
        <DeactivateModal
          name={`${user.firstName} ${user.lastName}`}
          onConfirm={() => deactivateUser({ variables: { userId: user?.userID } })}
          denyText={'Cancel'}
        />
      )
    },
    reactivate: user =>
      modal?.actions?.open(
        <ActivateModal
          name={`${user.firstName} ${user.lastName}`}
          onConfirm={() => activateUser({ variables: { userId: user?.userID } })}
          denyText={'Cancel'}
        />
      ),
  }
}
