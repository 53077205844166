import './FullscreenImage.scss'
import { defaultProductImg } from 'assets/raster'
import { useSvgButton } from 'utilities/hooks'
import { HamburderMenuXIcon } from 'assets/vector'
import { useOutsideClick } from 'utilities/hooks'
import { useRef } from 'react'
import { ImageSlider } from 'components/Global'

export const FullscreenImage = ({ image, closeModal, ...rest }) => {
  const imgRef = useRef()
  useOutsideClick({ ref: imgRef, customAction: closeModal })

  return (
    <div className="fullscreen-modal-wrap">
      {useSvgButton(<HamburderMenuXIcon className="close-button__white" role="button" onClick={closeModal} />)}
      <img
        ref={imgRef}
        src={image}
        onError={({ currentTarget }) => (currentTarget.src = defaultProductImg)}
        alt="product"
      />
    </div>
  )
}

export const FullscreenImages = ({ images, closeModal, label, ...rest }) => {
  const sliderRef = useRef()
  useOutsideClick({ ref: sliderRef, customAction: closeModal })

  return (
    <div className="fullscreen-modal-wrap slider">
      {useSvgButton(<HamburderMenuXIcon className="close-button__white" role="button" onClick={closeModal} />)}
      <ImageSlider
        ref={sliderRef}
        showArrows={false}
        className={'fullscreen-image-slider'}
        images={images}
        fallbackImage={defaultProductImg}
      />
      {label && <span className="slider-label">{label}</span>}
    </div>
  )
}
