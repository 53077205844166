import { Table } from 'components'
import './Alternatives.scss'
import { TrashIcon } from 'assets/vector'
import { Button, Loader } from 'components/Global'
import useAlternates from './useAlternates'
import { Products } from 'pages/Admin/Products'

const columns = [
  { Header: 'PRODUCT', accessor: 'product' },
  { Header: 'MANUFACTURER', accessor: 'manu' },
  { Header: 'CATEGORIES', accessor: 'categories', unsortable: true },
  { Header: 'PRODUCT #', accessor: 'productNum' },
  // { Header: 'HCPCS', accessor: 'hcpcs' },
  { Header: 'PRICE', accessor: 'price' },
  { Header: <TrashIcon className="trash-icon-header" />, accessor: 'delete', unsortable: true },
]

export const Alternatives = () => {
  const { data, isLoading, error, setShowProductList, showProductList, addAlternate } = useAlternates()

  // const buttonAction = () => (data?.length ? console.log('Change alternates') : console.log('Add Alternates'))
  const toggleProductList = () => setShowProductList(!showProductList)
  const chooseAlternate = product => {
    addAlternate(product)
    toggleProductList()
  }

  //** Here they will choose the alternate product */
  if (showProductList) return <Products chooseAltProduct={chooseAlternate} />

  return (
    <div
      className={`edit-product-alternatives${isLoading || error ? '--alert' : !data?.length ? '--show-button' : ''}`}
    >
      {isLoading ? (
        <Loader />
      ) : error ? (
        <span className="error-msg--alternates">There was an error getting alternate products.</span>
      ) : data?.length ? (
        <>
          <h5>Out of Stock Alternate Product</h5>
          <Table
            className="edit-product-alternatives__table"
            loading={isLoading}
            error={error}
            data={{ columns, data }}
          />
          <Button onClick={toggleProductList} className="action-button">
            Change Alternate Product
          </Button>
        </>
      ) : (
        <Button onClick={toggleProductList} className="edit-product-alternatives__add-alternate-button">
          Add Alternate Product
        </Button>
      )}
    </div>
  )
}
