import { Field } from 'react-final-form'

import { AlertIcon, YesNoSlider } from 'assets/vector/icons'
import { svgButton } from 'utilities/utils'

import './YesNoToggle.scss'

/** @param {import('react-final-form').FieldProps} props */
const YesNoToggle = ({ label, canChangeOnError, ...props }) => {
  return (
    <Field name={props.name} {...(props || {})}>
      {
        /** @param {import('react-final-form').FieldRenderProps} field */

        ({ input, meta, ...rest }) => {
          return (
            <div className={'yes-no-toggle'}>
              <label className={'yes-no-toggle__label'}>{label || ''}</label>
              {svgButton(
                <YesNoSlider
                  onClick={() => {
                    if (meta.error && !canChangeOnError) return input.onBlur()
                    input.onBlur()
                    input.onChange(!input.value)
                  }}
                  className={`slider--${input.value ? 'active' : 'unactive'}`}
                  {...rest}
                />
              )}
              {meta.error && meta.touched && (
                <span className={'error'}>
                  <AlertIcon className="error__icon" />
                  {meta.error}
                </span>
              )}
            </div>
          )
        }
      }
    </Field>
  )
}

export default YesNoToggle
