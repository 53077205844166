export const setMarker = (a, b) => {
  if (!a) return true
  if (a[0].toUpperCase() !== b[0].toUpperCase()) return true
  return false
}

/**
 *
 * @param {boolean} asc should sort ascending
 * @param {string} keyName key to access for sorting
 * @param {boolean} lastName should split the name and grab last
 * @returns {sortFn} sortFn
 */
export const sortData =
  (asc, keyName, lastName = false) =>
  (a, b) => {
    let aName = null
    let bName = null
    if (lastName) {
      const aSplit = a?.[keyName]?.split(' ')
      const bSplit = b?.[keyName]?.split(' ')
      aName = aSplit[aSplit.length - 1].toUpperCase() || null
      bName = bSplit[bSplit.length - 1].toUpperCase() || null
    } else {
      aName = a?.[keyName]?.toUpperCase() || null
      bName = b?.[keyName]?.toUpperCase() || null
    }

    const order = aName < bName ? -1 : aName > bName ? 1 : 0
    return asc ? order * -1 : order
  }
