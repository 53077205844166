import { AlertModal, BlockPatientReason, SuccessModal } from 'components'
import { useCallback, useContext } from 'react'
import './Patients.scss'
import { MainContext } from 'context'
import { useQueryClient } from 'react-query'

const useToggleBlockPatients = () => {
  const { admin, modal } = useContext(MainContext)
  const qc = useQueryClient()

  const onSuccess = useCallback(
    ({ firstName, lastName, blockNotifications }) => {
      qc.invalidateQueries('patient_list')
      const title = `${firstName} ${lastName} has been ${blockNotifications ? 'blocked.' : 'unblocked!'}.`
      modal.actions.open(<SuccessModal title={title} />)
    },
    [modal?.actions, qc]
  )

  const { mutate, ...rest } = admin.api.blockedPatients.toggleBlockPatient({
    queryOptions: {
      onSuccess,
      onError: () => modal.actions.open(<AlertModal message="There was an error" />),
    },
  })

  const handleBlockPatient = useCallback(
    patient =>
      modal.actions.open(
        <BlockPatientReason
          message={`Are you sure you want to block ${patient.firstName} ${patient.lastName}?`}
          onConfirm={reason => {
            mutate({ variables: { patientId: patient.id }, body: { blockNotification: true, reason } })
          }}
        />
      ),
    [modal?.actions, mutate]
  )

  const handleUnblockPatient = useCallback(
    patient =>
      modal.actions.open(
        <AlertModal
          message={`Are you sure you want to unblock ${patient.firstName} ${patient.lastName}?`}
          denyText={'Cancel'}
          onConfirm={() => {
            mutate({ variables: { patientId: patient.id }, body: { blockNotification: false, reason: '' } })
          }}
        />
      ),
    [mutate, modal?.actions]
  )

  const handleSelectPatient = useCallback(
    patient => (patient.blockNotifications ? handleUnblockPatient(patient) : handleBlockPatient(patient)),
    [handleBlockPatient, handleUnblockPatient]
  )

  return { handleSelectPatient, ...rest }
}

export default useToggleBlockPatients
