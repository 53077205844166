import { ProviderList } from 'components/AlphabeticList'
import { useCallback, useContext } from 'react'
import { MedBotsContext } from '../context'
import { PageHeader } from './PageHeader'
import { useNavigate } from 'react-router-dom'

export const AddDoctors = () => {
  const nav = useNavigate()
  const { actions, medbotId } = useContext(MedBotsContext)

  const onSelectProvider = useCallback(
    provider => {
      actions.addDoctor(provider)
      nav(`/admin/medbots/${medbotId || 'review'}`)
    },
    [actions, nav, medbotId]
  )

  return (
    <>
      <PageHeader title="Add A Provider" onBack={() => nav(-1)} />
      <ProviderList onSelectProvider={onSelectProvider} />
    </>
  )
}

///PROVIDER EXAMPLE
/*
{
    "doctorId": 224,
    "name": "Albert Snape",
    "avatarID": 3724,
    "avatarUrl": "https://medtrixfilesqa.blob.core.windows.net/avatars/21d6b3b1-d439-400b-9a1d-d0d4ded05fc0.jpeg",
    "practiceId": 327,
    "providerType": "Doctor"
}
        */
