import { FormButtons } from '../FormButtons'
import { Input, ScrollToTop } from 'components'
import { useClearTotals } from './useClearTotals'
import { Totals } from '../Components'
import { useBubbleProps } from 'utilities/hooks'
import { Form } from 'react-final-form'
import './CreditCardInfo.scss'
import { CardNumber, Cvc, ExpireDate, Zip } from 'components/Global/Input/MaskedInputs'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import useProcessPayment from './useProcessPayment'

dayjs.extend(customParseFormat)

export const CreditCardInfo = () => {
  useBubbleProps({
    custom: {
      title: '',
      message:
        'Please enter your credit card details. This transaction will appear as MEDBOT on your credit card statement.',
    },
  })

  const { mustResubmitForTaxAndShipping } = useClearTotals()

  return mustResubmitForTaxAndShipping ? null : <CreditCardForm />
}

function CreditCardForm() {
  const { processPayment, isLoading } = useProcessPayment()

  return (
    <>
      <ScrollToTop />
      <div>
        <div className="payment-form__section-header">
          <h3>Payment INFORMATION</h3>
        </div>

        <div className="payment-form__credit-card">
          <Form
            onSubmit={processPayment}
            render={props => (
              <form onSubmit={props.handleSubmit}>
                <Input
                  required
                  maskedInput={CardNumber}
                  name="cardNumber"
                  label="Card Number"
                  placeholder="1234 1234 1234 1234"
                  validate={value => {
                    return value?.length > 14 ? undefined : 'Invalid Card Number'
                  }}
                />
                <div className="payment-form__credit-card--row">
                  <Input
                    required
                    maskedInput={ExpireDate}
                    name="expiration"
                    label="Expiration"
                    validate={value => {
                      const [month] = value.split('/')
                      const now = dayjs()
                      const expirationDate = dayjs(value, 'MM/YY')
                      if (+month > 12) return 'Invalid Date'
                      if (expirationDate.format('MM/YY') === 'Invalid Date') return 'Invalid Expiration'
                      if (now.isAfter(expirationDate)) return 'Invalid Expiration'
                    }}
                  />
                  <Input required maskedInput={Cvc} name="cvc" label="CVC" />
                </div>
                <Input required maskedInput={Zip} name="postalCode" label="Postal Code" placeholder="12345" />
                <FormButtons
                  disabled={isLoading}
                  onSubmit={props.handleSubmit}
                  text={isLoading ? 'Loading...' : 'Continue'}
                />
              </form>
            )}
          />
        </div>
      </div>
      <Totals />
    </>
  )
}
