import { Route, Routes } from 'react-router-dom'
import { SelectPatientForWellnessStore } from './SelectPatient'
import { SelectProviderForSendWellness } from './SelectProvider'
import { SendWellnessProvider } from './context'
import { PatientList } from './PatientList'

export const SendWellness = () => {
  return (
    <SendWellnessProvider>
      <Routes>
        <Route path="" element={<SelectProviderForSendWellness />} />
        <Route path="patient" element={<SelectPatientForWellnessStore />} />
        <Route path="patient/search" element={<PatientList />} />
      </Routes>
    </SendWellnessProvider>
  )
}

export const SendWellnessScreens = {
  SelectPatient: SelectPatientForWellnessStore,
  SelectProvider: SelectProviderForSendWellness,
}
