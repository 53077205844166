import { Form } from 'react-final-form'
import './Search.scss'
import { Input, Button } from 'components/Global'
import { SearchIcon } from 'assets/vector'

const Search = ({ requireInputBeforeSearchable = false, onSubmit, name, placeholder, className, defaultValue }) => {
  return (
    <Form onSubmit={onSubmit || (v => console.log(v))}>
      {({ handleSubmit, values }) => (
        <form className={`search-bar ${className || ''}`} onSubmit={handleSubmit}>
          <SearchIcon />
          <Input
            aria-label="search-bar"
            defaultValue={defaultValue || null}
            placeholder={placeholder || 'Search'}
            name={name || 'query'}
          />
          <Button disabled={requireInputBeforeSearchable && !values?.[name]}>Search</Button>
        </form>
      )}
    </Form>
  )
}

export default Search
