import { AddIcon, DocFileIcon, VideoFileIcon, DeleteFileIcon } from 'assets/vector'
import { AlertModal } from 'components'
import { Loader } from 'components/Global'
import { MainContext } from 'context'
import { useContext, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { customQueryKeys } from 'utilities/constants'
import { useSvgButton, useModals } from 'utilities/hooks'
import { svgButton } from 'utilities/utils'

const keys = [customQueryKeys.EDIT_PRODUCT]

export const Documents = () => {
  const { admin, modal } = useContext(MainContext)
  const { practiceId, productId } = useParams()
  const { error, openSuccessModal } = useModals()

  const { data, refetch } = admin?.api?.products?.getProductMedia({ variables: { practiceId, productId }, keys })

  const success = useCallback(
    msg => () => {
      refetch()
      openSuccessModal(msg)
    },
    [openSuccessModal, refetch]
  )

  const { mutate, isLoading } = admin.api.products.uploadFile({
    variables: { practiceId, productId },
    fetchProps: {
      headers: {
        Accept: 'application/json',
      },
    },
    queryOptions: {
      onSuccess: success('File Uploaded!'),
      onError: error('Could not upload the file.'),
    },
  })

  const { mutate: deleteFile, isLoading: delIsLoading } = admin.api.products.deleteFile({
    queryOptions: {
      onSuccess: success('File Deleted.'),
      onError: error('Could not delete the file.'),
    },
  })

  const postFile = useCallback(
    file => {
      const fd = new FormData()
      fd.append('file', file)
      fd.append('shortName', file.name)
      mutate({ body: fd })
    },
    [mutate]
  )

  const mapFiles = useCallback(() => {
    return data?.map((file, i) => (
      <span className="file-item-wrap" key={i}>
        {svgButton(
          <DeleteFileIcon
            className="delete-file-icon"
            onClick={() => {
              modal.actions.open(
                <AlertModal
                  message="Are you sure you want to delete this file?"
                  onConfirm={() => {
                    deleteFile({ variables: { fileId: file.id } })
                    modal.actions.close()
                  }}
                  onDeny={() => modal.actions.close()}
                  denyText={'cancel'}
                />
              )
            }}
          />
        )}
        {file?.contentType?.includes('video') ? <VideoFileIcon id="file-icon" /> : <DocFileIcon id="file-icon" />}
        <p>{file.shortName || '--'}</p>
      </span>
    ))
  }, [data, deleteFile, modal.actions])

  return (
    <div className="edit-product-main__left--documents">
      <span id="sub-header">Product Documents, Images, and Videos</span>
      <span id="notice">
        Please select a file smaller than 30MB. <br /> Videos must be in mp4 format.
      </span>
      {isLoading || delIsLoading ? (
        <Loader />
      ) : (
        <div className="documents-list">
          {mapFiles(data)}
          <AddFile postFile={postFile} />
        </div>
      )}
    </div>
  )
}

const AddFile = ({ postFile }) => {
  const fileBtn = useRef()

  const handleChange = e => postFile(e.target.files[0])

  return (
    <span className="add-file-btn">
      <input ref={fileBtn} onChange={handleChange} type="file" style={{ display: 'none' }} />
      {useSvgButton(<AddIcon onClick={() => fileBtn.current.click()} id="add-file-icon" />)}
      <p>Add File, Img, or Video</p>
    </span>
  )
}
