import { PATIENT_REQUEST_PASSWORD_RESET } from 'apiv2'
import { ADMIN_REQUEST_RESET_PASSWORD } from 'apiv2/calls/admin'
import { MainContext } from 'context'
import { useContext } from 'react'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import { SuccessModal } from '../Success'
import { AlertModal } from '../Alert'

const endpointMap = {
  '/admin': ADMIN_REQUEST_RESET_PASSWORD,
  '/patient': PATIENT_REQUEST_PASSWORD_RESET,
}

export function useRequestPasswordReset() {
  const { pathname } = useLocation()
  const { modal } = useContext(MainContext)

  const mutation = useMutation(endpointMap?.[pathname], {
    onSuccess: () => modal.actions.open(<SuccessModal title="Recovery email has been sent." />),
    onError: () => modal.actions.open(<AlertModal title="There was an error sending recovery email." />),
  })

  /**
   * @param {String} email
   */
  const sendEmail = email => mutation.mutate(email)

  return { sendEmail, ...mutation }
}
