import { useNavWithParams } from 'utilities/hooks'
import { useEffect } from 'react'

export const makePaymentBody = ({ values, data, patient }) => {
  return {
    City: values?.city || null,
    Address1: values?.address1,
    Address2: '',
    Zip: values?.zip,
    State: values?.state,
    NameOnCard: values?.nameOnCard,
    CreditCardNumber: values?.creditCardNumber || null,
    ExpirationMo: values?.expirationMo,
    ExpirationYr: values?.expirationYr,
    Cvc: values?.cvc,
    Email: values?.email || null,
    Token: patient?.order?.patientToken?.value || null,
    PatientToken: patient?.order?.patientToken?.value || null,
    OrderAmount: data?.totalPrice,
    PatientEmail: data?.patientEmail,
    OrderId: data?.localId || data?.id,
    PatientId: data?.patientId,
    PatientFirstName: values?.firstName || null,
    PatientLastName: values?.lastName || null,
    PracticeId: data?.practiceId,
    DoctorId: data?.doctorId,
    HasOutOfStock: checkForOutOfStock(data?.LineItems || data?.lineItems),
    StripePatientCustomerId: '',
    Order: {
      ...patient?.order,
      localId: patient?.order?.id,
    },
  }
}

export function formatPayment(vals) {
  const exp = vals?.expireDate.split('/')

  vals.creditCardNumber = vals.creditCardNumber?.toString()
  vals.expirationMo = +exp?.[0]
  vals.expirationYr = +exp?.[1]
  return vals
}

export const formatUpdateBody = ({ data }) => {
  let localOrderId = data?.localOrderId || data?.id || data?.orderId || data?.localId || 0

  return {
    isChanged: true,
    practiceId: data?.practiceId,
    localOrderId,
    medbotId: data?.medbotId || null,
    shopifyOrderId: null,
    // patient: null,
    // doctor:
    //   data?.doctorUserId || data?.doctor?.userID
    //     ? {
    //         userID: data?.doctorUserId || data?.doctor?.userID,
    //         practiceID: data?.practiceId,
    //       }
    //     : null,
    LineItems: formatLineItems(data?.LineItems || data?.lineItems),
    shopifyDraftOrderId: data?.shopifyDraftOrderId || null,
    shipAddress: null,
    isNewOrder: !data?.medbotId && !data?.shopifyDraftOrderId && !data?.localOrderId,
  }
}

const checkForOutOfStock = LineItems => LineItems?.some(item => item?.isOutOfStock)

const formatLineItems = lineItems => {
  /* 
  we have to make sure there are no out of stock items in the verify
  also have to lift the variantId to the main lineItem object for each lineItem
  */
  return lineItems?.reduce(
    (acc, item) =>
      item?.isOutOfStock ? acc : [...acc, { ...item, variantId: item?.variantId || item?.variants[0].variantId }],
    []
  )
}

const payableOrders = ['draft order', 'aging retail']

export const useRedirectIfPaid = order => {
  const nav = useNavWithParams()

  useEffect(() => {
    if (!Object.keys?.(order)?.length) return
    //redirecting back to order screen if the order has been paid
    if (!payableOrders.includes(order?.status)) {
      nav(`/order/${order?.id || order?.orderId || order?.localId}`)
    }
  }, [order, nav])
}
