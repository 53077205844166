import { useMemo } from 'react'
import './PromoMessage.scss'
import { MessageBubbleGlobal } from 'components/MessageBubble/MessageBubble'

export const PromoMessage = ({ provider }) => {
  const displayDr = `Dr. ${provider?.name?.split(' ')[1] || ''}`
  const displayOther = `${provider?.name}, ${provider?.providerType}`
  const toDisplay = provider?.providerType?.toLowerCase() === 'doctor' ? displayDr : displayOther

  const bubbleProps = useMemo(
    () => ({
      image: provider?.avatarUrl || '',
      title: `Hi, it's ${toDisplay}!`,
      message: `Please see the medical products I have recommended for you.`,
      className: 'review-order-promo__bubble',
    }),
    [provider, toDisplay]
  )

  if (!provider || !provider?.name) return null

  return (
    <div className="review-order-promo">
      <span className="review-order-promo__title">Promotion Message</span>
      <MessageBubbleGlobal {...bubbleProps} />
    </div>
  )
}
