import { Form } from 'react-final-form'
import './EditName.scss'
import { Button, Input } from 'components'
import { useContext } from 'react'
import { MainContext } from 'context'

export const EditName = ({ currentName, onUpdate }) => {
  const { modal } = useContext(MainContext)
  return (
    <div className="edit-medbot-name">
      <Form
        onSubmit={vals => {
          onUpdate(vals)
          modal.actions.close()
        }}
        initialValues={{ medBotName: currentName }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Input name="medBotName" label="New MedBot Name" />
            <div className="actions">
              <Button onClick={handleSubmit}>Update Name</Button>
              <Button type="button" onClick={modal.actions.close}>
                Cancel
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  )
}
