import { PATIENT_RESET_PASSWORD } from 'apiv2'
import { ADMIN_RESET_PASSwORD } from 'apiv2/calls/admin'
import { SuccessModal } from 'components'
import { MainContext } from 'context'
import { useContext } from 'react'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useParamsToObject } from 'utilities/hooks'

const endpointMap = {
  admin: ADMIN_RESET_PASSwORD,
  patient: PATIENT_RESET_PASSWORD,
}

export function useResetPassword(create = false) {
  const nav = useNavigate()
  const { modal } = useContext(MainContext)
  const { portal } = useParams() //path params
  const { token, email: emailQuery } = useParamsToObject() //query params

  const mutation = useMutation(endpointMap?.[portal] || (() => Promise.reject()), {
    onSuccess: () => {
      if (portal === 'patient') {
        nav('/patient', { replace: true })
      } else {
        nav('/admin', { replace: true })
      }
      modal.actions.open(<SuccessModal title={`Password has been ${create ? 'created' : 'reset'}!`} />)
    },
  })

  /**
   * @param {String} newPassword
   * @param {?String} email
   */
  const onResetPassword = ({ newPassword, email = emailQuery }) => mutation.mutate({ newPassword, email, token })

  return { onResetPassword, ...mutation }
}
