import { medbotLoadingData } from 'assets/lottie'
import { MedBotDarkBlue } from 'assets/vector'
import { Button, ForgotPassword, Input, ModalContainer } from 'components'
import { useModal } from 'context/Modal/ModalContext'
import { useCallback, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import Lottie from 'react-lottie-player'
import './LoginForm.scss'

export function LoginForm({ login, loading, isError }) {
  const { actions } = useModal()
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    let timer = setTimeout(() => setAnimate(true), 3000)
    return () => clearInterval(timer)
  }, [])

  return (
    <main className="login admin">
      <ModalContainer isAdmin />
      <Lottie
        className={`loading-medbot ${animate && 'shrink'}`}
        role="img"
        play
        loop={true}
        animationData={medbotLoadingData}
      />
      <Form
        onSubmit={values => login(values)}
        initialValues={{}}
        render={useCallback(
          ({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={`login-form admin ${animate && 'show'}`}>
              <MedBotDarkBlue className="medtrix-logo-horizontal-admin" />
              <Input label="Email" type="text" />
              <Input label="Password" type="password" />
              <button
                type="button"
                className="forgot-password"
                onClick={() => actions?.open(<ForgotPassword />)}
                style={{ textDecoration: 'underline' }}
              >
                Forgot Password?
              </button>
              <Button type="submit">{loading ? 'loading...' : 'Login'}</Button>
              {isError && <p className="error-msg">There was an error logging in.</p>}
            </form>
          ),
          [isError, loading, animate, actions]
        )}
      />
    </main>
  )
}
