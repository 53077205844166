import { Loader, Search } from 'components/Global'
import useSortListBy from '../useSortListBy'
import { setMarker } from '../utils'
import './PaginatedPatientList.scss'
import { PatientListItem } from './PatientListItem'
import { useGetPatients } from './useGetPatients'
import { useMemo } from 'react'
import { AlphaListHeader } from '../peices'
import { ChevronRightIcon } from 'assets/vector'

export const PaginatedPatientList = ({ onSelectPatient, practiceId, initSearchKeyword = null }) => {
  const [sortListBy, setSortListBy] = useSortListBy(initSearchKeyword)
  const { data, isLoading, error, paginate } = useGetPatients({ sortListBy, practiceId })

  const search = keyword => {
    paginate.setPage(1)
    setSortListBy({ asc: false, keyword: keyword?.keyword?.replace(/\\/g, '') || '' })
  }

  const mapPatients = () => {
    let prevPatient = null
    return isLoading ? (
      <Loader />
    ) : (
      data?.map((patient, i) => {
        let addTab = setMarker(prevPatient, patient.lastName)
        prevPatient = patient.lastName
        return <PatientListItem key={i} addTab={addTab} onSelectPatient={onSelectPatient} patient={patient} />
      })
    )
  }

  const columns = useMemo(
    () => [
      {
        header: 'Patient Last Name',
        sortable: true,
        onClick: () => setSortListBy({ asc: !sortListBy.asc, header: 'Patient Last Name' }),
      },
      {
        header: 'Patient First Name',
        sortable: true,
        onClick: () => setSortListBy({ asc: !sortListBy.asc, header: 'Patient First Name' }),
      },
      {
        header: 'Patient Date Of Birth',
        sortable: false,
      },
    ],
    [setSortListBy, sortListBy]
  )

  return (
    <>
      <div className="paginated-patient-wrapper">
        <div className="paginated-patient-search">
          <Search defaultValue={sortListBy.keyword} onSubmit={search} name="keyword" placeholder={'Search Patients'} />
        </div>
        <AlphaListHeader sortListBy={sortListBy} className={'paginated-patient'} columns={columns} />
        <ol className="create-order-page__alphabetic-list paginated-patient-list">
          {!practiceId ? (
            <li className="paginated-patient-error__no-practice-id">Please choose a practice to view its patients.</li>
          ) : error ? (
            <li className="paginated-patient-error">There was an error</li>
          ) : (
            mapPatients()
          )}
        </ol>
      </div>
      <Paginate data={data} {...paginate} />
    </>
  )
}

const Paginate = ({ data, pagesize, setPage, page }) => {
  const prevShow = useMemo(() => page > 1 && !!data?.length, [page, data])
  const nextShow = useMemo(() => data?.length === pagesize, [pagesize, data])

  return (
    <div className="paginated-patient-pagination">
      <button
        disabled={!prevShow}
        className={`paginated-patient-pagination__prev-btn ${prevShow ? 'show' : 'hide'}`}
        onClick={() => setPage(page > 1 ? page - 1 : 1)}
      >
        <ChevronRightIcon className="flip" />
        Previous
      </button>

      <div className={`paginated-patient-pagination__page-actual ${data?.length || page > 1 ? 'show' : 'hide'}`}>
        <span>{page}</span>
      </div>

      <button
        disabled={!nextShow}
        className={`paginated-patient-pagination__next-btn ${nextShow ? 'show' : 'hide'}`}
        onClick={() => setPage(page + 1)}
      >
        Next
        <ChevronRightIcon />
      </button>
    </div>
  )
}
