import { useMemo, useCallback } from 'react'
import { AlertModal } from 'components'
import { useFetch } from 'utilities/api'
import { useAnalytics, useReportsV2, useWellnessStore } from './api'

const keys = {
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
  PRACTICE: 'PRACTICE',
  PRACTICES: 'PRACTICES',
  USER: 'USER',
  SET_ORDER_SUMMARY: 'SET_ORDER_SUMMARY',
  SET_COMBINED_REPORTS: 'SET_COMBINED_REPORTS',
}

const sessionToken = sessionStorage?.getItem?.('medtrix_admin_token')
const sessionUser = JSON.parse(sessionStorage?.getItem?.('medtrix_admin_user'))

export const AdminReducer = (draft, { payload, type }) => {
  const { LOG_IN, LOG_OUT, PRACTICE, PRACTICES, USER, SET_ORDER_SUMMARY, SET_COMBINED_REPORTS } = keys
  switch (type) {
    case LOG_IN: {
      // localStorage?.setItem?.('medtrix_admin_token', payload)
      sessionStorage?.setItem?.('medtrix_admin_token', payload)
      return void (draft.authentication = payload)
    }
    case USER: {
      const { avatarUrl, ...rest } = payload
      sessionStorage?.setItem?.('medtrix_admin_user', JSON.stringify(rest))
      return void (draft.user = payload)
    }
    case LOG_OUT: {
      sessionStorage?.removeItem?.('medtrix_admin_token')
      sessionStorage?.removeItem?.('medtrix_admin_user')
      return void (draft.authentication = null)
    }
    case PRACTICE: {
      return void (draft.practices.one = payload)
    }
    case PRACTICES: {
      return void (draft.practices.many = payload)
    }
    case SET_ORDER_SUMMARY: {
      return void (draft.orderSummary = payload)
    }
    case SET_COMBINED_REPORTS: {
      return void (draft.combinedReports = payload)
    }
    default:
      return draft
  }
}

export const useAdminActions = (dispatch, token, modalActions) => {
  const logOut = useCallback(() => {
    dispatch({ type: keys.LOG_OUT })
  }, [dispatch])

  const unauthorized = useCallback(() => {
    modalActions?.open(<AlertModal title="Session Expired" message="For your security, you have been logged out." />)
    logOut()
  }, [logOut, modalActions])

  return {
    // @blocksort recursive
    api: {
      analyticsV2: useAnalytics(token, unauthorized),
      reportsV2: useReportsV2(token, unauthorized),
      wellnessStore: useWellnessStore(token, unauthorized),
      blockedPatients: {
        getPatientList: useFetch({
          method: 'GET',
          /*
          params available:
          page=1
          pagesize=100
          sortOrder=ASC | DESC
          searchText=searchKeyword
          */
          endpoint: '/patients/getPagedPatientList/{practiceId}',
          token,
          unauthorized,
        }),
        getBlockedList: useFetch({
          method: 'GET',
          endpoint: '/patients/getBlockedPatientList/{practiceId}',
          token,
          unauthorized,
        }),
        toggleBlockPatient: useFetch({
          method: 'POST',
          endpoint: '/patients/blocknotifications/{patientId}',
          token,
          unauthorized,
        }),
      },
      createEditMedbots: {
        deleteMedbot: useFetch({ method: 'DELETE', endpoint: '/medbot/delete/{medbotId}', token, unauthorized }),
        // updateMedbot: useFetch({ method: 'PUT', endpoint: '/medbot/update/{medbotId}', token, unauthorized }), moved to apiv2
        createMedBot: useFetch({ method: 'POST', endpoint: '/medbot/CreateV2/{practiceId}', token, unauthorized }),
        getMedBotDetails: useFetch({ method: 'GET', endpoint: '/orders/getmedbots/{medbotId}', token, unauthorized }),
      },
      createOrder: {
        isTextEnabled: useFetch({
          method: 'GET',
          endpoint: '/practices/textenabled/{practiceId}',
          token,
          unauthorized,
        }),
        createDraftOrder: useFetch({
          method: 'POST',
          endpoint: '/shopify/createdraftorder/{practiceId}',
          token,
          unauthorized,
        }),
        sendOrder: useFetch({ method: 'POST', endpoint: '/orders/SendOrdertoPatients/{orderId}', token, unauthorized }),
        getPatients: useFetch({
          method: 'GET',
          endpoint: '/patients/getPatientList/{practiceId}',
          token,
          unauthorized,
        }),
        getProvider: useFetch({
          method: 'GET',
          endpoint: '/user/getpracticedoctors{practiceId}',
          token,
          unauthorized,
        }),
        getMedbots: useFetch({ method: 'GET', endpoint: '/medbot/bundles{practiceId}', token, unauthorized }),
      },
      analytics: {
        //add slash before practice id IF there is one
        getBots: useFetch({ method: 'GET', endpoint: '/medbot/analytics{practiceId}', token, unauthorized }),
      },
      dashboard: {
        getAll: useFetch({ method: 'GET', endpoint: '/reports/dashboard/{practiceId}', token, unauthorized }),
        getCombinedTopProducts: useFetch({
          method: 'GET',
          ///add / before practice id
          endpoint: '/reports/topproductscombined{practiceId}',
          token,
          unauthorized,
        }),
        getCombinedReports: useFetch({
          method: 'GET',
          // be sure to add the / before practice id if practice user
          endpoint: '/reports/combinedtopreports{practiceId}',
          token,
          unauthorized,
        }),
        getLineCharts: useFetch({ method: 'GET', endpoint: '/reports/topproductslinechart', token, unauthorized }),
        /* for endpoints, if you want to get ALL practices, set practiceId="" */
        getSales: useFetch({
          endpoint: '/medbot/physician/analytics/{practiceId}',
          method: 'GET',
          token,
          unauthorized,
        }),
        /// !!! ID MUST BE SET TO '/practiceId' FOR A SPECIFIC PRACTICE, NOT JUST 'practiceId' !!! ///
        getTopProducts: useFetch({ method: 'GET', endpoint: '/reports/topproducts{practiceId}', token, unauthorized }),
        medbotsDeployed: useFetch({
          endpoint: '/reports/topmedbottingthismonth/{practiceId}',
          method: 'GET',
          token,
          unauthorized,
        }),
        retailOrders: useFetch({
          endpoint: '/reports/toponeoffordersthismonth/{practiceId}',
          method: 'GET',
          token,
          unauthorized,
        }),
        topEarners: useFetch({ method: 'GET', endpoint: '/reports/topearningusers/{practiceId}', token, unauthorized }),
      },
      getUsers: {
        adminsWithAvatar: useFetch({ method: 'GET', endpoint: '/user/getmedadminswithAvatars', token, unauthorized }),
        medtrixAdmins: useFetch({ method: 'GET', endpoint: '/user/getmedadmins', token, unauthorized }),
        practiceDoctors: useFetch({ method: 'GET', endpoint: '/user/getpracticedoctors/{id}', token, unauthorized }),
        practiceUsers: useFetch({ method: 'GET', endpoint: '/user/getpracticeusers/{id}', token, unauthorized }),
        practiceUsersWithAvatar: useFetch({
          endpoint: '/user/getpracticeuserswithAvatars/v2/{practiceId}',
          method: 'GET',
          token,
          unauthorized,
        }),
      },
      login: useFetch({ method: 'POST', endpoint: '/login', token }),
      orders: {
        editOrder: useFetch({ method: 'PUT', endpoint: '/patientorders/{orderId}/draft/edit', token, unauthorized }),
        resendNotif: useFetch({
          method: 'POST',
          endpoint: '/orders/SendOrdertoPatients/{orderId}',
          token,
          unauthorized,
        }),
        resendNotificationAll: useFetch({
          method: 'POST',
          endpoint: '/orders/ResendNotification/{orderId}',
          token,
          unauthorized,
        }),
        resendAllAging: useFetch({
          method: 'GET',
          // if there is a practiceId, add a slash before it
          endpoint: '/orders/ResendAgingRetail{practiceId}',
          token,
          unauthorized,
        }),
        editShipping: useFetch({
          method: 'POST',
          endpoint: '/patientorders/updateshippingaddress/{orderId}',
          token,
          unauthorized,
        }),
        editPatient: useFetch({ method: 'PUT', endpoint: '/patients/updatepatient/{patientId}', token, unauthorized }),
        addNote: useFetch({ method: 'PUT', endpoint: '/orders/addnote', token, unauthorized }),
        // orders/query should be used for when the user searches a keyword
        getAllOrders: useFetch({ method: 'GET', endpoint: '/orders/query', token, unauthorized }),
        // orders/getsimpleorderslist should be used for order table when no keyword search is added
        getSimpleOrders: useFetch({ method: 'GET', endpoint: '/orders/getsimpleorderslist' }),
        getOrder: useFetch({ method: 'GET', endpoint: '/orders/{orderId}/detail', token, unauthorized }),
        getPracticeOrders: useFetch({ method: 'POST', endpoint: '/orders/query/{practiceId}', token, unauthorized }),
        getRefunds: useFetch({
          endpoint: '/orders/refundedorders/query/{practiceId}',
          method: 'GET',
          token,
          unauthorized,
        }),
        getStatuses: useFetch({ method: 'GET', endpoint: '/orders/statusCounts/{practiceId}', token, unauthorized }),
        payOrder: useFetch({ method: 'POST', endpoint: '/patientorders/postpayment/{orderId}', token, unauthorized }),
      },
      practice: {
        all: useFetch({ method: 'GET', endpoint: '/practices', token, unauthorized }),
        byId: useFetch({ method: 'GET', endpoint: `/practices/{id}`, token, unauthorized }),
        disable: useFetch({ method: 'PUT', endpoint: '/practices/{id}/disable', token, unauthorized }),
        enable: useFetch({ method: 'PUT', endpoint: '/practices/{id}/enable', token, unauthorized }),
        getAvatar: useFetch({ method: 'GET', endpoint: '/avatars/practice/{practiceId}', token, unauthorized }),
        getFees: useFetch({ method: 'GET', endpoint: '/practices/{practiceId}/fees', token, unauthorized }),
        new: useFetch({ method: 'POST', endpoint: '/practices', token, unauthorized }),
        postAvatar: useFetch({ method: 'POST', endpoint: '/practices/{id}/avatar', token, unauthorized }),
        update: useFetch({ method: 'PUT', endpoint: `/practices/update/{id}`, token, unauthorized }),
        updateBank: useFetch({ method: 'PUT', endpoint: '/practices/{id}/bankaccount', token, unauthorized }),
        updateBilling: useFetch({ method: 'PUT', endpoint: '/practices/{id}/billing', token, unauthorized }),
        updateFees: useFetch({ method: 'PUT', endpoint: '/practices/{practiceId}/fees', token, unauthorized }),
        wholesaler: {
          getLogo: useFetch({ method: 'GET', endpoint: '/warehouses/{wholesalerId}/avatar', token, unauthorized }),
          getWholesaler: useFetch({ method: 'GET', endpoint: '/warehouses/{practiceId}', token, unauthorized }),
          postLogo: useFetch({ method: 'POST', endpoint: '/avatars/warehouse/{wholesalerId}', token, unauthorized }),
          postWholesaler: useFetch({ method: 'POST', endpoint: '/warehouses/{practiceId}', token, unauthorized }),
        },
      },
      products: {
        brands: {
          forAdmin: useFetch({ method: 'GET', endpoint: '/product/brands', token, unauthorized }),
          forPractice: useFetch({ method: 'GET', endpoint: '/product/brands/{practiceId}', token, unauthorized }),
        },
        categories: {
          productsBySubCategory: useFetch({
            method: 'GET',
            ///add / if there is a practice id
            endpoint: '/product/practiceProductsbyCategorySubCategory/{collection}/{subcategory}{practiceId}',
            token,
            unauthorized,
          }),
          productsByCollection: useFetch({
            method: 'GET',
            //if there is a practice id, you must pass a / before it
            endpoint: '/product/practiceProductsbyCollection/{collection}{practiceId}',
            token,
            unauthorized,
          }),

          getPracticeCategories: useFetch({
            endpoint: '/shopify/getmaincategories/{practiceId}',
            method: 'GET',
            token,
            unauthorized,
          }),
          getSubsById: useFetch({
            endpoint: '/shopify/getsubcategories/{practiceId}/{categoryId}',
            method: 'GET',
            token,
            unauthorized,
          }),
          getSubsByTitle: useFetch({
            //add slash if there is a practiceId
            endpoint: '/shopify/getsubcategories/{title}{practiceId}',
            method: 'GET',
            token,
            unauthorized,
          }),
        },
        deleteFile: useFetch({ method: 'POST', endpoint: '/product/deletefile/{fileId}', token, unauthorized }),
        editAlternates: useFetch({
          endpoint: '/product/alternatives/{practiceId}',
          method: 'PUT',
          token,
          unauthorized,
        }),
        getAllProducts: useFetch({
          method: 'GET',
          endpoint: '/product/getAllProducts{practiceId}' /*add a slash before preactice id*/,
          token,
          unauthorized,
        }),
        getPracticeProducts: useFetch({
          method: 'POST',

          endpoint: '/product/query/{practiceId}',
          token,
          unauthorized,
        }),

        getProduct: useFetch({
          endpoint: '/product/{practiceId}/getproduct/{productId}',
          method: 'GET',
          token,
          unauthorized,
        }),
        getProductMedia: useFetch({
          endpoint: '/product/getProductPracticeFiles/{productId}/{practiceId}',
          method: 'GET',
          token,
          unauthorized,
        }),
        overrideMarkup: useFetch({
          endpoint: '/product/overridemarkup/{practiceId}/{variantId}',
          method: 'PUT',
          token,
          unauthorized,
        }),
        uploadFile: useFetch({
          endpoint: '/product/uploadProductPracticeFiles/{productId}/{practiceId}',
          method: 'POST',
          token,
          unauthorized,
        }),
      },
      sidebarStats: {
        retailOrders: useFetch({
          endpoint: '/reports/toponeoffordersthismonth/{practiceId}',
          method: 'GET',
          token,
          unauthorized,
        }),
        topEarning: useFetch({
          endpoint: '/reports/topearningusersThisMonth/{practiceId}',
          method: 'GET',
          token,
          unauthorized,
        }),
        topMedbots: useFetch({
          endpoint: '/reports/topmedbottingthismonth/{practiceId}',
          method: 'GET',
          token,
          unauthorized,
        }),
      },
      user: {
        editProvider: useFetch({ method: 'POST', endpoint: '/user/provider/edit', token, unauthorized }),
        createProvider: useFetch({ method: 'POST', endpoint: '/user/provider/create', token, unauthorized }),
        resendInvite: useFetch({ method: 'PUT', endpoint: '/Account/resendinvite/{email}', token, unauthorized }),
        activateUser: useFetch({ method: 'PUT', endpoint: '/user/{userId}/enable', token, unauthorized }),
        addDocAvatar: useFetch({ method: 'POST', endpoint: '/avatars/doctors/{userId}', token, unauthorized }),
        addStaffAvatar: useFetch({ method: 'POST', endpoint: '/avatars/staff/{userId}', token, unauthorized }),
        addAdminAvatar: useFetch({ method: 'POST', endpoint: '/avatars/admin/{userId}', token, unauthorized }),
        createUser: useFetch({ method: 'POST', endpoint: '/user/create', token, unauthorized }),
        deactivateUser: useFetch({ method: 'PUT', endpoint: '/user/{userId}/disable', token, unauthorized }),
        editUser: useFetch({ method: 'PUT', endpoint: '/user/edit', token, unauthorized }),
        getAvatar: useFetch({ method: 'GET', endpoint: '/avatars/{userId}', token, unauthorized }),
        getUser: useFetch({ method: 'GET', endpoint: '/user/{userId}', token, unauthorized }),
        inviteProvider: useFetch({ method: 'POST', endpoint: '/user/invitepractitioner', token, unauthorized }),
      },
    },
    actions: useMemo(
      () => ({
        authenticate: token => dispatch({ payload: token, type: keys.LOG_IN }),
        logOut,
        setUser: user => dispatch({ payload: user, type: keys.USER }),
        updatePractice: practice => dispatch({ payload: practice, type: keys.PRACTICE }),
        updatePractices: practice => dispatch({ payload: practice, type: keys.PRACTICES }),
        setOrderSummary: summary => dispatch({ payload: summary, type: keys.SET_ORDER_SUMMARY }),
        setCombinedReports: payload => dispatch({ payload, type: keys.SET_COMBINED_REPORTS }),
      }),
      [dispatch, logOut]
    ),
  }
}

export const adminInit = {
  authentication: sessionToken || null,
  user: sessionUser || {},
  users: [],
  practices: {
    one: {},
    many: [],
  },
  data: {},
  orders: {},
  products: {},
  actions: {},
  api: {},
  orderSummary: {},
  combinedReports: null,
  // combinedReports: { topMedbottingThisMonth: {}, topOneOffOrdersThisMonth: {}, topEarners: {} },
}

/*
      endpoints moved to apiv2
      
      featureSettings: useFetch({
          method: 'GET',
          endpoint: '/practices/featuresettings/{practiceId}',
          token,
          unauthorized,
        }),
      password: {
        sendResetPassword: useFetch({ method: 'POST', endpoint: '/Account/resetpassword' }),
        setPassword: useFetch({ method: 'PUT', endpoint: '/Account/changepassword' }),
      },
      */
