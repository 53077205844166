import { useTable, useSortBy, usePagination } from 'react-table'
import { UpAndDownArrowsIcon } from 'assets/vector'
import './Table.scss'
import { Loader } from 'components/Global'
import { useEffect, useMemo } from 'react'
import { Pagination } from 'components/Pagination'

const Table = ({
  noDataMessage,
  data,
  children,
  className,
  loading,
  error,
  sort,
  customFinalRow,
  paginate,
  pageSize,
}) => {
  if (className === undefined) className = ''

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    nextPage,
    previousPage,
    pageCount,
    state: { sortBy, pageIndex },
  } = useTable(
    { ...data, manualSortBy: !!sort, initialState: { pageSize, pageIndex: 0 } },
    useSortBy,
    paginate ? usePagination : ''
  )

  useEffect(() => {
    sort?.(sortBy?.[0])
  }, [sort, sortBy])

  const rowsToDisplay = useMemo(() => (paginate ? page : rows), [paginate, rows, page])

  return (
    <div className={`table-wrapper table-wrapper--${className}`}>
      {children && <div className={`table-actions table-wrapper--${className}`}>{children}</div>}
      <div className="table-scroller">
        <table className={`table table-wrapper--${className}`} {...getTableProps()}>
          <thead className={`table-head table-wrapper--${className}`}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  const header = column.render('Header')
                  const headerProps = column.getHeaderProps(column.unsortable ? '' : column.getSortByToggleProps())
                  return (
                    <th
                      style={{ ...column?.headerStyle }}
                      tabIndex={headerProps?.onClick ? 0 : undefined}
                      {...headerProps}
                      onKeyUp={
                        headerProps.onClick ? e => void (e.key === 'Enter' && headerProps.onClick(e)) : undefined
                      }
                    >
                      {header}
                      {!column.unsortable && header ? (
                        <UpAndDownArrowsIcon
                          className={`sort-arrows ${column.isSorted ? 'on' : 'off'} ${
                            column.isSortedDesc ? 'desc' : 'asc'
                          }`}
                        />
                      ) : null}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rowsToDisplay.map(row => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={row?.original?.onClick}
                  onKeyUp={
                    row?.original?.onClick ? e => void (e.key === 'Enter' ? row?.original?.onClick() : null) : undefined
                  }
                  tabIndex={row?.original?.onClick ? 0 : undefined}
                  role={row?.original?.onClick ? 'button' : undefined}
                  style={row?.original?.redRow ? { backgroundColor: 'rgb(255,221,221, 0.7)' } : {}}
                >
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
            {customFinalRow}
          </tbody>
        </table>
        {paginate && (
          <Pagination
            page={pageIndex + 1}
            nextPage={nextPage}
            prevPage={previousPage}
            /* we set the page to page # - 1 becasue react table goes by page Index which starts at 0 */
            selectPage={page => gotoPage(page - 1)}
            totalPages={pageCount}
          />
        )}
        {loading && !rows.length ? (
          <div className="table-load-loading">
            <Loader />
          </div>
        ) : error && !rows.length ? (
          <div className="table-load-error">{error.message}</div>
        ) : !rows.length ? (
          <div className="table-load-no-data">{noDataMessage || 'No Data.'}</div>
        ) : null}
      </div>
    </div>
  )
}

export default Table
