import apiv2 from 'apiv2'

/**
 * @param {String} email
 * @returns {Promise<void>} - 204 No Content
 */
export const PATIENT_REQUEST_PASSWORD_RESET = async email =>
  await apiv2.post('/patientportal/patient/resetpassword', { email })

/**
 * @param {Object} param
 * @param {String} param.newPassword
 * @param {String} param.token
 * @param {String} param.email
 * @returns  {Promise<void>} - 204 No Content
 */
export const PATIENT_RESET_PASSWORD = async ({ newPassword, token, email }) =>
  await apiv2.put('/patientportal/patient/changepassword', { email, newPassword, token })
