import { GET_FEATURE_SETTINGS } from 'apiv2'
import { useQuery } from 'react-query'

/**
 * @param {String} practiceId
 * @param {Object} options - react-query options
 */
export function usePracticeFeatureSettings(practiceId, options = {}) {
  return useQuery(['getFeatureSettings', practiceId], () => GET_FEATURE_SETTINGS(practiceId), {
    enabled: !!practiceId,
    ...options,
  })
}
