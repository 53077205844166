import { Button } from 'components'
import { useReportsState } from '../../context'
import { useReport } from './useReportData'
import { useCallback, useContext } from 'react'
import { MainContext } from 'context'
import { useParams } from 'react-router-dom'
import * as XLSX from 'xlsx'
import __ from 'lodash'
import dayjs from 'dayjs'

const formatDate = date => dayjs(date).format('MMM.DD.YYYY')

export const ExportReportButton = () => {
  const { admin } = useContext(MainContext)
  const { reportType } = useParams()
  const { fromdate, toDate, totalRows, practiceId } = useReportsState()

  const createFileName = useCallback(
    () => `${reportType.replaceAll('_', '')}__${formatDate(fromdate)}-${formatDate(toDate)}.xlsx`,
    [reportType, fromdate, toDate]
  )

  const createFile = data => {
    const items = Object.values(data).find(value => Array.isArray(value))
    const headers = [Object.keys(items[0]).map(key => __.startCase(key).replaceAll('Med Bot', 'MedBot'))]
    const worksheet = XLSX.utils.json_to_sheet(items)
    //modified headers
    XLSX.utils.sheet_add_aoa(worksheet, headers)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, createFileName())
  }

  const { isLoading, refetch } = useReport({
    admin,
    reportType,
    params: {
      practiceId: admin.user.practiceId || practiceId || 0,
      page: 1,
      pagesize: totalRows,
      fromdate: fromdate,
      toDate: toDate,
    },
    queryOptions: {
      enabled: false,
      onSuccess: createFile,
    },
  })

  return (
    <Button
      disabled={!totalRows || !toDate || !fromdate}
      className={'reports-view__export-button'}
      onClick={() => refetch()}
    >
      {isLoading ? 'Exporting...' : 'Export as Excel file'}
    </Button>
  )
}
