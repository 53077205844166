import { AlertModal, ProductPicker } from 'components'
import { useContext } from 'react'
import { MedBotsContext } from '../context'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from './PageHeader'
import { useMutation, useQueryClient } from 'react-query'
import { ADD_PRODUCT_TO_MEDBOT } from 'apiv2/calls/admin'
import { useModal } from 'context/Modal/ModalContext'

export const ProductSelection = () => {
  const queryClient = useQueryClient()
  const { actions, practiceId, lineItems, medbotId } = useContext(MedBotsContext)
  const modal = useModal()
  const nav = useNavigate()

  const { mutate } = useMutation(ADD_PRODUCT_TO_MEDBOT, {
    onSuccess() {
      queryClient.invalidateQueries(['get_medbot_details'])
    },
    onError() {
      modal.actions.open(<AlertModal message="Could not add product to MedBot. Please try again" />)
    },
  })

  return (
    <>
      <PageHeader title={'Select A Product'} onBack={() => nav(-1)} />
      <ProductPicker
        products={lineItems}
        practiceId={practiceId}
        onAdd={product => {
          if (medbotId) {
            mutate({
              medbotId,
              product: {
                quantity: product?.quantity,
                variantId: product?.variants?.[0].variantId,
                doctorsMessage: product?.doctorsMessage,
                refillFrequency: product?.refillFrequency || 'Never',
              },
            })
          } else {
            actions.addProduct(product)
          }
        }}
        onFinish={() => nav(`/admin/medbots/${medbotId || 'review'}`)}
      />
    </>
  )
}
