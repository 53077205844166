import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { LOCATION_STATE_MODES } from 'utilities/Patient/constants'

export const useStoreNavigation = () => {
  const { pathname, state } = useLocation()

  const backTo = useMemo(() => {
    if (state?.invitationId && pathname.includes('payment/shipping')) {
      return `/store/${state.invitationId}`
    }

    return -1
  }, [pathname, state])

  const showStoreBackButton = useMemo(
    () => pathname.includes('payment') && state?.mode === LOCATION_STATE_MODES.CONVENIENCE_STORE,
    [state, pathname]
  )

  return { backTo, showStoreBackButton }
}
