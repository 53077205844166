import { BackButton } from 'components'
import { useNavigate, useParams } from 'react-router-dom'
import './ReportView.scss'
import { ExportReportButton, Filters } from './components'
import ReportsTable from './components/Tables'
import { ReportsProvider } from '../context'

export const ReportView = () => {
  const nav = useNavigate()
  const { reportType } = useParams()

  return (
    <ReportsProvider>
      <div className="reports-view__header">
        <h4>{reportType.replaceAll('_', ' ') || ''}</h4>
        <BackButton onClick={() => nav(-1)} />
      </div>
      <div className="reports-view__sub-header fade-in">
        <Filters />
      </div>
      <br />
      <ReportsTable />
      <ExportReportButton />
    </ReportsProvider>
  )
}
