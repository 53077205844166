import { Table } from 'components'
import { useMemo } from 'react'
import './RevenueTable.scss'

const columns = [
  { Header: 'PHYSICIAN', accessor: 'physicianName', unsortable: true },
  { Header: 'TOTAL SALES', accessor: 'totalSales', unsortable: true },
  { Header: 'PROFIT MARGIN', accessor: 'profitMargin', unsortable: true },
  { Header: 'RECOMMENDATIONS SENT', accessor: 'recommendationsSent', unsortable: true },
  { Header: 'PRODUCTS PURCHASED', accessor: 'productsPurchased', unsortable: true },
]

const numberFormatting = number => {
  return number?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

export const RevenueTable = ({ data }) => {
  const tableData = useMemo(
    () =>
      data?.map(({ providerName, practiceName, totalSales, avatarURL = '', ...rest }) => {
        return {
          ...rest,
          physicianName: (
            <div className="cell cell__physician-name">
              {avatarURL ? (
                <div
                  className="doctor-avatar"
                  style={{ backgroundImage: `url(${avatarURL})` }}
                  alt="physician-avatar"
                />
              ) : (
                <div className="avatar-placeholder--revenue">{providerName?.split(' ')[1][0]}</div>
              )}
              <span>
                <p id="name">{providerName}</p>
                <p id="practice"> {practiceName}</p>
              </span>
            </div>
          ),
          productsPurchased: <span className="cell cell__medbots">{rest.productsPurchased}</span>,
          recommendationsSent: <span className="cell cell__orders-ytd">{rest.recommendationsSent}</span>,
          practiceName: <span className="cell cell__practice-name">{practiceName}</span>,
          profitMargin: <span className="cell cell__products-moved">${numberFormatting(+rest.profitMargin)}</span>,
          totalSales: <span className="cell cell__total-sales">${numberFormatting(+totalSales)}</span>,
        }
      }),
    [data]
  )

  return (
    <div className="analytics-body__table analytics-body__revenue--table">
      <Table
        className="analytics-revenue-table"
        loading={true}
        error={false}
        data={{ columns, data: tableData || [] }}
      />
    </div>
  )
}
