import { Button, CloseButton, Input, Loader } from 'components/Global'
import { Routing } from 'components/Global/Input/MaskedInputs'
import { MainContext } from 'context'
import { useContext } from 'react'
import { useModals } from 'utilities/hooks'
import { ConditionalForm } from '../ConditionalForm'

const msg = {
  success: 'Bank information updated!',
  err: 'Could not update bank information. Please check that the information you entered is correct',
}

export const Payments = ({ closeModal, add, id, data, ...rest }) => {
  const { admin } = useContext(MainContext)
  const { success, error } = useModals()

  const { mutate: updateBank, isLoading } = admin.api.practice.updateBank({
    variables: { id },
    queryOptions: {
      onSuccess: success(msg.success, ['practices', 'id', id]),
      onError: error(msg.err),
    },
  })

  if (isLoading) return <Loader />

  return (
    <ConditionalForm
      onSubmit={values => {
        !add &&
          updateBank({
            body: {
              ...values,
              practiceId: id,
              country: 'US',
              currency: 'USD',
            },
          })
      }}
      wrapperClass={'payments'}
      initialValues={{ ...data, accountNumber: null, routingNumber: null }}
      renderForm={add}
      {...rest}
    >
      <h4>{add ? 'Payments' : 'Edit Bank Account'}</h4>
      {add ? null : <CloseButton onClick={() => closeModal()} />}
      <div className="input-row input-row--single_field">
        <Input name="accountHoldersName" label="Account Holders Name" />
      </div>
      <div className="input-row input-row--single_field">
        <Input name="bankName" label="Bank Name" />
      </div>
      <div className="input-row">
        <Input
          placeholder={data?.accountNumber ? '•'.repeat(17 - 4) + data.accountNumber : ''}
          name="accountNumber"
          label="Bank Account Number"
          autoComplete="new-password" //chrome kept autofilling, this turns it off
          maskedInput={Routing}
        />
        <Input
          placeholder={data?.routingNumber ? '•'.repeat(9 - 4) + `${data.routingNumber}`.slice?.(-4) : ''}
          name="routingNumber"
          label="Routing Number"
          maskedInput={Routing}
        />
      </div>
      {add ? null : (
        <div className="actions">
          <Button
            onClick={e => {
              e.preventDefault()
              closeModal()
            }}
          >
            Cancel
          </Button>
          <Button>Save</Button>
        </div>
      )}
    </ConditionalForm>
  )
}
