import { GreyMinus, GreyPlus } from 'assets/vector'
import { useCallback } from 'react'

export const QuantityControls = ({ product, handleDelete, handleAdd, handleMinus }) => {
  const { quantity } = product

  const minus = useCallback(() => {
    if (quantity <= 1) return
    handleMinus(product)
  }, [handleMinus, product, quantity])

  const add = useCallback(() => handleAdd(product), [handleAdd, product])

  return (
    <div className="product-controls__quantity">
      <div className="product-controls__quantity--toggle-wrap">
        <button onClick={minus} className="product-controls__quantity--toggle-wrap__minus">
          <GreyMinus />
        </button>
        <div className="product-controls__quantity--toggle-wrap__amount">{quantity}</div>
        <button onClick={add} className="product-controls__quantity--toggle-wrap__plus">
          <GreyPlus />
        </button>
      </div>
      {!!handleDelete && (
        <button onClick={() => handleDelete(product)} className="product-controls__quantity--delete-button">
          Delete
        </button>
      )}
    </div>
  )
}
