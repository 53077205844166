import * as cards from 'assets/raster/ProductCategories'
import { useCallback, useContext } from 'react'
import { ProductPickerContext } from '../context'
import { productPickerPages } from '../utils'
import { allPossibleProductCategories } from 'utilities/productCategories'

export const Categories = () => {
  const { actions } = useContext(ProductPickerContext)

  const handleSelect = useCallback(
    category => () => actions.setSelectProduct({ category, subPage: productPickerPages.SUBCATEGORIES }),
    [actions]
  )

  return (
    <div className="select-product-categories">
      {Object.values(allPossibleProductCategories).map((category, i) => (
        <button key={i} onClick={handleSelect(category)}>
          <img alt="category" src={cards?.[category]} />
        </button>
      ))}
    </div>
  )
}
