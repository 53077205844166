import { YesNoSlider } from 'assets/vector'
import { useSvgButton } from 'utilities/hooks'
import { useContext } from 'react'
import { MainContext } from 'context'
import { useParams } from 'react-router-dom'
import { AlertModal, DeactivateModal } from 'components'

export const AccountStatus = ({ inGeneralSection = false }) => {
  const {
    admin: {
      practices: {
        one: { active: isActive },
      },
    },
  } = useContext(MainContext)
  const { toggleStatus } = useToggleStatus()

  if (inGeneralSection) {
    return <Main onToggle={toggleStatus(isActive)} isActive={isActive} />
  }

  return (
    <SectionWrapper>
      <Main onToggle={toggleStatus(isActive)} isActive={isActive} />
    </SectionWrapper>
  )
}

const Main = ({ onToggle, isActive }) => (
  <div className="detail-tile__data">
    <div className="detail-tile__data-item detail-tile__data-item--status">
      {useSvgButton(<YesNoSlider onClick={onToggle} className={`slider--${isActive ? 'active' : 'unactive'}`} />)}
      <h6
        className={`detail-tile__data-sub-title detail-tile__data-sub-title--status_${
          isActive ? 'active' : 'unactive'
        }`}
      >
        {`Account ${isActive ? 'Active' : 'Deactivated'}`}
      </h6>
    </div>
  </div>
)

const SectionWrapper = ({ children }) => (
  <div className="detail-tile detail-tile--status">
    <div className="detail-tile__header">
      <h4 className="detail-tile__title">Account Status</h4>
    </div>
    {children}
  </div>
)

const useToggleStatus = () => {
  const { admin, modal } = useContext(MainContext)
  const { id } = useParams()

  const { mutate: disable } = admin.api.practice.disable({
    queryOptions: {
      onSuccess: () => admin?.actions?.updatePractice({ ...admin?.practices?.one, active: false }),
      onError: () =>
        modal?.actions?.open(
          <AlertModal
            message={'There was an error deactivating the account'}
            support
            onConfirm={() => {
              modal?.actions.close()
            }}
          />
        ),
    },
  })

  const { mutate: enable } = admin.api.practice.enable({
    queryOptions: {
      onSuccess: () => admin?.actions?.updatePractice({ ...admin?.practices?.one, active: true }),
      onError: () =>
        modal?.actions?.open(
          <AlertModal
            message={'There was an error activating the account'}
            support
            onConfirm={() => {
              modal.actions.close()
            }}
          />
        ),
    },
  })

  const toggleStatus = isActive => {
    return isActive
      ? () => {
          modal?.actions?.open(
            <DeactivateModal
              name={admin.practices.one.name}
              denyText={'Cancel'}
              onConfirm={() => {
                disable({ variables: { id } })
                modal?.actions?.close()
              }}
            />
          )
        }
      : () => enable({ variables: { id } })
  }

  return { toggleStatus }
}
