import { useContext, useEffect } from 'react'
import { Input, Loader } from 'components/Global'
import { MainContext } from 'context'
import { AlertModal } from '../..'
import { ConditionalForm } from '../ConditionalForm'

export const Emr = ({ closeModal, add, id, data, ...rest }) => {
  const { admin, modal } = useContext(MainContext)

  const {
    refetch: practiceById,
    data: getData,
    isLoading,
    error,
  } = admin.api.practice.byId({ variables: { id }, lazy: true })

  useEffect(() => {
    if (!!id && !add && !data) {
      practiceById()
    }
  }, [add, data, id, practiceById])

  if (error) modal.actions.open(<AlertModal message={`${error?.message}`} />)

  return isLoading && !add && !data ? (
    <Loader />
  ) : data || getData || add ? (
    <ConditionalForm
      onSubmit={values => {
        console.log(values)
      }}
      wrapperClass={'emr'}
      initialValues={data || getData}
      renderForm={add}
      {...rest}
    >
      <h4>EMR Account</h4>
      <div className="input-row">
        <Input label="Account/Username" />
        <Input label="Password" />
      </div>
    </ConditionalForm>
  ) : null
}
