import { useCallback, useContext, useMemo } from 'react'
import './Products.scss'
import { ProductsTable } from './Table'
import { Filter, SubFilter, FilterByBrand } from './Components'
import { Search } from 'components/Global'
import * as cards from 'assets/raster/ProductCategories'
import { PracticeComboBox } from '../Orders/OrdersTable/PracticeComboBox.js/index.js'
import { useProductFilters } from './useProductFilters'
import { ProductTableContext } from './context/ProductsTableContext'
import { MainContext } from 'context'
import { allPossibleProductCategories } from 'utilities/productCategories'

/* Passing in close from edit product, here they will choise alternates */
export const Products = ({ chooseAltProduct }) => {
  const { admin } = useContext(MainContext)
  const { actions, brand } = useContext(ProductTableContext)
  const { isLoading, isFetching, error, data, subsList } = useProductFilters()

  const mapAllCollections = useCallback(
    () =>
      Object.values(allPossibleProductCategories).map((collectionName, i) => (
        <Filter name={collectionName} isLoading={isLoading || isFetching} key={i} img={cards?.[collectionName]} />
      )),
    [isLoading, isFetching]
  )

  const subCategories = useCallback(
    subs => subs?.map?.((item, i) => <SubFilter isLoading={isLoading || isFetching} name={item?.category} key={i} />),
    [isLoading, isFetching]
  )

  const isAdmin = useMemo(
    () => admin?.user?.role?.includes?.('admin') || admin?.user?.role === 'admin',
    [admin?.user?.role]
  )

  return (
    <div className="products-wrapper">
      <h4 className="products-title">Products</h4>
      <div className="products-body">
        <div className="products-body__search">
          <Search onSubmit={actions.keywordSearch} />
          {isAdmin && (
            <PracticeComboBox selectPractice={actions.selectPractice} searchable={false} className="products-search" />
          )}
          <FilterByBrand brand={brand} selectBrand={actions.setBrandFilter} />
        </div>
        <div className="products-body__grid">
          <div className={`categories`}>{mapAllCollections()}</div>
          <div className={`sub-categories ${subsList?.length ? 'hasSubs' : 'none'}`}>{subCategories(subsList)}</div>
          <ProductsTable
            chooseAltProduct={chooseAltProduct ? chooseAltProduct : null}
            data={data}
            status={{ isLoading: isLoading || isFetching, error }}
          />
        </div>
      </div>
    </div>
  )
}
