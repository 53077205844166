// Covers both XXXXX and XXXXX-XXXX
export const usZipRegex = /^\d{5}(?:[ -]?\d{4})?$/
export const zipCode = value => {
  if (!value) return undefined
  if (`${value}`.length < 5) return 'Must be 5 digits.'
  if (usZipRegex.test(`${value}`)) return undefined
  return 'Invalid zip code.'
}

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
export const email = value => {
  if (!value) return undefined
  if (emailRegex.test(`${value}`)) return undefined
  return 'Invalid email address.'
}
