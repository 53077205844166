import { createContext, useMemo } from 'react'
import { useImmerReducer } from 'use-immer'
import { useMedBotActions, MedBotsReducer, init } from './MedBotsReducer'

export const MedBotsContext = createContext(init)

export const MedBotsProvider = ({ children, practiceId }) => {
  const [state, dispatch] = useImmerReducer(MedBotsReducer, { ...init })
  const actions = useMedBotActions(dispatch)

  const value = useMemo(
    () => (!!practiceId ? { ...state, practiceId, actions } : { ...state, actions }),
    [practiceId, state, actions]
  )

  return <MedBotsContext.Provider value={value}>{children}</MedBotsContext.Provider>
}
