import './ScopeToggle.scss'
import { useAnalyticsContext } from '../context'

const scopes = ['today', 'week', 'lastWeek', 'month', 'year']

const labelKeys = {
  today: 'today',
  week: 'This Week',
  lastWeek: 'Last Week',
  month: 'This Month',
  year: 'This Year',
}

export const ScopeToggle = () => {
  const { scope: active, actions } = useAnalyticsContext()

  return (
    <div className="scope-toggle">
      {scopes.map((scope, i) => (
        <button
          onClick={() => actions.setScope(scope)}
          className={`scope-toggle__button ${scope === active ? 'active' : ''}`}
          key={i}
        >
          <span>{labelKeys[scope]}</span>
        </button>
      ))}
    </div>
  )
}
