import { useContext, useState } from 'react'
import { MainContext } from 'context'
import { defaultProductImg } from 'assets/raster'
import { Image } from '../Image'
import './ViewAvatar.scss'
import { usePracticeAvatar } from 'utilities/hooks'
import { AlertIcon } from 'assets/vector'

export const TableAvatar = ({ userId, name, lastName }) => {
  const { admin } = useContext(MainContext)
  const [avatar, setAvatar] = useState(null)

  admin.api.user.getAvatar({
    variables: { userId },
    queryOptions: {
      onSuccess: data => {
        data instanceof Blob && setAvatar(URL.createObjectURL(data))
      },
    },
  })

  return (
    <span className="avatar-view-wrap user-avatar-firstname">
      {avatar ? (
        <Image sources={[avatar]} className={'profile-image'} alt="user avatar" />
      ) : (
        <span className="avatar-placeholder">{lastName?.[0]?.toUpperCase()}</span>
      )}
      <p>{name}</p>
    </span>
  )
}

export const ProductImageName = ({ imgString, name, className, isRemoved = false }) => {
  return (
    <span className={`product-image-name-wrap ${className || ''}`}>
      <img
        alt="product-img"
        src={imgString || ''}
        onError={({ currentTarget }) => (currentTarget.src = defaultProductImg)}
      />
      <div className="text-wrap">
        <span className="product-name">{name}</span>
        {isRemoved && (
          <span className="removed-notice">
            <AlertIcon /> Product has been removed.
          </span>
        )}
      </div>
    </span>
  )
}

/* THIS METHOD IS WAYYYYY BETTER */
/** @param {{avatar: Blob | string, firstName: string, lastName: string, showName: boolean, className: string}} props */
export const AvatarWithFallback = ({ avatar, firstName, lastName, className, showName = true, imgSrc = false }) => {
  if (avatar instanceof Blob) imgSrc = URL.createObjectURL(avatar)

  return (
    <span className={`avatar-view-wrap user-avatar-firstname ${className}`}>
      <AvatarOrInitial imgSrc={imgSrc} avatar={imgSrc ? null : avatar} firstName={firstName} lastName={lastName} />
      {showName && (
        <p>
          {firstName} {lastName}
        </p>
      )}
    </span>
  )
}

export const AvatarOrInitial = ({ avatar, lastName, firstName, imgSrc }) => {
  const backgroundImage = imgSrc
    ? imgSrc
    : avatar?.fileContents
    ? `data:${avatar?.contentType};base64,${avatar?.fileContents}`
    : null

  return (
    <div className="avatar-wrap">
      {backgroundImage ? (
        <div className="avatar-actual" style={{ backgroundImage: `url(${backgroundImage})` }} />
      ) : (
        <span className="avatar-placeholder">
          {lastName?.[0]?.toUpperCase() || firstName?.[0]?.toUpperCase() || ''}
        </span>
      )}
    </div>
  )
}
export const TablePractice = ({ practiceId, name }) => {
  const { avatar } = usePracticeAvatar({ practiceId, onSuccess: () => {}, onError: () => {} })

  return (
    <span className="practice-name__wrapper">
      <Image sources={[avatar]} className="practice-name__img" />
      <p className="practice-name">{name || 'No name'}</p>
    </span>
  )
}
