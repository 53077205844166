import { useState } from 'react'
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom'

import { BackButton, Button, Loader } from 'components/Global'
import { Alternatives, Details } from './peices'
import useProduct from './useProduct'

import './EditProduct.scss'

export const EditProduct = () => {
  const { data, isLoading, error, overrideMarkup, isFetching, ...rest } = useProduct()
  const [showProductList, setShowProductList] = useState(false)
  const nav = useNavigate()

  return (
    <div className={`edit-product-wrap${isLoading || isFetching || error ? '--alert' : ''}`}>
      {isLoading || isFetching ? (
        <Loader />
      ) : error ? (
        <span className="edit-product-error-msg">There was an error.</span>
      ) : data ? (
        <>
          <Outlet context={[{ data, showProductList, setShowProductList, ...rest }]} />
          <div className="edit-product-buttons">
            <Button onClick={() => nav(-1)}>Cancel</Button>
            <Button disabled={rest.price < rest.wholeSalePrice} onClick={overrideMarkup}>
              Save
            </Button>
          </div>
        </>
      ) : (
        <span className="edit-product-error-msg">Could not find the product</span>
      )}
    </div>
  )
}

export const EditProductChildren = () => {
  const [{ showProductList }] = useOutletContext()
  if (showProductList) return <Alternatives />
  return (
    <>
      <div className="edit-product-header">
        <h4>Edit Product</h4>
        <BackButton />
      </div>
      <Details />
      {/* <Alternatives /> */}
    </>
  )
}
