export const allPossibleProductCategories = {
  ORTHOPEDICS: 'orthopedics',
  //   OPHTHALMOLOGY: 'ophthalmology',
  EYE_CARE: 'eyeCare',
  UROLOGY: 'urology',
  OBGYN: 'obgyn',
  PODIATRY: 'podiatry',
  VASCULAR: 'vascular',
  DERMATOLOGY: 'dermatology',
  PLASTICS: 'plastics',
  PRIMARY_CARE: 'primaryCare',
  DENTAL: 'dental',
  GASTROENTEROLOGY: 'gastroenterology',
  CARDIOLOGY: 'cardiology',
  NEUROLOGY: 'neurology',
  PAIN_MANAGEMENT: 'painManagement',
}
