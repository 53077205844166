import { AlertModal, Button } from 'components'
import { MainContext } from 'context'
import { useCart } from 'pages/RecommendationPortal/Cart/context'
import { useCallback, useContext, useMemo } from 'react'

export const CartActionButton = () => {
  const {
    modal,

    patient: {
      actions: { viewProduct },
      viewProduct: { product },
    },
  } = useContext(MainContext)
  const { actions, ...cart } = useCart()

  const isInCart = useMemo(() => cart.LineItems.find(item => item.productId === product.productId), [cart, product])

  const removeFromCartConfirm = useCallback(
    () =>
      modal.actions.open(
        <AlertModal
          message={`Are you sure you want to remove ${product?.productName} from your cart?`}
          onConfirm={() => {
            actions.removeItem(product.productId)
            viewProduct(null)
            modal.actions.close()
          }}
          denyText={'Keep'}
          onDeny={modal.actions.close}
        />
      ),
    [actions, modal, product, viewProduct]
  )

  const handleClick = useCallback(() => {
    if (isInCart) {
      removeFromCartConfirm()
    } else {
      actions.addItem(product)
    }
  }, [isInCart, product, actions, removeFromCartConfirm])

  return <Button onClick={handleClick}>{isInCart ? 'Remove From Cart' : 'Add To Cart'}</Button>
}
