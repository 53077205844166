import { SelectButtonInList, Tab } from 'components/AlphabeticList'

const providerTypes = {
  ARNP: 'Advanced Registered Nurse Practitioner',
  PA: 'Physicians Assistant',
  DOCTOR: 'Doctor',
}

export const ProviderItem = ({ onSelectProvider, addTab, provider }) => {
  const { name, providerType, avatarUrl } = provider

  const lastLetter = name => {
    const arr = name
      .split(' ')
      .filter(str => str.length)
      .map(str => str.trim())

    return arr[arr.length - 1]?.[0]?.toUpperCase()
  }

  return (
    <li className="create-order-page__item select-provider__item">
      {addTab && <Tab letter={lastLetter(name)} />}
      <button onClick={() => onSelectProvider(provider)}>
        <div className="select-provider__item--name">
          <div className="select-provider__item--name-avatar">
            <img alt="provider" src={avatarUrl} />
          </div>
          <span className="select-provider__item--name-text">{name}</span>
        </div>
        <span className="select-provider__item--type">{providerTypes?.[providerType?.toUpperCase?.()] || '--'}</span>
        <SelectButtonInList text="Select" />
      </button>
    </li>
  )
}
