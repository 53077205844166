import { MainContext } from 'context'
import { sortData } from '../utils'
import { useCallback, useContext, useMemo } from 'react'

export const useGetProviders = sortListBy => {
  const { admin } = useContext(MainContext)
  const { asc, letter, keyword } = sortListBy

  const { data, isLoading, error } = admin.api.createOrder.getProvider({
    variables: { practiceId: admin?.user?.practiceId ? `/${admin.user.practiceId}` : '' },
  })

  const sorted = useMemo(() => data?.sort(sortData(asc, `name`, true)), [data, asc])

  const dataFilter = useCallback(() => {
    const regex = new RegExp(keyword.toLowerCase(), 'g')

    return sorted?.filter(({ name }) => {
      const nameSplit = name.split(' ')
      const lastName = nameSplit[nameSplit.length - 1][0].toUpperCase()
      const letterMatch = letter ? lastName === letter : true
      const nameMatch = name.toLowerCase().match(regex)
      return letterMatch && nameMatch
    })
  }, [sorted, letter, keyword])

  return {
    data: useMemo(
      () => (keyword?.length || letter ? dataFilter() : sorted || null),
      [letter, keyword, dataFilter, sorted]
    ),
    isLoading,
    error,
  }
}
