import './ViewProduct.scss'
import { LeftArrowInBlueCircleIcon } from 'assets/vector'
import { Button, ImageSlider } from 'components/Global'
import { useCallback, useContext, useMemo, useState } from 'react'
import { MainContext } from 'context'
import { DoctorMessage } from '../Product'
import { defaultProductImg } from 'assets/raster'
import { FullscreenImages } from 'components'
import DOMPurify from 'dompurify'
import { CartActionButton } from './peices/CartActionButton'

export const ViewProduct = () => {
  const {
    patient,
    patient: {
      //data can be the order data or type: "store" | "cart"
      viewProduct: { product, order: data },
    },
    modal,
  } = useContext(MainContext)
  const [closing, setClosing] = useState(false)

  const mapImages = useCallback(images => images?.map(img => img.src), [])
  const images = useMemo(
    () =>
      (product?.variantImages?.length ? product?.variantImages : mapImages(product?.image || product?.images)) || null,
    [product, mapImages]
  )

  const displayPrice = useMemo(() => {
    if (product?.useOverride && product?.overridePrice) {
      return Number(product?.overridePrice).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    }

    if (!product?.retailPrice) return 'Invalid Price'

    return Number(product.retailPrice).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }, [product])

  if (!product) return null

  const renderButton = () => {
    return (
      <>
        {!!data?.type && <CartActionButton />}
        <Button onClick={() => setClosing(true)}>Back</Button>
      </>
    )
  }

  return (
    <div
      className={`view-product-wrap ${closing ? 'closing' : ''}`}
      onAnimationEnd={e => {
        if (e.animationName === 'fade-out') {
          patient.actions.viewProduct(null)
          setClosing(false)
        }
      }}
    >
      <div className="close-remove-buttons-wrap">
        <button onClick={() => setClosing(true)} className="close-button">
          <LeftArrowInBlueCircleIcon role="button" />
          <p>BACK</p>
        </button>
      </div>
      <ImageSlider
        onImageClick={() => modal.actions.open(<FullscreenImages label={product?.productName} images={images} />)}
        className={`view-product__image-slider ${product?.isOutOfStock ? '--isOutOfStock' : ''}`}
        images={images}
        fallbackImage={defaultProductImg}
      />
      <h3 className="view-product__name">{product?.productName || product?.productTitle}</h3>
      <div className="quantity-control-wrap">
        <div className="quantity-control__price-tag">
          <span style={{ lineHeight: 'unset' }}>Price</span>
          <span>${displayPrice || 'Invalid Price'}</span>
        </div>
      </div>
      <DoctorMessage
        doctorId={data?.doctorId || null}
        title={'Message from your Doctor'}
        msg={product?.doctorsMessage}
      />
      {!!product?.productDescription && (
        <div className="view-product__details">
          <span className="view-product__details--label">PRODUCT DETAILS</span>
          {product?.productDescription ? <Description html={product?.productDescription} /> : <p>No Description</p>}
        </div>
      )}
      {renderButton()}
    </div>
  )
}

const Description = ({ html }) => {
  const __html = useMemo(() => {
    const cleanedHtml = DOMPurify.sanitize(html)
    return { __html: cleanedHtml }
  }, [html])

  return <div dangerouslySetInnerHTML={__html} />
}
