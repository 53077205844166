import React, { useCallback, useContext } from 'react'
import { Form } from 'react-final-form'
import { Input } from 'components/Global/Input'
import { Button } from 'components/Global/Button'
import { Zip } from 'components/Global/Input/MaskedInputs'
import { HamburderMenuXIcon } from 'assets/vector'
import { MainContext } from 'context'
import { statesShort } from './states'
import './ChangeAddressModal.scss'
import { useSvgButton } from 'utilities/hooks'
import { AlertModal } from '../../Alert'
import { Spinner } from 'pages/RecommendationPortal/Spinner'
import { formatUpdateBody } from 'pages/RecommendationPortal/Payment/Form/utils'

const lackingInventryMsg = 'A product you have chosen does not have enough inventory to complete your order.'
const genericError = 'There was an error verifying your order.'

const ChangeAddressModal = ({ closeModal, ...rest }) => {
  const {
    patient,
    patient: {
      order: data,
      shippingAddress: { address1, city, state, zip },
    },
  } = useContext(MainContext)
  const { update, isLoading } = useUpdateAddress({ closeModal })

  const onSubmit = values => update({ body: formatUpdateBody({ data, patient, newAddress: { ...values } }) })

  return (
    <div className="modal-content change-address-modal-wrap" {...rest}>
      {isLoading ? <Spinner /> : null}
      {useSvgButton(<HamburderMenuXIcon className="close-button" role="button" onClick={closeModal} />)}
      <span className="address-form-title">Please update the shipping address</span>
      <Form
        onSubmit={onSubmit}
        initialValues={{ address1, city, state, zip }}
        render={useCallback(
          ({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="change-address-form">
              <Input label="Street" name="address1" />
              <Input label="City" name="city" />
              <Input defaultValue={state} label="State" name="state" component="select">
                {statesShort.map((s, i) => (
                  <option value={s} key={i}>
                    {s}
                  </option>
                ))}
              </Input>
              <Input label="Zip Code" name="zip" maskedInput={Zip} />
              <Button type="submit">Update Address</Button>
            </form>
          ),
          [state]
        )}
      />
    </div>
  )
}

const useUpdateAddress = ({ closeModal }) => {
  const {
    modal,
    patient,
    patient: {
      api,
      viewOrder: { isCart, data },
      actions: { setShippingAddress, setOrder, setCart },
    },
  } = useContext(MainContext)

  const { mutate: updateOrder, isLoading } = api.updateOrder({
    variables: { orderId: data?.orderId || data?.id || data?.localId || data?.localOrderId },
    queryOptions: {
      onSuccess: d => {
        isCart ? setCart({ ...d }) : setOrder({ ...d })
        setShippingAddress(d.shippingAddress)
        closeModal()
      },
      onError: e =>
        modal.actions.open(
          <AlertModal
            message={`${
              e.message.includes('line_items: Unable to reserve inventory.') ? lackingInventryMsg : genericError
            }`}
          />
        ),
    },
  })

  const { mutate: verify, isLoading: verifyLoading } = patient.api.verifyOrder({
    queryOptions: {
      onSuccess: d => {
        isCart ? setCart({ ...d }) : setOrder({ ...d })
        setShippingAddress(d.shippingAddress)
        closeModal()
      },
      onError: e => {
        modal.actions.open(
          <AlertModal
            message={`${
              e.message.includes('line_items: Unable to reserve inventory.') ? lackingInventryMsg : genericError
            }`}
          />
        )
      },
    },
  })

  return { update: data?.shopifyDraftOrderId ? updateOrder : verify, isLoading: verifyLoading || isLoading }
}

export default ChangeAddressModal
