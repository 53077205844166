import './AgreeToTerms.scss'
import { useState } from 'react'
import { Button } from 'components'
import { Verbage } from 'components/Global'
import useAgree from './useAgree'

export const AgreeToTerms = ({ patientId = null, onSuccess = null }) => {
  const [agreed, setAgreed] = useState(false)
  const [open, setOpen] = useState(false)

  const { agree, isLoading } = useAgree(patientId, onSuccess)

  if (open)
    return (
      <div className="login-terms terms-and-conditions">
        <Verbage blueText />
        <TermSubmitButtons
          onAgree={() => {
            setAgreed(true)
            setOpen(false)
          }}
          closeTerms={() => setOpen(false)}
        />
      </div>
    )

  return (
    <div className="agree-to-terms">
      <div className="agree-to-terms__content">
        <div className="agree-to-terms__content-row-one">
          <button
            onClick={() => setAgreed(!agreed)}
            className={`agree-to-terms__checkmark ${agreed ? 'checked' : 'unchecked'}`}
          />
          <span>
            I Agree to <button onClick={() => setOpen(true)}>Terms and Conditions</button>
          </span>
        </div>
        <Button disabled={!agreed || isLoading} onClick={agree}>
          {isLoading ? 'Loading...' : 'Continue'}
        </Button>
      </div>
    </div>
  )
}

export const TermSubmitButtons = ({ onAgree, closeTerms }) => {
  return (
    <div className="submit-buttons">
      <Button onClick={onAgree}>Agree</Button>
      <button className={'decline-button'} onClick={closeTerms}>
        Close
      </button>
    </div>
  )
}
