import { ChevronRightIcon } from 'assets/vector'
import { useSvgButton } from 'utilities/hooks'

import './Pagination.scss'

const Pagination = ({ page, totalPages, nextPage, prevPage, selectPage, isLoading }) => {
  const pagesToDisplay = (page, totalPages) =>
    [1, 2, 3].includes(page)
      ? [1, 2, 3, 4].filter(e => e <= totalPages)
      : page === totalPages
      ? [page - 3, page - 2, page - 1, page]
      : [page - 2, page - 1, page, page + 1].filter(e => e <= totalPages && e > 0)

  return (
    <div className="pagination">
      {useSvgButton(
        <ChevronRightIcon
          onClick={() => prevPage?.() || (page - 1 > 0 ? selectPage(page - 1) : null)}
          className="chevron-button chevron-button--left"
          disabled={page === 1 || isLoading}
        />
      )}
      <div className="pagination__page-numbers">
        {pagesToDisplay(page, totalPages).map((e, i) => (
          <button
            key={i}
            className={`pagination__page-button ${e === page ? 'pagination__page-button--active' : ''}`}
            onClick={() => (e === page ? null : selectPage(e))}
            disabled={e === page || isLoading}
          >
            {e}
          </button>
        ))}
      </div>
      {useSvgButton(
        <ChevronRightIcon
          onClick={() => nextPage?.() || (page + 1 <= totalPages ? selectPage(page + 1) : null)}
          className="chevron-button"
          disabled={page + 1 > totalPages || isLoading}
        />
      )}
    </div>
  )
}

export default Pagination
