import { confettiLottieData } from 'assets/lottie'
import { SubHeader } from 'components'
import { MainContext } from 'context'
import { useContext, useState } from 'react'
import Lottie from 'react-lottie-player'
import { useParams } from 'react-router-dom'
import { useBubbleProps, useParamsToObject } from 'utilities/hooks'
import { Spinner } from '../../../pages/RecommendationPortal/Spinner'
import './NoPurchase.scss'

export const NoPurchase = () => {
  const { patient } = useContext(MainContext)
  const { orderId, reasonId } = useParams()
  const { isMedbot, patientId } = useParamsToObject()
  const [loading, setLoading] = useState(true)

  const { data, isLoading } = patient?.api.recordNoPurchaseReason({
    params: {
      orderId,
      reasonId,
      patientId,
      isMedbot: isMedbot === 'true',
    },
    queryOptions: {
      onSuccess: () => setLoading(false),
      onError: () => setLoading(false),
    },
  })

  useBubbleProps({
    custom: {
      title: `Hi, it's Dr. ${data?.doctorLastName || ''}!`,
      message: 'Thank you for your response. We will make note of that in your file.',
      image: data?.doctorAvatarURL,
    },
  })

  if (loading || isLoading) return <Spinner />

  return (
    <div className="patient-response-confirm">
      <SubHeader />
      <Lottie role="img" play loop={false} className="response-success-animation" animationData={confettiLottieData} />
    </div>
  )
}
