import { HamburderMenuXIcon } from 'assets/vector'
import { Form, useFormState } from 'react-final-form'
import { passwordValidation, svgButton } from 'utilities/utils'
import { Button, Input } from 'components'
import { useCallback } from 'react'

export const Create = ({ close }) => {
  const handleFormSubmit = useCallback(values => console.log(values), [])

  return (
    <Form
      onSubmit={handleFormSubmit}
      render={({ handleSubmit }) => <Inner handleSubmit={handleSubmit} close={close} />}
    />
  )
}

//makes it easier to compare passwords using useFormState()
const Inner = ({ handleSubmit, close }) => {
  const { values } = useFormState()

  const validator = useCallback(v => (values?.password !== v ? 'Passwords do not match' : null), [values])

  return (
    <form className="patient-login-create" onSubmit={handleSubmit}>
      <div className="patient-login-create__head">
        {svgButton(<HamburderMenuXIcon className="patient-login__close-button" onClick={close} />)}
      </div>
      <h4>To create a new account please enter your email and password.</h4>
      <Input name="email" label="Email" />
      <Input validate={passwordValidation} name="password" label="Password" />
      <Input validate={validator} name="verifyPassword" type="password" label="Retype Password" />
      <Button onClick={handleSubmit}>Create Account</Button>
    </form>
  )
}
