import { useContext, useMemo } from 'react'
import { SpeechBubbleTail } from 'assets/vector'
import { MainContext } from 'context'
import './MessageBubble.scss'
import { useBubbleTitle, useDocAvatar } from './utils'
import { useLocation } from 'react-router-dom'

export const MessageBubbleGlobal = ({ image, title, message, className }) => {
  return (
    <div className={`bubble-wrapper bubble ${className || ''}`}>
      {image ? (
        <img
          aria-label="doctor profile"
          src={image}
          onError={({ currentTarget }) => (currentTarget.style.opacity = 0)}
          className="bubble-image bubble"
          lazy="true"
        />
      ) : null}
      <div className="bubble-body bubble">
        <h2 className="bubble-title bubble">{title}</h2>
        <p className="bubble-message bubble">{message}</p>
      </div>
      <SpeechBubbleTail aria-hidden className="bubble-tail bubble" />
    </div>
  )
}

const MessageBubble = () => {
  const {
    patient,
    patient: {
      bubble: { bubbleProps, showBubble },
    },
  } = useContext(MainContext)
  useDocAvatar()
  useBubbleTitle()
  const { pathname } = useLocation()

  const show = useMemo(() => pathname !== '/cart' || !patient?.hasAgreed, [pathname, patient?.hasAgreed])

  const message = useMemo(
    () =>
      patient?.hasAgreed === false
        ? 'Please review the Terms and Conditions to view the medical products I have suggested for you.'
        : bubbleProps.message,
    [patient?.hasAgreed, bubbleProps.message]
  )

  return showBubble && show ? <MessageBubbleGlobal {...{ ...bubbleProps, message }} /> : null
}

export default MessageBubble
