import { MainContext } from 'context'
import { useContext, useMemo, useState, useCallback } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { TrashIcon } from 'assets/vector'
import { svgButton } from 'utilities/utils'
import { ProductImageName } from 'components/Global'
import { useModals } from 'utilities/hooks'
import { AlternateModal } from 'components/Modals'

const mapVariantIds = pX => pX?.variants?.map(v => v?.id) || []

const queryKeys = ['product', 'practiceProductAlternatives', 'practiceId', 'productId']

const useAlternates = () => {
  const { admin, modal } = useContext(MainContext)
  const { success: successModal, error: errorModal, openErrorModal } = useModals()
  const [{ data: product, ...rest }] = useOutletContext()
  const { practiceId, productId } = useParams()
  const [alternates, setAlternates] = useState({
    practiceId,
    productId,
    sameProductVariantIds: product?.variants?.map(v => v?.id),
    alternativeProductOne: null,
    alternativeProductOneVariantIds: [],
    alternativeProductTwo: null,
    alternativeProductTwoVariantIds: [],
  })

  const { data, isLoading, error } = admin.api.products.getAlternates({
    variables: { practiceId, productId },
    queryOptions: {
      onSuccess: data => {
        const p1 = data?.[0]
        const p2 = data?.[1]

        setAlternates({
          ...alternates,
          alternativeProductOne: p1?.id || null,
          alternativeProductOneVariantIds: mapVariantIds(p1),
          alternativeProductTwo: p2?.id || null,
          alternativeProductTwoVariantIds: mapVariantIds(p2),
        })
      },
    },
  })

  const { mutate } = admin?.api?.products?.editAlternates({
    variables: { practiceId },
    queryOptions: {
      onSuccess: successModal('Alternate products updated!', queryKeys),
      onError: errorModal('There was an error selecting the alternate product.'),
    },
  })

  const addAlternate = useCallback(
    item => {
      const oneExists = !!alternates?.alternativeProductOne
      if (item?.id === +productId) {
        openErrorModal('Alternates must be different than the original product.')
        return
      }

      if (item?.id === alternates?.alternativeProductOne || item?.id === alternates?.alternativeProductTwo) {
        openErrorModal('This product is already an alternate.')
        return
      }

      modal.actions.open(
        <AlternateModal
          addAlt
          productImg={item?.images?.[0]?.src}
          productName={item.productName}
          openProductList={() => rest.setShowProductList(true)}
          onConfirm={() =>
            mutate({
              body: {
                ...alternates,
                alternativeProductOne: !oneExists ? item?.id : alternates?.alternativeProductOne,
                alternativeProductOneVariantIds: !oneExists
                  ? mapVariantIds(item)
                  : alternates?.alternativeProductOneVariantIds,
                alternativeProductTwo: oneExists ? item?.id : null,
                alternativeProductTwoVariantIds: oneExists ? mapVariantIds(item) : [],
              },
            })
          }
        />
      )
    },
    [alternates, modal.actions, mutate, openErrorModal, productId, rest]
  )

  const deleteAlternate = useCallback(
    //notice the curried function
    item => () => {
      const {
        alternativeProductOne,
        alternativeProductTwo,
        alternativeProductTwoVariantIds,
        alternativeProductOneVariantIds,
      } = alternates
      // if you delete the first one, you ahve to move the second one into it's place
      const isOne = alternativeProductOne === item?.id

      modal?.actions?.open(
        <AlternateModal
          removeAlt
          productImg={item?.images?.[0]?.src}
          productName={item?.productName}
          onConfirm={() =>
            mutate({
              body: {
                ...alternates,
                alternativeProductOne: isOne ? alternativeProductTwo : alternativeProductOne,
                alternativeProductOneVariantIds: isOne
                  ? alternativeProductTwoVariantIds
                  : alternativeProductOneVariantIds,
                alternativeProductTwo: null,
                alternativeProductTwoVariantIds: [],
              },
            })
          }
        />
      )
    },
    [alternates, modal?.actions, mutate]
  )

  return {
    data: useMemo(
      () =>
        data?.map?.(alt => ({
          product: (
            <ProductImageName
              className={'product-img-name'}
              name={alt?.productName}
              imgString={alt?.images?.[0]?.src}
            />
          ),
          manu: alt?.vendor,
          categories: alt?.collections?.map?.(c => c.title)?.join(' > '),
          productNum: alt?.id,
          price: `$${alt?.variants?.[0]?.retailPrice?.toFixed(2)}`,
          delete: (
            <span className="trash-icon-wrap">
              {svgButton(<TrashIcon className="trash-icon-row" onClick={deleteAlternate(alt)} />)}
            </span>
          ),
        })) || [],
      [data, deleteAlternate]
    ),
    isLoading,
    error,
    addAlternate,
    deleteAlternate,
    ...rest,
  }
}

export default useAlternates
