import { useContext } from 'react'
import { MainContext } from 'context'
import { EditIcon } from 'assets/vector'
import { useSvgButton } from 'utilities/hooks'

import './EditButton.scss'

export const EditButton = props => {
  const { modal } = useContext(MainContext)
  return useSvgButton(
    <EditIcon className={`edit-btn ${props.className}`} onClick={() => modal.actions.open(props.modal)} />
  )
}
