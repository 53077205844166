import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useParamsToObject } from 'utilities/hooks'

let session = JSON.parse(sessionStorage.getItem('_patient_order'))

let affirmSession = JSON.parse(window.sessionStorage.getItem('_patient_session_affirm'))

export const paidStatuses = ['open', 'pending delivery', 'completed', 'cancelled', 'refunded']

export const useReEnter = () => {
  const { orderId } = useParams()
  const nav = useNavigate()
  const { PracticeId, isMedbot, DoctorId, DoctorLastName, PatientFirstName } = useParamsToObject()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (!session) return
    const { orderId: id } = session

    if (!orderId || !PracticeId || !isMedbot || !DoctorId || !DoctorLastName || !PatientFirstName) {
      nav(`order/${orderId || id}?${session?.params}`)
    }
  }, [orderId, nav, isMedbot, DoctorId, PatientFirstName, PracticeId, DoctorLastName])

  return {
    setStorage: () =>
      sessionStorage?.setItem(
        '_patient_order',
        JSON.stringify({
          orderId: orderId || session?.orderId,
          params: searchParams.toString(),
        })
      ),
  }
}

export const useAffirmRedirect = ({ use = false, login, orderId }) => {
  const nav = useNavigate()
  useEffect(() => {
    if (!use) return
    affirmSession && login({ variables: { orderId }, body: session })
  }, [login, orderId, nav, use])
}

export const bubbleKeys = {
  true: 'Please review and agree to our User Agreement and Privacy Policy to see your recommended products.',
  'aging retail': 'Please see the medical supplies I have recommended for you. Please log in to view your products.',
  'draft order': 'Please see the medical supplies I have recommended for you. Please log in to view your products.',
  open: 'We are currently processing your order. You will receive a notification when it has shipped.',
  paid: 'We are currently processing your order. You will receive a notification when it has shipped.',
  shipped: 'Your order has been processed and shipped!',
  'pending delivery': 'Your order has been processed and shipped!',
  completed: 'Your order has been delivered. Be sure to view the product instructions.',
  delivered: 'Your order has been delivered. Be sure to view the product instructions.',
  cancelled: 'Your order has been cancelled.',
  'refund suceeded': 'Your products have been refunded.',
}

export const msgKeys = {
  'aging retail': "Let's confirm who you are, so we can get your order on its way!",
  'draft order': "Let's confirm who you are, so we can get your order on its way!",
}

export const loadingMessages = ["Thank you, let's move forward.", 'Please wait while I gather your order.']
