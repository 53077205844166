import { useRef } from 'react'
import Lottie from 'react-lottie-player'
import { medtrixCheckFlipLottieData } from 'assets/lottie'
import { Button } from 'components/Global'
import './RXInfo.scss'

const RXInfo = ({ closeModal, ...rest }) => {
  const ref = useRef()

  return (
    <div ref={ref} className="modal-content rx-info-modal" {...rest}>
      <Lottie play loop={false} animationData={medtrixCheckFlipLottieData} />
      <span>This RX Item is coverd by most insurance companies.</span>
      <span>We will need to process this item through your insurance company for a price discount.</span>
      <Button onClick={closeModal}>Return to order</Button>
    </div>
  )
}

export default RXInfo
