import { MedBotList } from 'components/AlphabeticList'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { MedBotsContext } from '../context'
import { useQueryClient } from 'react-query'
import { PageHeader } from './PageHeader'
import { MainContext } from 'context'

export const AllMedBots = () => {
  const { admin } = useContext(MainContext)
  const { actions } = useContext(MedBotsContext)
  const qc = useQueryClient()
  const nav = useNavigate()

  useEffect(() => {
    qc.invalidateQueries(['medbot_details'])
    actions.reset()
  }, [actions, qc])

  const isAdmin = useMemo(
    () => admin?.user?.role?.includes?.('admin') || admin?.user?.role === 'admin',
    [admin?.user?.role]
  )

  //admin users cannot create medbots -- for now
  const onNewMedBotClick = !isAdmin ? () => nav('create') : null

  const handleSelectMedBot = useCallback(({ medbotId }) => nav(`${medbotId}`), [nav])

  return (
    <>
      <PageHeader title="MedBots" />
      <MedBotList
        onSelectMedbot={handleSelectMedBot}
        onNewMedBotClick={onNewMedBotClick}
        usePracticeFilter={isAdmin}
        type={'edit'}
      />
    </>
  )
}

// /**
//  * merge images with medbots
//  * @param {Array.<{productImages: Array.<String>, productName: String}>} medBotProducts
//  * @param {Array.<{productName: String}>} lineItems
//  * @returns lineItems with productImages[]
//  */
// function mergeImagesWithMedBots(medBotProducts, lineItems) {
//   let x = medBotProducts.map(({ productImages, productName }) => {
//     const obj = lineItems.find(item => item.productName === productName)
//     return { ...obj, productImages }
//   })
//   return x
// }
