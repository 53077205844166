import { useMedBotCreate } from './useMedBotCreate'
import { useMedbotUpdate } from './useMedbotUpdate'
import { useCallback, useContext } from 'react'
import { MedBotsContext } from '../../context'

export function useManageMedbot() {
  const { hasEdits, medbotId, actions } = useContext(MedBotsContext)
  const { createMedBot, isLoading: createLoading } = useMedBotCreate()
  const { updateMedBot, isLoading: updateLoading } = useMedbotUpdate()

  const handleSubmit = useCallback(
    medbotName => {
      actions.nameBot(medbotName)
      !!medbotId ? updateMedBot(medbotName) : createMedBot(medbotName)
    },
    [medbotId, updateMedBot, createMedBot, actions]
  )

  return { handleSubmit, isLoading: createLoading || updateLoading, hasEdits }
}
