import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ModalProvider } from './Modal/ModalContext'
import { MainProvider } from './MainContext'

const GlobalProvider = ({ children }) => {
  const client = new QueryClient()
  return (
    <QueryClientProvider client={client}>
      <ModalProvider>
        <MainProvider>
          <BrowserRouter>{children}</BrowserRouter>
        </MainProvider>
      </ModalProvider>
    </QueryClientProvider>
  )
}

export default GlobalProvider
