import { useContext } from 'react'
import { MedBotList } from 'components/AlphabeticList'
import { CreateOrderContext, useCreateOrderNav } from '../../context'

export const SelectMedbot = () => {
  const nav = useCreateOrderNav()
  const { actions } = useContext(CreateOrderContext)

  const onSelectMedbot = medbot => {
    /// we have to join the medBotProduct and lineItems
    const { medBotProducts, lineItems, ...restOfMedbot } = medbot
    const merged = []
    for (const item of lineItems) {
      let i = medBotProducts.find(p => p.productName === item.productName)
      merged.push({ ...item, ...i })
    }
    ///set products to merged array and set medbot
    nav(`provider`)
    actions.setProducts(merged)
    actions.setMedbot(restOfMedbot)
  }

  return <MedBotList onSelectMedbot={onSelectMedbot} />
}
