import { useCallback } from 'react'
import { Input } from 'components'
import { addressInputs } from './utils'

export const InnerShippingForm = ({ handleSubmit, children }) => {
  const mapInputs = useCallback(
    inputs =>
      inputs.map(({ component, ...inp }, i) =>
        component === 'select' ? (
          <Input required className={`payment-form__${inp.name}`} key={i} component={component || null} {...inp}>
            {inp?.options}
          </Input>
        ) : (
          <Input required key={i} className={`payment-form__${inp.name}`} component={component || null} {...inp} />
        )
      ),
    []
  )

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <Input required label="Email" name="email" type="text" />
      <div className="payment-form__section-header">
        <h3>SHIPPING ADDRESS</h3>
      </div>
      {mapInputs(addressInputs({}).manyLines)}
      <div className="state-zip-line">{mapInputs(addressInputs({}).oneLine)}</div>
      {children}
    </form>
  )
}
