import './EditProduct.scss'
import { useCallback, useContext, useEffect, useState } from 'react'
import { MainContext } from 'context'
import { useParams } from 'react-router-dom'
import { useModals } from 'utilities/hooks'
import { customQueryKeys } from 'utilities/constants'

const keys = [customQueryKeys.EDIT_PRODUCT]

const useProduct = () => {
  const { admin } = useContext(MainContext)
  const { success, error } = useModals()
  const { practiceId, productId } = useParams()
  const [variant, setVariant] = useState({})
  const [price, setPrice] = useState(0)
  const [wholeSalePrice, setWholeSalePrice] = useState(0)

  const res = admin.api.products.getProduct({
    variables: { practiceId, productId },
    queryOptions: {
      onSuccess: data => {
        setVariant(data?.variants?.[0])
        setPrice(data?.variants?.[0]?.retailPrice)
        setWholeSalePrice(data?.variants?.[0]?.wholeSalePrice)
      },
    },
    keys: [...keys, productId],
  })

  useEffect(() => {
    if (!price) {
      res.refetch()
    }
  }, [price, res])

  const { mutate } = admin.api.products.overrideMarkup({
    variables: { practiceId, variantId: variant?.variantId },
    queryOptions: {
      onSuccess: success('Product price updated!', variant?.variantId),
      onError: error('There was an error updated the product.'),
    },
  })

  const calc = useCallback(
    () => ({ msrp: variant?.price, profitMargin: price - variant?.wholeSalePrice, variantId: variant?.id }),
    [price, variant?.price, variant?.wholeSalePrice, variant?.id]
  )

  const overrideMarkup = useCallback(
    () => mutate({ body: { useOverride: true, markupPriceOverride: price } }),
    [mutate, price]
  )

  return {
    price,
    wholeSalePrice,
    setPrice,
    variant,
    overrideMarkup,
    ...calc(),
    ...res,
  }
}

export default useProduct
