import { useNavigate } from 'react-router-dom'
import { Button } from 'components/Global'
import './ShipButton.scss'
import { CancelButton } from '../CancelButton'

const showButtonStatus = ['draft order', 'aging retail']

export const ShipButton = ({ data }) => {
  const navigate = useNavigate()

  if (
    !showButtonStatus.includes(data?.status) ||
    data?.totalPrice === 0 ||
    ['completed', 'open', 'pending delivery', 'refunded', 'refund pending', 'cancelled'].includes(data?.status)
  )
    return null

  return (
    <div className="ready-ship-stick">
      <div className="top">
        <Button
          onClick={() =>
            navigate(`/order/${data?.localId || data?.id}/payment/shipping`, { state: { from: 'recommendation' } })
          }
          className="ready-ship-btn"
        >
          Check out
        </Button>
      </div>
      <CancelButton />
    </div>
  )
}
