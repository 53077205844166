import { MainContext } from 'context'
import { createContext, useContext, useMemo } from 'react'
import { useImmerReducer } from 'use-immer'

import { tableInit, useProductTableActions, ProductsTableReducer } from './ProductsTableReducer'

export const ProductTableContext = createContext(tableInit)

export const ProductTableProvider = ({ children }) => {
  const { admin } = useContext(MainContext)
  const [{ practiceFilter, ...state }, dispatch] = useImmerReducer(ProductsTableReducer, { ...tableInit })
  const actions = useProductTableActions(dispatch)

  //practice users won't be able to see anything other than their own products
  const practiceId = useMemo(
    () => (admin?.user?.practiceId ? admin.user.practiceId : practiceFilter),
    [admin?.user?.practiceId, practiceFilter]
  )

  return (
    <ProductTableContext.Provider value={{ ...state, practiceFilter: practiceId, ...actions }}>
      {children}
    </ProductTableContext.Provider>
  )
}
