import { createOrderLottieData } from 'assets/lottie'
import { Button } from 'components'
import { ProductPickerContext } from 'components/ProductPicker/context'
import { productPickerPages } from 'components/ProductPicker/utils'
import { useCallback, useContext } from 'react'
import Lottie from 'react-lottie-player'

export const AddAnother = () => {
  const { actions } = useContext(ProductPickerContext)

  const restartProductSelection = useCallback(
    () =>
      actions.setSelectProduct({
        subPage: productPickerPages.CATEGORIES,
        product: null,
        subCategory: null,
        category: null,
      }),
    [actions]
  )

  const handleNo = useCallback(() => {
    restartProductSelection()
    actions.onFinish()
  }, [actions, restartProductSelection])

  return (
    <div className="select-product-add-another">
      <Lottie className="select-product-add-another__lottie" animationData={createOrderLottieData} play loop={false} />
      <div className="select-product-add-another__prompt">
        <span>Add Another Product?</span>
      </div>
      <div className="select-product-add-another__buttons">
        <Button onClick={restartProductSelection}>Yes</Button>
        <Button onClick={handleNo}>No</Button>
      </div>
    </div>
  )
}
