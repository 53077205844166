import { ExitIcon, MedBotSmallLogo, PoweredByMedBotDarkBlue } from 'assets/vector'
import './NavFooter.scss'
import { usePatientMenu } from '../context/PatientMenuContext'
import { useAuth } from 'context/PatientPortal'
import { useModal } from 'context/Modal/ModalContext'
import { AlertModal } from 'components/Modals'

export function NavFooter() {
  const modal = useModal()
  const { actions } = useAuth()
  const { isOpen } = usePatientMenu()

  const logout = () =>
    modal.actions.open(
      <AlertModal
        message={'Are you sure you want to logout?'}
        onConfirm={() => {
          modal.actions.close()
          actions.logoutUser()
        }}
        confirmText={'Logout'}
        denyText={'Cancel'}
      />
    )

  return (
    <div className="patient-portal-menu__nav-footer">
      <CollapsingMedtrixLogo />
      <a
        className={isOpen ? 'open' : 'closed'}
        target="_blank"
        rel="noreferrer"
        href="mailto:support@medbot.com?subject=Customer Support Inquiry"
      >
        Contact Us
      </a>
      <a
        className={isOpen ? 'open' : 'closed'}
        href="https://medbot.com/privacy-policy/"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      <button onClick={logout} className={`patient-portal-menu__nav-footer--logout ${isOpen ? 'open' : 'closed'}`}>
        <ExitIcon />
        <p>LOG OUT</p>
      </button>
    </div>
  )
}

const CollapsingMedtrixLogo = () => {
  const { isOpen } = usePatientMenu()

  return (
    <div className={`patient-portal-menu__nav-footer--logo ${isOpen ? 'open' : 'closed'}`}>
      {!isOpen ? <MedBotSmallLogo /> : <PoweredByMedBotDarkBlue style={{ padding: '5px' }} />}
    </div>
  )
}
