import { Form, Field } from 'react-final-form'
import { Button, Input, CloseButton, Loader, AvatarCrop } from 'components/Global'
import { formatSpecialties, MapMultiField } from '../utils'
import { useUserActions } from '../useUserActions'
import { useState, useMemo } from 'react'
import { ErrorMsg } from '../components'
import './ProviderModal.scss'
import useProviderActions from './useProviderActions'

const specialties = [
  'Orthopedic',
  'Ophthalmology',
  'Urology',
  'OBGYN',
  'Podiatry',
  'Vascular',
  'Dermatology',
  'Plastics',
  'Primary Care',
  'Dental',
]

const providerTypes = {
  Doctor: 'Doctor',
  ARNP: 'Advanced Registered Nurse Practitioner',
  PS: 'Physicians Assistant',
}

export const ProviderModal = ({ closeModal, refetchUsers, user = {}, edit, admin, isAdmin, ...rest }) => {
  const { reactivate, deactivate } = useUserActions(user)
  const { create, editProvider, isLoading } = useProviderActions(refetchUsers)
  const [customError, setError] = useState(null)

  const img = useMemo(
    () => (user?.avatar ? `data:${user?.avatar?.contentType};base64,${user?.avatar?.fileContents}` : null),
    [user]
  )

  if (isLoading) return <Loader />

  return (
    <div {...rest} className="modal-content create-provider-modal create-provider-wrapper">
      <CloseButton onClick={() => closeModal()} />
      <h2>{edit ? 'Edit Provider' : 'Create Provider'}</h2>
      <Form
        onSubmit={(values, { getState }) => {
          const { values: v } = getState()

          if ((!v.fileName && !v?.avatar) || !v?.providerType) {
            setError('Providers must have an avatar and provider type.')
            return
          }

          edit ? editProvider({ ...user, ...values }) : create(values)
        }}
        initialValues={
          edit
            ? {
                ...user,
                providerType: providerTypes?.[user?.providerType] || null,
                isPhysician: true,
                specialties: formatSpecialties(user?.specialties),
              }
            : { isPhysician: true, role: 'doctor' }
        }
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="create-user-form">
            <div className="avatar-name-wrapper" id={'provider'}>
              <div className="avatar-wrapper">
                <AvatarCrop required id="avatar" name="avatar" src={img} />
              </div>
              <div className="name-fields-wrapper" id={'doctor'}>
                <Input id="first" label="First Name" required={true} />
                <Input id="last" label="Last Name" required={true} />
                <Input id="npi" label="NPI" name="npi" />
              </div>
            </div>
            <div className="provider-email-line">
              <Input id="email" label="Email" name="email" required={true} />
              <div className="send-invite-wrapper">
                <Field type="checkbox" name="sendInviteEmail" component={'input'} className="send-invite-checkbox" />
                <p>Send User Invite Email?</p>
              </div>
            </div>

            <div className="multi-fields-wrapper">
              <MapMultiField
                {...{
                  fields: ['Doctor', 'Advanced Registered Nurse Practitioner', 'Physicians Assistant'],
                  type: 'radio',
                  className: 'multi-field multi-field__provider-type',
                  required: true,
                  name: 'providerType',
                  label: 'Provider Type',
                }}
              />
            </div>
            <div className="multi-fields-wrapper">
              <MapMultiField
                {...{
                  fields: specialties,
                  type: 'checkbox',
                  className: 'multi-field multi-field__specialties',
                  required: true,
                  name: 'specialties',
                  label: 'Please select the physicians specialties',
                }}
              />
            </div>
            <ErrorMsg error={customError} />
            <div className={`actions ${edit ? 'edit' : ''}`}>
              <Button
                onClick={e => {
                  e.preventDefault()
                  closeModal()
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
              {edit && (
                <Button
                  type="button"
                  onClick={() => (user?.isActive ? deactivate(user) : reactivate(user))}
                  className="deactivate-btn"
                >
                  {user?.isActive ? 'Deactivate' : 'Activate'}
                </Button>
              )}
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}
