import { createContext, useContext } from 'react'
import { userInit, authReducer, useAuthActions } from './AuthReducer'
import { useImmerReducer } from 'use-immer'
import AxiosInterceptors from 'apiv2/interceptors'

export const AuthContext = createContext(userInit)

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(authReducer, userInit)
  const actions = useAuthActions(dispatch)

  return (
    <AxiosInterceptors token={state?.token} logout={actions.logoutUser}>
      <AuthContext.Provider value={{ ...state, actions }}>{children}</AuthContext.Provider>
    </AxiosInterceptors>
  )
}

export const useAuth = () => useContext(AuthContext)
