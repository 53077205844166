import { createContext, useContext } from 'react'
import { AnalyticsReducer, init, useAnalyticsActions } from './AnalyticsReducer'
import { useImmerReducer } from 'use-immer'

export const AnalyticsContext = createContext(init)

export const AnalyticsProvider = ({ children, practiceId = 0 }) => {
  const [state, dispatch] = useImmerReducer(AnalyticsReducer, init)
  const actions = useAnalyticsActions(dispatch)

  return <AnalyticsContext.Provider value={{ ...state, practiceId, actions }}>{children}</AnalyticsContext.Provider>
}

export const useAnalyticsContext = () => useContext(AnalyticsContext)
