import { BlueRightArrow } from 'assets/vector'
import { MainContext } from 'context'
import { useCart } from 'pages/RecommendationPortal/Cart/context'
import { useCallback, useContext, useMemo } from 'react'
import { DoctorMessage } from './DoctorMessage'
import './Product.scss'
import { defaultProductImg } from 'assets/raster'
import { AlertModal, Button, ProductControls } from 'components'

const formatNum = num =>
  Number(num).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const Product = ({
  addToCartButton = false,
  productControls = null,
  pos = 0,
  className,
  product,
  closeDropdown,
  doctorId,
  order,
}) => {
  const { patient } = useContext(MainContext)

  const displayPrice = useMemo(() => {
    if (product?.subscribed && product?.subscribedPrice) {
      return formatNum(product?.subscribedPrice)
    }

    if (product?.useOverride) {
      return formatNum(product?.overridePrice)
    }

    if (!product?.retailPrice) return 'Invalid Price'

    return formatNum(product.retailPrice)
  }, [product])

  return (
    <div className={`product-master-wrapper slide-in-pos__${pos}`}>
      <button
        onClick={() => {
          closeDropdown?.()
          patient?.actions?.viewProduct({ ...product, doctorId }, order)
        }}
        className={`product-wrap ${className || ''}`}
      >
        <div className="product-wrap-main">
          <div className={`product-img-wrap`}>
            <img
              className={`product-img`}
              src={
                product?.variantImages?.[0] ||
                product?.image?.[0]?.src ||
                product?.images?.[0]?.src ||
                product?.imageSrc
              }
              onError={({ currentTarget }) => (currentTarget.src = defaultProductImg)}
              alt="product-img"
            />
          </div>
          <div className={`product-info`}>
            <span className="product-info__name">
              {product?.productName || product?.productTitle || product?.title}
            </span>
            <span className="product-info__tagline">{product?.productTagline}</span>
            <div className="product-info__price">
              <span className="product-info__price-label">Price</span>
              <span className="product-info__price-amount">${displayPrice || '--'}</span>
            </div>
          </div>
        </div>
        <div className="product-arrow-wrap">
          <BlueRightArrow />
        </div>
      </button>
      <div className="product-info-controls">
        {addToCartButton && <AddToCartButton product={product} />}
        <DoctorMessage
          increasedImgSizes
          doctorId={order?.doctorId}
          // title={state?.mode === LOCATION_STATE_MODES.CONVENIENCE_STORE ? 'Why We Chose This' : 'Directions'}
          title={'Message from your Doctor'}
          msg={product?.doctorsMessage}
        />
        {!!productControls ? (
          <ProductControls patient practiceId={order?.practiceId} product={product} {...productControls} />
        ) : null}
      </div>
    </div>
  )
}

const AddToCartButton = ({ product }) => {
  const { modal } = useContext(MainContext)
  const { actions, LineItems } = useCart()

  const isInCart = useMemo(() => !!LineItems.find(item => item.productId === product.productId), [LineItems, product])

  const handleClick = useCallback(() => {
    if (isInCart) {
      modal.actions.open(
        <AlertModal
          message={`Are you sure you want to remove ${product?.productName || 'this item'} from your cart?`}
          onConfirm={() => {
            actions.removeItem(product.productId, false)
            modal.actions.close()
          }}
          confirmText={'Remove'}
          onDeny={modal.actions.close}
          denyText={'keep'}
        />
      )
    } else {
      actions.addItem(product)
    }
  }, [actions, product, isInCart, modal.actions])

  return (
    <Button className={`product-info-controls__cart-button ${isInCart ? 'is-in-cart' : ''}`} onClick={handleClick}>
      {isInCart ? 'Remove From Cart' : 'Add To Cart'}
    </Button>
  )
}
