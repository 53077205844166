import { useCallback, useContext } from 'react'
import { Form } from 'react-final-form'

import { Button, Input } from 'components/Global'

import { MainContext } from 'context'

import { MedtrixLogoSlogan } from 'assets/vector'
import './PracticeLogin.scss'
import ModalContainer from 'components/Modals/ModalContainer'

export const PracticeLogin = () => {
  const {
    admin: {
      authentication: { loading, token, error },
      actions,
    },
  } = useContext(MainContext)

  console.log(loading, token, error)
  return (
    <main className="login practice-admin">
      <ModalContainer />
      <div className="login-logo">
        <MedtrixLogoSlogan />
      </div>
      <div className="login-form-wrapper">
        <Form
          onSubmit={values => {
            actions.login(values)
          }}
          initialValues={{}}
          render={useCallback(
            ({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="login-form practice-admin">
                <h2>Welcome Back</h2>
                <Input label="Email" name="email" type="text" />
                <Input label="Password" name="password" type="password" placeholder="MM-DD-YYYY" />
                <Button>{loading ? 'loading...' : 'Login'}</Button>
              </form>
            ),
            [loading]
          )}
        />
      </div>
    </main>
  )
}
