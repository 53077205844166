import { Spinner } from 'pages/RecommendationPortal/Spinner'
import { Header } from './Header'
import './List.scss'

export const PatientListWrap = ({
  title,
  children,
  isLoading,
  error = false,
  errorMsg = 'There was an error.',
  useAnimation = false,
}) => (
  <div className={`store-list-wrap ${isLoading ? 'show-loading' : 'show-content'} ${useAnimation ? 'animate' : ''}`}>
    <Header title={title} />
    {isLoading ? (
      <Spinner className={'store-list-spinner'} />
    ) : error ? (
      <span className="store-list-wrap__error">{errorMsg}</span>
    ) : (
      children
    )}
  </div>
)
