import { BlockedPatientRedCircleIcon, UnblockedPatientGreyCircleIcon } from 'assets/vector'
import { Tab } from 'components/AlphabeticList'

export const PatientItem = ({ onSelectPatient, addTab, patient }) => {
  const { lastName, firstName, dateOfBirth, blockNotificationsReason, blockNotifications } = patient

  return (
    <li className="create-order-page__item blocked-patients__item">
      {addTab && <Tab letter={lastName[0]} />}
      <button onClick={() => onSelectPatient(patient)}>
        <div className={`blocked-patients__item--name ${blockNotifications ? 'blocked' : 'unblocked'}`}>
          <span>
            <p>{`${firstName} ${lastName}`}</p>
          </span>
        </div>
        <div className={`blocked-patients__item--dob ${blockNotifications ? 'blocked' : 'unblocked'}`}>
          <span>
            <p>{dateOfBirth}</p>
          </span>
        </div>
        {blockNotifications && (
          <div className="blocked-patients__item--reason">
            <span>
              <p>{blockNotificationsReason}</p>
            </span>
          </div>
        )}
        {blockNotifications ? <BlockedPatientRedCircleIcon /> : <UnblockedPatientGreyCircleIcon />}
      </button>
    </li>
  )
}
