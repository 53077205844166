export const billingShape = (v, currentState) => {
  const expiration = v?.exp?.split?.('/')

  return {
    ...currentState,
    practiceBilling: {
      isChanged: true,
      nameOnCard: v.nameOnCard,
      id: v.id,
      address1: v.address1,
      address2: v.address2 ? v.address2 : '',
      city: v.city,
      state: v.state,
      zip: v.zip,
      creditCardNumber: v.creditCardNumber,
      expirationMo: +expiration?.[0],
      expirationYr: +expiration?.[1],
      cvc: v.cvc,
      practiceID: currentState?.id,
      stripeCardId: currentState?.practiceBilling.stripeCardId || null,
    },
  }
}

export const paymentShape = (values, currentState) => {
  let toDelete = !currentState?.practiceBankAccount
    ? false
    : values?.bankName || values?.accountNumber || values?.routingNubmer
    ? true
    : false

  return {
    ...currentState,
    practiceBankAccount: {
      toDelete,
      id: currentState?.practiceBankAccount?.id || 0,
      bankName: values.bankName,
      accountHoldersName: values.accountHoldersName,
      accountNumber: values.accountNumber,
      routingNumber: values.routingNumber,
      currency: 'USD',
      country: 'US',
    },
  }
}

export const feeShape = (values, currentState) => {
  return {
    enrollmentFeeAmount: values?.enrollmentFeeAmount || 0,
    enrollmentFeeDate: values?.enrollmentFeeDate || null,
    // monthlyFeeStart: values.monthlyFeeStart,
    monthlyFee: +values?.monthlyFee ?? 0,
    transactionFee: +values?.transactionFee ?? 0,
    textEmailFee: +values?.textEmailFee ?? 0,
    medbotFee: +values?.medbotFee ?? 0,
    medbotMiningBackfillFee: +values?.medbotMiningBackfillFee ?? 0,
  }
}

export const generalShape = (values, currentState) => {
  return {
    address1: values?.address1,
    city: values?.city,
    email: values?.email,
    fax: values?.fax,
    groupNpi: values?.groupNpi || '',
    name: values?.name,
    phoneNumber: values?.phoneNumber,
    physicianAdminContactPhone: values?.physicianAdminContactPhone,
    physicianAdminContactEmail: values?.physicianAdminContactEmail,
    physicianAdminContactName: values?.physicianAdminContactName,
    ptan: '',
    state: values?.state,
    website: values?.website,
    zip: values?.zip,
  }
}
