import apiv2 from 'apiv2'

/**
 * @param {Object} params
 * @param {string|number} params.medbotId
 * @param {string|number} params.lineItemId
 */
export const DELETE_PRODUCT_ON_MEDBOT = async ({ medbotId, lineItemId }) => {
  const url = '/medbot/lineitems/delete/:medbotId/:lineItemId/'
    .replace(':medbotId', medbotId)
    .replace(':lineItemId', lineItemId)

  return await apiv2.post(url)
}
