import { memo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { capNotifsAt } from 'utilities/utils'

const NavigationItem = ({ label, name, icon, subMenu, notifications, end, path }) => {
  const collapsed = !useLocation().pathname.includes(name)

  return subMenu ? (
    <li className={`nav-item nav-${name || 'dashboard'}`}>
      <NavLink className={`nav-submenu-head ${!collapsed ? 'active' : ''}`} to={name && `./${path || name}`} end={end}>
        {icon}
        <p>{label}</p>
        {(!!notifications || subMenu?.[0]?.notifications) && (
          <span className="notifications" role="status" aria-label="Notifications">
            {notifications
              ? capNotifsAt(notifications, 99)
              : capNotifsAt(
                  subMenu?.reduce?.((acc, item) => (+item?.notifications ? item?.notifications + acc : acc), 0),
                  99
                )}
          </span>
        )}
      </NavLink>
      <ul
        style={{ height: collapsed ? '0px' : `${subMenu.length * 34 || 0}px` }}
        className={`nav-submenu  ${collapsed ? 'collapsed' : 'open'}`}
      >
        {subMenu.map(({ name: subName, icon, label, notifications, onClick }) => (
          <li key={`submenu-${subName}`} className={`nav-submenu-item nav-submenu-${subName || 'dashboard'}`}>
            <NavLink onClick={onClick} to={`./${name}/${subName}`}>
              {icon}
              <p>{label}</p>
              {!!notifications && (
                <span className="notifications" role="status" aria-label="Notifications">
                  {capNotifsAt(notifications, 99)}
                </span>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </li>
  ) : (
    <li className={`nav-item nav-${name || 'dashboard'}`}>
      <NavLink to={name} end={end}>
        {icon}
        <p>{label}</p>
      </NavLink>
    </li>
  )
}

export default memo(NavigationItem)
