import { MainContext } from 'context'
import { useContext } from 'react'
import { AlertModal } from 'components'
import { useNavWithState } from 'utilities/Patient'
import { useQueryClient } from 'react-query'

export const useUpdateOrder = ({ orderId }) => {
  const queryClient = useQueryClient()
  const { patient, modal } = useContext(MainContext)
  const { navWithState, location } = useNavWithState()

  const { mutate: updateOrder, isLoading } = patient.api.updateOrder({
    variables: { orderId },
    queryOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries(['patient_order'])
        // patient.actions.setOrder({ ...patient?.order, ...d })
      },
      onError: () => {
        modal.actions.open(
          <AlertModal
            message="There was an error updating your order."
            onConfirm={() => {
              modal.actions.close()
              if (location?.state?.from) navWithState(-1)
              else navWithState('/')
            }}
          />
        )
      },
    },
  })

  return { updateOrder, isLoading }
}
