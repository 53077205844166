import apiv2 from 'apiv2'

/**
 *
 * @param {string\number} orderId
 */
export const GET_PATIENT_ORDER = async orderId => {
  const url = `/patientorders/v2/:orderId`.replace(':orderId', orderId)
  return await apiv2.get(url).then(res => ({
    ...res,
    data: {
      ...res.data,
      LineItems: res.data.LineItems.map(item => {
        /*
      Here we set the line item default, sandsDefault will be set server side

      Once a user has called PUT /patientorders/v2/:orderId,
      the order with their preferences/edits, 
      sandsDefault is mapped to the subscribed value
      */
        item.subscribed = item?.sandsDefault ?? item.subscribed
        return item
      }),
    },
  }))
}
