import { Input } from 'components'
import { PracticeComboBox } from 'pages/Admin/Orders/OrdersTable/PracticeComboBox.js/index.js'

const Combo = ({ input, meta, ...rest }) => (
  <PracticeComboBox
    {...input}
    {...rest}
    clearText="All"
    placeholder="All"
    //if the backend wants name, use this
    // getName={name => input.onChange(name)}
    //if the backend wants id, use this
    selectPractice={practiceId => {
      input.onChange(practiceId)
    }}
  />
)

const SearchableDropdown = props => <Input {...props} component={<Combo />} />

export default SearchableDropdown
