import { ChevronRightIcon } from 'assets/vector'
import { useNavigate } from 'react-router-dom'

import './BackButton.scss'

export const BackButton = ({ className }) => {
  const nav = useNavigate()

  return (
    <button className={`back-btn ${className || ''}`} onClick={() => nav(-1)}>
      <span className="back-btn__chevron-box">
        <ChevronRightIcon className="back-btn__chevron-icon" role="img" aria-label="chevron" />
      </span>
      <span className="back-btn__text">Back</span>
    </button>
  )
}
