import { Table } from 'components'
import dayjs from 'dayjs'
import { useMemo } from 'react'

const columns = [
  { Header: 'PRACTICE', accessor: 'practiceName', unsortable: true },
  { Header: 'USER', accessor: 'userName', unsortable: true },
  { Header: 'MEDBOTS SENT', accessor: 'medbotsSent', unsortable: true },
  { Header: 'SEND DATE', accessor: 'sentDate', unsortable: true },
]

const MedBotsSentByUser = ({ data, isLoading, error }) => {
  const sentData = useMemo(
    () =>
      data?.staffList?.map(item => ({
        ...item,
        sender: `${item?.senderFirstName} ${item?.senderLastName}`,
        sentDate: dayjs(item.sentDate).format('MM/DD/YYYY'),
      })),
    [data]
  )

  return <Table loading={isLoading} error={error} data={{ columns, data: sentData || [] }} />
}

export default MedBotsSentByUser
