import React from 'react'
import { medbotLoadingData } from 'assets/lottie'
import Lottie from 'react-lottie-player'

import './Loader.scss'

const Loader = ({ fadeEdge = true }) => {
  return (
    <Lottie
      className={`global-loader ${fadeEdge ? 'fade-edge' : ''}`}
      play
      loop={true}
      animationData={medbotLoadingData}
    />
  )
}

export default Loader
