import { useMemo } from 'react'

const keys = {
  ADD_ITEM: 'ADD_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  SET_PRACTICE_ID: 'SET_PRACTICE_ID',
  NOTIFY: 'NOTIFY',
  EDIT_ITEM: 'EDIT_ITEM',
  TOGGLE_SHOW_ICON: 'TOGGLE_SHOW_ICON',
  SET_LINE_ITEMS: 'SET_LINE_ITEMS',
  RESET_CART: 'RESET_CART',
}

export const cartInit = {
  LineItems: [],
  practiceId: null,
  notification: null,
  showIcon: false,
}

export const CartReducer = (draft, { type, payload }) => {
  switch (type) {
    case keys.ADD_ITEM:
      //make sure there is a quantity
      const newItem = !!payload?.quantity ? payload : { quantity: 1, ...payload }
      return void (draft.LineItems = [...draft.LineItems, newItem])
    case keys.REMOVE_ITEM:
      return void (draft.LineItems = draft.LineItems.filter(item => item.productId !== payload))
    case keys.EDIT_ITEM:
      const i = draft.LineItems?.findIndex(item => item?.productId === payload.productId)
      return void (draft.LineItems[i] = payload)
    case keys.SET_PRACTICE_ID:
      return void (draft.practiceId = payload)
    case keys.NOTIFY:
      return void (draft.notification = payload)
    case keys.TOGGLE_SHOW_ICON:
      return void (draft.showIcon = payload)
    case keys.SET_LINE_ITEMS:
      return void (draft.LineItems = payload)
    case keys.RESET_CART:
      return cartInit
    default:
      return draft
  }
}

export const useCartActions = dispatch =>
  useMemo(
    () => ({
      resetCart: () => dispatch({ type: keys.RESET_CART }),
      setLineItems: LineItems => dispatch({ type: keys.SET_LINE_ITEMS, payload: LineItems }),
      addItem: (product, notify = true) => {
        dispatch({ type: keys.ADD_ITEM, payload: product })
        notify && dispatch({ type: keys.NOTIFY, payload: `${product?.productName || 'Item'} Added To Cart!` })
      },
      notify: text => dispatch({ type: keys.NOTIFY, payload: text }),
      removeItem: (productId, notify = true) => {
        dispatch({ type: keys.REMOVE_ITEM, payload: productId })
        notify && dispatch({ type: keys.NOTIFY, payload: `Item Removed From Cart.` })
      },
      editItem: product => dispatch({ type: keys.EDIT_ITEM, payload: product }),
      setPracticeId: practiceId => dispatch({ type: keys.SET_PRACTICE_ID, payload: practiceId }),
      toggleCartIcon: bool => dispatch({ type: keys.TOGGLE_SHOW_ICON, payload: bool }),
    }),
    [dispatch]
  )
