import { useMemo } from 'react'

const keys = {
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
}

export const init = {
  product: null,
}

export const ProductViewReducer = (draft, { type, payload }) => {
  switch (type) {
    case keys.UPDATE_PRODUCT:
      return void (draft.product = { ...draft.product, ...payload })
    default:
      return draft
  }
}

export const useProductViewActions = dispatch =>
  useMemo(
    () => ({
      updateProduct: updatedProduct => dispatch({ type: keys.UPDATE_PRODUCT, payload: updatedProduct }),
    }),
    [dispatch]
  )
