import './Details.scss'
import { PriceControl } from '../PriceControl'
import { ImageSlider } from 'components/Global'
import { Documents } from './peices'
import { defaultProductImg } from 'assets/raster'
import { useOutletContext } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import DOMPurify from 'dompurify'

export const Details = () => {
  const [{ data: product }] = useOutletContext()

  /* 
  NOTE
  Images could be mp4, pdfs, or images...
  */
  const mapImages = useCallback(
    () => (product?.image?.length ? product?.image?.map(img => img?.src) : [defaultProductImg]),
    [product]
  )

  return (
    <div className="edit-product-main">
      <div className="edit-product-main__left">
        <PriceControl />
        <Description bodyHtml={product?.bodyHtml} />
        <Documents />
      </div>
      <ImageSlider
        className={'edit-product-main__image-slider'}
        fallbackImage={defaultProductImg}
        images={mapImages()}
        showArrows={product?.image?.length > 1}
      />
    </div>
  )
}

const Description = ({ bodyHtml }) => {
  const __html = useMemo(() => {
    const cleanedHtml = DOMPurify.sanitize(bodyHtml)
    return { __html: cleanedHtml }
  }, [bodyHtml])

  return (
    <div className="edit-product-main__left--description">
      <span id="sub-header">Description</span>
      {bodyHtml ? <div dangerouslySetInnerHTML={__html} /> : <p>'No Description'</p>}
    </div>
  )
}
