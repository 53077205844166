import { useContext, useCallback, useMemo } from 'react'
import { EditUserModal, NewUserModal, Pagination, Table } from 'components'
import { Search, Button, EditButton } from 'components/Global'
import './MedtrixAdmins.scss'
import { MainContext } from 'context'
import { useUserPagination } from 'utilities/hooks'
import { AvatarWithFallback } from 'components/Global'

const columns = [
  { Header: 'FIRST NAME', accessor: 'firstName' },
  { Header: 'LAST NAME', accessor: 'lastName' },
  { Header: 'EMAIL', accessor: 'email' },
  // { Header: 'PHYSICIAN', accessor: 'isPhysician', unsortable: true },
  { Header: 'EDIT', accessor: 'edit', unsortable: true },
  { Header: 'STATUS', accessor: 'isActive', unsortable: false },
]

export const MedtrixAdmins = () => {
  const { modal, admin } = useContext(MainContext)

  const { page, totalPages, data, isLoading, error, setPage, tableSort, search } = useUserPagination({
    apiFn: admin.api.getUsers.adminsWithAvatar,
    params: { rowsPerPage: 6 },
    keys: ['medtrix_admin_table'],
  })

  const writeStatus = useCallback(active => (active ? 'Account Active' : 'Account Deactivated'), [])

  const tableData = useMemo(
    () =>
      data?.items?.map(user => {
        const { firstName, lastName, email, isActive, avatar } = user

        return {
          firstName: <AvatarWithFallback avatar={avatar}  firstName={firstName} />,
          lastName,
          email,
          edit: <EditButton modal={<EditUserModal isAdmin user={user} />} />,
          isActive: <span className={writeStatus(isActive).toLowerCase()}>{writeStatus(isActive)}</span>,
        }
      }),
    [data, writeStatus]
  )

  return (
    <div className="medtrix-admins-wrapper">
      <h4 className="detail-tile__title detail-tile__title--main">Medtrix Admin Accounts</h4>
      <Table
        sort={tableSort}
        loading={isLoading}
        error={error}
        className="medtrix-admins-table"
        data={{ columns, data: tableData || [] }}
      >
        <div className="table-actions__user-search">
          <Search onSubmit={search} className="user-search" />
          <Button onClick={() => modal?.actions?.open(<NewUserModal isAdmin />)} className="add-user-btn">
            ADD NEW USER
          </Button>
        </div>
      </Table>
      <Pagination page={page} totalPages={totalPages} selectPage={setPage} />
    </div>
  )
}
