import './Button.scss'

const Button = ({ className, children, disabled, ...rest }) => {
  return (
    <button disabled={disabled} className={`btn ${className || ''} ${disabled ? 'disabled' : ''}`} {...rest}>
      {children}
    </button>
  )
}

export default Button
