export const Stats = ({ data }) => {
  return (
    <div className="analytics-body__breakdown analytics-body__revenue--breakdown">
      <div className="section">
        <span>{data?.activePhysicians ?? '--'}</span>
        <span>current physicians</span>
      </div>
      <div className="section">
        <span>{data?.productsSold ?? '--'}</span>
        <span>products Purchased</span>
      </div>
      <div className="section">
        <span>{data?.ordersPurchased ?? '--'}</span>
        <span>orders purchased</span>
      </div>
      <div className="section">
        <span>{data?.activeSubscriptions ?? '--'}</span>
        <span>Active Monthly Subscriptions</span>
      </div>
    </div>
  )
}
