import Lottie from 'react-lottie-player'
import { whiteShoppingCartData } from 'assets/lottie'
import './Header.scss'

export const Header = ({ title = 'Our Recommended Products' }) => {
  return (
    <div className="store-header">
      <div className="store-header__lottie-wrap">
        <Lottie role="img" play loop className="store-header__lottie" animationData={whiteShoppingCartData} />
      </div>
      <span>{title}</span>
    </div>
  )
}
