import { SelectButtonInList, Tab } from 'components/AlphabeticList'
import dayjs from 'dayjs'

export const PatientListItem = ({ patient, addTab, onSelectPatient }) => {
  const letter = patient?.lastName?.[0]

  return (
    <li className="create-order-page__item paginated-patient-list__item">
      {addTab && letter && <Tab letter={patient?.lastName?.[0]} />}
      <button onClick={() => onSelectPatient(patient)}>
        <span className="paginated-patient-list__item--last-name">{patient?.lastName || '--'}</span>
        <span className="paginated-patient-list__item--first-name">{patient?.firstName || '--'}</span>
        <span className="paginated-patient-list__item--dob">
          {dayjs(patient?.dateOfBirth).format('MM/DD/YYYY') || '--'}
        </span>
        <SelectButtonInList text="Select" />
      </button>
    </li>
  )
}

export * from './useGetPatients'
