import { useMemo } from 'react'
import {
  BasketIcon,
  OrdersIcon,
  PlusIcon,
  PracticeIcon,
  MedtrixUsersIcon,
  WeeklyPaymentIcon,
  MedbotOrderIcon,
  CreateOrderIcon,
  SingleOrderIcon,
  WhiteThumbsUpBotSmall,
  BlockPatientIcon,
  ReportsMenuIcon,
  HospitalBuildingIcon,
} from 'assets/vector'

const AdminMenu = () => {
  return useMemo(
    () => [
      /*
      Dashboard Removed ~ user story 204
      { label: 'Dashboard', icon: <DashboardIcon />, name: '', end: true },
      */
      { label: 'Analytics', icon: <WeeklyPaymentIcon />, name: '', end: true },
      {
        label: 'Send Recommendation',
        icon: <CreateOrderIcon />,
        name: 'create-order',
        path: 'create-order/medbot/select',
        subMenu: [
          {
            icon: <MedbotOrderIcon />,
            name: 'medbot',
            label: 'Saved Recommendations',
          },
          { icon: <SingleOrderIcon />, name: 'single', label: 'Create New Recommendation' },
        ],
      },
      {
        label: 'Send Wellness Store',
        icon: <HospitalBuildingIcon />,
        name: 'send-wellness-store',
      },
      { label: 'MedBots', icon: <WhiteThumbsUpBotSmall className="thumbs-up-bot" />, name: 'medbots' },
      {
        label: 'All Orders',
        icon: <OrdersIcon />,
        name: 'orders',
        path: 'orders/all',
        subMenu: [
          {
            icon: <PlusIcon />,
            name: 'open',
            label: 'Purchased Orders',
          },
        ],
      },
      { label: 'Practices', icon: <PracticeIcon />, name: 'practices' },

      { label: 'Products', icon: <BasketIcon />, name: 'products' },
      { label: 'MedBot Users', icon: <MedtrixUsersIcon />, name: 'users' },
      { label: 'Blocked Patients', icon: <BlockPatientIcon />, name: 'patients' },
      { label: 'Reports', icon: <ReportsMenuIcon />, name: 'reports' },
    ],
    []
  )
}
export default AdminMenu
