import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ResendInvite, lazyPortalWrap } from 'utilities'
import { SetPassword } from 'pages'

//lazy loaded modules
const OptInConsent = lazy(() => import('pages/OptInConsent'))
const RecommendationPortalRoutes = lazy(() => import('utilities/Portals/RecommendationPortal'))
const AdminPortalRoutes = lazy(() => import('utilities/Portals/AdminPortal'))
const PatientPortalRoutes = lazy(() => import('utilities/Portals/PatientPortal'))

function App() {
  return (
    <div className="App">
      <Routes>
        {/* UNPROTECTED ROUTES */}
        <Route path="consent" element={lazyPortalWrap(<OptInConsent />)} />
        <Route path="consent/:practiceId" element={lazyPortalWrap(<OptInConsent practice />)} />
        <Route path="create-password/:portal" element={<SetPassword create />} />
        <Route path="reset-password/:portal" element={<SetPassword reset />} />
        <Route path="/resendinvite" element={<ResendInvite />} />

        {/* Portals */}
        <Route path="/*" element={<RecommendationPortalRoutes />} />
        <Route path="admin/*" element={<AdminPortalRoutes />} />
        <Route path="/patient/*" element={<PatientPortalRoutes />} />
      </Routes>
    </div>
  )
}

export default App
