import { useMemo } from 'react'

const keys = {
  SELECT_PROVIDER: 'SELECT_PROVIDER',
  SELECT_PATIENT: 'SELECT_PATIENT',
  SELECT_PRACTICEID: 'SELECT_PRACTICEID',
  SET_PATIENT_SEARCH_KEYWORD: 'SET_PATIENT_SEARCH_KEYWORD',
  RESET: 'RESET',
}

export const initialState = {
  provider: null,
  patient: null,
  practiceId: null,
  keyword: null,
}

export const SendWellnessReducer = (draft, { type, payload }) => {
  switch (type) {
    case keys.SELECT_PATIENT:
      return void (draft.patient = payload)
    case keys.SELECT_PROVIDER:
      draft.practiceId = payload.practiceId
      return void (draft.provider = payload)
    case keys.SELECT_PRACTICEID:
      return void (draft.practiceId = payload)
    case keys.SET_PATIENT_SEARCH_KEYWORD:
      return void (draft.keyword = payload)
    case keys.RESET:
      return initialState
    default:
      return draft
  }
}

export const useSendWellnessActions = dispatch =>
  useMemo(
    () => ({
      reset: () => dispatch({ type: keys.RESET }),
      selectProvider: provider => dispatch({ type: keys.SELECT_PROVIDER, payload: provider }),
      selectPatient: patient => dispatch({ type: keys.SELECT_PATIENT, payload: patient }),
      setPracticeId: id => dispatch({ type: keys.SELECT_PRACTICEID, payload: id }),
      setPatientSearchKeyword: keyword => dispatch({ type: keys.SET_PATIENT_SEARCH_KEYWORD, payload: keyword }),
    }),
    [dispatch]
  )
