import api from 'apiv2'

/**
 * @param {Object} params
 * @param {string | number} params.subscriptionId
 * @param {'cancel' | "skip" | "resume"} params.action ~ "cancel" | "skip" | "resume" (resubscribe)
 */
export const MANAGE_SUBSCRIPTION = async ({ subscriptionId, action }) => {
  const url = `/patientportal/subscriptions/${action}/${subscriptionId}`
  return await api.post(url)
}
