import { memo, useState } from 'react'
import { Field } from 'react-final-form'

import { useImageDropzone } from 'utilities/hooks'

import { ArrowDownIcon, PlusIcon, AlertIcon } from 'assets/vector/icons'
import './PracticeLogo.scss'
import { Cropper } from 'components/Global/AvatarCrop/Cropper'

const PracticeLogo = memo(({ input: { onChange, ...inputProps }, meta, className, img, ...rest }) => {
  const [isCropping, setIsCropping] = useState(false)
  const [{ getInputProps, getRootProps, isDragActive }, { files, error, setFiles }] = useImageDropzone(
    {
      dropzoneOptions: {
        onDrop: () => setIsCropping(true),
      },
      onNewFiles: file => onChange(file?.data),
    },
    true
  )

  const onFinishCropping = image => {
    image?.preview && image?.data && setFiles(image)
    setIsCropping(false)
  }

  const cancelCropping = () => {
    setIsCropping(false)
    setFiles(null)
  }

  return (
    <>
      {isCropping && (
        <Cropper isPracticeLogo img={files?.preview} onCancel={cancelCropping} onFinish={onFinishCropping} />
      )}
      <div
        {...getRootProps({
          ...rest,
          className: `practice-logo-uploader ${isDragActive ? 'drag' : ''} ${!!error ? 'error' : ''} ${
            className || ''
          }`,
          ...inputProps,
        })}
      >
        <input {...getInputProps()} />
        {files && !isDragActive ? (
          <img src={files?.preview} alt="logo preview" className="image uploaded" />
        ) : img && !isDragActive ? (
          <img src={img} className="image uploaded" alt="logo saved" />
        ) : (
          <div className="image">
            <PlusIcon className="plus" />
            {isDragActive ? <ArrowDownIcon className="upload" /> : null}
          </div>
        )}
        {error && (
          <span className="error-message">
            <AlertIcon className="error-message__alert" /> {error}
          </span>
        )}
      </div>
    </>
  )
})

/**
 * @param {import('react-final-form').FieldProps | import('react').HTMLProps} props
 */
const PracticeLogoFieldWrapper = props => <Field {...props} component={PracticeLogo} />

export default memo(PracticeLogoFieldWrapper)
