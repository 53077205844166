import { recursiveFieldGroup } from 'utilities/utils'

/**
 * Groups all input values inside together under the given "group" name inside [form values](https://final-form.org/docs/react-final-form/types/FormProps#onsubmit).
 * @example
 * <InputGroup group='bio' >
 *   <Input name='firstName' /> // bio.firstName
 *   <Input name='lastName' />  // bio.lastName
 *   <Input name='birthday' />  // bio.birthday
 * </InputGroup>
 *
 * // <Form /> values: { bio: { firstName, lastName, birthday } }
 * */
export const InputGroup = ({ children, group: name, wrap, ...rest }) =>
  wrap ? (
    <div className={wrap} {...rest}>
      {recursiveFieldGroup(children, name)}
    </div>
  ) : (
    recursiveFieldGroup(children, name)
  )
