import { useBubbleProps } from 'utilities/hooks'
import { Login, Create, Forgot } from './components'
import './PatientLoginV2.scss'
import { useCallback, useContext, useState } from 'react'
import { MainContext } from 'context'

export const patientAuthPages = {
  LOGIN: 'LOGIN',
  CREATE: 'CREATE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
}

const { LOGIN, CREATE, FORGOT_PASSWORD } = patientAuthPages

export const PatientLoginV2 = () => {
  const { patient } = useContext(MainContext)
  const [page, setPage] = useState(LOGIN)
  useBubbleProps({ isOpen: page === LOGIN && Object.keys(patient?.order).length })

  const close = useCallback(() => setPage(LOGIN), [setPage])

  const renderComp = useCallback(() => {
    switch (page) {
      case CREATE:
        return <Create close={close} />
      case FORGOT_PASSWORD:
        return <Forgot close={close} />
      default:
        return <Login setPage={setPage} />
    }
  }, [page, setPage, close])

  return <div className="patient-login">{renderComp()}</div>
}
