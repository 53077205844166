/* example
"subtotalPrice": 226.9800,
"totalTax": 0.0000,
"totalShipping": 0.0000,
"totalPrice": 226.9800,
"totalBasePrice": 194.0000,
"totalMarkupAmount": 32.9800,
"taxPercentage": 0,
*/

/* formulas
      productSubtotal = (product.price + product.markupPrice) * product.quantity
      subtTotal = product1Subtotal + product2Subtotal + .... 
      tax = (subtotal + shipping) * taxPercentage
      totalPrice = subtotal + tax + shipping
*/

const calcProductSubtotal = product => product.quantity * product.retailPrice
const calcProductMarkup = product => product.quantity * product?.markupAmount ?? 0
const calcProductBasePrice = product => product.quantity * (product.retailPrice - product?.markupAmount ?? 0)

const calcTotalBasePrice = LineItems =>
  +LineItems.reduce((a, c) => (c?.removed || c.isOutOfStock ? a : (a += calcProductBasePrice(c))), 0)
const calcTotalMarkup = LineItems =>
  +LineItems.reduce((a, c) => (c?.removed || c.isOutOfStock ? a : (a += calcProductMarkup(c))), 0)
const calcSubtotal = LineItems =>
  +LineItems.reduce((a, c) => (c?.removed || c.isOutOfStock ? a : (a += calcProductSubtotal(c))), 0)

/*
make all product manipulations before this

*/
export const calcPrices = draft => {
  let { LineItems, totalShipping, shipping, lineItems } = draft.order
  draft.order.totalBasePrice = calcTotalBasePrice(LineItems || lineItems)
  draft.order.totalMarkupAmount = calcTotalMarkup(LineItems || lineItems)

  let shipCost = totalShipping || shipping || 0

  let subtotal = calcSubtotal(LineItems || lineItems)
  // let totalTax = !tax ? (subtotal + shipCost) * taxPercentage : tax
  // let totalPrice = subtotal + totalTax + shipCost
  let totalPrice = subtotal + shipCost
  // console.log(`subtotal: ${subtotal}, tax: ${totalTax}, totalPrice: ${totalPrice}`)
  // draft.order.totalPrice = totalPrice
  // draft.order.totalTax = totalTax
  draft.order.subtotalPrice = subtotal
  return { totalPrice, subtotal }
}

/*
Cart Actions
*/

/* assigning doctor to cart with the most items */
export const assignDoctorToCart = draft => {
  let max = { qty: 0, doctorUserId: null, practiceId: null }
  const docs = {}
  const items = draft.shoppingCart.LineItems
  const len = items.length
  let i = 0
  while (i < len) {
    let { quantity, doctorUserId, practiceId } = items[i]
    docs[doctorUserId] ? (docs[doctorUserId] += quantity) : (docs[doctorUserId] = quantity)
    /* if doctor has the most items */
    if (docs[doctorUserId] > max.qty) {
      max.qty = docs[doctorUserId]
      max.doctorUserId = doctorUserId
      max.practiceId = practiceId
    }
    i++
  }
  draft.shoppingCart.doctor.userID = max.doctorUserId
  draft.shoppingCart.practiceId = max.practiceId
  return max
}

/* 
calculating subtotal for shopping cart 
will need this if I have to calculate costs by hand

 productSubtotal = (product.price + product.markupPrice) * product.quantity
 subtTotal = product1Subtotal + product2Subtotal + .... 
*/
export const calcCartSubtotal = draft => {
  let subtotal = 0
  let LineItems = draft.shoppingCart.LineItems
  let len = LineItems.length
  let i = 0
  while (i < len) {
    const { retailPrice, quantity } = LineItems[i]
    subtotal += retailPrice * quantity
    i++
  }
  draft.shoppingCart.subtotal = subtotal
  return subtotal
}

export const markAsChanged = draft => (draft.order.isChanged = true)
