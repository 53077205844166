import { createContext } from 'react'
import { useImmerReducer } from 'use-immer'
import {
  adminInit,
  AdminReducer,
  patientInit,
  PatientReducer,
  useAdminActions,
  usePatientActions,
} from 'context/Reducers'
import { useModal } from 'context/Modal/ModalContext'
import AxiosInterceptors from 'apiv2/interceptors'

const adminStored = sessionStorage?.getItem?.('medtrix_admin_login')

const initialState = {
  admin: adminStored ? JSON?.parse?.(adminStored) : adminInit,
  modal: {},
  patient: patientInit,
}

export const MainContext = createContext(initialState)

export const MainProvider = ({ children }) => {
  const { actions: modalActions, ...modalState } = useModal()

  const [patientState, patientDispatch] = useImmerReducer(PatientReducer, patientInit)
  const patientActions = usePatientActions(patientDispatch)

  const [adminState, adminDispatch] = useImmerReducer(AdminReducer, adminInit)
  const adminActions = useAdminActions(adminDispatch, adminState?.authentication, modalActions)

  return (
    <AxiosInterceptors token={adminState?.authentication} logout={adminActions.logOut}>
      <MainContext.Provider
        value={{
          admin: { ...adminState, ...adminActions },
          modal: { ...modalState, actions: modalActions },
          patient: { ...patientState, ...patientActions },
        }}
      >
        {children}
      </MainContext.Provider>
    </AxiosInterceptors>
  )
}
