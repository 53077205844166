import { useNavigate } from 'react-router-dom'
import { useSendWellness } from '../context'
import { ProviderList } from 'components'

export const SelectProviderForSendWellness = () => {
  const { actions } = useSendWellness()
  const nav = useNavigate()

  const onSelectProvider = provider => {
    actions.selectProvider(provider)
    nav('patient')
  }

  return (
    <div className="create-order-wrapper">
      <div className="create-order-header">
        <h4 className="create-order-header__title">Select Provider</h4>
      </div>
      <ProviderList onSelectProvider={onSelectProvider} />
    </div>
  )
}
