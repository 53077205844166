import { useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Input, CloseButton, AvatarCrop, Loader } from 'components/Global'
import './UserModal.scss'
import { useUserActions } from './useUserActions'
import { passwordValidation } from 'utilities/utils'
import { onSubmit } from './utils'

const NewUserModal = ({ closeModal, edit, user, refetchUsers, isAdmin, admin, ...rest }) => {
  const { buildBody, createUser, editUser, isLoading, deactivate, reactivate } = useUserActions(user, refetchUsers)

  const img = useMemo(
    () => (user?.avatar ? `data:${user?.avatar?.contentType};base64,${user?.avatar?.fileContents}` : null),
    [user]
  )

  if (isLoading) return <Loader />

  return (
    <div {...rest} className="modal-content create-user-modal create-user-modal-wrapper">
      <CloseButton onClick={() => closeModal()} />
      <h2>{edit ? 'Edit User' : 'New User'}</h2>
      <Form
        onSubmit={(values, { getState }) => {
          const { body, isValid, avatarMod, avatar } = onSubmit({ values, getState, isAdmin, buildBody, edit, user })
          // console.log(body)
          if (isValid) {
            edit ? editUser(body, avatarMod ? avatar : null) : createUser(body, avatarMod ? avatar : null)
          }
        }}
        initialValues={
          edit
            ? {
                ...user,
                isPhysician: false,
              }
            : { isPhysician: false, role: 'staff' }
        }
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="create-user-form">
            <div className="avatar-name-wrapper" id={edit ? 'edit' : 'new'}>
              {edit && <AvatarCrop id="avatar" name="avatar" src={img} />}
              <div className="name-fields-wrapper" id={'staff'}>
                <Input id="first" label="First Name" required={true} />
                <Input id="last" label="Last Name" required={true} />
              </div>
            </div>
            <div className={`email-password__${edit ? 'edit' : 'create'}`}>
              <Input label={`${edit ? 'Active User' : ''} Email`} name="email" required={true} />
              {edit && (
                <Input
                  className="email-password__password-input"
                  validate={passwordValidation}
                  name="newPassword"
                  label="Password"
                  required={false}
                />
              )}
            </div>
            {!isAdmin && (
              <div className="delegated-access__wrap">
                <Field
                  type="checkbox"
                  name="delegatedAccess"
                  className="delegated-access__checkbox"
                  component={'input'}
                />
                <span>Delegated Access</span>
              </div>
            )}
            <div className={`actions ${edit ? 'edit' : ''}`}>
              <Button
                onClick={e => {
                  e.preventDefault()
                  closeModal()
                }}
                type="button"
              >
                Cancel
              </Button>
              <Button type="submit">{edit ? 'Save' : 'Send Invite'}</Button>
              {edit ? (
                <Button
                  type="button"
                  onClick={() => (user?.isActive ? deactivate(user) : reactivate(user))}
                  className="deactivate-btn"
                >
                  {user?.isActive ? 'DEACTIVATE' : 'ACTIVATE'}
                </Button>
              ) : null}
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}
export const EditUserModal = props => <NewUserModal edit {...props} />

export default NewUserModal
