import { SadMedbotIcon } from 'assets/vector'
import { Button } from 'components'
import { MainContext } from 'context'
import { useContext } from 'react'
import './AutomateButton.scss'
import Lottie from 'react-lottie-player'
import { animatedBackgroundLottieData } from 'assets/lottie'

export const AutomateButton = () => {
  const { modal } = useContext(MainContext)

  return <Button onClick={() => modal.actions.open(<OnAutomateModal />)}>Automate MedBot</Button>
}

const OnAutomateModal = ({ closeModal }) => {
  return (
    <div className="on-automate-medbot-modal">
      <div className="on-automate-medbot-modal__animation">
        <Lottie
          className="on-automate-medbot-modal__animation--actual"
          animationData={animatedBackgroundLottieData}
          play
          loop
        />
        <SadMedbotIcon className="sad-medbot-float" />
      </div>
      <span>
        <p>Please contact your sales representative to have your MedBot automated.</p>
      </span>
      <Button onClick={closeModal}>Okay</Button>
    </div>
  )
}
