import { createContext, useContext } from 'react'
import { ModalReducer, modalInit, useModalActions } from './ModalReducer'
import { useImmerReducer } from 'use-immer'

export const ModalContext = createContext(modalInit)

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(ModalReducer, modalInit)
  const actions = useModalActions(dispatch)

  return <ModalContext.Provider value={{ ...state, actions }}>{children}</ModalContext.Provider>
}

export const useModal = () => useContext(ModalContext)
