import { MainContext } from 'context'
import { useContext, useEffect } from 'react'
import { useAnalyticsContext } from '../context'

export const useAnalyticsV2 = () => {
  const { scope, practiceId, pagesize, page, totalRows, actions } = useAnalyticsContext()
  const { admin } = useContext(MainContext)

  const query = admin.api.analyticsV2.getAnalytics[scope]({
    params: {
      practiceId,
      page,
      pagesize,
    },
    queryOptions: {
      onSuccess: data => {
        actions.setTotalRows(data.totalRows)
      },
    },
  })

  useEffect(() => {
    if (!totalRows) {
      query.refetch()
    }
  }, [query, totalRows])

  return query
}
