import { useState, cloneElement, useCallback, forwardRef } from 'react'
import './Images.scss'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { ChevronRightIcon } from 'assets/vector'

export const ImageSlider = forwardRef(
  (
    {
      className,
      images: files, // images can be video, image, or file
      components,
      fallbackImage = null,
      showArrows = true,
      config,
      onImageClick,
    },
    ref
  ) => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [sliderRef, instanceRef] = useKeenSlider(
      {
        loop: true,
        slideChanged: slider => setCurrentSlide(slider?.track?.details?.rel),
        created: () => setLoaded(true),
        ...config,
      },
      [MutationPlugin, ResizePlugin]
    )

    /*
  Images should be string[] 
  */
    const mapImages = useCallback(
      () =>
        files?.length ? (
          files?.map((file, i) => (
            <div key={i} className={`keen-slider__slide number-slide${i + 1}`}>
              {file?.includes('mp4') ? (
                <video height="100%" width="100%" controls>
                  <source src={file} type="video/mp4" />
                </video>
              ) : (
                <img
                  onClick={() => onImageClick?.(file)}
                  alt={'product-media'}
                  src={file ?? fallbackImage}
                  onError={({ currentTarget }) => (currentTarget.src = fallbackImage ?? '')}
                />
              )}
            </div>
          ))
        ) : (
          <div className="keen-slider__slide number-slides1">
            <img alt="product-media" src={fallbackImage || ''} />
          </div>
        ),
      [files, fallbackImage, onImageClick]
    )

    const mapComponents = useCallback(() => {
      return components?.map((Component, i) =>
        cloneElement(Component, {
          key: i,
          className: `${Component.props.className} keen-slider__slide number-slide${i + 1}`,
        })
      )
    }, [components])

    return (
      <div ref={ref} className={`image-slider ${className}`}>
        <div ref={sliderRef} className="keen-slider">
          {components ? mapComponents() : mapImages()}
          {loaded && instanceRef.current && showArrows && (
            <>
              <Arrow
                left
                onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                disabled={currentSlide === 0}
              />
              <Arrow
                right
                onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                disabled={currentSlide === instanceRef.current?.track?.details?.slides?.length - 1}
              />
            </>
          )}
        </div>

        {loaded && instanceRef.current && (
          <div className="dots">
            {[...Array(instanceRef?.current?.track?.details?.slides?.length).keys()]?.map(i => (
              <button
                className={`dot ${currentSlide === i && 'active'}`}
                key={i}
                onClick={() => instanceRef.current?.moveToIdx(i)}
              ></button>
            ))}
          </div>
        )}
      </div>
    )
  }
)

const Arrow = ({ onClick, left }) => (
  <ChevronRightIcon onClick={onClick} className={`arrow arrow--${left ? 'left' : 'right'}`} />
)

const ResizePlugin = slider => {
  const observer = new ResizeObserver(() => slider.update())

  slider.on('created', () => observer.observe(slider.container))
  slider.on('destroyed', () => observer.unobserve(slider.container))
}

const MutationPlugin = slider => {
  const observer = new MutationObserver(mutations => {
    mutations.forEach(m => slider.update())
  })

  const config = { childList: true }

  slider.on('created', () => observer.observe(slider.container, config))

  slider.on('destroyed', () => observer.disconnect())
}
