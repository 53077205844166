import { cloneElement, useContext } from 'react'
import { MainContext } from 'context'
import { useRouteCheck } from 'utilities/hooks'

import './ModalContainer.scss'

const ModalContainer = ({ isAdmin, doNotClose = false }) => {
  const {
    modal: { isOpen, isClosing, modal, actions },
  } = useContext(MainContext)

  const adminStyle = useRouteCheck().includes(`admin`)

  return isOpen ? (
    <div
      className={`modal-container ${adminStyle || isAdmin ? 'admin' : 'patient'} ${isClosing ? 'closing' : 'opening'}`}
      onAnimationEnd={e => e.animationName === 'fadeOut' && actions.reset()}
      onMouseDown={e => e.target.classList?.[0] === 'modal-container' && !doNotClose && actions.close()}
    >
      {cloneElement(modal, {
        'aria-modal': true,
        admin: !!adminStyle || !!isAdmin,
        closeModal: actions.close,
        role: 'dialog',
      })}
    </div>
  ) : null
}

export default ModalContainer
