import { Button, Loader, Search } from 'components'
import { useCallback } from 'react'
import { AlphaListHeader, AlphaSearch } from '../peices'
import { MedbotItem } from './MedBotItem'
import { useGetMedbots } from './useGetMedbots'
import { setMarker } from 'pages/Admin/CreateOrder/utils'
import './SelectMedbot.scss'
import { PracticeComboBox } from 'pages/Admin/Orders/OrdersTable/PracticeComboBox.js/index.js'
import useSortLisBy from '../useSortListBy'

/*
type: 'send' | 'edit'
onSelectMedbot: () => void
*/
export const MedBotList = ({ onNewMedBotClick = null, usePracticeFilter = false, ...rest }) => {
  const [sortListBy, setSortListBy] = useSortLisBy()
  const { data, isLoading, error, setPracticeId } = useGetMedbots(sortListBy)

  const search = keyword => setSortListBy({ keyword: keyword?.keyword?.replace(/\\/g, '') || '' })

  const mapItems = useCallback(() => {
    let prevMedbot = null
    return isLoading ? (
      <Loader />
    ) : error ? (
      <p className="create-order-page__alphabetic-list--error-msg">There was an error</p>
    ) : (
      data?.map((medbot, j) => {
        let addTab = setMarker(prevMedbot, medbot.medbotName)
        prevMedbot = medbot.medbotName
        return <MedbotItem key={j} addTab={addTab} medbot={medbot} {...rest} />
      })
    )
  }, [data, isLoading, error, rest])

  return (
    <div className="create-order-page select-medbot-wrapper">
      <div className="select-medbot-search">
        <Search onSubmit={search} name={'keyword'} placeholder={'Search Medbot Products'} />
        {usePracticeFilter && (
          <PracticeComboBox className={'select-medbot-practice-combo-box'} selectPractice={setPracticeId} />
        )}
        {onNewMedBotClick && (
          <Button onClick={onNewMedBotClick} className={'new-medbot-button'}>
            New MedBot
          </Button>
        )}
      </div>
      <AlphaSearch setSortListBy={setSortListBy} sortListBy={sortListBy} />
      <AlphaListHeader
        sortListBy={sortListBy}
        className={'select-medbot'}
        columns={[
          {
            header: 'Medbot Name',
            onClick: () => setSortListBy({ asc: !sortListBy.asc, header: 'Medbot Name' }),
            sortable: true,
          },
          { header: 'Products', sortable: false },
        ]}
      />
      <ol className="create-order-page__alphabetic-list select-medbot-list">{mapItems()}</ol>
    </div>
  )
}
