///type is subAndSave or standard

export function SubAndSaveOption({ type = 'standard', title, price, isSelected, onSelect, children }) {
  return (
    <div className={`sub-and-save__option ${type === 'standard' ? 'dark' : ''}`}>
      <div className="sub-and-save__option--header">
        <span>{title}</span>
        <RadioButton isSelected={isSelected} onClick={onSelect} />
      </div>
      <div className="sub-and-save__option--content">
        <span className={`sub-and-save__option--content-price ${type === 'subAndSave' ? 'highlighted' : ''}`}>
          ${price}
        </span>
        {type === 'subAndSave' && (
          <span className="sub-and-save__option--content-savings">
            Save 10% on this purchase and on repeat deliveries! Cancel anytime.
          </span>
        )}
        {isSelected ? children : null}
      </div>
    </div>
  )
}

function RadioButton({ isSelected, onClick }) {
  return (
    <button onClick={onClick} className="radio-button-wrap">
      <div className={`radio-button-inner ${isSelected ? 'filled' : 'empty'}`} />
    </button>
  )
}
