import { Loader } from 'components/Global/Loader'
import { Spinner } from 'pages/RecommendationPortal/Spinner'
import { Suspense } from 'react'

export const PortalLoader = ({ patientPortal = false, adminPortal = false }) => {
  return (
    <div
      style={{
        height: adminPortal ? '100%' : '100vh',
        width: adminPortal ? '100%' : '100vw',
        display: 'grid',
        placeItems: 'center',
      }}
      className="portal-loader"
    >
      {patientPortal ? <Spinner /> : <Loader />}
    </div>
  )
}

export const lazyPortalWrap = Component => <Suspense fallback={<PortalLoader />}>{Component}</Suspense>
export const lazyPatientWrap = Component => <Suspense fallback={<PortalLoader patientPortal />}>{Component}</Suspense>
export const lazyAdminWrap = Component => <Suspense fallback={<PortalLoader adminPortal />}>{Component}</Suspense>

export default PortalLoader
