import { useCallback, useContext } from 'react'
import { CreateOrderContext, useCreateOrderNav } from '../context'
import { ProductView } from 'components/ProductView/ProductView'
import { useParams } from 'react-router-dom'

export const ProductReview = () => {
  const { productId } = useParams()
  const nav = useCreateOrderNav()
  const { products, actions } = useContext(CreateOrderContext)

  const product = products.find(prod => prod.productId === +productId)

  const onUpdate = product => {
    actions.setProducts(products.map(p => (p.productId === product.productId ? product : p)))
    nav('review')
  }

  const onDelete = useCallback(() => {
    actions.setProducts([...products.filter(p => p.productId !== product.productId)])
    nav('review')
  }, [actions, product, nav, products])

  return (
    <ProductView
      isInOrder
      product={product}
      onAddAnother={null} //disables onAdd another
      onUpdate={onUpdate}
      onDelete={onDelete}
    />
  )
}
