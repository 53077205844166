import axios from 'axios'
import { AUTHORIZE_CONFIG } from './config'

/**
 *
 * @param {Object} params
 * @param {String} params.customerProfileId
 * @param {Object} params.card
 * @param {String} params.card.cardNumber
 * @param {String} params.card.expirationDate YYYY-MM
 * @param {String} params.card.cardCode
 * @param {String} params.card.firstName
 * @param {String} params.card.lastName
 *
 * {@link https://developer.authorize.net/api/reference/index.html#customer-profiles-create-customer-payment-profile}
 */
export const CREATE_AUTHORIZE_CUSTOMER_PAYMENT_PROFILE = async params => {
  return await axios
    .post(AUTHORIZE_CONFIG.url, {
      createCustomerPaymentProfileRequest: {
        merchantAuthentication: {
          name: AUTHORIZE_CONFIG.name,
          transactionKey: AUTHORIZE_CONFIG.transactionKey,
        },
        customerProfileId: params.customerProfileId,
        paymentProfile: {
          billTo: {
            firstName: params?.card?.firstName,
            lastName: params?.card?.lastName,
          },
          payment: {
            creditCard: {
              cardNumber: params?.card?.cardNumber,
              expirationDate: params?.card?.expirationDate,
              cardCode: params?.card?.cardCode,
            },
          },
        },
      },
    })
    .then(res => {
      if (res.data.messages.resultCode === 'Error') {
        throw new Error(res.data.messages?.message?.[0]?.text || 'unknown error occured')
      }
      return res
    })
}
