import { CreateOrderProvider } from './context/CreateOrderContext'
import { titles } from './constants'
import { ReviewOrder, SelectMedbot, SelectPatient, SelectProduct, SelectProvider } from './components'
import './CreateOrder.scss'

import { BackButtonIcon } from 'assets/vector'
import { ProductReview } from './components/Product'
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { svgButton } from 'utilities/utils'

export const CreateOrder = () => {
  const { pathname } = useLocation()
  const nav = useNavigate()

  const finalRouteName = pathname.split('/')[pathname.split('/').length - 1]

  if (finalRouteName === 'medbot') return <Navigate to={'select'} />
  if (finalRouteName === 'single') return <Navigate to="provider" />

  const title = () => {
    const arr = pathname.split('/')
    return titles[arr[arr.length - 1]]
  }

  return (
    <CreateOrderProvider>
      <div className="create-order-wrapper">
        <div className="create-order-header">
          <h4 className="create-order-header__title">{title()}</h4>
          {svgButton(
            <BackButtonIcon
              onClick={() => nav(-1)}
              // className={`create-order-header__back-button ${showBackButton ? 'visible' : 'hide'}`}
              className={`create-order-header__back-button visible`}
            />
          )}
        </div>
        <Outlet />
      </div>
    </CreateOrderProvider>
  )
}

export const CreateRoutes = () => (
  <Routes>
    <Route path=":type" element={<CreateOrder />}>
      <Route path="select" element={<SelectMedbot />} />
      <Route path="provider" element={<SelectProvider />} />
      <Route path="product" element={<SelectProduct />} />
      <Route path="patient" element={<SelectPatient />} />
      <Route path="product-view/:productId" element={<ProductReview />} />
      <Route path="review" element={<ReviewOrder />} />
    </Route>
  </Routes>
)
