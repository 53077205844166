import { useLocation, useParams } from 'react-router-dom'
import { useContext } from 'react'
import { MainContext } from 'context'
import { useCart } from 'pages/RecommendationPortal/Cart/context'
import { useQuery } from 'react-query'
import { GET_PATIENT_ORDER } from 'apiv2'
// const noLoginRequiredStatus = ['draft order', 'aging retail']

export const useOrder = () => {
  const { patient } = useContext(MainContext)
  const { orderId } = useParams()
  const { state } = useLocation()
  const { actions } = useCart()

  const { isLoading, refetch, error, ...restOfQuery } = useQuery(
    ['patient_order', `${orderId}`],
    () => GET_PATIENT_ORDER(orderId),
    {
      onSuccess({ data }) {
        //init cart if reloading order that was from cart
        if (state?.from === 'cart') {
          actions.setLineItems(data.LineItems)
          actions.toggleCartIcon(true)
        }

        patient?.actions.toggleHasAgreed(data.hasAgreedToTerms)
        patient.actions.setOrder(data)
      },
    }
  )

  return {
    orderId,
    ...restOfQuery,
    data: patient.order,
  }
}
