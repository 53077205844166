import { createContext } from 'react'
import { useImmerReducer } from 'use-immer'
import { init, useProductViewActions, ProductViewReducer } from './ProductViewReducer'

export const ProductViewContext = createContext(init)

export const ProductViewProvider = ({
  children,
  onAddAnother, // only used in ProductPicker
  product,
  onAdd,
  onUpdate,
  onDelete,
  onBack,
  isInOrder,
  allowQtyControls = true,
}) => {
  const [state, dispatch] = useImmerReducer(ProductViewReducer, { ...init, isInOrder, product })
  const actions = useProductViewActions(dispatch)

  return (
    <ProductViewContext.Provider
      value={{
        ...state,
        allowQtyControls,
        isInOrder,
        actions: { ...actions, onAddAnother, onAdd, onUpdate, onDelete, onBack },
      }}
    >
      {children}
    </ProductViewContext.Provider>
  )
}
