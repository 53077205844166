import apiv2 from 'apiv2'

/**
 * @param {String} email
 * @returns {Promise<unknown>}
 */
export const ADMIN_REQUEST_RESET_PASSWORD = async email => await apiv2.post('/Account/resetpassword', { email })

/**
 * @param {Object} body
 * @param {String} body.email
 * @param {String} body.newPassword
 * @param {String} body.token
 * @returns {Promise<unknown>}
 */
export const ADMIN_RESET_PASSwORD = async body => await apiv2.put('/Account/changepassword', body)
