import { MainContext } from 'context'
import { useCallback, useContext, useMemo, useState } from 'react'
import { sortData } from 'pages/Admin/CreateOrder/utils'

export const useGetMedbots = sortListBy => {
  const { admin } = useContext(MainContext)
  const [practiceId, setPracticeId] = useState(admin?.user?.practiceId)
  const { asc, letter, keyword } = sortListBy

  const { data, isLoading, error } = admin.api.createOrder.getMedbots({
    variables: {
      practiceId: practiceId ? `/${practiceId}` : '/',
    },
    queryOptions: { staleTime: 1 },
    keys: ['medbot_list'],
  })

  const sorted = useMemo(() => data?.bundles?.sort(sortData(asc, 'medbotName')), [data, asc])

  const dataFilter = useCallback(
    () =>
      sorted?.filter(medbot => {
        const regex = new RegExp(keyword.toLowerCase(), 'g')
        const letterMatch = letter ? medbot.medbotName[0].toUpperCase() === letter : true
        const productMatch = medbot.medBotProducts.some(({ productName }) => productName.toLowerCase().match(regex))
        const nameMatch = medbot.medbotName.toLowerCase().match(regex)
        return (productMatch && letterMatch) || (nameMatch && letterMatch)
      }),
    [sorted, letter, keyword]
  )

  return {
    data: useMemo(
      () => (keyword?.length || letter ? dataFilter(keyword) : sorted || null),
      [keyword, letter, dataFilter, sorted]
    ),
    isLoading,
    error,
    setPracticeId,
  }
}
