import { useCallback } from 'react'
import { SelectButtonInList, Tab } from '../peices'

/*
type: 'send' | 'edit'
*/
export const MedbotItem = ({ onSelectMedbot, medbot, addTab, type = 'send' }) => {
  const { medBotProducts } = medbot

  const mapProducts = useCallback(
    () =>
      medBotProducts?.map(({ productImages }, i) => (
        <div key={i} className="select-medbot__item--product-image">
          <img alt="product-avatar" src={productImages?.[0]} />
        </div>
      )),
    [medBotProducts]
  )

  return (
    <li className="create-order-page__item select-medbot__item">
      {addTab && <Tab letter={medbot.medbotName[0].toUpperCase()} />}
      <button onClick={() => onSelectMedbot(medbot)}>
        <span className="name">{medbot.medbotName}</span>
        <div className="products">{mapProducts()}</div>
        <SelectButtonInList text={type === 'send' ? 'Send' : 'Edit'} />
      </button>
    </li>
  )
}
