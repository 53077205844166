import { useContext } from 'react'
import './SelectProvider.scss'
import { ProviderList } from 'components/AlphabeticList'
import { CreateOrderContext, useCreateOrderNav } from '../../context'
import { useParams } from 'react-router-dom'

export const SelectProvider = () => {
  const { type } = useParams()
  const nav = useCreateOrderNav()
  const { products, actions } = useContext(CreateOrderContext)

  const onSelectProvider = provider => {
    actions.setProvider(provider)
    if (type === 'medbot' || products?.length) {
      nav(`review`)
    } else {
      nav(`product`)
    }
  }

  return <ProviderList onSelectProvider={onSelectProvider} />
}
