import { useLocation } from 'react-router-dom'
import { AdminLogin, PracticeLogin } from '.'

import './Login.scss'
import { PatientLoginV2 } from './PatientV2'

const Login = () => {
  const { pathname } = useLocation()

  if (pathname.includes('practice-admin')) return <PracticeLogin />
  else if (pathname.includes('admin')) return <AdminLogin />
  else return <PatientLoginV2 />
}

export default Login
