import { Table } from 'components'
import { useMemo } from 'react'

const columns = [
  { Header: 'PRACTICE', accessor: 'practiceName', unsortable: true },
  { Header: 'PROVIDER', accessor: 'providerName', unsortable: true },
  { Header: 'MEDBOTS SENT', accessor: 'medbotsSent', unsortable: true },
  { Header: '# OF SALES', accessor: 'salesCount', unsortable: true },
  { Header: 'TOTAL SALES', accessor: 'totalSales', unsortable: true },
  { Header: 'SALES DATE', accessor: 'reportDate', unsortable: true },
]

export const SalesSummary = ({ data, isLoading, error }) => {
  const salesData = useMemo(
    () =>
      data?.salesList?.map(sale => ({
        ...sale,
        totalSales: `$${sale.totalNetSales?.toFixed(2)}`,
      })),
    [data]
  )

  return <Table loading={isLoading} error={error} data={{ columns, data: salesData || [] }} />
}

export default SalesSummary
