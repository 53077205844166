import { Table } from 'components'
import dayjs from 'dayjs'
import { useMemo } from 'react'

const columns = [
  { Header: 'PRACTICE', accessor: 'practiceName', unsortable: true },
  { Header: "PATIENT OPT IN'S", accessor: 'optInsCollected', unsortable: true },
  { Header: '# OF SALES', accessor: 'salesCount', unsortable: true },
  { Header: 'TOTAL SALES', accessor: 'totalNetSales', unsortable: true },
  { Header: 'SEND DATE', accessor: 'sentDate', unsortable: true },
]

const WellnessStoreSummary = ({ data, isLoading, error }) => {
  const sentData = useMemo(
    () =>
      data?.summaryList?.map(item => ({
        ...item,
        totalNetSales: `$${item.totalNetSales?.toFixed(2)}`,
        sentDate: dayjs(item?.reportDate).format('MM/DD/YYYY'),
      })),
    [data]
  )

  return <Table loading={isLoading} error={error} data={{ columns, data: sentData || [] }} />
}

export default WellnessStoreSummary
