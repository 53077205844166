import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useYMin, buildPlugins, buildOptions, convertToCoords } from './utils'
import { numberWithCommas } from 'utilities/utils'
import './Graph.scss'
import { useMemo } from 'react'
import { IncreaseArrowIcon } from 'assets/vector'
import dayjs from 'dayjs'

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Filler)

export const Graph = ({ totalRevenueYTD, revenueDifferenceToLastYTD, ...data }) => {
  const { yMin } = useYMin(data)
  const plugins = useMemo(() => buildPlugins(data), [data])
  const options = useMemo(() => buildOptions(yMin), [yMin])

  const chartData = useMemo(() => {
    if (!data) return null

    const ytd = convertToCoords(data?.monthlyRevenues)?.slice(0, dayjs().month() + 1)

    const pastYr = convertToCoords(data?.previousYearMonthlyRevenues)
    let graceEnd = { x: '', y: pastYr?.[pastYr?.length - 1]?.y }
    let graceStart = { x: '', y: pastYr?.[0].y }
    const labels = ['', ...pastYr?.map(({ x }) => x), '']
    let bottom = [{ x: '', y: yMin * 2 }, ...pastYr?.map(({ x }) => ({ x, y: yMin * 2 })), { x: '', y: yMin * 2 }]

    return {
      labels,
      datasets: [
        {
          data: [{ x: '', y: ytd?.[0]?.y }, ...ytd],
          borderColor: context => {
            const { ctx, chartArea } = context?.chart
            if (!chartArea) return null
            let gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
            gradient.addColorStop(0, '#a3e3ff')
            gradient.addColorStop(1, '#276890')
            return gradient
          },
          borderWidth: 5,
        },
        {
          data: [graceStart, ...pastYr, graceEnd],
        },
        {
          fill: '-1', // this fills area between this line and last line
          data: bottom,
          backgroundColor: context => {
            const { ctx, chartArea } = context?.chart
            if (!chartArea) return null
            const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom)
            gradient.addColorStop(0, '#a3e3ff')
            gradient.addColorStop(1, '#276890')
            return gradient
          },
        },
      ],
    }
  }, [data, yMin])

  const diff = useMemo(() => {
    let x = revenueDifferenceToLastYTD?.toFixed(2)
    return { diff: Math.abs(x), msg: x >= 0 ? 'UP FROM LAST YEAR' : 'DOWN FROM LAST YEAR', isNeg: x <= -1 }
  }, [revenueDifferenceToLastYTD])

  return (
    <div className="analytics-body__graph">
      <div className="analytics-body__graph--title">
        <span>TOTAL REVENUE YTD</span>
        <span>${numberWithCommas(totalRevenueYTD?.toFixed(2) || 0)}</span>
      </div>
      <div className="analytics-body__graph--compare">
        <span>
          <IncreaseArrowIcon className={diff?.isNeg ? 'down' : 'up'} /> {diff?.isNeg ? '-' : '+'}${diff?.diff}
        </span>
        <span>{diff?.msg}</span>
      </div>
      <Line plugins={plugins} options={options} data={chartData} />
    </div>
  )
}
