import { AlertIcon } from 'assets/vector'
import { useCallback } from 'react'
import { Field } from 'react-final-form'
import './CheckBox.scss'

export const CheckBoxInput = ({
  text,
  className = '',
  showRequired = false,
  validator,
  required,
  children,
  ...rest
}) => {
  const validateCheckBox = useCallback(
    v => {
      if (!required && !v) return null
      if (required && !v) return 'Required'
      if (typeof validator === 'function') return validator?.(v)
      if (Array.isArray(validator)) return validator?.flatMap?.(f => Array(f?.(v) || 0))?.[0]
    },
    [validator, required]
  )

  return (
    <div className={`checkbox-wrap ${className}`}>
      <Field type="checkbox" component={'input'} validate={validateCheckBox} {...rest} />
      {children ? children : <span>{text}</span>}
      <div />
      <span role="note" className={`required-notice ${showRequired ? 'show' : 'hide'}`}>
        <AlertIcon className="alert-icon" /> Required
      </span>
    </div>
  )
}
