import { Children, cloneElement } from 'react'
import camelCase from 'lodash/camelCase'
import { Input } from '..'

import './MultiField.scss'

const MultiField = ({ className, disabled, name, label, children, required }) => {
  // const formState = useForm()?.getState?.()

  return (
    <div
      className={`field field-wrapper ${className || ''} ${disabled ? 'disabled' : ''} ${
        //       Object.entries(meta).reduce(
        //     (acc, cur) => {
        //       if (cur[0] === 'valid' && !cur[1]) return acc + ' invalid'
        //       return typeof cur[1] === 'boolean' ? (cur[1] ? acc + ` ${cur[0]}` : acc) : acc
        //     },
        ''
        //   )
      }`}
    >
      {!!label && (typeof label == 'string' || label instanceof String) ? (
        <label htmlFor={name} className={`field field-label ${className || ''} ${disabled ? 'disabled' : ''}`}>
          {label}
        </label>
      ) : null}
      <div className={`field-options field-options${className || label ? `--${className || camelCase(label)}` : ''}`}>
        {Children.map(children, (child, i) => {
          const childName = `${name || camelCase(label)}.${child.props.value}`
          if (child.props.type === 'radio')
            return (
              <div key={i}>
                {cloneElement(child, { id: childName, name: name || camelCase(label) })}
                <label htmlFor={childName}>{child.props.label}</label>
              </div>
            )
          if (child.props.type === 'checkbox')
            return (
              <div key={i}>
                {cloneElement(child, { id: childName, name: childName })}
                <label htmlFor={childName}>{child.props.label}</label>
              </div>
            )
          return <Input {...child.props} />
        })}
      </div>
      {/* {meta?.error && meta?.touched && (
        <span role="note" className={`field-error ${className || ''}`}>
          <AlertIcon className="field-error__alert" />
          {meta?.error}
        </span>
      )} */}
    </div>
  )
}

export default MultiField
