import { DownloadFileIcon } from 'assets/vector'
import { useSvgButton } from 'utilities/hooks'
import { api } from 'utilities/constants'

const BASE_URL = api.API_URL // ends with /api

const Download = ({ url }) =>
  useSvgButton(
    <DownloadFileIcon
      style={{ cursor: 'pointer' }}
      onClick={() => window.open(`${BASE_URL}/reports/export/${url}`, '_blank')}
    />
  )

export default Download
