import { useCallback, useState } from 'react'
import './AvatarCrop.scss'
import { Field } from 'react-final-form'
import { Cropper } from './Cropper'
import { useImageDropzone } from 'utilities/hooks'
import { PlusIcon, ArrowDownIcon } from 'assets/vector'

const AvatarCropInner = ({
  input: { onChange, ...inputProps },
  src = null,
  required = false,
  isPracticeLogo = false,
  ...rest
}) => {
  const [isCropping, setIsCropping] = useState(false)
  const [{ getInputProps, getRootProps, isDragActive }, { files, setFiles }] = useImageDropzone(
    {
      dropzoneOptions: { onDrop: useCallback(() => setIsCropping(true), []) },
      onNewFiles: file => onChange(file?.data),
    },
    true
  )

  //image needs to be: {data: File{}, preview: URL.createObjectURL(blob)}
  const onFinishCropping = useCallback(
    image => {
      image?.preview && image?.data && setFiles(image)
      setIsCropping(false)
    },
    [setFiles]
  )

  const onCancel = () => {
    setIsCropping(false)
    setFiles(null)
  }

  return (
    <div className="avatar-wrapper">
      {isCropping && <Cropper img={files?.preview} onCancel={onCancel} onFinish={onFinishCropping} />}
      <>
        <div
          {...getRootProps({
            ...rest,
            className: `avatar-uploader ${isDragActive ? 'drag' : ''}`,
            ...inputProps,
          })}
        >
          <input {...getInputProps()} />
          {files && !isDragActive ? (
            <div className="image uploaded" style={{ backgroundImage: `url(${files?.preview})` }} alt="user avatar" />
          ) : src && !isDragActive ? (
            <div style={{ backgroundImage: `url(${src})` }} className="image uploaded" alt="avatar saved" />
          ) : (
            <div className={`image ${required ? 'required' : ''}`}>
              <PlusIcon className="plus" />
              {isDragActive ? <ArrowDownIcon className="upload" /> : null}
              {required && !isDragActive && <span className="image-required-text">Image Required</span>}
            </div>
          )}
        </div>
        <label htmlFor="avatar">
          <p className="instructions">Click to Edit Avatar</p>
          <p className="tip">For best results use a square image</p>
        </label>
      </>
    </div>
  )
}

export const AvatarCrop = props => <Field {...props} component={AvatarCropInner} />
