import './View.scss'
import { AlertModal, Loader } from 'components'
import { useMutation, useQueryClient } from 'react-query'
import { DELETE_PRODUCT_ON_MEDBOT } from 'apiv2/calls/admin'
import { useModal } from 'context/Modal/ModalContext'
import { useNavigate } from 'react-router-dom'

export function useRemoveProduct() {
  const queryClient = useQueryClient()
  const modal = useModal()
  const nav = useNavigate()

  const { mutate, ...mutation } = useMutation(DELETE_PRODUCT_ON_MEDBOT, {
    onMutate() {
      modal.actions.open(<Loader />)
    },
    onSuccess() {
      modal.actions.close()
      queryClient.invalidateQueries(['get_medbot_details'])
      nav(-1)
    },
    onError() {
      modal.actions.open(<AlertModal message="There was an error removing the item from MedBot." />)
    },
  })

  return { removeProduct: mutate, ...mutation }
}
