import { useFetch } from 'utilities'

export const useWellnessStore = (token, unauthorized) => ({
  /**
   * @example
   * request body:
   * body: {
   *    //both first and last or neither
   *    firstName?: '',
   *    lastName?: "",
   *    //cellNumber OR/AND email
   *    cellNumber: "1231231234",
   *    email: '',
   *    doctorId: 0
   * }
   */
  sendInviteToPatient: useFetch({ method: 'POST', endpoint: '/store/sendinvitation', token, unauthorized }),
})
