import { useContext } from 'react'

import { useFormState } from 'react-final-form'

import { AlertIcon } from 'assets/vector'
import { Button, CloseButton, Input, InputGroup, Loader } from 'components/Global'
import { CardNumber, Cvc, ExpireDate, Zip } from 'components/Global/Input/MaskedInputs'
import { ConditionalForm } from '../ConditionalForm'
import { email } from 'utilities/fieldValidation'
import { MainContext } from 'context'
import { useModals } from 'utilities/hooks'

export const Billing = ({ add, cardError, closeModal, data, id, ...rest }) => {
  const { admin } = useContext(MainContext)
  const { openSuccessModal, openErrorModal, invQueries } = useModals()

  const { mutate: update, isLoading } = admin.api.practice.updateBilling({
    variables: { id },
    queryOptions: {
      onError: () => openErrorModal('There was an error updating the billing information.'),
      onSuccess: data => {
        if (data?.Success === 'True') {
          openSuccessModal('Billing information updated successfully!')
          invQueries(id)
        }
        if (data?.Success === false) {
          openErrorModal('There was an error updating the billing information.')
        }
      },
    },
  })

  if (isLoading) return <Loader />

  return (
    <ConditionalForm
      onSubmit={v => {
        const exp = v?.exp?.split('/')
        !add &&
          update({
            body: {
              ...v,
              expirationMo: exp?.[0],
              expirationYr: exp?.[1],
            },
          })
      }}
      wrapperClass={'billing'}
      initialValues={{
        ...data,
        exp: `${data?.expirationMo < 10 ? '0' + data?.expirationMo : data?.expirationMo}/${data?.expirationYr}`,
        creditCardNumber: '',
      }}
      renderForm={add}
      {...rest}
    >
      <BillingFields {...{ add, closeModal, data, cardError }} />
    </ConditionalForm>
  )
}

export const BillingFields = ({ add, cardError, closeModal, combined, data, invalidCardNum, validation }) => {
  if ([null, undefined].includes(validation)) validation = true
  const form = useFormState()

  return (
    <InputGroup group={add || combined ? 'billing' : null}>
      <h4>{add ? null : 'Edit'} Billing Account</h4>
      {add || combined ? null : <CloseButton onClick={() => closeModal()} />}
      <div className="input-row input-row-0">
        <Input label="Name on card" />
        <Input label="Accounting Contact Email" name="accountingEmail" validate={email} />
      </div>
      <div className="input-row input-row-1">
        <Input
          name="creditCardNumber"
          label="Card Number"
          placeholder={data?.creditCardNumber ? `•••• •••• •••• ${data?.creditCardNumber}` : undefined}
          maskedInput={CardNumber}
          className={`${cardError || invalidCardNum ? 'invalid--server' : ''}`}
          validate={v => (/^[1-6].{12,15}$/.test(v) ? undefined : 'invalid card number')}
        />
        <Input
          name="exp"
          label="Expiration Date"
          maskedInput={ExpireDate}
          validate={v => (/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/.test(v) ? undefined : 'Invalid Date')}
        />
        <Input
          type="password"
          hidable={false}
          label="CVC"
          maskedInput={Cvc}
          validate={v => (/^[0-9]{3,4}$/.test(v) ? undefined : 'Invalid CVC')}
        />
      </div>
      <Input name="address1" label="Billing Address" />
      <div className="input-row input-row-2">
        <Input label="City" />
        <Input label="State" />
        <Input name="zip" label="Zipcode" maskedInput={Zip} />
      </div>
      {invalidCardNum || cardError ? (
        <div className="billing__warning">
          <AlertIcon className="field-error__alert" />
          The credit card information is invalid.
        </div>
      ) : null}
      {(!combined && form.dirty && !add) || (!add && validation) ? (
        <p className="billing__warning">
          <AlertIcon className="field-error__alert" />
          Saving this data will overwrite the currently saved card data.
        </p>
      ) : null}
      {add || combined ? null : (
        <div className="actions">
          <Button
            onClick={e => {
              e.preventDefault()
              closeModal()
            }}
          >
            Cancel
          </Button>
          <Button>Save</Button>
        </div>
      )}
    </InputGroup>
  )
}

// function dataShape(v, one) {
//   return {
//     practiceBilling: {
//       nameOnCard: v.nameOnCard,
//       id: v.id,
//       address1: v.address1,
//       address2: v.address2 ? v.address2 : '',
//       city: v.city,
//       state: v.state,
//       zip: v.zip,
//       creditCardNumber: v.creditCardNumber,
//       expirationMo: +v.exp.slice(0, 2),
//       expirationYr: +v.exp.slice(3),
//       cvc: v.cvc,
//       stripeCardId: one?.practiceBilling.stripeCardId || null,
//     },
//   }
// }
