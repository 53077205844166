import { useState, useCallback } from 'react'

const useSortLisBy = initKeyword => {
  const [sortListBy, setSort] = useState({ letter: null, keyword: initKeyword || '', asc: false, header: null })

  const setSortListBy = useCallback(args => setSort({ ...sortListBy, ...args }), [sortListBy])

  const reset = useCallback(() => setSort({ letter: null, keyword: '', asc: false, header: null }), [setSort])

  return [sortListBy, setSortListBy, reset]
}

export default useSortLisBy
