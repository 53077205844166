import { useContext, useEffect } from 'react'

import { Form } from 'react-final-form'
import { useImmer } from 'use-immer'
import produce from 'immer'

import { BillingFields } from './Billing'
import { FeesFields } from './Fees'
import { Button, CloseButton, Loader } from 'components/Global'
import { MainContext } from 'context'
import { useFees, useModals } from 'utilities/hooks'

export const BillingAndFees = ({ add, cardError, data: billingData, id, ...rest }) => {
  const [results, setResults] = useImmer([null, null])
  const { admin, modal } = useContext(MainContext)
  const { invQueries, openErrorModal, openSuccessModal } = useModals()
  const closeModal = () => modal.actions.close()

  const {
    fees: feesData,
    isLoading: feesLoading,
    editFees,
  } = useFees(add, {
    onSuccess: () => setResults(results => void (results[1] = true)),
    onError: () => setResults(results => void (results[1] = false)),
  })

  useEffect(() => {
    if (results[0] === null || results[1] === null) return
    if (results[0] === true && results[1] === true)
      return invQueries(id) && openSuccessModal('Billing and fees updated successfully!')
    if (results[0] === false && results[1] === false) return openErrorModal('Billing and fees failed to update.')
    if (results[0] === false) return openErrorModal('Billing information update failed!')
    if (results[1] === false) return openErrorModal('Fees information update failed!')
  }, [openErrorModal, openSuccessModal, results, id, invQueries])

  const { mutate: updateBilling, isLoading: billingLoading } = admin.api.practice.updateBilling({
    variables: { id },
    queryOptions: {
      onSuccess: data =>
        (data?.Success === 'True' && setResults(results => void (results[0] = true))) ||
        (data?.Success === 'False' && setResults(results => void (results[0] = false))),
      onError: () => setResults(results => void (results[0] = false)),
    },
  })

  return (
    <Form
      onSubmit={({ billing, fees }, { getState }) => {
        const { dirtyFields } = getState()
        const billingDirty = Object.entries(dirtyFields).some(([key, value]) => value && key.includes('billing.'))
        const feesDirty = Object.entries(dirtyFields).some(([key, value]) => value && key.includes('fees.'))

        console.log(billing)
        const exp = billing?.exp?.split('/')
        billingDirty
          ? updateBilling({
              body: {
                address1: billing?.address1,
                address2: '',
                city: billing?.city,
                creditCardNumber: billing?.creditCardNumber,
                cvc: billing?.cvc,
                expirationMo: exp?.[0],
                expirationYr: exp?.[1],
                id: billing?.id,
                nameOnCard: billing?.nameOnCard,
                practiceID: billing?.practiceID,
                state: billing?.state,
                stripeCardId: billing?.stripeCardId,
                zip: billing?.zip,
                accountingEmail: billing?.accountingEmail,
              },
            })
          : setResults(results => void (results[0] = true))
        feesDirty ? editFees(fees) : setResults(results => void (results[1] = true))
      }}
      initialValues={{
        fees: {
          ...feesData,
          backfillToggle: false,
          endDate: feesData?.fees?.[0]?.endDate,
          feeAmount: feesData?.fees?.[0]?.feeAmount,
          startDate: feesData?.fees?.[0]?.startDate,
        },
        billing: {
          ...produce(billingData, draft => void delete draft.creditCardNumber),
          exp: `${billingData?.expirationMo < 10 ? '0' + billingData?.expirationMo : billingData?.expirationMo}/${
            billingData?.expirationYr
          }`,
        },
      }}
      {...rest}
    >
      {({ handleSubmit, submitting, dirtyFields }) => {
        const billingDirty = Object.entries(dirtyFields).some(([key, value]) => value && key.includes('billing.'))
        const feesDirty = Object.entries(dirtyFields).some(([key, value]) => value && key.includes('fees.'))
        if (billingLoading || feesLoading) return <Loader />
        return (
          <form className="billing-and-fees-modal" onSubmit={handleSubmit}>
            <CloseButton onClick={() => closeModal()} />
            <div className="fees">
              <FeesFields add={add} validation={feesDirty} combined closeModal={closeModal} />
            </div>
            <div className="billing">
              <BillingFields add={add} validation={billingDirty} data={billingData} combined closeModal={closeModal} />
            </div>
            <div className="actions">
              <Button
                onClick={e => {
                  e.preventDefault()
                  closeModal()
                }}
              >
                Cancel
              </Button>
              <Button>Save</Button>
            </div>
          </form>
        )
      }}
    </Form>
  )
}
