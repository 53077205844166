import { Field, Form } from 'react-final-form'
import './ReviewOrderInputs.scss'
import { Button, Input, Search } from 'components/Global'
import { ProviderReview } from 'components/ProviderReview'
import dayjs from 'dayjs'

export const ReviewOrderInputs = ({
  onReplaceProvider,
  isTextEnabled,
  handleSubmit,
  validatorOverride = null,
  provider,
  isValid = true,
  isLoading,
  showDateInHeader = false,
  initialValues = {},
  handlePatientSearch = null,
  confirmSendText = 'Send Order',
  //   onSelectPatient = null,
}) => {
  const validateCellNumber = value => (value?.length < 10 ? 'Must have 10 digits' : null)

  return (
    <div className="review-order-inputs">
      {showDateInHeader && (
        <div className="review-order-inputs__header">
          <span className="review-order-inputs__header-date">Order Date {dayjs().format('MMM DD, YYYY')}</span>
        </div>
      )}
      {handlePatientSearch && (
        <Search
          requireInputBeforeSearchable
          onSubmit={handlePatientSearch}
          placeholder={'Search Patient'}
          name="keyword"
        />
      )}
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={values => {
          if (validatorOverride) return validatorOverride(values)
          const errors = {}

          if (!values.patientFirstName && values.patientLastName) {
            errors.patientFirstName = 'Required'
          }
          if (values.patientFirstName && !values.patientLastName) {
            errors.patientLastName = 'Required'
          }
          if (!values.patientOptIn || values.patientOptIn === false) {
            errors.patientOptIn = 'Required'
          }
          if (!values.patientEmail && !values.patientPhone) {
            errors.patientEmail = 'Required'
            errors.patientPhone = 'Required'
          }
          if (!provider) {
            errors.provider = 'Required'
          }
          return errors
        }}
        render={props => (
          <form className="review-order-inputs__form" onSubmit={props.handleSubmit}>
            <div className="review-order-inputs__form--input-row">
              <Input name="patientFirstName" label="Patient First Name" />
              <Input name="patientLastName" label="Patient Last Name" />
            </div>
            <div className="review-order-inputs__form--input-row">
              <Input name="patientEmail" label="Patient Email" />
              <span>AND OR</span>
              <Input
                name="patientPhone"
                label="Patient Mobile Phone"
                validate={validateCellNumber}
                disabled={!isTextEnabled}
              />
            </div>
            <div className="review-order-inputs__checkbox">
              <Field
                required
                validate={v => (v === false ? 'required' : undefined)}
                type="checkbox"
                name="patientOptIn"
                component={'input'}
                className="review-order-inputs__checkbox-check"
              />
              <span>
                I confirm that the person - identified by cell number and/or e-mail - has given me consent for
                notifications.
              </span>
            </div>
            <Button
              disabled={props.invalid || !isValid}
              onClick={props.handleSubmit}
              className="review-order-inputs__submit-button"
            >
              {isLoading ? 'Loading...' : confirmSendText}
            </Button>
            <ProviderReview
              label="Send as provider"
              onReplace={onReplaceProvider ? () => onReplaceProvider?.() : undefined}
              provider={provider}
              noProviderMessage="You need to select a provider."
            />
          </form>
        )}
      />
    </div>
  )
}
