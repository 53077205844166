import { RefillFrequencyPicker, SubAndSavePicker } from './components'
import { usePracticeFeatureSettings } from 'utilities/hooks'
import './SubAndSave.scss'

export const AutoshipControls = props => {
  const { data, isLoading, isFetching, isError } = usePracticeFeatureSettings(props?.practiceId)

  if (isLoading || isFetching) return <div className="sub-and-save__loading" />

  if (!props.toggleSubAndSave || isError || !data?.data?.subscribeAndSaveEnabled) {
    return <RefillFrequencyPicker {...props} />
  }

  return <SubAndSavePicker {...props} />
}
