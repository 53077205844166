import { useContext, useEffect, useMemo, useState } from 'react'
import { MainContext } from 'context'

export const sortKeys = {
  'Patient Last Name': 'lastName',
  'Patient First Name': 'firstName',
  'Patient Date Of Birth': 'dateOrBirth',
}

const pagesize = 10

export const useGetPatients = ({ sortListBy, practiceId }) => {
  const { admin } = useContext(MainContext)
  const { asc, keyword } = sortListBy
  const [page, setPage] = useState(1)

  useEffect(() => setPage(1), [practiceId])

  const { data, isLoading, error } = admin.api.blockedPatients.getPatientList({
    variables: { practiceId },
    params: {
      page,
      pagesize,
      sortOrder: !asc ? 'ASC' : 'DESC',
      searchText: keyword,
    },
    queryOptions: { enabled: !!practiceId },
  })

  const paginate = useMemo(() => ({ pagesize, page, setPage }), [setPage, page])

  return { cannotConnect: data?.length === 0, data, isLoading, error, paginate }
}
