import { useCallback, useContext } from 'react'
import { MedBotsContext } from '../../context'
import './View.scss'
import { AlertModal, SuccessModal } from 'components'
import { MainContext } from 'context'
import { useNavigate } from 'react-router-dom'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useQueryClient } from 'react-query'
dayjs.extend(utc)

export const useMedBotCreate = () => {
  const nav = useNavigate()
  const qc = useQueryClient()
  const { admin, modal } = useContext(MainContext)
  const { actions, practiceId, medBotName, lineItems } = useContext(MedBotsContext)

  const onSuccess = useCallback(() => {
    qc.invalidateQueries(['medbot_list'])
    actions.reset()
    modal.actions.open(
      <SuccessModal
        title={`${medBotName} was created successfully!`}
        onClick={() => {
          nav(`/admin/medbots`)
          modal.actions.close()
        }}
      />
    )
  }, [actions, modal.actions, qc, nav, medBotName])

  const { mutate, ...rest } = admin.api.createEditMedbots.createMedBot({
    variables: { practiceId },
    queryOptions: {
      onSuccess,
      onError: () => modal.actions.open(<AlertModal message="There was an error creating your MedBot." />),
    },
  })

  const createMedBot = useCallback(
    name =>
      mutate({
        body: {
          query: {
            appointmentTypes: [],
            dateToSendMedbot: dayjs().utc().format(),
            sendAtAppointmentCheckout: false,
            sendDayOfAppointment: false,
            sendToAllPatients: false,
            dateNoteIsClosed: false,
            doctorIds: [],
            // doctorsIds: doctors.map(({ doctorId, name }) => ({ DoctorId: doctorId, Name: name })),
          },
          medbotDraftOrder: {
            medBotName: name || medBotName,
            medbotType: 'none',
            practiceId,
            lineItems,
            whenToSend: dayjs().utc().format(),
          },
        },
      }),
    [medBotName, lineItems, practiceId, mutate]
  )

  return { createMedBot, ...rest }
}
