import { useCallback, useContext, useMemo } from 'react'
import { Table } from 'components'
import './ProductsTable.scss'
import { EditIcon } from 'assets/vector'
import { svgButton } from 'utilities/utils'
import { useNavigate } from 'react-router-dom'
import { ProductImageName } from 'components/Global'
import { ProductTableContext } from '../context/ProductsTableContext'

export const ProductsTable = ({ data, status: { error, isLoading }, chooseAltProduct }) => {
  const {
    actions: { setSortBy },
  } = useContext(ProductTableContext)

  const nav = useNavigate()

  const columns = useMemo(
    () =>
      chooseAltProduct
        ? [
            { Header: 'Product', accessor: 'ProductName' },
            { Header: 'Brand', accessor: 'vendor' },
          ]
        : [
            { Header: 'Product', accessor: 'ProductName' },
            { Header: 'Brand', accessor: 'vendor' },
            { Header: 'Edit', accessor: 'edit', unsortable: true },
          ],
    [chooseAltProduct]
  )

  const onClick = useCallback(
    //notice the curried function
    item => () => chooseAltProduct ? chooseAltProduct(item) : nav(`${item?.productId}/${item?.practiceId}`),
    [chooseAltProduct, nav]
  )

  const tableData = useMemo(
    () =>
      data?.results?.map(item => {
        return {
          ProductName: (
            <ProductImageName
              className={'product-img-name'}
              name={item?.productName}
              imgString={item?.images?.[0]?.src || item?.image?.[0]?.src}
            />
          ),
          vendor: <span className="brand">{item?.vendor}</span>,
          edit: svgButton(<EditIcon className="edit-btn-product" onClick={() => nav(`${item?.id}`)} />),
          onClick: onClick(item),
        }
      }),
    [data, nav, onClick]
  )

  return (
    <div className="products-table-wrap">
      <Table
        sort={setSortBy}
        loading={isLoading}
        error={error}
        noDataMessage={'No Products'}
        className="products-table"
        data={{ columns, data: tableData || [] }}
      />

      {/* {data && (
        <Pagination
          page={data?.currentPage}
          totalPages={data?.totalPages}
          selectPage={selectPage}
          isLoading={isLoading}
        />
      )} */}
    </div>
  )
}
