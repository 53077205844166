import { ReactCrop, centerCrop } from 'react-image-crop'
import { Button, CloseButton } from '../Button'
import 'react-image-crop/dist/ReactCrop.css'
import './AvatarCrop.scss'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useOutsideClick } from 'utilities/hooks'
import useCanvasPreview from './useCanvasPreview'

export const Cropper = ({ img, onFinish, onCancel, isPracticeLogo = false }) => {
  const previewCanvasRef = useRef()
  const imgRef = useRef()
  const cropperRef = useRef()
  const [crop, setCrop] = useState({
    unit: '%',
    x: 25,
    y: 25,
    ...(isPracticeLogo ? {} : { height: 50, width: 50 }),
  })
  const [closing, setClosing] = useState(false)
  const { isAllowed, height, width } = useCanvasPreview(crop, imgRef, previewCanvasRef, isPracticeLogo)

  const hasCropped = useMemo(() => crop.x !== 25 && crop.y !== 25 && crop.height !== 50 && crop.width !== 50, [crop])

  const close = useCallback(
    img => {
      setClosing(true)
      setTimeout(() => onFinish(img), 500)
    },
    [onFinish]
  )

  useOutsideClick({ ref: cropperRef, customAction: useCallback(() => close(null), [close]) })

  const handleFinish = useCallback(async () => {
    await previewCanvasRef.current.toBlob(blob => {
      blob.lastModifiedDate = new Date()
      blob.name = 'cropped_avatar'
      close({ data: new File([blob], blob.name, { type: blob.type }), preview: URL.createObjectURL(blob) })
    })
  }, [close])

  const onImageLoad = useCallback(
    e => {
      const { naturalWidth: width, naturalHeight: height } = e.currentTarget
      const centeredCrop = centerCrop({ ...crop }, width, height)
      setCrop(centeredCrop)
      // const aspectCrop = centerCrop(makeAspectCrop({ ...crop }, 16 / 9, width, height), width, height)
      // // const aspectCrop = makeAspectCrop({ ...crop }, 16 / 9, width / height)
      // setCrop(aspectCrop)
    },
    [crop]
  )

  return (
    <div className={`avatar-cropper-backdrop-blur ${closing ? 'closing' : 'opening'}`}>
      <div ref={cropperRef} className={`avatar-cropper ${closing ? 'closing' : 'opening'}`}>
        <div className="avatar-cropper-header">
          <CloseButton onClick={onCancel} />
          <h4>Align Your Avatar</h4>
          <span>
            <p>Click and drag to align your avatar.</p>
          </span>
        </div>
        <div className="avatar-cropper__main" id={isPracticeLogo ? 'practice-logo-mode' : 'avatar-mode'}>
          <ReactCrop
            aspect={isPracticeLogo ? 20 / 9 : undefined}
            id={isPracticeLogo ? 'practice-logo-mode' : 'avatar-mode'}
            className="avatar-cropper__main--react-crop-element"
            crop={crop}
            onChange={crop => setCrop(crop)}
          >
            <img ref={imgRef} alt="avatar-crop" src={img} onLoad={onImageLoad} />
          </ReactCrop>

          <div className="avatar-cropper__main--canvas-contain">
            <span>
              <p>How your avatar will display:</p>
            </span>
            <div
              className="avatar-cropper__main--canvas-wrap"
              id={isPracticeLogo ? 'practice-logo-mode' : 'avatar-mode'}
            >
              <canvas ref={previewCanvasRef} />
            </div>
          </div>
        </div>

        <div className="avatar-cropper__sizing-notice">
          <span>
            <p className={hasCropped ? 'show' : 'hide'} id={isAllowed ? 'valid-img' : 'invalid-img'}>
              {useMemo(
                () =>
                  isAllowed
                    ? 'This size avatar is allowed.'
                    : `This image is too big for an avatar. Images should be no more than ${
                        isPracticeLogo ? '600x600' : '300X300'
                      }. This image is ${width}x${height}`,
                [isAllowed, width, height, isPracticeLogo]
              )}
            </p>
          </span>
        </div>
        <div className="avatar-cropper__actions">
          <Button disabled={!isAllowed} type="button" onClick={handleFinish}>
            Accept
          </Button>

          <Button type="button" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}
