import { memo } from 'react'
import { Field } from 'react-final-form'
import { useImageDropzone } from 'utilities/hooks'
import { ArrowDownIcon, PlusIcon } from 'assets/vector/icons'
import './Avatar.scss'

const Avatar = memo(({ input: { onChange, ...inputProps }, required, meta, className, avatar, ...rest }) => {
  const [{ getInputProps, getRootProps, isDragActive }, { files }] = useImageDropzone(
    {
      onNewFiles: file => onChange(file?.data),
    },
    true
  )

  return (
    <div
      {...getRootProps({
        ...rest,
        className: `avatar-uploader ${isDragActive ? 'drag' : ''} ${className || ''}`,
        ...inputProps,
      })}
    >
      <input {...getInputProps()} />

      {files && !isDragActive ? (
        <div className="image uploaded" style={{ backgroundImage: `url(${files?.preview})` }} alt="user avatar" />
      ) : avatar && !isDragActive ? (
        <div style={{ backgroundImage: `url(${avatar})` }} className="image uploaded" alt="avatar saved" />
      ) : (
        <div className={`image ${required ? 'required' : ''}`}>
          <PlusIcon className="plus" />
          {isDragActive ? <ArrowDownIcon className="upload" /> : null}
          {required && !isDragActive && <span className="image-required-text">Image Required</span>}
        </div>
      )}
    </div>
  )
})

/**
 *
 * @param {import('react-final-form').FieldProps | import('react').HTMLProps} props
 * @returns
 */
const AvatarFieldWrapper = props => <Field {...props} component={Avatar} />

export default memo(AvatarFieldWrapper)
