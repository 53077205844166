import apiv2 from 'apiv2'

/**
 * @param {Object} params
 * @param {string|number} params.medbotId
 * @param {Object} params.body ~ the medbot details (quite large)
 */
export const UPDATE_MEDBOT = async ({ medbotId, body }) => {
  const url = '/medbot/update/:medbotId'.replace(':medbotId', medbotId)
  return await apiv2.put(url, body)
}
