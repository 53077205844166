import { useMemo } from 'react'

const keys = {
  SET_SCOPE: 'SET_SCOPE',
  SET_PAGE: 'SET_PAGE',
  SET_TOTAL_ROW: 'SET_TOTAL_ROWS',
}

const validScopes = ['today', 'week', 'lastWeek', 'month', 'year']

export const init = {
  scope: 'month',
  page: 1,
  pagesize: 50,
  totalRows: null,
}

export const AnalyticsReducer = (draft, { type, payload }) => {
  switch (type) {
    case keys.SET_SCOPE:
      draft.page = 1
      return void (draft.scope = payload)
    case keys.SET_PAGE:
      return void (draft.page = payload)
    case keys.SET_TOTAL_ROW:
      return void (draft.totalRows = payload)
    default:
      return draft
  }
}

export const useAnalyticsActions = dispatch =>
  useMemo(
    () => ({
      setTotalRows: totalRows => dispatch({ type: keys.SET_TOTAL_ROW, payload: totalRows }),
      setPage: page => dispatch({ type: keys.SET_PAGE, payload: page }),
      setScope: scope => {
        if (validScopes.includes(scope)) dispatch({ type: keys.SET_SCOPE, payload: scope })
      },
    }),
    [dispatch]
  )
