import { CheckmarkCheckedIcon } from 'assets/vector'
import { usePrint } from 'utilities/hooks'
import { useContext } from 'react'
import { MainContext } from 'context'
import dayjs from 'dayjs'
// import { ForDownload } from '../ForDownload'

export const HasSigned = ({ printRef }) => {
  const { patient } = useContext(MainContext)
  // const downloadRef = useRef()

  const { PrintBtn } = usePrint({ printRef, title: 'MedtrixUserAgreement' })
  // const { DownloadBtn } = useDownload({ targetRef: downloadRef, filename: 'MedtrixUserAgreement' })

  return (
    <>
      {/* <ForDownload ref={downloadRef} /> */}
      <div className="first-last print-only">
        <p>
          {patient?.user?.firstName} {patient?.user?.lastName}
        </p>
      </div>
      <div className="checkbox-wrap">
        <p className="checkbox-label do-not-print">
          I confirm I have read, consent and agree to Medtrix User Agreement and Privacy Policy.
        </p>
        <p className="checkbox-label print-only">
          Patient has read, consent and agree to Medtrix User Agreement and Privacy Policy.
        </p>
        <div className="checkbox-check">
          <CheckmarkCheckedIcon />
          <p>Yes</p>
        </div>
        <div className="signed-on print-only">
          <p className="signed-on">Signed on</p>
          <p className="date">{dayjs(patient?.user?.termsDate).format('MM/DD/YYYY')}</p>
        </div>
      </div>
      <div className="row-2 do-not-print">
        <div className="signed-on">
          <p className="signed-on">Signed on</p>
          <p className="date">{dayjs(patient?.user?.termsDate).format('MM/DD/YYYY')}</p>
        </div>
        <div className="download-print">
          <PrintBtn />
          {/* <DownloadBtn /> */}
        </div>
      </div>
    </>
  )
}
