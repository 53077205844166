import { useMemo } from 'react'
import { buildBrandFilter } from './utils'

const keys = {
  SELECT_COLLECTION: 'SELECT_COLLECTION',
  SELECT_SUBFILTER: 'SELECT_SUBFILTER',
  SELECT_BRAND: 'SELECT_BRAND',
  KEYWORD_SEARCH: 'KEYWORD_SEARCH',
  SELECT_PRACTICE: 'SELECT_PRACTICE',
  SELECT_SORTBY: 'SELECT_SORTBY',
  SELECT_PAGE: 'SELECT_PAGE',
}

export const tableInit = {
  collection: null,
  sub: null,
  brand: null,
  sortBy: { id: null, desc: false },
  keyword: '',
  practiceFilter: null, /// order practice id associated with user
  page: 1,
  brandFilter: [],
}

export const ProductsTableReducer = (draft, { type, payload }) => {
  switch (type) {
    case keys.SELECT_COLLECTION:
      draft.page = 1
      draft.sub = null
      return void (draft.collection = draft.collection === payload ? null : payload)
    case keys.SELECT_SUBFILTER:
      draft.page = 1
      return void (draft.sub = draft.sub === payload ? null : payload)
    case keys.KEYWORD_SEARCH:
      draft.page = 1
      return void (draft.keyword = payload?.query || '')
    case keys.SELECT_BRAND:
      draft.page = 1
      draft.brandFilter = buildBrandFilter(payload)
      return void (draft.brand = payload)
    case keys.SELECT_PRACTICE:
      draft.page = 1
      return void (draft.practiceFilter = payload)
    case keys.SELECT_PAGE:
      return void (draft.page = payload)
    case keys.SELECT_SORTBY:
      return void (draft.sortBy = { ...draft.sortBy, ...payload })
    default:
      return draft
  }
}

export const useProductTableActions = dispatch =>
  useMemo(
    () => ({
      actions: {
        setCollection: name => dispatch({ type: keys.SELECT_COLLECTION, payload: name }),
        setSub: newSub => dispatch({ type: keys.SELECT_SUBFILTER, payload: newSub }),
        setBrandFilter: brand => dispatch({ type: keys.SELECT_BRAND, payload: brand }),
        keywordSearch: keyword => dispatch({ type: keys.KEYWORD_SEARCH, payload: keyword }),
        selectPractice: practiceId => dispatch({ type: keys.SELECT_PRACTICE, payload: practiceId }),
        selectPage: number => dispatch({ type: keys.SELECT_PAGE, payload: number }),
        setSortBy: payload => dispatch({ type: keys.SELECT_SORTBY, payload }),
      },
    }),
    [dispatch]
  )
