import { Form, useFormState } from 'react-final-form'
import './Namer.scss'
import { Button, Input, Loader } from 'components'
import { SpeechTailIcon } from 'assets/vector'
import { MedBotsContext } from '../../context'
import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from '../PageHeader'

export const Namer = () => {
  const nav = useNavigate()
  const { actions, medBotName } = useContext(MedBotsContext)

  const nameBot = useCallback(({ medBotName }) => actions.nameBot(medBotName), [actions])

  const addProducts = useCallback(() => nav('/admin/medbots/addproducts'), [nav])

  return (
    <>
      <PageHeader title={'Name Your MedBot'} onBack={() => (medBotName ? actions.nameBot(null) : nav(-1))} />
      <div className="medbot-namer">
        <Welcome named={!!medBotName} />
        {medBotName ? (
          <div className="medbot-namer__add-products fade-in">
            <Button className={'medbot-namer__add-products-button fade-in'} onClick={addProducts}>
              Add Products
            </Button>
          </div>
        ) : (
          <Form onSubmit={nameBot} initialValues={{}} render={props => <InnerForm {...props} />} />
        )}
        <Loader />
      </div>
    </>
  )
}

const Welcome = ({ named }) => {
  return (
    <div className="medbot-namer__desc fade-in">
      <h4 className="medbot-namer__welcome">{named ? 'Almost There' : 'Welcome'}!</h4>
      <p>{named ? `Let's add some products` : 'You are about to create a product'}</p>
      <p>{named ? `to your MedBot!` : `promotion called a MedBot.`}</p>
    </div>
  )
}

const InnerForm = ({ handleSubmit }) => {
  const { values } = useFormState()
  return (
    <form className="medbot-namer__form fade-in">
      <p className="medbot-namer__form--label">Let's name your MedBot.</p>
      <div className="medbot-namer__form--input-wrap">
        <Input autoComplete={'off'} name="medBotName" label="" />
        <SpeechTailIcon />
      </div>
      <Button
        className={'medbot-namer__form--next-button fade-in'}
        disabled={!values.medBotName}
        onClick={handleSubmit}
      >
        Next
      </Button>
    </form>
  )
}
