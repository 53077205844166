import { default as DP } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Input from '../Input'
import dayjs from 'dayjs'
import './DatePickerV2.scss'

export const DatePicker = props => (
  <Input
    className={`datepicker ${props.className || ''}`}
    parse={v => dayjs(v).isValid() && dayjs(v).toISOString()}
    format={v => dayjs(v).isValid() && dayjs(v).format('YYYY/MM/DD')}
    component={<Picker />}
    {...props}
  />
)

const Picker = ({ input, meta, ...rest }) => {
  return <DP {...input} {...rest} />
}
