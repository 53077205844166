import { createContext, useContext } from 'react'
import { StoreReducer, init, useStoreActions } from './StoreReducer'
import { useImmerReducer } from 'use-immer'

export const StoreContext = createContext(init)

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(StoreReducer, init)
  const actions = useStoreActions(dispatch)

  return <StoreContext.Provider value={{ ...state, actions }}>{children}</StoreContext.Provider>
}

export const useStore = () => useContext(StoreContext)
