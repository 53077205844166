import Lottie from 'react-lottie-player'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button } from 'components/Global/Button'
import { useBubbleProps } from 'utilities/hooks'
import { sadEmptyBoxLottieData } from 'assets/lottie'

import './NoOrders.scss'

const NoOrders = () => {
  const nav = useNavigate()
  const isCart = useLocation()?.pathname?.includes('shoppingcart')
  useBubbleProps({ isOpen: false })

  return (
    <div className="no-order">
      <div className="animation-message-wrapper">
        <Lottie
          className="lottie empty-box-animation"
          role="img"
          aria-label="sad empty box animation"
          animationData={sadEmptyBoxLottieData}
          play
        />
        <p>{isCart ? 'Your cart is empty' : 'You currently do not have any active orders'}</p>
      </div>
      <div className="actions">
        <Button onClick={() => nav('/history', { replace: true })}>View History</Button>
        {/* <Button onClick={() => nav('/refills', { replace: true })}>View Refills</Button> */}
      </div>
    </div>
  )
}

export default NoOrders
