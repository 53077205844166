import { useMemo } from 'react'
import './TotalSales.scss'
import { Sales } from './peices/Sales'
import { numberWithCommas } from 'utilities/utils'
import { IncreaseArrowIcon } from 'assets/vector'
import { useAnalyticsContext } from '../context'

export const TotalSales = ({ ...data }) => {
  const { scope } = useAnalyticsContext()

  const diff = useMemo(() => {
    let x = data.revenueDifferenceToLastYTD?.toFixed(2)
    return {
      diff: numberWithCommas(Math.abs(x)),
      msg: x >= 0 ? 'UP FROM LAST YEAR' : 'DOWN FROM LAST YEAR',
      isNeg: x <= -1,
    }
  }, [data])

  return (
    <div className={`total-sales-wrap`}>
      <div className="total-sales-header">
        <div className="total-sales__total-rev-ytd">
          <h5>Total Revenue YTD</h5>
          <p>${numberWithCommas(data.totalRevenueYTD)}</p>
        </div>
        <div className="total-sales-legend">
          <span className="total-sales-legend__this-year">This Year</span>
          <span className="total-sales-legend__last-year">Last Year</span>
        </div>
        <div className="total-sales-revenue">
          <div className="total-sales-revenue__difference">
            <span>
              <IncreaseArrowIcon className={diff?.isNeg ? 'down' : 'up'} /> {diff?.isNeg ? '-' : '+'}${diff?.diff}
            </span>
            <span>{diff?.msg}</span>
          </div>
        </div>
      </div>
      <Sales {...formatData(data, scope)} />
    </div>
  )
}

function formatData(data, scope) {
  switch (scope) {
    case 'year':
      return {
        lastYear: data.previousYearMonthlyRevenues,
        thisYear: data.monthlyRevenues,
      }
    case 'week':
      return {
        thisYear: data.thisYearRevenueList,
        lastYear: data.lastYearRevenueList,
      }
    case 'lastWeek':
      return {
        thisYear: data.thisYearRevenueList,
        lastYear: data.lastYearRevenueList,
      }
    case 'month':
      return {
        thisYear: data.thisYearRevenueList,
        lastYear: data.lastYearRevenueList,
      }
    case 'today':
      return {
        thisYear: data.todaysRevenue,
        lastYear: data.lastYearsRevenue,
      }
    default:
      return { lastYear: [], thisYear: [] }
  }
}
