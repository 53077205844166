import { useContext, useMemo, useState } from 'react'
import { UserProfile } from './UserProfile'
import { AdminMenu, Navigation, PracticeMenu } from './Navigation'
import { MainContext } from 'context'

import './Sidebar.scss'
import { ExitIcon, MedBotSmallLogo, PoweredByMedBotDarkBlue } from 'assets/vector'
import { useQueryClient } from 'react-query'

const Sidebar = () => {
  const {
    admin: {
      user: { role },
      authentication: token,
    },
  } = useContext(MainContext)

  const [sidebarState, setSidebarState] = useState('open')

  const toggleSidebar = () => {
    setSidebarState(state => {
      switch (state) {
        case 'open':
          return 'closing'
        case 'opening':
          return 'closing'
        case 'closed':
          return 'opening'
        case 'closing':
          return 'opening'
        default:
          return 'closing'
      }
    })
  }
  const completeToggle = (property, state) => {
    if (property !== 'width') return
    if (state === 'closing') return setSidebarState('closed')
    if (state === 'opening') return setSidebarState('open')
  }

  const menu = useMemo(() => (role?.includes?.('admin') ? AdminMenu : PracticeMenu), [role])

  return (
    <nav
      onTransitionEnd={({ propertyName }) => completeToggle(propertyName, sidebarState)}
      className={`sidebar ${sidebarState}`}
    >
      <UserProfile admin={!!token} practiceData={!!token} toggleSidebar={toggleSidebar} sidebarState={sidebarState} />
      <Navigation menu={menu()} sidebarState={sidebarState} />
      <CollapsingMedtrixLogo sidebarState={sidebarState} />
      <LogoutButton sidebarState={sidebarState} />
    </nav>
  )
}

const LogoutButton = ({ sidebarState, onClick }) => {
  const { admin } = useContext(MainContext)
  const qc = useQueryClient()
  return (
    <div className="sidebar__logout-wrapper">
      <button
        onClick={
          onClick
            ? onClick
            : () => {
                qc.invalidateQueries()
                admin.actions.logOut()
              }
        }
        className="sidebar__logout-button"
      >
        <ExitIcon />
        {['closed', 'closing'].includes(sidebarState) ? null : <p>LOG OUT</p>}
      </button>
    </div>
  )
}

const CollapsingMedtrixLogo = ({ sidebarState }) => (
  <div className="sidebar__logo">
    {['closed', 'closing'].includes(sidebarState) ? (
      <MedBotSmallLogo />
    ) : (
      <PoweredByMedBotDarkBlue style={{ padding: '5px' }} />
    )}
  </div>
)

export default Sidebar
