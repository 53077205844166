import { MainContext } from 'context'
import { useContext, useMemo } from 'react'
import { customQueryKeys } from 'utilities/constants'
import { ProductTableContext } from './context/ProductsTableContext'
import _ from 'lodash'

const keys = [customQueryKeys.PRODUCTS, customQueryKeys.PRODUCTS_TABLE]

/// admin
export const useProductFilters = () => {
  const { admin } = useContext(MainContext)
  const { collection, sub, page, keyword, practiceFilter, brandFilter, sortBy } = useContext(ProductTableContext)

  const practiceId = useMemo(() => (!!practiceFilter ? `/${practiceFilter}` : ''), [practiceFilter])

  const params = useMemo(
    () => ({
      keyword,
      page,
      pageSize: 1000,
      sortOrder: sortBy?.desc ? 'desc' : 'asc',
      sortField: sortBy?.id || 'ProductName',
      ...brandFilter,
    }),
    [keyword, brandFilter, sortBy, page]
  )

  const {
    data: allData,
    isLoading: allLoading,
    error: allError,
  } = admin.api.products.getAllProducts({
    variables: { practiceId },
    params,
    queryOptions: { enabled: !collection && !sub },
    keys: [...keys, 'get_all_products', practiceId],
  })

  const { data: subsList } = admin?.api?.products?.categories?.getSubsByTitle({
    variables: { title: _.startCase(collection), practiceId },
    queryOptions: { enabled: !!collection },
    keys: [...keys, 'get_subs_by_collection', collection, practiceId],
  })

  const {
    data: byCollectionData,
    isLoading,
    error,
  } = admin?.api?.products?.categories?.productsByCollection({
    variables: { collection: _.startCase(collection), practiceId },
    params,
    queryOptions: { enabled: !!collection && !sub },
    keys: [...keys, collection, practiceId],
  })

  const {
    data: bySubsData,
    isLoading: productsLoadingBySubs,
    error: subCatsError,
  } = admin?.api?.products?.categories?.productsBySubCategory({
    variables: { collection: _.startCase(collection), subcategory: sub, practiceId },
    params,
    queryOptions: { enabled: !!collection && !!sub },
    keys: [...keys, collection, sub, practiceId],
  })

  // useEffect(() => () => qc.invalidateQueries(keys), [qc])

  const data = useMemo(
    () => (!collection && !sub ? allData : !!collection && !sub ? byCollectionData : bySubsData),
    [collection, sub, allData, byCollectionData, bySubsData]
  )

  return {
    subsList,
    data,
    isLoading: isLoading || allLoading || productsLoadingBySubs,
    error: error || allError || subCatsError,
  }
}
