import { Button, ImageSlider } from 'components'
import { useContext, useMemo, useState } from 'react'
import { ProductControls } from 'components'
import { useProductControls } from './useProductControls'
import { Directions } from './components'
import DOMPurify from 'dompurify'
import { BackButtonInPage } from 'components/AlphabeticList'
import { ProductViewContext, ProductViewProvider } from './context'
import { AddAnother } from './components/AddAnother'

/* PROPS
allowQtyControls // hide or show delete and qty toggle
onAddAnother? 
onAdd 
onDelete
onBack?
isInOrder
onUpdate
product
*/
export const ProductView = props => {
  return (
    <ProductViewProvider {...props}>
      <ProductViewInner />
    </ProductViewProvider>
  )
}

const defaultStateValues = {
  quantity: 1,
  isAutoShip: false,
  doctorsMessage: null,
}

export const ProductViewInner = () => {
  const [showAddAnother, setShowAddAnother] = useState(false)
  const { isInOrder, product, actions } = useContext(ProductViewContext)
  const { ...eventHandlers } = useProductControls()

  const images = useMemo(
    () => (product?.image || product?.productImages)?.map(img => (img?.src ? img?.src : img)),
    [product]
  )

  /*
  const prices = useMemo(() => {
    const wholeSale = product?.basePrice?.toFixed(2) || product?.variants?.[0]?.wholeSalePrice?.toFixed(2) || '0.00'
    const retailPrice = product?.retailPrice?.toFixed(2) || product?.variants?.[0]?.retailPrice?.toFixed(2) || '0.00'
    return {
      margin: product?.markupAmount?.toFixed(2) || (retailPrice - wholeSale)?.toFixed(2) || '0.00',
      wholeSale,
      retailPrice,
    }
  }, [product])
*/
  if (showAddAnother) return <AddAnother />

  return (
    <div className="select-product product-view">
      <div className="select-product-detail">
        <div className="select-product-detail-head">
          {!isInOrder && actions?.onBack && <BackButtonInPage onClick={() => actions.onBack()} />}
        </div>
        <div className="select-product-detail-body">
          <ImageSlider className={'select-product-detail__image-slider'} images={images} />
          <div className="select-product-detail__controls">
            <h5 className="select-product-detail__controls--name">{product?.productName || '----'}</h5>
            {/* <Prices prices={prices} /> */}
            <ProductControls product={{ ...product, quantity: product?.quantity || 1 }} {...eventHandlers} />
            <Directions doctorsMessage={product?.doctorsMessage} />
            <Description bodyHtml={product?.bodyHtml} />
            <Button
              onClick={() => {
                const variantId = product?.variantId || product?.variants?.[0]?.variantId || null
                const productToBeSaved = {
                  ...defaultStateValues,
                  ...product,
                  variantId,
                }
                if (isInOrder) {
                  actions.onUpdate(productToBeSaved)
                } else {
                  actions.onAdd(productToBeSaved)
                  if (!!actions?.onAddAnother) setShowAddAnother(true)
                }
              }}
              className={'select-product-detail__controls--submit-btn'}
            >
              {isInOrder ? 'Update Item' : `Add To Order`}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const Description = ({ bodyHtml }) => {
  const [expand, setExpand] = useState(false)

  const __html = useMemo(() => {
    const cleanedHtml = DOMPurify.sanitize(bodyHtml)
    return { __html: cleanedHtml }
  }, [bodyHtml])

  return (
    <div className="select-product-detail__controls--description">
      <h6>Product Details</h6>
      <div className={expand ? 'expand' : 'closed'} dangerouslySetInnerHTML={__html} />
      <button onClick={() => setExpand(!expand)}>{expand ? 'View Less' : 'View More'}</button>
    </div>
  )
}

/*
const PriceItem = ({ label, amount }) => (
  <div className={`select-product-detail__controls--prices-item ${label}`}>
    <span className="item-label">{label}</span>
    <span className="item-amount">${amount || '--'}</span>
  </div>
)

const Prices = ({ prices }) => (
  <div className="select-product-detail__controls--prices">
    <PriceItem label="Whole Price" amount={prices.wholeSale} />
    <div className="divider" />
    <PriceItem label="Retail Price" amount={prices.retailPrice} />
    <div className="divider" />
    <PriceItem label="Margin" amount={prices.margin} />
  </div>
)
*/
