import { Button } from 'components'
import { useMedBotCreate } from './useMedBotCreate'
import { useMedbotUpdate } from './useMedbotUpdate'
import { Loading } from './View'
import { useCallback, useContext } from 'react'
import { MedBotsContext } from '../../context'

export const ConfirmButton = () => {
  const { hasEdits, medbotId } = useContext(MedBotsContext)
  const { createMedBot, isLoading: createLoading } = useMedBotCreate()
  const { updateMedBot, isLoading: updateLoading } = useMedbotUpdate()

  const handleClick = useCallback(() => {
    !!medbotId ? updateMedBot() : createMedBot()
  }, [medbotId, updateMedBot, createMedBot])

  return (
    <>
      <Loading isLoading={createLoading || updateLoading} />
      <Button
        disabled={!!medbotId && !hasEdits}
        onClick={handleClick}
        className={'medbots-view__action-buttons--confirm'}
      >
        {!!medbotId ? 'Save' : 'Create'}
      </Button>
    </>
  )
}
