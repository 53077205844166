import apiv2 from 'apiv2'

/**
 *
 * @param {Object} data
 * @param {number} data.id
 * @param {?string} data.firstName
 * @param {?string} data.lastName
 * @param {?string} data.cellNumber
 * @param {?string} data.phoneNumber
 * @param {?string} data.email
 */
export const UPDATE_PATIENT = async data => {
  const url = '/patientportal/patient/update'

  if (!data.id) return Promise.reject('No Patient id')

  return await apiv2.post(url, data)
}
