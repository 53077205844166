import { Loader } from '../Loader'
import './PageWrap.scss'

export function PageWrap({ className = '', isLoading = false, title, children }) {
  return (
    <section className={`page-wrap ${className}`}>
      <h4 className="page-wrap__title">{title}</h4>
      {isLoading ? (
        <div className="page-wrap__loading">
          <Loader />
        </div>
      ) : (
        children
      )}
    </section>
  )
}
