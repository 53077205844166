import { useBubbleProps } from 'utilities/hooks'
import { useRef } from 'react'
import './Terms.scss'
import { HasSigned } from './peices'
import { Verbage } from './Verbage'
import { Button } from '../Button'
import { HamburderMenuXIcon } from 'assets/vector'
import { svgButton } from 'utilities/utils'
import Lottie from 'react-lottie-player'
import { alertLottieData } from 'assets/lottie'
import { TermSubmitButtons } from 'pages'

export const Terms = ({ loginPage, blueText, openText, setOpenText, hasTried, ...rest }) => {
  const printRef = useRef()
  useBubbleProps({ isOpen: !!loginPage })

  if (openText)
    return (
      <div style={{ paddingBottom: '200px' }} className={`login-terms terms-and-conditions`}>
        {svgButton(<HamburderMenuXIcon className="close-terms" onClick={() => setOpenText(false)} />)}
        <Verbage blueText={true} />
        <TermSubmitButtons {...rest} />
      </div>
    )

  return (
    <div>
      <div ref={printRef} className={`${loginPage ? 'login-terms' : ''} terms-and-conditions`}>
        <div className={`decline-alert ${hasTried ? 'show' : 'hide'}`}>
          {hasTried && (
            <Lottie
              style={{ height: '150px' }}
              role="img"
              aria-label="Alert Animation"
              play
              loop={false}
              animationData={alertLottieData}
            />
          )}
          <p className={`must-agree-notice`}>
            You must agree to the User Agreement and Privacy Policy to see your recommended products.
          </p>
        </div>
        {loginPage ? (
          <Button
            onClick={() => {
              setOpenText(true)
            }}
            className={'open-terms-button'}
          >
            User Agreement & Privacy Policy
          </Button>
        ) : (
          <Verbage blueText={blueText} />
        )}
        {loginPage ? <TermSubmitButtons {...rest} /> : <HasSigned printRef={printRef} />}
      </div>
    </div>
  )
}
