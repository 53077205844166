import { useCallback } from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'

//note these are partial routes
const permittedRoutes = ['/admin/create-order', '/admin/send-wellness-store']

export const StaffTier2Routes = () => {
  const { pathname } = useLocation()

  const runRouteChecks = useCallback(
    () => permittedRoutes.some(partialRoute => pathname.includes(partialRoute)),
    [pathname]
  )

  return runRouteChecks() ? <Outlet /> : <Navigate replace to="/admin/create-order/medbot" />
}
