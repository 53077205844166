import { useContext } from 'react'
import { MainContext } from 'context'
import './AdminLogin.scss'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { LoginForm } from '../LoginForm'

export const AdminLogin = () => {
  const {
    admin: { actions, api },
  } = useContext(MainContext)
  const nav = useNavigate()

  const onSuccess = data => {
    const { token, statusSummary, ...user } = data

    actions.setOrderSummary({ fetchedDate: dayjs(), ...statusSummary })
    actions.setUser({ ...user, practiceId: user?.practiceId || null })
    actions.authenticate(data?.token)

    const role = data?.role
    const providerType = data?.providerType || ''

    if (
      role.includes('ceo') ||
      role.includes('admin') ||
      (role.includes('doctor') && providerType.toLowerCase() === 'doctor')
    ) {
      nav('/admin')
    } else if (role.includes('stafftier2')) {
      nav('/admin/create-order/medbot', { replace: true })
    } else {
      nav('/admin/orders/all', { replace: true })
    }
  }

  const { mutate: login, isLoading, isError } = api.login({ queryOptions: { onSuccess } })

  return <LoginForm login={vals => login({ body: vals })} loading={isLoading} isError={isError} />
}
