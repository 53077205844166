import { useFormState } from 'react-final-form'

import { Button, CloseButton, DatePicker, Input, InputGroup, Loader, YesNoToggle } from 'components/Global'
import { ConditionalForm } from '../ConditionalForm'
import { Money, Percent } from 'components/Global/Input/MaskedInputs'
import { useFees } from 'utilities/hooks'

export const Fees = ({ add, closeModal, data, id, ...rest }) => {
  const { editFees, fees, isLoading } = useFees(add)

  if (isLoading) return <Loader />

  return (
    <ConditionalForm
      onSubmit={values => !add && editFees(values)}
      wrapperClass={'fees'}
      initialValues={{
        ...fees,
        endDate: fees?.fees?.[0]?.endDate,
        feeAmount: fees?.fees?.[0]?.feeAmount,
        startDate: fees?.fees?.[0]?.startDate,
      }}
      renderForm={add}
      {...rest}
    >
      <FeesFields closeModal={closeModal} add={add} />
    </ConditionalForm>
  )
}

export const FeesFields = ({ add, closeModal, combined, validation: validationOn }) => {
  if ([null, undefined].includes(validationOn)) validationOn = true
  const { values } = useFormState()
  return (
    <InputGroup group={add || combined ? 'fees' : null}>
      <h4>{add ? null : 'Edit'} Fees</h4> {add || combined ? null : <CloseButton onClick={() => closeModal()} />}
      <div className="dates">
        <DatePicker clearIcon={null} name="enrollmentDate" label="Enrollment Date" />
        <DatePicker clearIcon={null} name="startDate" label="Start Date" />
      </div>
      <Input defaultValue={0.85} maskedInput={Money} name="transactionFee" label="Credit Card Processing Fee" />
      <Input
        className={'cc-processing-fee-percent'}
        maskedInput={Percent}
        defaultValue={5.95}
        name="transactionFeePercentage"
        label="Credit Card Processing Fee Percentage"
      />
      <Input defaultValue={1495} maskedInput={Money} name="feeAmount" label="Monthly Subscription Fee" />
      <Input defaultValue={19.95} maskedInput={Money} name="medBotPlacedFee" label="MedBot Fee" />
      <Input defaultValue={0.08} maskedInput={Money} name="textEmailFee" label="Text/Email Fee" />
      <YesNoToggle
        name="backfillToggle"
        defaultValue={false}
        label="MedBot Mining Backfill"
        canChangeOnError={true}
        validate={() =>
          !values?.billing?.accountingEmail && values.fees?.backfillToggle ? 'Valid Accounting Email Required.' : null
        }
      />
      <Input defaultValue={4.95} maskedInput={Money} name="refundProcessingFee" label="Refund Processing Fee" />
      {(values.fees?.backfillToggle || values.backfillToggle) && (
        <Input
          maskedInput={Money}
          validate={() =>
            validationOn && !values?.billing?.accountingEmail && values.fees?.backfillToggle
              ? 'Valid Accounting Email Required.'
              : null
          }
          name="medMinningBackfillFee"
          label="MedBot Mining Backfill Fee"
        />
      )}
      <br />
      {add || combined ? null : (
        <div className="actions">
          <Button
            onClick={e => {
              e.preventDefault()
              closeModal()
            }}
          >
            Cancel
          </Button>
          <Button>Save</Button>
        </div>
      )}
    </InputGroup>
  )
}
