import { useContext, useMemo } from 'react'
import './Header.scss'
import { MainContext } from 'context'
import { usePracticeLogo } from './utils'
import { ShoppingCart } from './components/ShoppingCart'

const Header = () => {
  const {
    patient: { practiceLogo },
  } = useContext(MainContext)

  usePracticeLogo()

  const logo = useMemo(
    () =>
      typeof practiceLogo === 'string'
        ? practiceLogo
        : practiceLogo?.contentType
        ? `data:${practiceLogo?.contentType};base64,${practiceLogo?.fileContents}`
        : null,
    [practiceLogo]
  )

  return (
    <>
      <div className="main-header">
        {logo ? <img src={logo} alt="practice logo" loading="lazy" /> : null}
        <ShoppingCart />
      </div>
    </>
  )
}

export default Header
