import { MainContext } from 'context'
import { useCallback, useContext, useEffect, useState } from 'react'
import produce from 'immer'
import { AlertModal, SuccessModal } from 'components'
import { useNavigate } from 'react-router-dom'

/*
STEPS
1. create practice ======================> returns ID
2. post the practice logo with ID
        ---requires practiceId
*/

//need to set this as header for sending FormData
const fetchProps = {
  headers: {
    Accept: 'application/json',
  },
}

export const useAddPractice = () => {
  const nav = useNavigate()
  const { admin, modal } = useContext(MainContext)
  const [practiceId, setPracticeId] = useState(null)
  const [practiceAvatar, setPracticeAvatar] = useState(null)

  const success = useCallback(
    id => {
      modal.actions.open(
        <SuccessModal
          onClick={() => {
            nav(id ? `/admin/practices/${id}` : '/admin/practices')
            modal.actions.close()
          }}
          title="Practice created!"
        />
      )
    },
    [modal.actions, nav]
  )

  /* 
  create practice
  */
  const { mutate: add, isLoading: addLoading } = admin.api.practice.new({
    queryOptions: {
      onSuccess: data => setPracticeId(data?.id),
      onError: () => modal?.actions?.open(<AlertModal message={'There was a problem creating the practice.'} />),
    },
  })

  /* 
  post logo
  */
  const { mutate: postPraticeAvatar, isLoading: avatarLoading } = admin.api.practice.postAvatar({
    fetchProps,
    queryOptions: {
      onSuccess: () => success(practiceId),
      onError: () => {
        modal.actions.open(
          <SuccessModal
            onClick={() => {
              nav(practiceId ? `/admin/practices/${practiceId}` : '/admin/practices')
              modal.actions.close()
            }}
            Title="Practice created! However, We were not able to add the practice logo."
          />
        )
      },
    },
  })

  const postAvatar = useCallback(
    async avatarFile => {
      const data = new FormData()
      data.append('avatar', avatarFile, avatarFile?.name)
      await postPraticeAvatar({ variables: { id: practiceId }, body: data })
    },
    [postPraticeAvatar, practiceId]
  )

  const createPractice = async values => {
    let body = buildBody(values)
    const { general } = values
    setPracticeAvatar(general?.avatar) // if there is a practice avatar
    await add({ body })
  }

  ///after adding, check for logo, wholesaler info.
  useEffect(() => {
    if (!practiceId) return

    if (practiceAvatar) postAvatar(practiceAvatar)
    else success(practiceId)

    return () => setPracticeId(null)
  }, [practiceId, setPracticeId, postAvatar, practiceAvatar, success])

  return { createPractice, loading: addLoading || avatarLoading }
}

export const buildBody = vals => {
  return produce(vals, draft => ({
    name: draft?.general?.name,
    address1: draft?.general?.address1,
    address2: '',
    city: draft?.general?.city,
    state: draft?.general?.state,
    zip: draft?.general?.zip,
    phoneNumber: draft?.general?.phoneNumber,
    // fax: draft?.general?.fax,
    // email: draft?.general?.email,
    groupNPI: '',
    website: draft?.general?.website,
    physicianAdminContactEmail: draft?.general?.physicianAdminContactEmail,
    physicianAdminContactName: draft?.general?.physicianAdminContactName,
    physicianAdminContactPhone: draft?.general?.physicianAdminContactPhone,
    /////////////////// fees
    // enrollmentDate: draft?.fees?.enrollmentDate,
    // monthlyFeeStart: draft?.fees?.startDate,
    // monthlyFeeEnd: draft?.fees?.endDate,
    // monthlyFee: draft?.fees?.feeAmount,
    // textEmailFee: draft?.fees?.textEmailFee,
    // medBotPlacedFee: draft?.fees?.medBotPlacedFee,
    // medMinningBackfillFee: draft?.fees?.medMinningBackfillFee,
    // transactionFee: draft?.fees?.transactionFee,
    // transactionFeePercentage: draft?.fees?.transactionFeePercentage,
    // practiceBilling: {
    //   address1: draft?.billing?.address1,
    //   address2: '',
    //   city: draft?.billing?.city,
    //   state: draft?.billing?.state,
    //   zip: draft?.billing?.zip,
    //   nameOnCard: draft?.billing?.nameOnCard,
    //   creditCardNumber: draft?.billing?.creditCardNumber,
    //   expirationMo: +draft?.billing?.exp?.slice?.(0, 2) || null,
    //   expirationYr: +draft?.billing?.exp?.slice?.(-2) || null,
    //   cvc: draft?.billing?.cvc,
    // },
    // practiceBankAccount: !!draft?.payments?.accountNumber
    //   ? {
    //       accountHoldersName: draft?.payments?.accountHoldersName,
    //       accountNumber: draft?.payments?.accountNumber,
    //       bankName: draft?.payments?.bankName,
    //       country: 'US',
    //       routingNumber: draft?.payments?.routingNumber,
    //       currency: 'USD',
    //     }
    //   : null,
  }))
}
