import { cloneElement, memo, useCallback } from 'react'
import { Field } from 'react-final-form'
import camelCase from 'lodash/camelCase'

import { Password } from '.'

import { AlertIcon } from 'assets/vector/icons'
import './Input.scss'

/** @typedef {{label: string, maskedInput: import('react').ReactElement, validate: function | Array<function>, regex: RegExp} | import('react-final-form').FieldProps | import('react').HTMLProps} InputProps */
/** @param {InputProps} props Takes final-form Field props. validate can be an array of validation functions in order of priority. */
const Input = ({
  disabled,
  className,
  label,
  name,
  component,
  maskedInput,
  required,
  validate,
  children,
  regex,
  ...rest
}) => {
  const maskedInputComponent = useCallback(
    ({ input, meta, ...rest }) => (maskedInput ? maskedInput({ ...input, ...rest }) : false),
    [maskedInput]
  )

  const validationFunc = useCallback(
    /** @returns {String | undefined | null}  */
    v => {
      if (!required && !v) return null
      if (required && !v) return 'Required.'
      if (typeof validate === 'function') return validate?.(v)
      if (Array.isArray(validate)) return validate?.flatMap?.(f => Array(f?.(v) || 0))?.[0]
    },
    [required, validate]
  )

  const elementSelector = useCallback(
    (component, maskedInput, { input, meta, rest }) => {
      if (input?.type === 'password' || (!input?.type && [label?.toLowerCase?.(), name]?.includes?.('password')))
        return cloneElement(<Password />, { input, meta, rest })
      if (component && (typeof component === 'string' || component instanceof String)) {
        switch (component) {
          case 'input':
            return <input {...input} {...rest} validate={null} />
          case 'select':
            return (
              <select {...input} {...rest}>
                {children}
              </select>
            )
          case 'textarea':
            return <textarea {...input} {...rest} validate={null} />
          case 'children':
            return children
          default:
            return <input {...input} {...rest} validate={null} />
        }
      }
      if (component) return cloneElement(component, { input, meta, rest })
      if (maskedInput) return maskedInputComponent({ input, meta, ...rest })
      return <input {...input} {...rest} validate={null} />
    },
    [children, label, maskedInputComponent, name]
  )

  return (
    <Field
      className={`field field-input ${className || ''} ${disabled ? 'disabled' : ''}`}
      disabled={disabled}
      id={name}
      name={name || camelCase(label)}
      validate={validationFunc}
      {...rest}
    >
      {useCallback(
        ({ input, meta, ...rest }) => {
          return (
            <div
              className={`field field-wrapper ${className || ''} ${disabled ? 'disabled' : ''} ${Object.entries(
                meta
              ).reduce((acc, cur) => {
                if (cur[0] === 'valid' && !cur[1]) return acc + ' invalid'
                return typeof cur[1] === 'boolean' ? (cur[1] ? acc + ` ${cur[0]}` : acc) : acc
              }, '')}`}
            >
              {!!label && (typeof label == 'string' || label instanceof String) ? (
                <label
                  htmlFor={name}
                  title={label}
                  className={`field field-label ${className || ''} ${disabled ? 'disabled' : ''}`}
                >
                  {label}
                </label>
              ) : null}
              {elementSelector(component, maskedInput, { input, meta, rest })}
              <span
                role="note"
                className={`field-error ${className || ''} ${meta?.error && meta?.touched && 'field-error--active'}`}
              >
                <AlertIcon className="field-error__alert" />
                {meta?.error}
              </span>
            </div>
          )
        },
        [className, component, disabled, elementSelector, label, maskedInput, name]
      )}
    </Field>
  )
}

export default memo(Input)
