import axios from 'axios'
import { AUTHORIZE_CONFIG } from './config'

/**
 *
 * @param {Object} params
 * @param {String} params.customerProfileId
 * @param {String} params.customerPaymentProfileId
 * @param {Object} params.creditCard
 * @param {String} params.creditCard.cardNumber
 * @param {String} params.creditCard.expirationDate YYYY-MM
 * @param {String} params.creditCard.cardCode
 * @param {Object} params.billTo
 * @param {String} params.billTo.firstName
 * @param {String} params.billTo.lastName
 * {@link https://developer.authorize.net/api/reference/index.html#customer-profiles-update-customer-payment-profile}
 */
export const UPDATE_AUTHORIZE_CUSTOMER_PAYMENT_PROFILE = async ({
  customerProfileId,
  customerPaymentProfileId,
  creditCard,
  billTo,
}) => {
  return await axios.post(AUTHORIZE_CONFIG.url, {
    updateCustomerPaymentProfileRequest: {
      merchantAuthentication: {
        name: AUTHORIZE_CONFIG.name,
        transactionKey: AUTHORIZE_CONFIG.transactionKey,
      },
      customerProfileId,
      paymentProfile: {
        billTo,
        payment: { creditCard },
        // defaultPaymentProfile: false,
        customerPaymentProfileId,
      },
      // validationMode: 'liveMode', //or 'testMode'
    },
  })
}
