import SelectSearch from 'react-select-search'
import { Input } from '..'
import { ChevronRightIcon } from 'assets/vector'
import './Dropdown.scss'

/** @param {import('../Input').InputProps | import('react-select-search').SelectSearchProps} props */
const Dropdown = props => (
  <Input {...props} className={` dropdown ${props.className || ''}`} component={<DropdownComponent />} />
)

const DropdownComponent = ({ input, meta, rest }) => {
  const options = rest?.options?.map?.(option =>
    !!option?.name && !!option?.value
      ? option
      : ['string', 'number'].includes(typeof option) || option instanceof Number || option instanceof String
      ? { name: option + '', value: option }
      : !option?.name && !!option?.value
      ? { ...option, name: option?.value }
      : !!option?.name && !option?.value
      ? { ...option, value: option?.name }
      : option
  )

  return (
    <div className="dropdown-wrapper">
      <SelectSearch
        {...input}
        {...rest}
        options={options}
        closeOnSelect={rest?.closeOnSelect === undefined ? !input?.multiple : rest?.closeOnSelect}
        placeholder={
          rest?.placeholder !== undefined ? rest?.placeholder : input?.multiple ? 'Choose options...' : 'Choose one...'
        }
        printOptions={rest?.printOptions || 'on-focus'}
        className={`field-select ${rest?.search ? 'searchable' : ''} ${input?.className || rest?.className || ''}`}
      />
      <ChevronRightIcon id="dropdown-chevron" pointerEvents="none" />
    </div>
  )
}

export default Dropdown
