import { MainContext } from 'context'
import { useContext } from 'react'
import './CreatedBy.scss'
import { AvatarWithFallback } from 'components'

export const CreatedBy = ({ label = 'Order Created By' }) => {
  const { admin } = useContext(MainContext)
  const { actions, user } = admin

  const { data } = admin.api.user.getAvatar({
    variables: { userId: admin?.user?.userId },
    queryOptions: {
      enabled: !admin?.user?.avatarUrl,
      onSuccess: res => {
        const avatarUrl = URL.createObjectURL(res)
        actions.setUser({ ...user, avatarUrl })
      },
      retry: 0,
    },
    keys: ['get_user_created_by'],
  })

  const { data: userData } = admin.api.user.getUser({
    variables: { userId: admin?.user?.userId },
    queryOptions: { enabled: !admin?.user?.firstName, onSuccess: res => actions.setUser({ ...user, ...res }) },
  })

  const avatar = data || admin?.user?.avatarUrl
  const firstName = admin?.user?.firstName || userData?.firstName || '--'
  const lastName = admin?.user?.lastName || userData?.lastName || '--'

  return (
    <div className="review-order-created-by">
      <span className="review-order-created-by__label">{label}</span>
      <div className="review-order-created-by__content">
        <div className="review-order-created-by__content-img">
          <AvatarWithFallback imgSrc={avatar} avatar={avatar} lastName={lastName} showName={false} />
        </div>
        <span className="review-order-created-by__content-name">
          {firstName} {lastName}
        </span>
      </div>
    </div>
  )
}
