import { useContext, useCallback, useMemo } from 'react'
import { EditButton, Button, Search, AvatarWithFallback } from 'components/Global'
import { Pagination, Table, NewUserModal, EditUserModal } from 'components'
import { MainContext } from 'context'
import { useUserPagination } from 'utilities/hooks'
import { customQueryKeys } from 'utilities/constants'
import { ProviderModal } from 'components/Modals/ModalComponents/User/Providers'
import { useParams } from 'react-router-dom'

const columns = [
  { Header: 'FIRST NAME', accessor: 'firstName' },
  { Header: 'LAST NAME', accessor: 'lastName' },
  { Header: 'EMAIL', accessor: 'email' },
  { Header: 'TYPE', accessor: 'type' }, // user || provider
  { Header: 'EDIT', accessor: 'edit', unsortable: true },
]

export const Users = () => {
  const { id } = useParams()
  const { modal, admin } = useContext(MainContext)

  const { page, totalPages, data, isLoading, error, setPage, tableSort, search } = useUserPagination({
    apiFn: admin.api.getUsers.practiceUsersWithAvatar,
    variables: { practiceId: id || admin.practices.one.id },
    params: { rowsPerPage: 4 },
    keys: [customQueryKeys.PRACTICE_DETAILS],
  })

  const writeStatus = useCallback(active => (active ? 'Account Active' : 'Account Deactivated'), [])

  const tableData = useMemo(
    () =>
      data?.items?.map(user => {
        const { firstName, lastName, email, isActive, avatar, role } = user
        const status =
          role === 'doctor' && isActive
            ? 'Provider Account Active'
            : role === 'doctor' && !isActive
            ? 'Provider Not Activated'
            : role === 'staff' && isActive
            ? 'User Account Active'
            : role === 'staff' && !isActive
            ? 'Account Deactivated'
            : 'missing logic'

        return {
          firstName: <AvatarWithFallback avatar={avatar} firstName={firstName} />,
          lastName: <span>{lastName}</span>,
          email: <span className="user-email">{email}</span>,
          type: role === 'doctor' ? 'Provider' : 'User',
          status: <span className={status.toLowerCase()}>{status}</span>,
          isActive: <span className={writeStatus(isActive).toLowerCase()}>{writeStatus(isActive)}</span>,
          edit: (
            <EditButton
              modal={
                role === 'doctor' ? (
                  <ProviderModal edit={true} user={user} isAdmin={false} />
                ) : (
                  <EditUserModal user={user} isAdmin={false} />
                )
              }
            />
          ),
        }
      }),
    [data?.items, writeStatus]
  )

  return (
    <>
      <Table
        sort={tableSort}
        loading={isLoading}
        error={error}
        className="practice-users"
        data={{ columns, data: tableData || [] }}
      >
        <div className="practice-users-table-head">
          <div className="practice-users-table-head__name-search">
            <h4>Practice Users</h4>
            <Search onSubmit={search} className="user-search" />
          </div>
          <div className="practice-users-table-head__buttons">
            <div className="add-user-buttons">
              <Button onClick={() => modal?.actions?.open(<NewUserModal />)} className="add-user-btn">
                ADD NEW USER
              </Button>
              <Button
                onClick={() => modal?.actions?.open(<ProviderModal user={null} edit={false} />)}
                className="add-user-btn"
              >
                ADD PROVIDER
              </Button>
            </div>
          </div>
        </div>
      </Table>
      <Pagination page={page} totalPages={totalPages} selectPage={setPage} />
    </>
  )
}

/*
****THIS IS HOW TO PASS DATA TO NEW USER MODAL FOR CHECKBOXES ******
  physicians: {
    'Albus Dumbledore': ['Albus Dumbledore'],
    'Harry Potter': [], ------> WILL NO BE SELECTED
    'Ron Weasly': ['Ron Weasly'],
  }, /// THIS IS WHAT WORKS
  specialties: { Vascular: ['Vascular'], Podiatry: ['Podiatry'] },
*/
