import axios from 'axios'
import { AUTHORIZE_CONFIG } from './config'
import apiv2 from 'apiv2'

/**
 *
 * @param {Object} params
 * @param {String|number} params.amount
 * @param {String|number} params.orderId
 *
 * @param {Object} params.creditCard
 * @param {String} params.creditCard.cardNumber
 * @param {String} params.creditCard.expirationDate -- YYYY-MM
 * @param {String} params.creditCard.cardCode
 *
 * @param {Object} params.shippingAddress
 * @param {String} params.shippingAddress.firstName
 * @param {String} params.shippingAddress.lastName
 * @param {String} params.shippingAddress.address1
 * @param {String} params.shippingAddress.city
 * @param {String} params.shippingAddress.state
 * @param {String} params.shippingAddress.zip
 * @param {String} params.shippingAddress.email
 *
 * {@link https://developer.authorize.net/api/reference/index.html#payment-transactions-charge-a-credit-card}
 */
export const CREATE_TRANSACTION_REQUEST = async params => {
  try {
    /* 
    Step 1: Send transaction request to Authorize.net
      - Card Validation happens here
    */
    const res = await axios.post(AUTHORIZE_CONFIG.url, {
      createTransactionRequest: {
        merchantAuthentication: {
          name: AUTHORIZE_CONFIG.name,
          transactionKey: AUTHORIZE_CONFIG.transactionKey,
        },
        transactionRequest: {
          transactionType: 'authCaptureTransaction',
          amount: params.amount,
          payment: {
            creditCard: {
              cardNumber: params.creditCard.cardNumber,
              expirationDate: params.creditCard.expirationDate,
              cardCode: params.creditCard.cardCode,
            },
          },
          order: {
            description: params.orderId,
          },
        },
      },
    })

    //handle possible errors for Authorize.net
    if (res?.data?.messages?.resultCode === 'Error') {
      throw res.data
    }

    /* 
    Step 2: Send transaction response to MedBot API finalize payment
      - registers the payment with our system
    */
    const { status, data } = await MEDBOT_FINALIZE_PAYMENT({
      orderId: params.orderId,
      aNetResponse: { transactionResponse: res.data.transactionResponse },
      ...params.shippingAddress,
    })

    //MedBot API error
    if (status !== 200) {
      const error = { ...data, error: 'MedBot API error' }
      throw error
    }

    return Promise.resolve(res.data)
  } catch (err) {
    // if (err.error === 'MedBot API error') {
    //   return Promise.resolve(err)
    // }

    return Promise.reject(err)
  }
}

/**
 * @param {Object} params
 * @param {string\number} params.orderId
 *
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.address1
 * @param {string} params.address2
 * @param {string} params.city
 * @param {string} params.state
 * @param {string} params.zip
 * @param {string} params.email
 * @param {?string} params.phone
 *
 * @param {Object} params.aNetResponse
 * @param {Object} params.aNetResponse.transactionResponse {...response from successful transaction to Authorize.net}
 */
async function MEDBOT_FINALIZE_PAYMENT(params) {
  const url = '/patientorders/finalizeorder/v3/:orderId'.replace(':orderId', params.orderId)
  return await apiv2.post(url, params)
}
