import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertModal } from 'components/Modals/ModalComponents'
import { MainContext } from 'context'
import { useQueryClient } from 'react-query'

export const useHandleCancelOrder = data => {
  const qc = useQueryClient()
  let navigate = useNavigate()
  const { patient, modal } = useContext(MainContext)

  const onSuccess = useCallback(() => {
    qc.invalidateQueries(['patient_order'])
    modal?.actions?.open(
      <AlertModal
        onConfirm={() => {
          patient?.actions?.setOrder({ ...data, status: 'cancelled' })
          patient.actions.setBubbleProps({ message: 'Your order has been cancelled.' })
          modal.actions.close()
          navigate(`/order/${data?.id || data?.localId || data?.orderId}`, { replace: true })
        }}
        message="Your order has been cancelled."
        buttonText={'Okay'}
      />
    )
  }, [modal?.actions, patient?.actions, data, navigate, qc])

  const onError = useCallback(
    () =>
      modal?.actions?.open(
        <AlertModal message="There was a problem cancelling your order." support buttonText={'Okay'} />
      ),
    [modal?.actions]
  )

  const { mutate: cancelDraft, isLoading } = patient?.api?.cancelDraftOrder({
    variables: {
      practiceId: data?.practiceId,
      orderId: data?.id || data?.localId || data?.orderId,
      shopifyDraftOrderId: data?.shopifyDraftOrderId,
    },
    queryOptions: { onSuccess, onError },
  })

  return {
    cancelOrder: async () => {
      await modal?.actions?.close()
      cancelDraft()
    },
    isLoading,
  }
}

/*

  const { mutate: cancelOrder, isLoading } = patient?.api.cancelOrder({
    variables: {
      practiceId: data?.practiceId,
      orderId: data?.id || data?.localId || data?.orderId,
      shopifyOrderId: data?.shopifyOrderId,
    },
    queryOptions: { onSuccess, onError },
  })
*/
