import { Zip } from 'components/Global/Input/MaskedInputs'
import { States } from 'utilities/constants'

export const addressInputs = customAttr => ({
  manyLines: [
    { label: 'First Name', name: 'firstName', ...customAttr },
    { label: 'Last Name', name: 'lastName', ...customAttr },
    { label: 'Street', name: 'address1', ...customAttr },
    { label: 'City', name: 'city', ...customAttr },
  ],
  oneLine: [
    {
      label: 'State',
      name: 'state',
      component: 'select',
      defaultValue: 'FL',
      options: States.map(s => s.abbr).map((s, i) => (
        <option key={i} value={s}>
          {s}
        </option>
      )),
      ...customAttr,
    },
    { label: 'Zip Code', name: 'zip', maskedInput: Zip, ...customAttr },
  ],
})
