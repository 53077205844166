import { ForgotPassword, ModalContainer, SuccessModal, AlertModal } from 'components'
import { MainContext } from 'context'
import { useCallback, useContext, useEffect, useState } from 'react'

import './ResendInvite.scss'

export const ResendInvite = () => {
  const { admin, modal } = useContext(MainContext)
  const [init, setInit] = useState(false)

  const { mutate: resendInvite, isLoading } = admin.api.user.resendInvite({
    queryOptions: {
      onSuccess: () =>
        modal.actions.open(
          <SuccessModal
            onConfirm={() => {
              modal.actions.open(<ForgotPassword resendLoading={isLoading} resendInvite={resendInvite} />)
            }}
            title="Invite has been resent. Please check your email."
          />
        ),
      onError: () =>
        modal.actions.open(
          <AlertModal
            onConfirm={() => {
              modal.actions.open(<ForgotPassword resendLoading={isLoading} resendInvite={resendInvite} />)
            }}
            message="Could not resend invite. Please check your email is correct"
          />
        ),
    },
  })

  const openForgotPassword = useCallback(() => {
    !init && modal.actions.open(<ForgotPassword resendLoading={isLoading} resendInvite={resendInvite} />)
    setInit(true)
  }, [isLoading, resendInvite, modal.actions, init])

  useEffect(() => {
    openForgotPassword()
  }, [openForgotPassword])

  return (
    <main className="resend-invite-wrap">
      <ModalContainer doNotClose={true} isAdmin={true} />
    </main>
  )
}
