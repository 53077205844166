import { createContext, useContext } from 'react'
import { useImmerReducer } from 'use-immer'
import { CartReducer, cartInit, useCartActions } from './CartReducer'

export const CartContext = createContext(cartInit)

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(CartReducer, cartInit)
  const actions = useCartActions(dispatch)

  return <CartContext.Provider value={{ ...state, actions }}>{children}</CartContext.Provider>
}

export const useCart = () => useContext(CartContext)
