import { Field, Form, FormSpy } from 'react-final-form'
import './PriceEditor.scss'
import { useOutletContext } from 'react-router-dom'
import { Money } from 'components/Global/Input/MaskedInputs'
import { useLongPress } from 'utilities/hooks'

export const PriceEditor = () => {
  const [{ price, setPrice, wholeSalePrice }] = useOutletContext()
  const increase = useLongPress(() => {
    if (+price >= 9999) return
    setPrice(+price + 1)
  }, 200)
  const decrease = useLongPress(() => {
    if (+price <= 1) return
    setPrice(+price - 1)
  }, 200)

  return (
    <div className="price-editor">
      <Form
        initialValues={{ price }}
        onSubmit={() => {}}
        render={props => (
          <form onSubmit={props.handleSubmit}>
            {/* <Field name="price">{props => <input className="custom-price-input" {...props.input} />}</Field> */}
            <Field
              validate={value =>
                value > wholeSalePrice ? undefined : 'Price must be greater than the wholesale price'
              }
              name="price"
            >
              {props => (
                <>
                  <div className="custom-price">
                    <div className="custom-price-input__plus-minus">
                      <button {...increase}>
                        <span>+</span>
                      </button>
                      <button {...decrease}>
                        <span>-</span>
                      </button>
                    </div>
                    <Money
                      className="custom-price-input"
                      isAllowed={({ floatValue }) => floatValue <= 9999 && floatValue > 0}
                      {...props.input}
                    />
                    <span className="custom-price__error-msg">{props.meta.error}</span>
                  </div>
                </>
              )}
            </Field>
            <FormSpy
              subscription={{ dirtyFields: true, values: true }}
              onChange={({ values }) => {
                if (values.price === price) return
                if (isNaN(+values.price)) return
                setPrice(values.price)
              }}
            />
          </form>
        )}
      />
    </div>
  )
}
