import { useCallback, useEffect } from 'react'
import { useBubbleProps } from 'utilities/hooks'
import { StoreBackButton, StoreActionButtons } from '../Store/components'
import './Cart.scss'
import { Product } from '../Orders'
import { useCartControls } from './useCartControls'
import { PatientListWrap } from 'components'
import { useCart } from 'pages/RecommendationPortal/Cart/context'
import { useLocation } from 'react-router-dom'

export const Cart = () => {
  useBubbleProps({ isOpen: false })
  const { LineItems, actions } = useCart()
  const cartControls = useCartControls()
  const { state } = useLocation()

  useEffect(() => {
    actions.toggleCartIcon(true)
  }, [actions])

  const renderItems = useCallback(
    () =>
      LineItems?.length === 0 ? (
        <h4>There are no products in your cart</h4>
      ) : (
        LineItems.map((item, i) => (
          <Product pos={i + 1} key={i} product={item} productControls={cartControls} order={{ type: 'cart' }} />
        ))
      ),
    [LineItems, cartControls]
  )

  return (
    <div className="patient-cart">
      <StoreBackButton backTo={state?.invitationId ? `/store/${state.invitationId}/selection` : -1} />
      <PatientListWrap title="Your Cart">{renderItems()}</PatientListWrap>
      <StoreActionButtons />
    </div>
  )
}
