import { AlertModal } from 'components'
import { MainContext } from 'context'
import { useContext, useCallback } from 'react'
import { useNavWithState } from 'utilities/Patient'
import { usePaymentState } from '../context'

export const useTaxAndShipping = () => {
  const { navWithState, location } = useNavWithState()
  const { patient, modal } = useContext(MainContext)
  const { actions } = usePaymentState()

  const { mutate, isLoading } = patient.api.getTaxAndShipping({
    queryOptions: {
      onSuccess: res => {
        actions.setTotals(res)
        patient.actions.setOrder({ ...patient.order, ...res })
        //persist the state, state = {from: "cart" | "recommendation"}
        navWithState(location.pathname.replace('shipping', 'checkout'))
      },
      onError: () =>
        modal.actions.open(
          <AlertModal message="There was an error calculating order totals. Is your shipping address correct?" />
        ),
    },
  })

  const getTotals = useCallback(
    async (orderId, values) => {
      actions.setShippingAddress({
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        state: values?.state,
        city: values?.city,
        zip: values?.zip,
        address1: values?.address1,
        address2: '',
      })
      await mutate({
        body: { orderId, shipAddress: { ...values, address2: '' } },
      })
    },
    [mutate, actions]
  )

  return {
    getTotals,
    isLoading,
  }
}
