import { AlertModal, Button, Input } from 'components'
import { patientAuthPages } from '../PatientLoginV2'
import { Form } from 'react-final-form'
import { useCallback, useContext } from 'react'
import { MainContext } from 'context'
import { useNavigate } from 'react-router-dom'

export const Login = ({ setPage }) => {
  const nav = useNavigate()
  const { patient, modal } = useContext(MainContext)

  const onError = useCallback(
    () => modal.actions.open(<AlertModal message={'There was an error logging in.'} />),
    [modal]
  )

  const { mutate: login, isLoading } = patient.api.loginV2({
    queryOptions: {
      onSuccess: res => {
        if (!res.role.includes('patient')) onError()
        else {
          patient.actions.showNav(true)
          patient?.actions.setUser(res)
        }

        if (!Object.keys(patient.order).length) nav('/history')
      },
      onError,
    },
  })

  const handleLogin = useCallback(v => login({ body: { ...v } }), [login])

  return (
    <Form
      onSubmit={handleLogin}
      render={({ handleSubmit }) => (
        <form className="patient-login-form" onSubmit={handleSubmit}>
          <h4>Log in or create an account to view order.</h4>
          <Input label="Email" name="email" />
          <Input label="Password" name="password" />
          <button
            type="button"
            onClick={() => setPage(patientAuthPages.FORGOT_PASSWORD)}
            className="patient-login-form__forgot-password"
          >
            Forgot Password
          </button>
          <Button onClick={handleSubmit} type="submit" className={'patient-login-form__login-btn'}>
            {isLoading ? 'Loading...' : 'Login'}
          </Button>
          {/* <Button
            onClick={() => setPage(patientAuthPages.CREATE)}
            type="button"
            className={'patient-login-form__create-btn'}
          >
            Create New Account
          </Button> */}
        </form>
      )}
    />
  )
}
