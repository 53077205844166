import { useContext, useState, memo, useRef, useCallback } from 'react'
import { Form } from 'react-final-form'
import { Input, Button, CloseButton, Loader, ForDownload } from 'components/Global'
import { GreenCheckmarkIcon, RedCheckmarkIcon, YesNoSlider } from 'assets/vector'
import { svgButton } from 'utilities/utils'
import './EditPatient.scss'
import { MainContext } from 'context'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { SuccessModal } from '../Success'
import { AlertModal } from '../Alert'
import dayjs from 'dayjs'
import { usePrint } from 'utilities/hooks'

export const EditPatient = ({ closeModal, data }) => {
  const { admin, modal, patient } = useContext(MainContext)
  const { id: orderId } = useParams()
  const [prefs, setPrefs] = useState({ sms: data.smsNotifications, email: data.emailNotifications })
  const [status, setStatus] = useState({ ship: false, patient: false, requested: false })
  const qc = useQueryClient()
  const targetRef = useRef()

  const { mutate: toggleSMS } = patient.api.toggleSMSPreferences({ variables: { patientId: data.patientId } })

  const { mutate: toggleEmail } = patient.api.toggleEmailPreferences({ variables: { patientId: data.patientId } })

  const onSuccess = () => {
    toggleEmail({ booleanBody: prefs.email })
    toggleSMS({ booleanBody: prefs.sms })
    modal.actions.open(<SuccessModal message="Patient information updated!" />)
    qc.invalidateQueries(['orders', 'orderId', 'detail', orderId])
  }

  const { mutate: editShipping, isLoading } = admin.api.orders.editShipping({
    variables: { orderId },
    queryOptions: {
      onSuccess,
      onError: () => modal.actions.open(<AlertModal message="Could not updated patient details." />),
    },
  })
  const agreed = data?.hasAgreedToTerms

  const bundleProps = useCallback(
    () => ({ signedOnDate: data?.termsDate, first: data?.firstName, last: data?.lastName }),
    [data]
  )
  return (
    <>
      {agreed && <ForDownload {...bundleProps()} ref={targetRef} />}
      <div className="modal-content edit-patient-modal-wrap">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <CloseButton className="close-btn" onClick={closeModal} />
            <h2>Edit Patient Details</h2>
            <Form
              onSubmit={v => {
                setStatus({ ...status, requested: true })
                const { firstName, lastName } = data
                const { zip, email, cellNumber, address1, city, state } = v

                editShipping({
                  body: {
                    orderId: +orderId,
                    address1,
                    address2: '',
                    city,
                    state,
                    zip,
                    firstName,
                    lastName,
                    email,
                    phone: cellNumber,
                  },
                })
              }}
              initialValues={data}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="edit-patient-modal__row one">
                    <Input disabled={true} name="firstName" label="First Name" />
                    <Input disabled={true} name="lastName" label="Last Name" />
                  </div>
                  <div className="edit-patient-modal__row two">
                    <Input name="address1" label="Patient Address" />
                  </div>
                  <div className="edit-patient-modal__row three">
                    <Input name="city" label="City" />
                    <Input name="state" label="State" />
                    <Input name="zip" label="Zipcode" />
                  </div>
                  <div className="edit-patient-modal__row four">
                    <Input disabled={true} name="dob" label="Date of Birth" />
                    <Input name="email" label="Patient Email" />
                  </div>
                  <div className="edit-patient-modal__row five">
                    <Input name="cellNumber" label="Mobile Phone" />
                  </div>
                  <div className="edit-patient-modal__row six">
                    <div className="six--column">
                      <span>Receive Emails</span>
                      {svgButton(
                        <YesNoSlider
                          onClick={() => setPrefs({ ...prefs, email: !prefs.email })}
                          className={`slider--${prefs.email ? 'active' : 'unactive'}`}
                        />
                      )}
                    </div>
                    <div className="six--column">
                      <span>Receive SMS Text Messages</span>
                      {svgButton(
                        <YesNoSlider
                          onClick={() => setPrefs({ ...prefs, sms: !prefs.sms })}
                          className={`slider--${prefs.sms ? 'active' : 'unactive'}`}
                        />
                      )}
                    </div>
                    <AgreementInfo targetRef={targetRef} data={data} />
                  </div>
                  <div className="edit-patient-modal__row actions">
                    <Button onClick={closeModal} type="button">
                      Cancel
                    </Button>
                    <Button type="submit">Save</Button>
                  </div>
                </form>
              )}
            />
          </>
        )}
      </div>
    </>
  )
}

const filename = 'MedtrixUserAgreement'

const AgreementInfo = memo(({ targetRef, data }) => {
  const { PrintBtn } = usePrint({ printRef: targetRef, title: filename })
  // const { DownloadBtn } = useDownload({ targetRef, filename })
  const agreed = data?.hasAgreedToTerms

  return (
    <div className="user-agreement-status">
      <p>Patient has read, consent and agree to Medtrix User Agreement and Privacy Policy.</p>
      <span>
        {agreed ? <GreenCheckmarkIcon /> : <RedCheckmarkIcon />}
        <p>{agreed ? 'Yes' : 'No'}</p>
      </span>
      {agreed && (
        <>
          <div className="signed-on">
            <span>Signed on</span>
            <span>{data?.termsDate ? dayjs(data?.termsDate).format('MM/DD/YYYY') : '--'}</span>
          </div>
          <div className="print-and-download">
            <PrintBtn />
            {/* <DownloadBtn /> */}
          </div>
        </>
      )}
    </div>
  )
})
