import Lottie from 'react-lottie-player'
import './Approved.scss'
import { useNavigate } from 'react-router-dom'
import { confettiLottieData } from 'assets/lottie'
import { useContext } from 'react'
import { MainContext } from 'context'
import { useBubbleProps } from 'utilities/hooks'

export const Approved = () => {
  const { patient } = useContext(MainContext)
  const nav = useNavigate()

  useBubbleProps({
    isOpen: true,
    custom: { title: '', message: 'Your payment has been approved! You will have your products in no time!' },
  })

  return (
    <Lottie
      role="img"
      play
      loop={false}
      className="payment-approved-lottie"
      animationData={confettiLottieData}
      onComplete={() =>
        nav(`/order/${patient?.order?.id || patient?.order?.localId || patient?.order?.id}`, {
          replace: true,
          state: { recentlyApproved: true },
        })
      }
    />
  )
}
