import { useMemo } from 'react'

export const initPaymentState = {
  shippingAddress: {},
  isLoading: false,
  /* order data that cannot be overwritten by page refresh */
  totals: null, //this should be tax and shipping, if null, redirect back to shipping form
}

const actions = {
  SET_SHIPPING_ADDRESS: 'SET_SHIPPING_ADDRESS',
  SET_TOTALS: 'SET_TOTALS',
  SET_IS_LOADING: 'SET_IS_LOADING',
}

const paymentReducer = (draft, { type, payload }) => {
  switch (type) {
    case actions.SET_SHIPPING_ADDRESS:
      return void (draft.shippingAddress = payload)
    case actions.SET_TOTALS:
      return void (draft.totals = payload)
    case actions.SET_IS_LOADING:
      return void (draft.isLoading = payload)
    default:
      return draft
  }
}

export const usePaymentReducer = dispatch =>
  useMemo(
    () => ({
      setShippingAddress: address => dispatch({ type: actions.SET_SHIPPING_ADDRESS, payload: address }),
      setTotals: totals => dispatch({ type: actions.SET_TOTALS, payload: totals }),
      setIsLoading: isLoading => dispatch({ type: actions.SET_IS_LOADING, payload: isLoading }),
    }),

    [dispatch]
  )

export default paymentReducer
