import { useContext, useMemo, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Pagination, Table } from 'components'
import { Button, Search } from 'components/Global'
import { MainContext } from 'context'
import { removeNullValues } from 'utilities/utils'
import './PracticeList.scss'

const columns = [
  {
    Header: 'PRACTICE NAME',
    accessor: 'practiceName',
  },
  {
    Header: 'CITY',
    accessor: 'city',
  },
  {
    Header: 'STATE',
    accessor: 'state',
  },
  {
    Header: 'ADMIN NAME',
    accessor: 'adminName',
  },
  // {
  //   Header: 'ADMIN NUMBER',
  //   accessor: 'adminNumber',
  // },
  {
    Header: 'EMAIL',
    accessor: 'adminEmail',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
]

const PracticeList = () => {
  const { admin } = useContext(MainContext)
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ id: 'practiceName', desc: true })
  const [totalPages, setTotalPages] = useState(0)
  const [searchString, search] = useState('')
  const nav = useNavigate()

  const {
    data,
    isLoading: loading,
    error,
  } = admin.api.practice.all({
    params: removeNullValues({
      Filter: 'All',
      perPage: 10,
      sort: sortBy?.id || 'practiceName',
      sortDirection: sortBy?.desc ? 'DESC' : 'ASC',
      page,
      searchString,
    }),
    queryOptions: {
      onSuccess: data => setTotalPages(data?.totalPages),
      staleTime: 0,
    },
  })

  const writeStatus = useCallback(active => (active ? 'Account Active' : 'Account Deactivated'), [])

  const tableData = useMemo(
    () =>
      data?.practices?.map?.(
        ({ name: practiceName, city, id, physicianAdminContactEmail, physicianAdminContactName, state, active }) => ({
          practiceName: (
            <span>
              <p>{practiceName}</p>
            </span>
          ),
          city,
          state,
          adminEmail: (
            <div>
              <span>{physicianAdminContactEmail}</span>
            </div>
          ),
          adminName: (
            <div>
              <span>{physicianAdminContactName}</span>
            </div>
          ),
          status: <span className={writeStatus(active).toLowerCase()}>{writeStatus(active)}</span>,
          onClick: () => {
            nav(`./${id}`)
          },
        })
      ) || [],
    [data?.practices, nav, writeStatus]
  )

  return (
    <div className="practices">
      <h3>Practices</h3>
      <Table
        sort={setSortBy}
        loading={loading}
        error={error}
        className="practices"
        data={{
          columns,
          data: tableData,
        }}
      >
        <div className="practices__actions">
          <Search onSubmit={v => search(v?.query || '')} />
          <Button onClick={() => nav('new')} className="add-practice-btn">
            ADD NEW PRACTICE
          </Button>
        </div>
      </Table>
      <Pagination page={page} totalPages={totalPages} selectPage={setPage} />
    </div>
  )
}

export default PracticeList
