import { useContext, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContext } from 'context'
import { Loader } from 'components/Global'
import { BackButton } from 'components/Global'
import { useAdminStatus } from 'utilities/hooks'
import { Users, GeneralInfo } from './Sections'

import './PracticeDetails.scss'
import { customQueryKeys } from 'utilities/constants'

const PracticeDetails = () => {
  const { admin } = useContext(MainContext)
  const { id } = useParams()
  const { isAdmin } = useAdminStatus()
  const nav = useNavigate()
  const { isPracticeUser } = useAdminStatus()

  // if a practice user tries to view other practices, it will reroute back to their own practice
  useEffect(() => void (!isAdmin && id && nav('/admin/practice', { replace: true })), [isAdmin, nav, id])

  const { data, isLoading } = admin.api.practice.byId({
    variables: { id: id ? id : admin?.user?.practiceId },
    queryOptions: {
      onSuccess: data => {
        admin?.actions?.updatePractice(data)
      },
    },
    keys: [customQueryKeys.PRACTICE_DETAILS],
  })

  const parsed = useMemo(
    () =>
      data && {
        general: {
          address1: data.address1,
          avatar: data.avatarURL,
          city: data.city,
          email: data.email,
          fax: data.fax,
          groupNpi: data.groupNPI || '',
          name: data.name,
          phoneNumber: data.phoneNumber,
          physicianAdminContactEmail: data.physicianAdminContactEmail,
          physicianAdminContactName: data.physicianAdminContactName,
          physicianAdminContactPhone: data.physicianAdminContactPhone,
          ptan: data.ptan || '',
          salesRepEmail: data.salesRepEmail,
          salesRepFirst: data.salesRepFirst,
          state: data.state,
          website: data.website,
          zip: data.zip,
        },
        fees: {
          // eligibilityBenefitAuthFee: data.eligibilityBenefitAuthFee,
          // patientConciergeServiceFee: data.patientConciergeServiceFee,
          enrollmentFeeAmount: data.enrollmentFeeAmount,
          enrollmentFeeDate: data.enrollmentFeeDate,
          medbotFee: data.medBotPlacedFee,
          medMinningBackfillFee: data.medMinningBackfillFee,
          monthlyFee: data.monthlyFee,
          monthlyFeeStart: data.monthlyFeeStart,
          textEmailFee: data.textEmailFee,
          transactionFee: data.transactionFee,
          transactionFeePercentage: data.transactionFeePercentage,
        },
        billing: data.practiceBillings,
        payments: {},
        // wholesale: {},
        active: data.active,
      },
    [data]
  )

  return isLoading ? (
    <div className="practice-details--loading">
      <Loader />
    </div>
  ) : (
    <div className="practice-details">
      <div className="practice-details__header">
        <h3 className="practice-details__title">Practice Account Details</h3>
        {isAdmin && <BackButton />}
      </div>
      {isPracticeUser ? (
        <PracticeView id={admin?.user?.practiceId} parsed={parsed} />
      ) : (
        <AdminView id={id} parsed={parsed} />
      )}
    </div>
  )
}

const AdminView = ({ id, parsed }) => {
  return (
    <div className="practice-details__grid">
      <div className="practice-details__grid-col-1">
        <GeneralInfo id={id} data={parsed?.general} />
        {/* <AccountStatus /> */}
        <Users />
      </div>
      <div className="practice-details__grid-col-2">
        {/* <AccountStatus /> */}
        <div className="combined-fees-billing-card">
          {/* <FeesInfo admin id={id} data={parsed?.billing} />
          <BillingInfo admin id={id} data={parsed?.billing} /> */}
        </div>
        {/* <PaymentsInfo id={id} data={parsed?.payments} /> */}
        {/* <WholesalerInfo id={id} data={parsed?.wholesale} /> */}
      </div>
    </div>
  )
}

const PracticeView = ({ id, parsed }) => {
  return (
    <div className="practice-details__grid--practice-user">
      <div className="row-1">
        <GeneralInfo id={id} data={parsed?.general} />
        {/* <BillingInfo id={id} data={parsed?.billing} />
        <PaymentsInfo id={id} data={parsed?.payments} /> */}
        {/* <WholesalerInfo id={id} /> */}
      </div>
      <div className="row-3">
        <Users />
      </div>
    </div>
  )
}

export default PracticeDetails
