import { Form } from 'react-final-form'
import { InputGroup } from 'components/Global'

export const ConditionalForm = ({
  children,
  renderForm,
  onSubmit,
  initialValues,
  wrapperClass,
  group,
  admin,
  ...rest
}) =>
  renderForm ? (
    <InputGroup group={group || wrapperClass} wrap={wrapperClass} {...rest}>
      {children}
    </InputGroup>
  ) : (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={wrapperClass} {...rest}>
          {children}
        </form>
      )}
    </Form>
  )
