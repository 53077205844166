import { Button, CloseButton, Input, Loader } from 'components/Global'
import { ConditionalForm } from '../ConditionalForm'
import { Percent } from 'components/Global/Input/MaskedInputs'

export const Wholesale = ({ controls, closeModal, add, data, ...rest }) => {
  if (controls?.isLoading) return <Loader />

  return (
    <ConditionalForm
      onSubmit={(values, { getState }) => {
        const {
          modified: { markupPercentage },
        } = getState()
        markupPercentage && controls?.edit({ body: { ...data, markupPercentage: values?.markupPercentage } })
      }}
      wrapperClass={`wholesale`}
      initialValues={data}
      renderForm={add}
      {...rest}
    >
      <h4>{'Edit Global Product Markup'}</h4>
      {add ? null : <CloseButton onClick={() => closeModal()} />}
      <div className="input-row three">
        <Input
          required={!add}
          maskedInput={Percent}
          name="markupPercentage"
          label="Increase the MSRP of all products in the marketplace"
        />
      </div>
      <p className="markup-note">All product MSRP prices will be marked up by the percentage chosen above.</p>
      {add ? null : (
        <div className="actions">
          <Button
            onClick={e => {
              e.preventDefault()
              closeModal()
            }}
          >
            Cancel
          </Button>
          <Button>Save</Button>
        </div>
      )}
    </ConditionalForm>
  )
}
