import { productPickerPages } from '../utils'
import { useMemo } from 'react'

const keys = {
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_SELECT_PRODUCT: 'SET_SELECT_PRODUCT',
  REVIEW_PRODUCT: 'REVIEW_PRODUCT',
}

export const init = {
  products: [],
  selectProduct: {
    category: null,
    subCategory: null,
    product: null,
    subPage: productPickerPages.CATEGORIES,
  },
  practiceId: null,
}

export const ProductPickerReducer = (draft, { type, payload }) => {
  switch (type) {
    case keys.SET_SELECT_PRODUCT:
      return void (draft.selectProduct = { ...draft.selectProduct, ...payload })
    case keys.REVIEW_PRODUCT:
      return void (draft.selectProduct = {
        ...draft.selectProduct,
        product: payload,
        subPage: productPickerPages.PRODUCT_DETAILS,
      })
    default:
      return draft
  }
}

export const useProductPickerActions = dispatch =>
  useMemo(
    () => ({
      reviewProduct: product => dispatch({ type: keys.REVIEW_PRODUCT, payload: product }),
      setSelectProduct: payload => dispatch({ type: keys.SET_SELECT_PRODUCT, payload }),
    }),
    [dispatch]
  )
