import dayjs from 'dayjs'
import produce from 'immer'
import camelCase from 'lodash/camelCase'
import { Children, cloneElement, isValidElement } from 'react'

/** Cap notification count at specified number. If above that number it returns cap + "+"
 * @example capNotifsAt(134, 99) // returns '99+' */
export const capNotifsAt = (notifs, cap) => (notifs > cap ? `${cap}+` : `${notifs}`)

export const getBase64 = file =>
  new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
  })

export const changeName = (child, parentName) =>
  cloneElement(
    child,
    (child?.props?.label || child?.props?.name) && parentName
      ? { name: `${parentName}.${child?.props?.name || camelCase(child.props.label)}` }
      : {}
  )

export const recursiveChildMap = (children, fn, conditional = child => true) =>
  Children.map(children, child => {
    if (!isValidElement(child)) return child
    if (child.props.children && conditional(child))
      child = cloneElement(child, {
        children: recursiveChildMap(child.props.children, fn),
      })
    return fn(child)
  })

export const recursiveFieldGroup = (children, name) =>
  recursiveChildMap(
    children,
    child => changeName(child, name),
    child => !(child?.props?.label || child?.props?.name)
  )

export const formatNewPracticeCall = formValues => produce(formValues, draft => ({}))

/** @param {Date | string} dateString @param {dayjs.OptionType} form @param {string}*/
export const formatDate = (dateString, form, format) => {
  const date = form ? dayjs(dateString, form) : dayjs(dateString)
  return date.isValid() ? date.format(format) : null
}

export const removeNullValues = obj =>
  Object.keys(obj).reduce((a, c) => (obj[c] !== null ? { ...a, [c]: obj[c] } : a), {})

/* Might need to use this because react doesn't like useSvgButton inside of callback */
export const passwordValidation = [
  v => (!/(?=.*\d)/.test(v) ? 'Password must contain one number' : null),
  v => (!/(?=.*[A-Z])/.test(v) ? 'Password must have a capital letter' : null),
  v => (!/(?=.*?[#?!@$%^&*-])/.test(v) ? 'Password must have a special character' : null),
  v => (v?.length < 8 ? 'Password must be at least 8 characters long' : null),
]
/* Might need to use this because react didn't like useSvgButton inside of callback */
export const svgButton = svg => (
  <button
    {...svg.props}
    onClick={e => {
      e.preventDefault()
      svg?.props?.onClick?.(e)
    }}
    role={undefined}
    style={{
      border: 'none',
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      ...svg?.props?.style,
    }}
    {...{ 'aria-label': undefined }}
  >
    {cloneElement(svg, {
      className: svg.props.className ? `${svg.props.className}--img` : '',
      role: 'img',
      pointerEvents: 'none',
    })}
  </button>
)

export const capFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

export const capitalizeOrderStatus = text => {
  return text
    ?.split('-')
    ?.map(word => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
    .join(' ')
}

export const capitalize = string => string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())

export const numberWithCommas = x => x?.toString?.()?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ',')

export const convertHtmlToText = htmlString => htmlString?.replace(/<[^>]+>/g, '')
