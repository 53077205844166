import { MainContext } from 'context'
import { useContext, useEffect, useMemo, useState } from 'react'

const pagesize = 9

const useGetPatients = (sortListBy, blockedPatients, practiceId) => {
  const { admin } = useContext(MainContext)
  const { asc, keyword } = sortListBy
  const [page, setPage] = useState(1)

  useEffect(() => {
    !practiceId && setPage(1)
  }, [practiceId])

  useEffect(() => {
    setPage(1)
  }, [blockedPatients])

  const { data, ...rest } = admin.api.blockedPatients.getPatientList({
    variables: { practiceId },
    params: {
      page,
      pagesize,
      sortOrder: asc ? 'ASC' : 'DESC',
      searchText: keyword,
      blocked: !!blockedPatients,
    },
    queryOptions: { enabled: !!practiceId, staleTime: 0 },
    keys: ['patient_list', 'all_patients_list'],
  })

  const paginate = useMemo(() => ({ pagesize, page, setPage }), [setPage, page])

  return { paginate, data, ...rest }
}

export default useGetPatients
