import { BackButtonIcon } from 'assets/vector'
import { BlockedPatientList, Loader } from 'components'
import { useContext, useMemo, useState } from 'react'
import { useSvgButton } from 'utilities/hooks'
import useToggleBlockPatients from './useToggleBlockPatients'
import './Patients.scss'
import { PracticeComboBox } from '../Orders/OrdersTable/PracticeComboBox.js/index.js'
import { MainContext } from 'context'

export const Patients = () => {
  const { admin } = useContext(MainContext)
  const [practiceId, setPracticeId] = useState(admin?.user?.practiceId || null)
  const [blockedPatients, setBlockedPatients] = useState(true)
  const { handleSelectPatient, isLoading } = useToggleBlockPatients()

  const title = useMemo(() => (blockedPatients ? 'Blocked Patients' : 'Block A Patient'), [blockedPatients])

  return (
    <div className="patients-list">
      <div className="patients-list-header">
        <h4 className="patients-list-header__title">{title}</h4>
        {!admin?.user?.practiceId && (
          <PracticeComboBox className={!blockedPatients ? 'hide' : 'show'} selectPractice={id => setPracticeId(id)} />
        )}
        {useSvgButton(
          <BackButtonIcon
            className={`patients-list-header__back-button ${blockedPatients ? 'hide' : 'show'}`}
            onClick={() => setBlockedPatients(true)}
          />
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <BlockedPatientList
          practiceId={practiceId}
          blockedPatients={blockedPatients}
          onBlockAPatient={() => setBlockedPatients(false)}
          onSelectPatient={patient => {
            setBlockedPatients(true)
            handleSelectPatient(patient)
          }}
        />
      )}
    </div>
  )
}
