import { useSvgButton } from 'utilities/hooks'
import { useSendWellness } from '../context'
import { BackButtonIcon } from 'assets/vector'
import { useNavigate } from 'react-router-dom'
import { AlertModal, ReviewOrderInputs, SuccessModal } from 'components'
import { useContext } from 'react'
import { MainContext } from 'context'

export const SelectPatientForWellnessStore = () => {
  const { admin, modal } = useContext(MainContext)
  const nav = useNavigate()
  const isTextEnabled = useTextEnabled()
  const { provider, actions, patient } = useSendWellness()

  const { mutate, isLoading } = admin.api.wellnessStore.sendInviteToPatient({
    queryOptions: {
      onError: () => modal.actions.open(<AlertModal message="There was an error sending the Wellness Store invite" />),
      onSuccess: () =>
        modal.actions.open(
          <SuccessModal
            title="Successfully Sent Wellness Store Invite!"
            onClick={() => {
              actions.reset()
              modal.actions.close()
              nav(-1)
            }}
          />
        ),
    },
  })

  const handleSubmit = v => {
    const patientNames =
      v?.patientFirstName && v?.patientLastName ? { firstName: v.patientFirstName, lastName: v.patientLastName } : {}
    mutate({
      body: {
        ...patientNames,
        ...(v?.patientPhone ? { cellNumber: v.patientPhone } : {}),
        ...(v?.patientEmail ? { email: v.patientEmail } : {}),
        doctorId: provider.doctorId,
      },
    })
  }

  return (
    <>
      <div className="create-order-wrapper">
        <div className="create-order-header">
          <h4 className="create-order-header__title">Select Patient</h4>
          {useSvgButton(<BackButtonIcon onClick={() => nav(-1)} className="create-order-header__back-button" />)}
        </div>
        <ReviewOrderInputs
          isTextEnabled={isTextEnabled}
          handleSubmit={handleSubmit}
          provider={provider}
          onReplaceProvider={() => nav(-1)}
          isLoading={isLoading}
          isValid={!isLoading}
          handlePatientSearch={keyword => {
            actions.setPatientSearchKeyword(keyword.keyword)
            nav('search')
          }}
          confirmSendText="Send Wellness Store"
          initialValues={{
            patientFirstName: patient?.firstName,
            patientLastName: patient?.lastName,
            patientEmail: patient?.email,
            patientPhone: patient?.cellNumber,
          }}
        />
      </div>
    </>
  )
}

const useTextEnabled = () => {
  const { admin } = useContext(MainContext)
  const { provider } = useSendWellness()

  const { data, isLoading, error } = admin.api.createOrder.isTextEnabled({
    variables: { practiceId: provider?.practiceId },
  })

  return !error && !isLoading && data.isTextEnabled
}
