import { useOutletContext } from 'react-router-dom'
import './PriceSlider.scss'
import { PriceEditor } from './PriceEditor'
import { useMemo } from 'react'
import { useAdminStatus } from 'utilities/hooks'

export const PriceControl = () => {
  const [{ price, msrp, profitMargin, wholeSalePrice }] = useOutletContext()
  const { isAdmin } = useAdminStatus()

  return (
    <>
      <Header />
      <div className="edit-product-main__left--slider">
        {!!price && !isAdmin && (
          <>
            <span id="sub-header">Patient Retail Price</span>
            <PriceEditor />
          </>
        )}
        <div className="price-points">
          <Price label="Wholesale Price" price={wholeSalePrice} />
          <Price label="MSRP" price={msrp} />
          <Price label="Profit Margin" price={profitMargin} />
        </div>
      </div>
    </>
  )
}

const Header = () => {
  const [{ data: product, price }] = useOutletContext()

  const formattedPrice = useMemo(
    () => (+price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    [price]
  )

  return (
    <div className="edit-product-main__left--header">
      <div className="name-price">
        <span id="name">{product?.productName}</span>
        <span id="price">${formattedPrice}</span>
        <span id="label">PRODUCT PRICE</span>
      </div>
      {/* 
      //removed per user story 189
      <div className="global-markup">
        <span id="sub-header">Global Markup</span>
        <span id="percentage">{product?.practiceMarkedUpPercentage?.toFixed?.(2)}%</span>
      </div> */}
    </div>
  )
}

const Price = ({ label, price }) => {
  const formatted = useMemo(
    () => Math.abs(+price).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
    [price]
  )

  return (
    <div className="price-point">
      <span>{label}</span>
      <span>
        {price < 0 ? '-' : ''}${formatted}
      </span>
    </div>
  )
}
