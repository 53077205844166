import { useCallback, useContext } from 'react'
import { StaffTier2Routes } from './StaffTier2'
import { MainContext } from 'context'
import { Outlet } from 'react-router-dom'

export const DelegatedRoutes = () => {
  const { admin } = useContext(MainContext)

  const renderDelegated = useCallback(() => {
    //sometimes role gets set to string when stored in localStorage
    //converting it to array if it is a string
    const role = Array.isArray(admin?.user?.role) ? admin?.user?.role : [admin?.user?.role]

    if (role?.includes('stafftier2')) return <StaffTier2Routes />

    return <Outlet />
  }, [admin?.user?.role])

  return renderDelegated()
}
