import './Navigation.scss'
import { NavigationItem } from '.'
import { useCallback, useContext, useMemo } from 'react'
import { MainContext } from 'context'

const Navigation = ({ sidebarState, menu }) => {
  const { admin } = useContext(MainContext)
  const { user } = admin

  const mapItemsWithoutPermissions = useCallback(
    () => menu?.map?.(props => <NavigationItem key={props?.name} {...props} />),
    [menu]
  )

  //Provider permissions will look at providerType on the user
  const shouldMapWithProviderPermissions = useMemo(() => user?.role?.includes('doctor'), [user])

  //Staff permissions will look at user role: 'staff' | 'stafftier2'
  const shouldMapWithStaffPermissions = useMemo(
    () =>
      user?.role?.includes('staff') ||
      user?.role?.includes('stafftier2') ||
      user?.role === 'staff' ||
      user?.role === 'stafftier2',
    [user]
  )

  /*
  If the user is role: ['doctor'], then we need to see what the providerType is 'ARNP' | 'PA' | "Doctor"
  They have different permissions as far as what menu items they see
  */
  const mapWithPermissions = useCallback(
    () =>
      menu
        .filter(obj => {
          if (shouldMapWithProviderPermissions) {
            return user?.providerType ? obj.permission.has(user?.providerType?.toUpperCase()) : true
          }
          if (shouldMapWithStaffPermissions) {
            if (Array.isArray(user?.role)) {
              return user?.role?.some(role => obj.permission.has(role.toUpperCase()))
            }
            if (typeof user?.role === 'string') {
              return obj.permission.has(user?.role.toUpperCase())
            }
          }
          return true
        })
        .map(props => <NavigationItem key={props?.name} {...props} />),
    [user, menu, shouldMapWithProviderPermissions, shouldMapWithStaffPermissions]
  )

  return (
    <ul className={`navigation ${sidebarState}`}>
      {shouldMapWithProviderPermissions || shouldMapWithStaffPermissions
        ? mapWithPermissions()
        : mapItemsWithoutPermissions()}
    </ul>
  )
}

export default Navigation
