import './Hamburger.scss'
import { usePatientMenu } from '../context/PatientMenuContext'

export const Hamburger = () => {
  const { toggleMenu, isOpen } = usePatientMenu()

  return (
    <button onClick={toggleMenu} className={`ham ${isOpen ? 'show' : 'hide'}`}>
      <div className={`ham-inner ${isOpen ? 'open' : 'closed'}`}></div>
    </button>
  )
}
