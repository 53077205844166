import { forwardRef } from 'react'
import './Verbage.scss'

export const Verbage = forwardRef(({ className, blueText }, ref) => {
  return (
    <div ref={ref} className={`c28 c41 doc-content ${blueText ? 'blue-text' : ''} ${className ? className : ''}`}>
      <p className="c48">
        <span className="c10 agreement-title">MEDBOT MARKETPLACE </span>
      </p>
      <p className="c48 c40">
        <span className="c7"></span>
      </p>
      <p className="c42">
        <span className="c10 sub-title">PATIENT TERMS OF USE AND PRIVACY POLICY</span>
      </p>
      <p className="c43 c28">
        <span className="c7">Effective Date: December 4, 2022</span>
      </p>
      <p className="c43 c28">
        <span className="c7">Last Updated: April 28, 2023</span>
      </p>
      <p className="c4">
        <span className="c14">Welcome to the MedBot Marketplace (the &ldquo;</span>
        <span className="c3">MedBot Marketplace</span>
        <span className="c14">
          &rdquo;), a convenient, purchasing platform offered by Medtrix Technologies, LLC dba MedBot (&ldquo;
        </span>
        <span className="c3">MedBot</span>
        <span className="c7">
          &rdquo;) where you can easily purchase health-related retail products recommended for you - by your physician!
        </span>
      </p>
      <p className="c4">
        <span className="c14">
          Specifically, the MedBot Marketplace is a web-based application that enables your healthcare provider (&quot;
        </span>
        <span className="c3">Healthcare Provider</span>
        <span className="c14">&quot;) to send you, the patient (&ldquo;</span>
        <span className="c3">you</span>
        <span className="c14">
          &rdquo;) text messages and/or emails recommending products to improve your health and wellness (collectively,
          &ldquo;
        </span>
        <span className="c3">Messages</span>
        <span className="c14">
          &rdquo;) selected based on your health and wellness needs as indicated by your electronic health records
          maintained by your Healthcare Provider (&ldquo;
        </span>
        <span className="c3">EHR</span>
        <span className="c7">
          &rdquo;). Your Healthcare Provider may also send more generalized health and wellness product recommendations
          from time to time, but most recommendations will be directly related to your specific conditions, including
          pre-op and post-op product needs. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c10">In this Patient Terms of Use and Privacy Policy you will find:</span>
      </p>
      <ol className="c2 lst-kix_37rnbhaxy3av-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c7">
            Your Consent to this Patient Terms of Use and Privacy Policy, including HIPAA Consent to receive marketing
            communications.
          </span>
        </li>
      </ol>
      <p className="c4 c24">
        <span className="c7"></span>
      </p>
      <ol className="c2 lst-kix_5kkwv32bvbyf-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c7">SMS/Text Messaging and Email Terms and Conditions </span>
        </li>
      </ol>
      <p className="c4 c24">
        <span className="c7"></span>
      </p>
      <ol className="c2 lst-kix_pazoo0n77c4y-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c7">Third-Party Product Disclaimer, Product Ordering and Refund Policy</span>
        </li>
      </ol>
      <p className="c4 c24">
        <span className="c7"></span>
      </p>
      <ol className="c2 lst-kix_2um42zbhx49e-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c7">General Terms and Conditions</span>
        </li>
      </ol>
      <p className="c4 c24">
        <span className="c7"></span>
      </p>
      <ol className="c2 lst-kix_fknlso7b78yv-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c7">Patient Privacy Policy</span>
        </li>
      </ol>
      <p className="c4 c40 c39">
        <span className="c7"></span>
      </p>
      <ol className="c2 lst-kix_dtopahngpjv3-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c7">MedBot Contact Information</span>
        </li>
      </ol>
      <p className="c4 c24">
        <span className="c7"></span>
      </p>
      <p className="c4 c24">
        <span className="c7"></span>
      </p>
      <ol className="c2 lst-kix_xg3zf99jynn8-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c3 c31">YOUR CONSENT TO THIS PATIENT TERMS OF USE AND PRIVACY POLICY </span>
        </li>
      </ol>
      <p className="c4">
        <span className="c14 c36">PLEASE READ THESE PATIENT TERMS OF USE AND PRIVACY POLICY (THESE &ldquo;</span>
        <span className="c3 c36">TERMS</span>
        <span className="c10">
          &rdquo;) CAREFULLY. BY CONSENTING TO THESE TERMS, OR BY USING THE MEDBOT MARKETPLACE, YOU EXPRESSLY CONSENT TO
          ALL OF THE TERMS AND POLICIES SET FORTH HEREIN, WHICH INCLUDES YOUR CONSENT TO RECEIVE MESSAGES FROM YOUR
          HEALTHCARE PROVIDER, AT THE MOBILE PHONE NUMBER(S) AND/OR EMAIL ADDRESS(ES) YOU HAVE PROVIDED TO YOUR
          HEALTHCARE PROVIDER, AS MAINTAINED IN YOUR ELECTRONIC HEALTH RECORDS MAINTAINED BY YOUR HEALTHCARE PROVIDER.
          THESE MESSAGES MAY BE CONSIDERED MARKETING UNDER THE PRIVACY RULE OF THE HEALTH INSURANCE PORTABILITY AND
          ACCOUNTABILITY ACT OF 1996 (“HIPAA”) AND THUS MAY REQUIRE YOUR WRITTEN CONSENT. A WRITTEN HIPAA CONSENT IS
          INCLUDED IN THESE TERMS, BELOW. YOU MAY ALSO BE ASKED TO RE-ACCEPT OUR THEN-CURRENT TERMS EVERY TIME YOU MAKE
          ANY USE OF THE MEDBOT MARKETPLACE. REGARDLESS, YOU ARE BOUND BY THESE TERMS IF YOU PROCEED TO USE THE MEDBOT
          MARKETPLACE IN ANY WAY.
        </span>
      </p>
      <p className="c4 c40">
        <span className="c7"></span>
      </p>
      <ol className="c2 lst-kix_3kjb0i872ma2-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c31 c3">SMS/TEXT MESSAGING AND EMAIL TERMS AND CONDITIONS</span>
        </li>
      </ol>
      <p className="c4">
        <span className="c7">
          Consent to receive Messages from your Healthcare Provider is required to purchase products from your
          Healthcare Provider through the Medbot Marketplace. &nbsp;The MedBot Marketplace is a platform licensed by
          your Healthcare Provider to better serve you as a patient. &nbsp;MedBot is relying on the consent you have
          previously given the Healthcare Provider&rsquo;s practice to communicate with you, by text and/or email,
          including consent to send marketing materials, as required by the HIPAA Privacy Rule, but we are also taking
          extra measures to confirm your consent to receive Messages related to the sale of products by including these
          terms related to Messages and your right to opt out.
        </span>
      </p>
      <p className="c4">
        <span className="c10">HIPAA Consent</span>
      </p>
      <ol className="c2 lst-kix_3kjb0i872ma2-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c31 c3">HIPAA Marketing Authorization</span>
        </li>
      </ol>
      <p className="c4">
        <span className="c7">
          As part of your health care, your physician may choose to use Medtrix Technologies, LLC d/b/a MedBot
          (“MedBot”) as its software platform to communicate with you about products and services your physician
          believes will be beneficial for you. In partnership with your physician, MedBot respects your privacy and
          strives to comply with applicable privacy laws, like the Health Insurance Portability and Accountability Act
          (“HIPAA”). If your physician chooses to make certain communications to you through the MedBot platform, your
          written consent and authorization is required before they can be made. Such communications may include, but
          are not limited to, communications about products or services that encourage you to purchase or use the
          products or services, and/or communications that are sponsored or reimbursed by a third-party whose products
          or services are promoted in the communication. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c7">
          By signing this Authorization, you understand that you are giving MedBot permission, at your physician’s
          direction, to use or disclose your protected health information, including your name, mailing address, email
          address, and medical conditions, to send you marketing communications, as defined under HIPAA. You understand
          MedBot may receive direct or indirect payment or remuneration from a third-party for making these
          communications. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c7">
          You understand you have the right to revoke this authorization at any time by providing written notice of your
          revocation to: MedBot, 360 Central Avenue, Suite 300, St. Petersburg, FL 33701, or to: info@medbot.com. Unless
          otherwise revoked, this Authorization will terminate when you opt out or otherwise disenroll from MedBot’s
          services. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c7">
          You understand that revocation of this Authorization will not affect any action MedBot took in reliance on
          this Authorization before receiving your revocation. Further, revocation of this Authorization will not revoke
          any consent or authorization granted by you to your physician; it will only revoke the authorization of the
          physician to communicate with you through Medbot regarding products and services. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c7">
          You agree to hold harmless and release MedBot from all claims, demands and causes of action which you, your
          heirs, representatives, executors, administrators or any other persons acting on your behalf or on behalf of
          your estate have or may have by reason of this Authorization. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c7">
          If neither federal nor state privacy laws apply to the recipient of the information, you understand that the
          information disclosed pursuant to this authorization may be re- disclosed by the recipient and no longer
          protected by federal or state privacy law. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c7">
          You may inspect and receive a copy of the information to be used and disclosed pursuant to this Authorization
          form. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c7">
          This Authorization is voluntary and you understand that you may refuse to sign. You understand that your
          refusal to sign this Authorization will have no effect on the medical treatment you receive from your
          physician; Medbot is a communications platform only and not a medical provider. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c14">
          Once you have indicated your consent to these Terms, you will be invited to proceed to the MedBot Marketplace
          patient portal (&ldquo;
        </span>
        <span className="c3">MedBot Portal</span>
        <span className="c7">
          &rdquo;) where MedBot requires only your first and last name, zip code and date of birth to see Messages from
          your Healthcare Provider. &nbsp;You can opt out at any time (see opt out instructions below).
        </span>
      </p>
      <p className="c4">
        <span className="c10">Import Medical Safety Notice</span>
      </p>
      <p className="c4">
        <span className="c7">
          MESSAGES SENT TO YOUR EMAIL OR MOBILE PHONE ARE OUTBOUND TO YOU ONLY. &nbsp;YOUR HEALTHCARE PROVIDER WILL NOT
          RECEIVE MESSAGES IN REPLY TO THE MEDBOT MESSAGES YOU RECEIVE. &nbsp;THEREFORE, IF YOU HAVE A HEALTH EMERGENCY,
          CALL 911 OR IF YOU NEED TO COMMUICATE WITH YOUR HEALTHCARE PROVIDER, CONTACT YOUR HEALTHCARE PROVIDER&rsquo;S
          OFFICE.
        </span>
      </p>
      <p className="c4">
        <span className="c10">Message Frequency </span>
      </p>
      <p className="c4">
        <span className="c7">
          The number and frequency of Messages that you receive will vary depending upon your account activity and your
          communications with and recommendations made by your Healthcare Provider. However, MedBot will comply with all
          applicable laws related to frequency of text and email messaging and is mindful of making this a positive
          experience for you by not excessively communicating with you. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c10">How to Change How You Receive Messages</span>
      </p>
      <p className="c4">
        <span className="c7">
          If you prefer to only receive Messages via SMS text or only by email, you can change your settings in the
          MedBot Portal at any time. &nbsp;You cannot change your cell phone number or email address in the MedBot
          Portal; to make changes to your contact information, please contact your Healthcare Provider&rsquo;s office.
        </span>
      </p>
      <p className="c4">
        <span className="c10">MedBot General Communications </span>
      </p>
      <p className="c4">
        <span className="c7">
          In addition to sending Messages to you from your Healthcare Provider, MedBot reserves the right to use your
          contact information to communicate with you for the purposes of informing you of applicable offers, changes or
          additions to the MedBot Marketplace, and changes to these Terms. By using the MedBot Marketplace you are
          consenting to these communications.
        </span>
      </p>
      <p className="c4">
        <span className="c10">How to Opt-Out of MedBot Marketplace Messages </span>
      </p>
      <p className="c4">
        <span className="c7">
          You can opt-out at any time. &nbsp;To stop receiving Messages about product recommendations from your
          Healthcare Provider, text &ldquo;STOP&rdquo; in response to any text Message, click on the UNSUBSCRIBE link in
          response to any email Message, or access the MedBot Portal and select &ldquo;opt-out&rdquo;. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c7">
          IF YOU OPT OUT OF RECEIVING MESSAGES THROUGH THE MEDBOT MARKETPLACE, YOU HAVE NOT OPTED OUT OF RECEVING TEXT
          OR EMAIL COMMUNICATIONS FROM YOUR HEALTHCARE PROVIDER, SUCH AS FOR APPOINTMENT REMINDERS. &nbsp;To opt out of
          text or email communications from your healthcare provider, contact your healthcare provider directly.
        </span>
      </p>
      <p className="c4">
        <span className="c10">Eligibility to Receive Messages Through MedBot Marketplace </span>
      </p>
      <p className="c4">
        <span className="c7">
          To receive Messages, you must be a resident of the United States, eighteen (18) years of age or older, and
          authorized to incur any mobile message or data charges incurred by participating which are not charged by
          MedBot but may be incurred due to your cellular plan. &nbsp;MedBot reserves the right to require you to prove
          the foregoing to participate in the MedBot Marketplace.
        </span>
      </p>
      <p className="c4">
        <span className="c10">No User Fees</span>
      </p>
      <p className="c4">
        <span className="c7">
          You will not be charged user fees to receive Messages or using the MedBot Marketplace, however, standard
          message and data rates may apply to each Message sent or received, as provided in your mobile telephone
          service rate plan. &nbsp;Please contact your mobile telephone carrier for pricing plans and information.
        </span>
      </p>
      <p className="c4">
        <span className="c10">Supported Carriers; Interruption </span>
      </p>
      <p className="c4">
        <span className="c14">
          Access to the MedBot Marketplace should be available through your equipment or mobile device (each, a &ldquo;
        </span>
        <span className="c3">Device</span>
        <span className="c7">
          &rdquo;) when the Device is within the operating range of your wireless service provider. The MedBot
          Marketplace may not be available on all Devices or through all wireless carriers, and not all functionalities
          of the MedBot Marketplace are guaranteed to be available on all Devices, or through all wireless carriers.
          MedBot may, from time to time, in its discretion and without notice to you, limit the carriers that support
          the MedBot Marketplace. &nbsp;Certain other carriers may not support the MedBot Marketplace.
        </span>
      </p>
      <p className="c4">
        <span className="c7">
          Delivery of Messages to your mobile phone or email inbox may fail due to a variety of circumstances or
          conditions. &nbsp;The MedBot Marketplace is subject to transmission limitation or interruption. &nbsp;You
          understand and acknowledge that mobile network services and email functionality are outside of MedBot&rsquo;s
          control, and MedBot is not responsible or liable for issues arising therefrom, or the failure thereof,
          &nbsp;including, &nbsp;without limitation, &nbsp;technical, hardware, software, &nbsp;electronic,
          &nbsp;network, telephone &nbsp; or &nbsp;other &nbsp;communications malfunctions, &nbsp; errors &nbsp;or
          &nbsp;failures &nbsp;of &nbsp;any &nbsp;kind, &nbsp;errors &nbsp;in transmission, online traffic congestion,
          lost or unavailable &nbsp;network connections, &nbsp;telephone connections, wireless &nbsp;phone
          &nbsp;connections, &nbsp;website, &nbsp;internet, &nbsp;or internet service provider availability,
          &nbsp;unauthorized &nbsp;human intervention, traffic congestion, &nbsp;incomplete or inaccurate capture of
          entry information (regardless of cause) &nbsp;or failed, incomplete, &nbsp;garbled, jumbled &nbsp;or delayed
          transmissions which &nbsp;may limit or restrict your ability to receive Messages, including any injury or
          damage to your or any other person&#39;s Device relating to or resulting from using the MedBot Marketplace.
          &nbsp;If the MedBot Marketplace is not available within your intended location, you agree that your sole
          remedy is to cease using the MedBot Marketplace.
        </span>
      </p>
      <p className="c4">
        <span className="c10">Your Contact Information and Use of Personal Health Information </span>
      </p>
      <p className="c4">
        <span className="c14">
          You grant MedBot the right to use certain Personal Information for purposes of providing Messages and to
          enable you to access the MedBot Marketplace, including but not limited to your name, date of birth, telephone
          number, mailing/shipping address, and email address (&ldquo;
        </span>
        <span className="c3">Personal Information</span>
        <span className="c7">,&rdquo; as further defined in the MedBot Patient Privacy Policy below). &nbsp;</span>
      </p>
      <p className="c4">
        <span className="c7">
          You also grant your consent to the use of your personal health information stored in your EHR to identify
          products that your Healthcare Provider will recommend to you via Messages, and which you can purchase using
          the MedBot Marketplace.
        </span>
      </p>
      <p className="c4">
        <span className="c7">
          You represent that you are the account holder for the mobile telephone number(s) and/or email address(es) that
          you provide to MedBot or your Healthcare Provider or that you have the account holder&#39;s permission to
          enter the mobile telephone number(s) and/or email address(es) that you provide. You further represent you will
          not initiate Messages to the mobile phone of any other person or entity by providing contact information for
          persons other than yourself. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c14">
          You agree to maintain accurate, complete, and up-to date mobile and/or email information on the MedBot Portal
          if you intend to continue your use of the MedBot Marketplace. &nbsp;Any updates you make on the MedBot Portal
          will{' '}
        </span>
        <span className="c3">not</span>
        <span className="c7">&nbsp;update your records with your Healthcare Provider.</span>
      </p>
      <p className="c4 c40">
        <span className="c7"></span>
      </p>
      <ol className="c2 lst-kix_ylyvw59flfvz-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c31 c3">THIRD-PARTY PRODUCTS DISCLAIMER, PRODUCT ORDERING AND REFUND POLICY</span>
        </li>
      </ol>
      <p className="c4">
        <span className="c7">
          The products offered for sale on the MedBot Marketplace are manufactured and distributed by third-parties, not
          MedBot. &nbsp;Further, the products recommended to you are selected by your Healthcare Provider, not MedBot.
          &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c14">
          MEDBOT EXPRESSLY DISCLAIMS ALL WARRANTIES AND MAKES NO REPRESENTATIONS OR CLAIMS ABOUT THE PRODUCTS, THEIR
          SAFETY OR THEIR SUITABILITY FOR YOU. &nbsp;YOU AGREE THAT NEITHER MEDBOT NOR ANY OF ITS OWNERS, EMPLOYEES, OR
          CONTRACTORS (&ldquo;
        </span>
        <span className="c3">MEDBOT PARTIES</span>
        <span className="c7">
          &rdquo;) SHALL HAVE ANY LIABILITY OF ANY KIND PURSUANT TO ANY CAUSE OF ACTION UNDER APPLICABLE LAW, AND MEDBOT
          SHALL BE HELD HARMLESS FROM ANY AND ALL CLAIMS YOU MAY HAVE RELATED IN ANY WAY TO THE PRODUCTS, THEIR DEFECTS
          OR ANY PERSONAL INJURY TO YOU THAT MAY GIVE RISE TO A PRODUCT LIABILITY OR ANY OTHER CLAIM FOR DAMAGES. &nbsp;
        </span>
      </p>
      <p className="c4">
        <span className="c10">No Medical Advice Provided. </span>
      </p>
      <p className="c4">
        <span className="c7">
          No medical advice or counsel is given, or shall be deemed to have been given by MedBot or the MedBot Parties.
        </span>
      </p>
      <p className="c4">
        <span className="c10">Product Ordering</span>
      </p>
      <p className="c4">
        <span className="c7">
          All products are initially presented to you through the Messages. &nbsp;You will receive a MedBot Message with
          a notice of a product recommendation from your Healthcare Provider, and a link to the MedBot Marketplace where
          you can access the MedBot Portal, view the products recommended to you, place your order and pay for your
          products, using Stripe (a third-party payment processor). &nbsp;Your order is then processed by a MedBot
          logistics provider and delivered to your choice of shipping address.
        </span>
      </p>
      <p className="c4">
        <span className="c10">Return Policy</span>
      </p>
      <p className="c16">
        <span className="c14 c26">
          This policy may be updated at any time, but any updates will not apply to products purchased prior to the date
          the update is posted.
        </span>
      </p>
      <p className="c12">
        <span className="c7"></span>
      </p>
      <p className="c16">
        <span className="c14">
          MedBot offers a hassle-free return policy. &nbsp;If you are not satisfied with any purchase made from your
          Healthcare Provider using the MedBot Marketplace, simply email returns@medbot.com or access the Return Request
          link on the MedBot Patient Portal. &nbsp;
        </span>
        <span className="c10">
          To be eligible for a refund or exchange, you must request a refund or exchange within thirty (30) days of
          receiving the product. &nbsp; After thirty (30) days, no requests for refunds or exchanges will be processed.
          &nbsp;
        </span>
      </p>
      <p className="c12">
        <span className="c7"></span>
      </p>
      <p className="c16">
        <span className="c7">
          At MedBot&rsquo;s discretion, a refund may be issued without requiring you to return the product. You will be
          refunded for the cost of the returned product and tax associated with the purchase of that product to your
          original form of payment within 7-10 business days.
        </span>
      </p>
      <p className="c12">
        <span className="c7"></span>
      </p>
      <p className="c16">
        <span className="c7">
          If your order included more than one product, you may request a return for one or all of the products
          delivered to you.
        </span>
      </p>
      <p className="c12">
        <span className="c7"></span>
      </p>
      <p className="c16">
        <span className="c7">
          MedBot may inquire about the reason for your return, and we appreciate you answering those questions so that
          we can improve your experience.
        </span>
      </p>
      <p className="c12">
        <span className="c7"></span>
      </p>
      <p className="c16">
        <span className="c14">
          If you are asked to return the product, you will be sent a return mailing slip for free shipping to the
          appropriate location. &nbsp;If your product is returned,
        </span>
        <span className="c14">
          <a
            className="c35"
            href="https://www.google.com/url?q=http://chewy.com/&amp;sa=D&amp;source=editors&amp;ust=1670359161796111&amp;usg=AOvVaw2x1egwppMvg691TuAQ7IVE"
          >
            &nbsp;
          </a>
        </span>
        <span className="c3 c33">
          <a
            className="c35"
            href="https://www.google.com/url?q=http://chewy.com/&amp;sa=D&amp;source=editors&amp;ust=1670359161796385&amp;usg=AOvVaw1c8VUCPj8vSWhDSgfTF0UT"
          >
            MedBot
          </a>
        </span>
        <span className="c7">
          &nbsp;will not take title to the refunded item. &nbsp;MedBot is a marketplace that facilitates the sale of
          health and wellness products from healthcare providers to their patients. &nbsp;MedBot is not the
          manufacturer, distributor or logistics company.
        </span>
      </p>
      <p className="c12">
        <span className="c7"></span>
      </p>
      <p className="c16">
        <span className="c7">
          If the product you ordered was damaged upon delivery and you want an exchange, MedBot will process a refund
          and you can re-order the product.
        </span>
      </p>
      <p className="c12">
        <span className="c7"></span>
      </p>
      <ol className="c2 lst-kix_z4zfjdcwy8y5-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c31 c3">GENERAL TERMS AND CONDITIONS</span>
        </li>
      </ol>
      <p className="c4">
        <span className="c10">Changes to the Terms </span>
      </p>
      <p className="c4">
        <span className="c7">
          These Terms may only be amended, updated, or modified in writing by MedBot as set forth in these Terms. MedBot
          will use commercially reasonable efforts to provide notice to you of any material changes to these Terms.
          Within three (3) calendar days of posting changes to these Terms (or three (3) calendar days from the date of
          notice, if such notice is provided), they will be binding on you. If you do not agree with the changes, you
          should discontinue using MedBot Marketplace and terminate these Terms immediately as set forth herein by
          exercising your right to opt out of Messages, as provided hereinabove. If you continue using Messages after
          such three (3) calendar-day period, you will be deemed to have accepted the changes to these Terms.
        </span>
      </p>
      <p className="c4">
        <span className="c10">Termination and Suspension of Services</span>
      </p>
      <p className="c27">
        <span className="c14">
          MedBot may suspend or terminate your receipt of Messages and use of the MedBot Marketplace if MedBot believes
          you are in breach of these the Terms or other terms to which you consent through the MedBot Portal. &nbsp;Your
          receipt of Messages is also subject to termination in the event that your mobile telephone or email account
          service terminates or lapses. &nbsp;MedBot reserves the right to modify or discontinue, temporarily or
          permanently, all or any part of the Messages and the MedBot Marketplace, for any reason, with or without
          notice to you.
        </span>
        <span className="c10">&nbsp;</span>
      </p>
      <p className="c27">
        <span className="c10">Access to the MedBot Marketplace; License.</span>
      </p>
      <p className="c27">
        <span className="c7">
          Subject to your compliance with all Terms, MedBot hereby grants you a non-exclusive, non-transferable right to
          access and use the MedBot Marketplace solely for your personal use as a patient of a Healthcare Provider who
          has subscribed to use the MedBot Marketplace. Such use is limited to your use, using the user ID and password
          or other credentials you set up to access the MedBot Portal and purchase products.
        </span>
      </p>
      <p className="c4">
        <span className="c10">Proprietary Rights and Ownership</span>
      </p>
      <p className="c4">
        <span className="c14">
          MedBot and the MedBot Marketplace is owned, trademarked and copyrighted by MedBot, and is protected by United
          States copyright, trademark and other state, federal, and international intellectual property laws. Through
          use of the MedBot Marketplace, you may have access to information, communications, software, photos, text,
          video, graphics, music, sound, images, and other materials owned, trademarked or copyrighted by MedBot (&quot;
        </span>
        <span className="c3">Content</span>
        <span className="c7">
          &quot;). You do not have any ownership interest in the Content, the MedBot Marketplace, or improvements and
          modifications to the MedBot Marketplace. &nbsp;Nothing in this Agreement shall be construed to transfer any of
          MedBot&rsquo;s proprietary or intellectual property rights to you. Except to the extent permitted by law, you
          may not modify, distribute, prepare derivative works of, reverse engineer, reverse assemble, disassemble,
          decompile, or otherwise attempt to decipher any code in connection with the MedBot Marketplace and/or any
          other aspect of MedBot&rsquo;s technology.
        </span>
      </p>
      <p className="c4">
        <span className="c10">Idea Submissions</span>
      </p>
      <p className="c4">
        <span className="c14">
          We welcome specific comments regarding the MedBot Marketplace. If you send MedBot creative suggestions, ideas,
          notes, drawings, concepts or other information (collectively &ldquo;
        </span>
        <span className="c3">Information</span>
        <span className="c7">
          &rdquo;), the Information shall be deemed, and shall remain, the property of MedBot. None of the Information
          shall be subject to any obligation of confidentiality on the part of MedBot and MedBot shall not be liable or
          owe any compensation for any use or disclosure of the Information.
        </span>
      </p>
      <p className="c4">
        <span className="c10">Confidential Information &nbsp;</span>
      </p>
      <p className="c4">
        <span className="c7">
          You agree to keep confidential and safeguard, using commercially reasonable means, all MedBot information you
          may view or obtain though your use of the MedBot Marketplace.
        </span>
      </p>
      <p className="c46">
        <span className="c10">Limitations of Liability</span>
      </p>
      <p className="c27">
        <span className="c7">
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL MEDBOT OR THE MEDBOT PARTIES HAVE ANY
          LIABILITY ARISING FROM OR RELATED TO:
        </span>
      </p>
      <ol className="c2 lst-kix_f11ko28gwnxg-1 start" start="1">
        <li className="c38 li-bullet-0">
          <span className="c7">
            MESSAGING YOU THROUGH TEXT OR EMAIL, UNLESS YOU OPTED OUT DIRECTLY BY: (i) TEXTING &ldquo;STOP&rdquo; IN
            RESPONSE TO A TEXT MESSAGE, (ii) UNSCRIBING TO AN EMAIL MESSAGE, OR (iii) SELECTING THE OPT-OUT OPTION IN
            THE MEDBOT PORTAL, AND AFTER OPTING OUT BY ANY OF THE ABOVE DESCRIBED METHODS, MEDBOT FAILED TO UPDATE ITS
            RECORDS.
          </span>
        </li>
        <li className="c38 li-bullet-0">
          <span className="c7">
            PRODUCTS YOU PURCHASE FROM YOUR HEALTHCARE PROVIDER THROUGH THE MEDBOT MARKETPLACE, INCLUDING BUT NOT
            LIMITED TO PRODUCT LIABILITY, INJURIES RESULTING FROM USE OF THE PRODUCTS OR INABILITY TO USE THE PRODUCTS
            DUE TO FAILURE BY MEDBOT&rsquo;S THIRD PARTY LOGISTICS PROVIDER TO DELIVER THE PRODUCTS (OTHER THAN A REFUND
            TO WHICH YOU WOULD BE ENTITLED), FALSE CLAIMS OR MISLEADING LABELING, OR ANY OTHER CLAIM ARISING FROM OR
            ASSOCIATED WITH THE PRODUCTS MARKETED TO YOU OR PURCHASED BY YOU.
          </span>
        </li>
        <li className="c38 li-bullet-0">
          <span className="c7">
            DATA BREACHES OR CREDIT CARD PROCESSING ERRORS OF ANY KIND MADE BY MEDBOT&rsquo;S THIRD PARTY PAYMENT
            PROCESSOR (CURRENTLY STRIPE), OVER WHICH MEDBOT HAS NO CONTROL.
          </span>
        </li>
        <li className="c38 li-bullet-0">
          <span className="c7">
            DATA BREACHES OR PRODUCT DAMAGE OR DELAYED DELIVERIES OF ANY KIND MADE BY MEDBOT&rsquo;S THIRD PARTY
            LOGISTICS PROVIDER (ALTHOUGH PRODUCT REFUNDS ARE OFFERED BY MEDBOT)
          </span>
        </li>
        <li className="c38 li-bullet-0">
          <span className="c7">
            PERSONAL INJURY, PROPERTY DAMAGE, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, TECHNOLOGY DEVICE
            FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE
            DAMAGES.
          </span>
        </li>
        <li className="c38 li-bullet-0">
          <span className="c7">
            DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE GREATER OF (i) ONE (1) HUNDRED DOLLARS ($100.00)
            OR (ii) THE AGGREGATE DOLLAR VALUE OF THE PRODUCTS YOU PURCHASED USING THE MEDBOT MARKETPLACE IN THE SIX (6)
            MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM, NOT TO EXCEED $2,500.00.
          </span>
        </li>
      </ol>
      <p className="c27 c34">
        <span className="c7">
          THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING
          NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR MEDBOT WAS ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL
          OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
        </span>
      </p>
      <p className="c4">
        <span className="c10">Governing Law </span>
      </p>
      <p className="c4">
        <span className="c7">
          The Agreement is governed by and construed in accordance with the internal laws of the State of Florida
          without giving effect to any choice or conflict of law provision or rule that would require or permit the
          application of the laws of any jurisdiction other than those of the State of Florida. Any legal suit, action,
          or proceeding arising out of or related to these Terms or the MedBot Marketplace will be instituted
          exclusively in the federal courts of the United States or the courts of the State of Florida in each case
          located in the city of Tampa and County of Hillsborough, and you irrevocably submit to the exclusive
          jurisdiction of such courts in any such suit, action, or proceeding.
        </span>
      </p>
      <p className="c54">
        <span className="c3">MUTUAL WAIVER OF JURY TRIAL</span>
        <span className="c7">
          . BECAUSE DISPUTES ARISING IN CONNECTION WITH SOFTWARE PLATFORMS ARE MOST QUICKLY AND ECONOMICALLY RESOLVED BY
          AN EXPERIENCED EXPERT AND THE PARTIES WISH APPLICABLE STATE AND FEDERAL LAWS TO APPLY (RATHER THAN ARBITRATION
          RULES), THE PARTIES DESIRE THAT THEIR DISPUTES BE RESOLVED BY A JUDGE APPLYING SUCH APPLICABLE LAWS.
          THEREFORE, TO ACHIEVE THE BEST COMBINATION OF THE BENEFITS OF THE JUDICIAL SYSTEM AND OF ARBITRATION, EACH
          PARTY TO THIS AGREEMENT HEREBY WAIVES ALL RIGHTS TO TRIAL BY JURY IN ANY ACTION, SUIT, OR PROCEEDING BROUGHT
          TO RESOLVE ANY DISPUTE BETWEEN OR AMONG ANY OF THE PARTIES HERETO, WHETHER ARISING IN CONTRACT, TORT, OR
          OTHERWISE, ARISING OUT OF, CONNECTED WITH, RELATED OR INCIDENTAL TO THIS AGREEMENT, THE TRANSACTIONS
          CONTEMPLATED HEREBY AND/OR THE RELATIONSHIP ESTABLISHED AMONG THE PARTIES HEREUNDER.
        </span>
      </p>
      <p className="c50 c40">
        <span className="c7"></span>
      </p>
      <ol className="c2 lst-kix_1nqlbonbczaq-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c31 c3 c28">MEDBOT PATIENT PRIVACY POLICY</span>
        </li>
      </ol>
      <p className="c28 c43">
        <span className="c7">Effective Date: December 4, 2022</span>
      </p>
      <p className="c43 c28">
        <span className="c7">Last Updated: December 4, 2022</span>
      </p>
      <p className="c13">
        <span className="c14">This Privacy Policy (the &ldquo;</span>
        <span className="c3">Policy</span>
        <span className="c14">
          &rdquo;) applies to your use of the MedBot Marketplace (the &ldquo;MedBot Marketplace&rdquo; or the &ldquo;
        </span>
        <span className="c3">Services</span>
        <span className="c14">&rdquo;) provided by Medtrix Technologies, LLC d/b/a MedBot (&ldquo;</span>
        <span className="c3">MedBot</span>
        <span className="c14">,&rdquo; &ldquo;</span>
        <span className="c3">we</span>
        <span className="c14">,&rdquo; &ldquo;</span>
        <span className="c3">us</span>
        <span className="c14">&rdquo; or &ldquo;</span>
        <span className="c3">our</span>
        <span className="c7">&rdquo;). &nbsp;</span>
      </p>
      <p className="c4">
        <span className="c14">
          The MedBot Marketplace is a web-based application that enables your healthcare provider (&quot;
        </span>
        <span className="c3">Healthcare Provider</span>
        <span className="c14">&quot;) to send you, the patient (&ldquo;</span>
        <span className="c3">you</span>
        <span className="c14">
          &rdquo;) text messages and/or emails recommending products to improve your health and wellness (collectively,
          &ldquo;
        </span>
        <span className="c3">Messages</span>
        <span className="c14">
          &rdquo;) selected based on your health and wellness needs as indicated by your electronic health records
          maintained by your Healthcare Provider (&ldquo;
        </span>
        <span className="c3">EHR</span>
        <span className="c7">
          &rdquo;). Your Healthcare Provider may also send more generalized health and wellness product recommendations
          from time to time, but most recommendations will be directly related to your specific conditions, including
          pre-op and post-op product needs. &nbsp;
        </span>
      </p>
      <p className="c13">
        <span className="c14">
          We respect your privacy and are committed to protecting any information that identifies, relates to,
          describes, references, or may be reasonably associated with or linked to you or your household, such as your
          name, date of birth, mailing address, email address and other similar information that you may provide us or
          we may receive through your use the Services, including any EHR Data (as defined below) retrieved from your
          EHR (collectively, &ldquo;
        </span>
        <span className="c3">Personal Information</span>
        <span className="c7">&rdquo;). </span>
      </p>
      <p className="c13">
        <span className="c7">
          By using the Services, you acknowledge you have read and understood the terms and conditions of this Privacy
          Policy. If you do not agree to the terms and conditions of this Privacy Policy, please do not use the
          Services.
        </span>
      </p>
      <ol className="c2 lst-kix_xqzy4esqw5ob-0 start" start="1">
        <li className="c9 li-bullet-0">
          <span className="c15 c3">Purpose of the Privacy Policy</span>
        </li>
      </ol>
      <p className="c21">
        <span className="c14">
          The purpose of this Policy is to describe the collection, use and disclosure of Personal Information that is
          collected through the Services.
        </span>
        <span className="c10">&nbsp;</span>
      </p>
      <ol className="c2 lst-kix_39stfpx3tont-0 start" start="1">
        <li className="c30 li-bullet-0">
          <span className="c15 c3">Collection of Information</span>
        </li>
      </ol>
      <p className="c13">
        <span className="c7">
          During your use of the Services, the types of Personal Information we may collect includes:
        </span>
      </p>
      <ul className="c2 lst-kix_167avz7l9ukf-0 start">
        <li className="c9 li-bullet-0">
          <span className="c0">Your name</span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">Your date of birth</span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">Your address / zip code (for billing and for shipping)</span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">Your mobile phone number</span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">Your email address</span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">Your product order history and related information</span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">Other Personal Information voluntarily disclosed by you</span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">Unique device identifier (solely by way of our cloud computing provider, Azure)</span>
        </li>
      </ul>
      <p className="c13">
        <span className="c14">
          In addition to the information collected above, your Healthcare Provider may either directly enter the
          following data into the MedBot Marketplace platform, or MedBot&rsquo;s backend API may receive the following
          Personal Information from your EHR (&ldquo;
        </span>
        <span className="c3">EHR Data</span>
        <span className="c7">&rdquo;):</span>
      </p>
      <ul className="c2 lst-kix_wm0xkrakcxxc-0 start">
        <li className="c9 li-bullet-0">
          <span className="c0">
            Patient demographics (your name, address, date of birth, email, telephone and similar data points)
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">
            Diagnostic codes (insurance billing codes associated with various medical procedures)
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">
            Billing/payment information (MedBot does not collect your payment information but such information may be
            funneled to a third-party payment processor. See Section 3 for details)
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">
            Healthcare Provider information (information about your physician and/or physician&rsquo;s practice)
          </span>
        </li>
      </ul>
      <p className="c17">
        <span className="c7">
          We will collect, or have collected, the following Personal Information from users of our Services in the last
          12 months:
        </span>
      </p>
      {/* <a id="t.82a515998fe820ca71bc6a7a481596ef49959ee9"></a> */}
      {/* <a id="t.0"></a> */}
      <table className="c49">
        <tbody>
          <tr className="c44">
            <td className="c28 c29" colSpan="1" rowSpan="1">
              <p className="c20">
                <span className="c10">Category of Personal Information</span>
              </p>
            </td>
            <td className="c28 c52" colSpan="1" rowSpan="1">
              <p className="c20">
                <span className="c10">Source(s) of Personal Information</span>
              </p>
            </td>
            <td className="c28 c32" colSpan="1" rowSpan="1">
              <p className="c20">
                <span className="c10">Business Purpose(s) for Collection / Disclosure</span>
              </p>
            </td>
            <td className="c28 c37" colSpan="1" rowSpan="1">
              <p className="c20">
                <span className="c10">Third Parties Personal Information is Disclosed To</span>
              </p>
            </td>
          </tr>
          <tr className="c53">
            <td className="c18" colSpan="1" rowSpan="1">
              <p className="c20">
                <span className="c10">Identifiers</span>
              </p>
              <p className="c20">
                <span className="c7">
                  (such as your real name or alias, postal address, a unique personal identifier, an online identifier
                  (such as your Internet Protocol or &ldquo;IP&rdquo; address), email address, account name, or other
                  similar identifiers)
                </span>
              </p>
            </td>
            <td className="c5" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c20">
                <span className="c7">Directly from you</span>
              </p>
              <p className="c20">
                <span className="c7">Indirectly from you (e.g., from using our Services) </span>
              </p>
              <p className="c20">
                <span className="c7">From a third party, such as your Healthcare Provider</span>
              </p>
            </td>
            <td className="c8" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c20">
                <span className="c7">
                  To fulfill or meet the reason the Personal Information was provided, e.g., to promote or provide the
                  Services, respond to an inquiry from you, or process or fulfill your order for a product offered by or
                  through the Services
                </span>
              </p>
              <p className="c12">
                <span className="c7"></span>
              </p>
            </td>
            <td className="c23" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c20">
                <span className="c7">
                  Information required to ship products to you (such as your mailing address and product information) is
                  provided to our logistics provider(s)
                </span>
              </p>
              <p className="c20">
                <span className="c7">
                  Your IP address may be collected by Microsoft Azure (&ldquo;Azure&rdquo;) through integrations built
                  into the Services, solely for purposes of providing cloud services used to support the Services,
                  however this data will not otherwise be disclosed by us or Azure.
                </span>
              </p>
              <p className="c12">
                <span className="c7"></span>
              </p>
            </td>
          </tr>
          <tr className="c47">
            <td className="c18" colSpan="1" rowSpan="1">
              <p className="c20">
                <span className="c10">Private, Non-Public Personal Information</span>
              </p>
              <p className="c20">
                <span className="c7">(such as your name, demographic information, and other EHR Data)</span>
              </p>
            </td>
            <td className="c5" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c4">
                <span className="c7">Directly from you</span>
              </p>
              <p className="c4">
                <span className="c7">From a third party, such as your Healthcare Provider</span>
              </p>
            </td>
            <td className="c8" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c20">
                <span className="c7">
                  To fulfill or meet the reason the Personal Information was provided, e.g., to promote or provide the
                  Services, respond to an inquiry from you, or process or fulfill your order for a product offered by or
                  through the Services
                </span>
              </p>
            </td>
            <td className="c23" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c20">
                <span className="c7">
                  None, other than certain select Personal Information provided to our logistics provider(s) for
                  purposes of shipping products to you
                </span>
              </p>
            </td>
          </tr>
          <tr className="c22">
            <td className="c18" colSpan="1" rowSpan="1">
              <p className="c20">
                <span className="c10">Protected classNameification Information</span>
              </p>
              <p className="c20">
                <span className="c7">(such as age, sex, medical condition)</span>
              </p>
            </td>
            <td className="c5" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c20">
                <span className="c7">Directly from you</span>
              </p>
              <p className="c20">
                <span className="c7">From a third party, such as your Healthcare Provider</span>
              </p>
            </td>
            <td className="c8" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c20">
                <span className="c7">
                  To fulfill or meet the reason the Personal Information was provided, e.g., to promote or provide the
                  Services, respond to an inquiry from you, or process or fulfill your order for a product offered by or
                  through the Services
                </span>
              </p>
            </td>
            <td className="c23" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c20">
                <span className="c7">
                  None; provided, however that our logistics provider(s) may be able to infer a medical condition based
                  on products that you order
                </span>
              </p>
            </td>
          </tr>
          <tr className="c19">
            <td className="c18" colSpan="1" rowSpan="1">
              <p className="c20">
                <span className="c10">Commercial Information</span>
              </p>
              <p className="c20">
                <span className="c7">
                  (such as records of products or services purchased, obtained, considered, returned, or cancelled, or
                  other purchasing or consuming histories or tendencies)
                </span>
              </p>
            </td>
            <td className="c5" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c4">
                <span className="c7">Indirectly from you (e.g., from your use of our Services)</span>
              </p>
            </td>
            <td className="c8" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c4">
                <span className="c7">
                  Only for purposes of compiling Deidentified Analytics (as defined in Section 4)
                </span>
              </p>
            </td>
            <td className="c23" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c7"></span>
              </p>
              <p className="c4">
                <span className="c7">
                  None; such Commercial Information is only shared to third parties in an aggregated, deidentified form
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="c21 c40">
        <span className="c7"></span>
      </p>
      <p className="c21">
        <span className="c7">
          We retain and store your Personal Information only for as long as we have a legitimate business purpose to do
          so and in accordance with our data retention policies or as required by applicable law, and then we will
          delete it.
        </span>
      </p>
      <ol className="c2 lst-kix_mangh5agezyi-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c15 c3">Use of Information</span>
        </li>
      </ol>
      <p className="c17">
        <span className="c7">We use the Personal Information we collect from you for the following purposes:</span>
      </p>
      <ul className="c2 lst-kix_umry1lpsac5d-0 start">
        <li className="c9 li-bullet-0">
          <span className="c0">To provide you with the Services.</span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">
            To send you marketing communications (i.e., MedBots from your Healthcare Provider(s)).
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">To respond to your requests and inquiries.</span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">To improve user experiences by making our Services easier to use and navigate.</span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c0">For other legitimate business purposes.</span>
        </li>
      </ul>
      <p className="c13">
        <span className="c14 c36">Payment Processing</span>
        <span className="c7">
          . Payment information such as your credit card, debit card or financial account number maybe submitted by you
          or pulled from your EHR Data through a secure interface which links directly to a third-party payment
          processor (e.g., PayPal, Stripe), and such information is not accessible to or collected by MedBot. All data
          collection by our payment processors is subject to such payment processor&rsquo;s respective privacy policy.
          Please read the privacy policy for our current payment processors, Stripe and PayPal, below:
        </span>
      </p>
      <ul className="c2 lst-kix_irp5u4uwbwzz-0 start">
        <li className="c9 li-bullet-0">
          <span className="c6">Stripe</span>
          <span className="c28 c14">:</span>
          <span className="c28 c14">
            <a
              className="c35"
              href="https://www.google.com/url?q=https://stripe.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1670359161817641&amp;usg=AOvVaw3YdBPtMFvr92CzE0eV4WHf"
            >
              &nbsp;
            </a>
          </span>
          <span className="c3 c28 c33">
            <a
              className="c35"
              href="https://www.google.com/url?q=https://stripe.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1670359161817888&amp;usg=AOvVaw2JqGNuo-wCaCnn7rJ_jBxN"
            >
              https://stripe.com/privacy
            </a>
          </span>
          <span className="c0">&nbsp;</span>
        </li>
        <li className="c51 li-bullet-0">
          <span className="c6">PayPal</span>
          <span className="c28 c14">: </span>
          <span className="c15 c3">https://www.paypal.com/us/webapps/mpp/ua/privacy-full</span>
        </li>
      </ul>
      <ol className="c2 lst-kix_bhbxutjnr8kt-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c15 c3">Sharing and Disclosure of Information</span>
        </li>
      </ol>
      <p className="c17">
        <span className="c7">
          We do not disclose your Personal Information, except as provided in this Privacy Policy and for the legitimate
          business purposes set forth herein. If we disclose Personal Information for a business purpose, we will ensure
          that any third party receiving Personal Information is obligated to keep such Personal Information
          confidential and not use or it for any purpose except for the business purpose for which it was disclosed.
        </span>
      </p>
      <p className="c17">
        <span className="c7">
          We may share or disclose information to the following categories of third parties and for the reasons set
          forth below:
        </span>
      </p>
      <ul className="c2 lst-kix_dznlhx1w1akq-0 start">
        <li className="c9 li-bullet-0">
          <span className="c3 c28">MedBot Employees and Contractors</span>
          <span className="c0">
            . We may disclose your Personal Information to any employee or independent contractor of MedBot who requires
            such Personal Information in order to fulfill your requests using the Services. We will ensure that any
            Personal Information is provided to such personnel only to the extent necessary and that such personnel are
            required to treat it as confidential information.
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c3 c28">Logistics Providers</span>
          <span className="c0">
            . We may disclose Personal Information, such as your shipping address, to our logistics provider for
            purposes of shipping you any products purchased using the Services.
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c3 c28">Business Transaction</span>
          <span className="c0">
            . We may disclose your Personal Information in the course of any reorganization process including, but not
            limited to, a merger, acquisition, or sale of all or substantially all of our assets, or upon a change in
            control, subject to confidentiality requirements.
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c3 c28">As Required by Law</span>
          <span className="c0">
            . We may disclose your Personal Information to law enforcement, government agencies, and other related third
            parties, in order to comply with the law, enforce our policies, or protect our or others&rsquo; rights,
            property or safety.
          </span>
        </li>
      </ul>
      <p className="c17">
        <span className="c14 c36">Deidentified Analytics</span>
        <span className="c14">
          . We do not sell your Personal Information, except for aggregated, de-identified information and data (i.e.,
          data and information which does not identify you or your household, hereinafter &ldquo;
        </span>
        <span className="c3">Deidentified Information</span>
        <span className="c14">
          &rdquo;). Such Deidentified Information is only provided to the following types of third parties for purposes
          of providing insight into product performance and related metrics for products sold using the Services
          (&ldquo;
        </span>
        <span className="c3">Deidentified Analytics</span>
        <span className="c7">&rdquo;):</span>
      </p>
      <ul className="c2 lst-kix_pp2akeiagsgr-0 start">
        <li className="c9 li-bullet-0">
          <span className="c3 c28">Healthcare Providers</span>
          <span className="c0">
            . We may provide participating healthcare providers with Deidentified Analytics in the form of information
            about products marketed and purchased using the Services and associated aggregated, deidentified data;
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c3 c28">Product Providers</span>
          <span className="c0">
            . We may provide certain third parties that provide, manufacture, and/or sell or resell products on or
            through the Services with Deidentified Analytics in the form of information about products marketed and
            purchased using the Services and associated aggregated, deidentified data.
          </span>
        </li>
      </ul>
      <p className="c13">
        <span className="c10">
          SUBJECT TO APPLICABLE STATE AND FEDERAL LAW, INCLUDING BUT NOT LIMITED TO OUR OBLIGATIONS UNDER THE HEALTH
          INSURANCE PORTABILITY AND ACCOUNTABILITY ACT OF 1996 (&ldquo;HIPAA&rdquo;), AS AMENDED, AND THE HEALTH
          INFORMATION TECHNOLOGY FOR ECONOMIC AND CLINICAL HEALTH ACT OF 2009, AS AMENDED, WE MAY LICENSE, SELL, OR
          OTHERWISE SHARE DEIDENTIFIED INFORMATION WITH OUR SUBSIDIARIES, AFFILIATES, PARTNERS, CUSTOMERS, INVESTORS,
          AND CONTRACTORS FOR ANY PURPOSE. YOU AGREE AND ACKNOWLEDGE THAT MEDBOT IS THE SOLE AND EXCLUSIVE OWNER OF ANY
          DEIDENTIFIED INFORMATION (INCLUDING THE DEIDENTIFIED ANALYTICS) CREATED BY MEDBOT AND THAT YOU HAVE NO
          OWNERSHIP OR OTHER INTELLECTUAL PROPERTY RIGHTS IN OR TO SUCH DEIDENTIFIED INFORMATION.
        </span>
      </p>
      <ol className="c2 lst-kix_vkmak7z5kbsh-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c15 c3">How We Use Cookies and Similar Technologies</span>
        </li>
      </ol>
      <p className="c17">
        <span className="c7">
          The MedBot Marketplace does not use cookies, however, our service providers may use cookies, web beacons, and
          other similar technologies in connection with the Services. Our third-party payment processors may use cookies
          or other tracking technologies for functionality and security purposes.
        </span>
      </p>
      <p className="c21">
        <span className="c14 c36">There are several ways to manage cookies.</span>
        <span className="c7">
          &nbsp;Please check your browser and browser settings to determine where these types of cookies are stored and
          whether and how they may be deleted. The help portion of the toolbar on most browsers will tell you how to
          prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new
          cookie, or how to disable most cookies altogether. Please note that if cookies are disabled or removed, not
          all features of the services you intend to use (e.g., a request to process a payment) will operate as
          intended.
        </span>
      </p>
      <ol className="c2 lst-kix_eph8wql1h6b2-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c3 c15">Collection and Use of Information from Children</span>
        </li>
      </ol>
      <p className="c21">
        <span className="c7">
          Our Services are not intended for children under the age of eighteen (18). We do not knowingly collect
          Personal Information from children, and our Services are not designed to attract children under the age of
          eighteen (18). In the event that we learn that a child under the age of eighteen (18) has provided Personal
          Information to us, we will delete such Personal Information as soon as possible.
        </span>
      </p>
      <ol className="c2 lst-kix_dhuc164ukyd-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c15 c3">Third Party Links</span>
        </li>
      </ol>
      <p className="c21">
        <span className="c7">
          When you use our Services, there may be links and you may be directed to third party websites. We are not
          responsible for the privacy practices or content of such other sites. If you have any questions about how
          these other sites use your information, you should review their policies and contact them directly.
        </span>
      </p>
      <ol className="c2 lst-kix_r2jpabz98htp-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c15 c3">Opt-Out</span>
        </li>
      </ol>
      <p className="c17">
        <span className="c7">
          At any time you may opt-out from receiving Messages (as defined in the Patient Terms of Use). To opt out,
          please refer to the method of opting out set forth in the Patient Terms of Use, which are incorporated herein
          by reference.
        </span>
      </p>
      <p className="c50">
        <span className="c14 c36">
          PLEASE NOTE THAT IF, AFTER OPTING IN TO RECEIVE MEDBOT MESSAGES, YOU DECIDE TO DISCONTINUE YOUR USE OF THE
          SERVICES, YOU MUST OPT OUT USING THE METHOD DESCRIBED IN THE TERMS OF USE. PLEASE BE ADVISED THAT IF YOU OPT
          OUT OF RECEIVING MESSAGES THROUGH THE MEDBOT MARKETPLACE, YOU HAVE{' '}
        </span>
        <span className="c3 c36">NOT</span>
        <span className="c10">
          &nbsp;OPTED OUT OF RECEVING TEXT OR EMAIL COMMUNICATIONS FROM YOUR HEALTHCARE PROVIDER, SUCH AS FOR
          APPOINTMENT REMINDERS. &nbsp;
        </span>
      </p>
      <ol className="c2 lst-kix_c3agqsgx2rjx-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c15 c3">Security</span>
        </li>
      </ol>
      <p className="c21">
        <span className="c7">
          We implement reasonable security measures to ensure the security of your Personal Information. Please
          understand, however, that no data transmissions over the internet can be guaranteed to be 100% secure.
          Consequently, we cannot ensure or warrant the security of any information you transmit to us and you
          understand that any information that you transfer to us is done at your own risk. If we learn of a breach of
          our security systems, we may attempt to notify you electronically so that you can take appropriate protective
          steps. By using our Services or otherwise providing information to us, you agree that we can communicate with
          you electronically regarding security, privacy and administrative issues relating to your use of the Services.
          We may post a notice via our Services if a security breach occurs. We may also send an email to you at the
          email address you have provided to us in these circumstances. Depending on where you live, you may have a
          legal right to receive notice of a security breach in writing.
        </span>
      </p>
      <ol className="c2 lst-kix_9t8ymepsgk2z-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c15 c3">Assignment</span>
        </li>
      </ol>
      <p className="c21">
        <span className="c7">
          In the event that all or part of our assets are sold or acquired by another party, or in the event of a
          merger, you grant us the right to assign any of your Personal Information collected via the Services.
        </span>
      </p>
      <ol className="c2 lst-kix_abgplnqgnu5h-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c15 c3">Your California Privacy Rights</span>
        </li>
      </ol>
      <p className="c17">
        <span className="c7">From January 1, 2020, many California consumers now have the following rights:</span>
      </p>
      <ul className="c2 lst-kix_wfw93ci0zym3-0 start">
        <li className="c9 li-bullet-0">
          <span className="c6">Right to know.</span>
          <span className="c0">
            &nbsp;You have the right to request information about the categories and specific pieces of Personal
            Information we have collected about you, as well as the categories of sources from which such Personal
            Information is collected, the purpose for collecting such information, and the categories of third parties
            with whom we share such information (please see Sections 1 &ndash; 5 above). You also have the right to
            request information about our sale or disclosure for business purposes of your Personal Information to third
            parties in the preceding 12 months. You may also request a copy of the Personal Information we have
            collected, and upon request, we will provide this information to you in electronic form.
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c6">Right to delete.</span>
          <span className="c0">
            &nbsp;You have the right to request the deletion of your Personal Information, subject to certain
            exceptions.
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c6">Right to opt-out of sale.</span>
          <span className="c0">
            &nbsp;You have the right to opt out of the sale of your Personal Information to third parties. Note that we
            do not and will not sell your Personal Information, other than in its de-identified form (i.e., Deidentified
            Information), as set forth in this Privacy Policy.
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c6">Right to non-discrimination.</span>
          <span className="c0">
            &nbsp;You have the right to not be discriminated against for exercising any of these rights.
          </span>
        </li>
      </ul>
      <p className="c17">
        <span className="c7">
          If you would like to exercise one or more of the rights above, please contact us using the contact information
          provided below. If you are emailing, please write &ldquo;Data Subject Request&rdquo; as your subject line, and
          explicitly state which right you are exercising in the body of the email. You may designate an authorized
          agent to make a request on your behalf. Such authorized agent must be registered with the California Secretary
          of State. We may deny a request from an agent that does not submit proof that they have been authorized by you
          to act on your behalf.
        </span>
      </p>
      <p className="c17">
        <span className="c7">
          Additionally, California Civil Code Section 1798.83 permits users who are California residents to request
          certain information regarding our disclosure of Personal Information to third parties for their direct
          marketing purposes. To make such a request, please email us using the contact information provided below and
          put &ldquo;Shine the Light&rdquo; in the subject line of your request.
        </span>
      </p>
      <p className="c21">
        <span className="c7">
          When submitting a request, we may need to take certain steps to ensure that we are receiving a verifiable
          consumer request before completing your request, and, for example, may ask for you to confirm data points we
          already have about you. We will only use Personal Information provided in a verifiable consumer request to
          verify the requestor&rsquo;s identity or authority to make the request. Whenever feasible for verification, we
          will match the identifying information provided by you to the Personal Information already maintained by us.
          If, however, we cannot verify your identity from the information already maintained by us, we may request
          additional information.
        </span>
      </p>
      <ol className="c2 lst-kix_80k67zimkumr-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c15 c3">Changes to This Privacy Policy</span>
        </li>
      </ol>
      <p className="c17">
        <span className="c7">
          We reserve the right to change this Privacy Policy from time to time. When we do, we will also revise the
          &ldquo;Last Updated&rdquo; date at the top. If we make material changes, we will notify you by placing a
          prominent notice on our Services. We encourage you to periodically review this Privacy Policy to keep up to
          date on how we are handling your Personal Information.
        </span>
      </p>
      <p className="c21 c40">
        <span className="c7"></span>
      </p>
      <ol className="c2 lst-kix_iyjg2hlviy0w-0 start" start="1">
        <li className="c1 li-bullet-0">
          <span className="c10 c28">CONTACT US</span>
        </li>
      </ol>
      <ul className="c2 lst-kix_kqmze6g0hftd-0 start">
        <li className="c30 li-bullet-0">
          <span className="c3">Support</span>
          <span className="c7">: for technical support email: &nbsp;support@medbot.com</span>
        </li>
        <li className="c25 li-bullet-0">
          <span className="c3">Product Returns</span>
          <span className="c7">
            : If you are not satisfied with any purchase made from your Healthcare Provider using the MedBot
            Marketplace, simply email returns@medbot.com or access the Return Request link on the MedBot Patient Portal.
            &nbsp;
          </span>
        </li>
      </ul>
      <p className="c12 c39">
        <span className="c7"></span>
      </p>
      <ul className="c2 lst-kix_7j0jr22mfukd-0 start">
        <li className="c1 li-bullet-0">
          <span className="c3 c28">Privacy Policy</span>
          <span className="c0">
            : If you have any questions, comments or concerns our privacy practices or this Privacy Policy, or to
            exercise any of your rights, please contact us at:
          </span>
        </li>
      </ul>
      <p className="c11">
        <span className="c7">MedBot</span>
      </p>
      <p className="c11">
        <span className="c7">360 Central Avenue Suite 300</span>
      </p>
      <p className="c11">
        <span className="c7">St. Petersburg, FL 33701</span>
      </p>
      <p className="c11">
        <span className="c7">info@medbot.com </span>
      </p>
      <p className="c12">
        <span className="c45"></span>
      </p>
    </div>
  )
})
